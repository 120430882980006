import { useFirebaseContext } from "../Firebase";
import React, { useCallback, useEffect, useState } from "react";
import { tagTypesAsOptions } from "./utils";
import {
  DataTable,
  Icon,
  Pagination,
  Card,
  Button,
  Popover,
  OptionList,
  Page,
} from "@shopify/polaris";
import { Link, useHistory } from "react-router-dom";
import { createEditableDataTableItems } from "../utils/utils.js";
const Tags = () => {
  const { expressFunction } = useFirebaseContext();

  const getTags = expressFunction("getTags");
  const history = useHistory();

  const [list, setList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [tagTypesToShow, setTagTypesToShow] = useState([]);
  const [selected, setSelected] = useState([]);
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );

  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      Filter
    </Button>
  );

  useEffect(() => {
    getTags({}).then((res) => {
      setList(
        createEditableDataTableItems({
          array: res,
          editSlug: "/tags/tag/",
        })
      );
    });
  }, []);

  useEffect(() => {
    getTags({ types: tagTypesToShow }).then((res) => {
      setList(
        createEditableDataTableItems({
          array: res,
          editSlug: "/tags/tag/",
        })
      );
    });
  }, [tagTypesToShow]);
  return (
    <Page
      breadcrumbs={[{ content: "Home", url: "/" }]}
      title="Tags"
      subtitle="Manage Tags for Logic, Users, Orders, and more."
      compactTitle
      primaryAction={{
        content: "Add New",
        onAction: () => history.push("/tags/new", { state: { addNew: true } }),
      }}
      secondaryActions={[
        {
          content: (
            <Popover
              active={popoverActive}
              activator={activator}
              onClose={togglePopoverActive}
            >
              <OptionList
                onChange={setTagTypesToShow}
                options={tagTypesAsOptions.map((item) =>
                  Object.assign({
                    ...item,
                    active: item.value === "bom_tag" ? true : false,
                  })
                )}
                allowMultiple
                selected={tagTypesToShow}
              />
            </Popover>
          ),
        },
      ]}
    >
      <Card>
        <DataTable
          columnContentTypes={[
            "numeric",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
          ]}
          headings={[
            "ID",
            "Title",
            "Description",
            "Active",
            "Created",
            "Updated",
            "Icon",
            "Color",
            "Edit",
          ].filter(Boolean)}
          rows={list}
          footerContent={`Showing ${list.length} items on this page`}
        />
      </Card>
      <div style={{ margin: "20px 0 40px 5px" }}>
        <Pagination
          onPrevious={() => {}} //onPageChange(pageNumber - 1)}
          onNext={() => {}} // onPageChange(pageNumber + 1)}
          plain={false}
          hasPrevious={pageNumber > 1}
          hasNext={totalPage > pageNumber}
        />
      </div>
    </Page>
  );
};

export default Tags;
