/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useEffect, useState } from "react";
import merge from "lodash/merge";
import MuiConfig from 'react-awesome-query-builder/lib/config/mui';
import { SqlString, sqlEmptyValue, mongoEmptyValue, spelEscape, spelFixList } from "react-awesome-query-builder/lib/utils/export";

// import {
//   allEntities,
//   entitySuggest,
//   EntitySuggestions,
//   sessToken,
// } from "../DrupalHelper";
import Select from "react-select";
const InitialConfig = MuiConfig;
import charJson from "../../../../characteristics.json";

import ActionParamsQty from "./ActionParamsQty";
import ActionParamsNotes from './ActionParamsNotes'
//////////////////////////////////////////////////////////////////////

export const stringifyForDisplay = (v) => (v == null ? "NULL" : v.toString());

const generateFields = () => {

  let subfieldObj = {};
  let listValues = [];

  charJson
    .filter((f, k) => k < 170)
    .map((a, b) => {
      Object.assign(subfieldObj, {
        [a.charass_id]: {
          label: a.charass_value,
          type: "number",
          operators: ["equal"],
          valueSources: ["value"],
          preferWidgets: ["number"],
          fieldSettings: {
            min: 0,
            max: 1000,
            hideOperator: true,
          },
        },
      });
    });

  const fields = {
    insert: {
      label: "Insert",
      type: "!group",
      subfields: subfieldObj,
    },
    remove: {
      label: "Remove",
      type: "!group",
      subfields: subfieldObj,
    },
  };

  return fields;
};

const fields = generateFields();
//////////////////////////////////////////////////////////////////////

const conjunctions = {
  AND: InitialConfig.conjunctions.AND,
  OR: InitialConfig.conjunctions.OR,
};

const operators = {
  ...InitialConfig.operators,
  // examples of  overriding
  between: {
    ...InitialConfig.operators.between,
    textSeparators: ["from", "to"],
  },
  in: {
    ...InitialConfig.operators.select_any_in,
    label: "Includes",
    // labelForFormat: "IN",
    // sqlOp: "IN",
    formatOp: function formatOp(
      field,
      op,
      values,
      valueSrc,
      valueType,
      opDef,
      operatorOptions,
      isForDisplay
    ) {
      // console.log(values);
      if (valueSrc == "value") return "".concat(field, " IN (");
      else return "".concat(field, " IN (");
    },
    // sqlFormatOp: function sqlFormatOp(field, op, values, valueSrc, valueType, opDef, operatorOptions) {
    //   return "".concat(field, " IN (").concat(values.join(", "), ")");
    // },
    // mongoFormatOp: mongoFormatOp1.bind(null, "$in", function (v) {
    //   return v;
    // }, false),
    // reversedOp: "select_not_any_in",
    // jsonLogic: "in",
    reversedOp: "notIn",
  },
  notIn: {
    ...InitialConfig.operators.select_not_any_in,
    label: "Does Not Include",
    formatOp: function formatOp(
      field,
      op,
      values,
      valueSrc,
      valueType,
      opDef,
      operatorOptions,
      isForDisplay
    ) {
      // console.log(values);
      if (valueSrc == "value") return "".concat(field, " IN (");
      else return "".concat(field, " IN (");
    },
    reversedOp: "in",
  },
  canIn: {
    ...InitialConfig.operators.select_any_in,
    label: "Can Includes",
    // labelForFormat: "IN",
    // sqlOp: "IN",
    formatOp: function formatOp(
      field,
      op,
      values,
      valueSrc,
      valueType,
      opDef,
      operatorOptions,
      isForDisplay
    ) {
      // console.log(values);
      if (valueSrc == "value") return "".concat(field, " IN (");
      else return "".concat(field, " IN (");
    },
    // sqlFormatOp: function sqlFormatOp(field, op, values, valueSrc, valueType, opDef, operatorOptions) {
    //   return "".concat(field, " IN (").concat(values.join(", "), ")");
    // },
    // mongoFormatOp: mongoFormatOp1.bind(null, "$in", function (v) {
    //   return v;
    // }, false),
    // reversedOp: "select_not_any_in",
    // jsonLogic: "in",
    reversedOp: "canNotIn",
  },
  canNotIn: {
    ...InitialConfig.operators.select_not_any_in,
    label: "Can Not Includes",
    formatOp: function formatOp(
      field,
      op,
      values,
      valueSrc,
      valueType,
      opDef,
      operatorOptions,
      isForDisplay
    ) {
      // console.log(values);
      if (valueSrc == "value") return "".concat(field, " IN (");
      else return "".concat(field, " IN (");
    },
    reversedOp: "canIn",
  },
};

const widgets = {
  ...InitialConfig.widgets,
  // examples of  overriding
  slider: {
    ...InitialConfig.widgets.slider,
    customProps: {
      width: "300px",
    },
  },
  number: {
    ...InitialConfig.widgets.number,
    customProps: {
      width: "200px",
    },
  },
  boolean: {
    ...InitialConfig.widgets.boolean,
    labelYes: "True",
    labelNo: "False",
  },
  rangeslider: {
    ...InitialConfig.widgets.rangeslider,
    customProps: {
      width: "300px",
    },
  },
  date: {
    ...InitialConfig.widgets.date,
    dateFormat: "DD.MM.YYYY",
    valueFormat: "YYYY-MM-DD",
  },
  time: {
    ...InitialConfig.widgets.time,
    timeFormat: "HH:mm",
    valueFormat: "HH:mm:ss",
  },
  select: {
    ...InitialConfig.widgets.select,
  },
  nType: {
    ...InitialConfig.widgets.select,
    factory: function factory(props) {
      return <Select {...props} />;
    },
  },
  qty: {
    ...InitialConfig.widgets.number,
    factory: function factory(props) {
      console.log(props);
      return <div {...props}>Sa HEllo</div>;
    },
  },
  datetime: {
    ...InitialConfig.widgets.datetime,
    timeFormat: "HH:mm",
    dateFormat: "DD.MM.YYYY",
    valueFormat: "YYYY-MM-DD HH:mm:ss",
  },
  treeselect: {
    ...InitialConfig.widgets.treeselect,
    customProps: {
      showSearch: true,
    },
  },
  action_params: {
    type: "object",
    jsType: "object",
    valueSrc: "value",
    valueLabel: "String",
    valuePlaceholder: "Enter string",
    factory: (props) => {
      console.log(props, "factoryProps")
      return <div css={css`
      display: flex;
      position: relative;
      top: 4px;`}>
        <ActionParamsQty {...props} placeholder={props.valuePlaceholderQty} />
        <div style={{ paddingTop: '3px', paddingLeft: '5px' }}><ActionParamsNotes {...props} placeholder={props.valuePlaceholderNotes} /></div>
      </div>
    },
    formatValue: (val, fieldDef, wgtDef, isForDisplay) => {
      console.log(val, 'formatValue')
      return isForDisplay ? stringifyForDisplay(val) : JSON.stringify(val);
    },
    spelFormatValue: (val, fieldDef, wgtDef, op, opDef) => {
      if (opDef.spelOp == "matches" && op != "regex") {
        let regex;
        if (op == "starts_with") {
          regex = `(?s)^${escapeRegExp(val)}.*`;
        } else if (op == "ends_with") {
          regex = `(?s).*${escapeRegExp(val)}$`;
        } else { // op == 'like'
          regex = `(?s).*${escapeRegExp(val)}.*`; //tip: can use (?sui) for case-insensitive
        }
        return spelEscape(regex);
      } else {
        return spelEscape(val);
      }
    },
    sqlFormatValue: (val, fieldDef, wgtDef, op, opDef) => {
      if (opDef.sqlOp == "LIKE" || opDef.sqlOp == "NOT LIKE") {
        return SqlString.escapeLike(val, op != "starts_with", op != "ends_with");
      } else {
        return SqlString.escape(val);
      }
    },
    toJS: (val, fieldSettings) => (val),
    mongoFormatValue: (val, fieldDef, wgtDef) => (val),
  }
};
// console.log(widgets)

const types = {
  ...InitialConfig.types,
  // examples of  overriding
  // text: merge(InitialConfig.types.text, {
  //   widget: {
  //     text: {
  //       operators: [
  //         "greater",
  //         "greater_or_equal",
  //         "less",
  //         "less_or_equal",
  //         "equal",
  //         "not_equal",
  //         "is_empty",
  //         "is_not_empty",
  //       ],
  //     },
  //   },
  // }),
  action_params: {
    // valueSources: ['value', 'field', 'func'],
    defaultOperator: 'equal',
    defaultValue: {
      qty: undefined,
      notes: undefined,
    },
    widgets: {
      action_params: {
        operators: ['equal'],
        widgetProps: {
          valuePlaceholderQty: "Qty",
          valuePlaceholderNotes: "Notes",
          hideOperator: true,
        },
        opProps: {
        },
      },
    },
  },
  select: merge(InitialConfig.types.select, {
    widgets: {
      select: {
        operators: [
          "select_equals",
          "select_not_equals",
          "less",
          "less_or_equal",
          "greater",
          "greater_or_equal",
          "is_empty",
          "is_not_empty",
          "in",
          "notIn",
          "canIn",
          "canNotIn",
        ],
      },
    },
  }),
  number: merge(InitialConfig.types.number, {
    widgets: {
      number: {
        operators: ["equal"],
        defaultOperator: "equal",
        widgetProps: {
          hideOperator: true,
          // showSearch: true,
        },
      },
    },
  }),
  multiselect: merge(InitialConfig.types.multiselect, {
    widgets: {
      multiselect: {
        operators: [
          "select_equals",
          "select_not_equals",
          "less",
          "less_or_equal",
          "greater",
          "greater_or_equal",
          "is_empty",
          "is_not_empty",
          "select_not_any_in",
          "select_any_in",
        ],
      },
    },
  }),
  nType: merge(InitialConfig.types.select, {
    widgets: {
      nType: {
        widgetProps: {
          hideOperator: true,
          showSearch: true,
        },
      },
    },
  }),
  qty: merge(InitialConfig.types.number, {
    widgets: {
      qty: {
        widgetProps: {
          hideOperator: true,
          showSearch: true,
        },
      },
    },
  }),
  boolean: merge(InitialConfig.types.boolean, {
    widgets: {
      boolean: {
        widgetProps: {
          hideOperator: true,
          operatorInlineLabel: "==",
        },
      },
    },
  }),
};

const localeSettings = {
  locale: {
    moment: "ru",
  },
  valueLabel: "Value",
  valuePlaceholder: "Value",
  fieldLabel: "Field",
  operatorLabel: "Operator",
  fieldPlaceholder: "Select item",
  operatorPlaceholder: "Select operator",
  deleteLabel: null,
  addGroupLabel: "Add group",
  addRuleLabel: "Add rule",
  delGroupLabel: null,
  notLabel: "Not",
  canLeaveEmptyGroup: false,
  shouldCreateEmptyGroup: false,
  valueSourcesPopupTitle: "Select value source",
  removeRuleConfirmOptions: {
    title: "Are you sure delete this rule?",
    okText: "Yes",
    okType: "danger",
  },
  removeGroupConfirmOptions: {
    title: "Are you sure delete this group?",
    okText: "Yes",
    okType: "danger",
  },
};

const settings = {
  ...InitialConfig.settings,
  ...localeSettings,

  valueSourcesInfo: {
    value: {
      label: "Value",
    },
    field: {
      label: "Field",
      widget: "field",
    },
    func: {
      label: "Function",
      widget: "func",
    },
  },
  // canReorder: false,
  // canRegroup: false,
  // showNot: false,
  // showLabels: true,
  maxNesting: 5,
  canLeaveEmptyGroup: false, //after deletion

  // renderField: (props) => <FieldCascader {...props} />,
  // renderOperator: (props) => <FieldDropdown {...props} />,
  // renderFunc: (props) => <FieldSelect {...props} />,
};

const funcs = {
  LOWER: {
    label: "Lowercase",
    mongoFunc: "$toLower",
    jsonLogic: "toLowerCase",
    jsonLogicIsMethod: true,
    returnType: "select",
    args: {
      str: {
        label: "String",
        type: "select",
        valueSources: ["value", "field"],
      },
    },
  },
};

const config = {
  conjunctions,
  operators,
  widgets,
  types,
  settings,
  fields,
  funcs,
};

export default config;
