import { Button } from "@shopify/polaris";
import { useState } from "react";
import { CameraFill, CloudUpload } from "react-bootstrap-icons";

const CameraField = ({ value, onChange }) => {
  // const [imageBase64, setImageBase64] = useState("");

  const iconSize = 100;

  return (
    <>
      {/* {imageBase64.length !== 0 && } */}
      <Button
        onClick={() => document.getElementById("cameraField").click()}
        disabled={value.length !== 0}
      >
        {value.length === 0 ? (
          <CameraFill size={iconSize} />
        ) : (
          <CloudUpload size={iconSize} />
        )}
      </Button>
      <input
        id="cameraField"
        type="file"
        accept="image/*"
        capture="camera"
        onChange={(e) => {
          //convert file to base64 string
          e.target.files[0].arrayBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: "image/jpeg" });
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => {
              const base64data = reader.result;
              onChange(base64data);
            };
          });
        }}
        style={{ display: "none" }}
      />
    </>
  );
};

export default CameraField;
