import {
  ChoiceList,
  TextField,
  RangeSlider,
  Card,
  Stack,
  ResourceList,
  Filters,
  Avatar,
  Box,
  Text,
} from "@shopify/polaris";
import Tag from "../../Commons/Tag";
import { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { useHistory, useRouteMatch } from "react-router-dom";

import { getSymDifference } from "../../utils/utils";
import { useIsMount } from "../Todos/utils";
function arr_diff(a1, a2) {
  var a = [],
    diff = [];

  for (var i = 0; i < a1.length; i++) {
    a[a1[i]] = true;
  }

  for (var i = 0; i < a2.length; i++) {
    if (a[a2[i]]) {
      delete a[a2[i]];
    } else {
      a[a2[i]] = true;
    }
  }

  for (var k in a) {
    diff.push(k);
  }

  return diff;
}

const OrderHistory = (props) => {
  const { taskHistory, author, orderNumber, createdDate, lines } = props;
  const isMount = useIsMount();
  // useEffect(() => {

  // }, [])

  const history = useHistory();
  const match = useRouteMatch();

  const [historyItems, setHistoryItems] = useState(taskHistory);
  // const [line, setLine] = useState(null);
  // const [queryValue, setQueryValue] = useState(null);
  const initFilters = {
    title: "",
    coitem_id: null,
  };
  const [historyFilters, setHistoryFilters] = useState(initFilters);

  useEffect(() => {
    setHistoryItems(taskHistory);
  }, [taskHistory]);

  useEffect(async () => {
    if (!isMount) {
      // perform filtering here to filter ResourceItems...
      // const x = searchBomLine({ coitem_id: coItemId, ...taskFilters });

      // const x = await getTaskHistory(historyFilters);
    }
  }, [historyFilters]);

  const handleLineChange = useCallback(
    (value) =>
      setHistoryFilters((prevState) => ({ ...prevState, coitem_id: value })),
    []
  );

  const handleFiltersQueryChange = useCallback(
    (value) =>
      setHistoryFilters((prevState) => ({ ...prevState, title: value })),
    []
  );
  const handleLineRemove = useCallback(() => {
    setHistoryFilters((prevState) => ({ ...prevState, coitem_id: null }));
  }, []);

  const handleQueryValueRemove = useCallback(
    () => setHistoryFilters((prevState) => ({ ...prevState, title: "" })),
    []
  );
  const handleFiltersClearAll = useCallback(() => {
    setHistoryFilters(initFilters);
  }, [handleLineRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "line",
      label: "Line",
      filter: (
        <ChoiceList
          title="Line"
          titleHidden
          choices={lines
            .filter((f) => f.tasks.length)
            .map((item) =>
              Object.assign({
                label: `Line ${item.coitem_linenumber} - ${item.coitem_custpn}`,
                value: item.coitem_id,
              })
            )}
          // selected={historyFilters.coitem_id}
          onChange={handleLineChange}
          // allowMultiple
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(historyFilters.coitem_id)) {
    const key = "line";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, historyFilters.coitem_id),
      onRemove: handleLineRemove,
    });
  }

  return (
    <div>
      <ResourceList
        filterControl={
          <Filters
            queryValue={historyFilters.title}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
        }
        items={historyItems}
        renderItem={(item) => {
          const {
            id,
            task_id,
            user_id,
            user,
            created,
            coitem_id,
            qty_pulled,
            qty_total,
            previous_tags,
            current_tags,
            tags,
            prev_tags,
            name,
          } = item;

          const media = <Avatar customer size="medium" />;

          const a = new Set(previous_tags.map((i) => i.value));
          const b = new Set(current_tags.map((i) => i.value));

          const allTags = [...previous_tags, ...current_tags];
          const changedTags = getSymDifference(
            previous_tags.map((i) => i.value),
            current_tags.map((i) => i.value)
          );

          const diffTags = previous_tags.length > current_tags.length;

          const changedTagsArr = allTags.filter((f) =>
            changedTags.includes(f.value)
          );

          const OrderHistoryTags = () => {
            if (!changedTagsArr.length) return null;
            return (
              <Stack>
                <Text>{diffTags ? `Removed tag` : `Added tag`} </Text>
                {changedTagsArr.map((i) => (
                  <Tag {...i} value={i.label} />
                ))}{" "}
              </Stack>
            );
          };
          return (
            <ResourceList.Item
              // id={}
              onClick={() =>
                history.push(
                  `/order/${match.params.orderNumber}/bom/${coitem_id}/${task_id}`
                )
              }
              media={media}
            >
              <Box paddingBlockEnd="2">
                <Text as="p" variant="bodySm" color="subdued">
                  {user && `${user.name},`} {moment(created).fromNow()}
                </Text>
              </Box>
              <Box paddingBlockEnd="2">
                <Text as="h3" variant="headingXs" fontWeight="">
                  {name}
                </Text>
              </Box>

              <OrderHistoryTags />
              <Text as="p" variant="bodyMd" fontWeight="">
                {qty_pulled && `Pulled ${qty_pulled} of ${qty_total}`}
              </Text>
            </ResourceList.Item>
          );
        }}
      />
    </div>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "line":
        return value.map((val) => `Line ${val}`).join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};

export default OrderHistory;
