import {Document as RP_Document, Page as RP_Page} from "react-pdf";
import React, {useEffect, useState} from "react";
// import {FirebaseContext} from "../Firebase";
import {pdf} from "@react-pdf/renderer";
import BockBox from "./LabelDesigns/BockBox";
import pdf2base64 from "pdf-to-base64";
import QRCode from "qrcode";
import BockFixture from "./LabelDesigns/BockFixture";
import LSIBox from "./LabelDesigns/LSIBox";
import LSIFixture from "./LabelDesigns/LSIFixture";
// import moment from "moment";

const LabelPreview = ({
                          orderNumber,
                          order,
                          partNumber,
                          company,
                          maxWattage,
                          qty,
                          fixtureType,
                          notes,
                          needFixtureLabel,
                          needBoxLabel,
                          lampType,
                          UL_Listed,
                          ratingLocation,
                          returnBoxLabel,
                          returnFixtureLabel,
                      }) => {


    const labelDetails = {
        box: {
            shipToName: order.cohead_shiptoname,
            shipToAddress1: order.cohead_shiptoaddress2,
            shipToAddress2: order.cohead_shiptoaddress3,
            shipToCity: order.cohead_shiptocity,
            shipToState: order.cohead_shiptostate,
            shipToZipCode: order.cohead_shiptozipcode,
            orderNumber: orderNumber,
            purchaseOrder: order.cohead_custponumber,
            partNumber: partNumber,
            type: fixtureType,
            qty: qty,
            notes: notes

        },
        fixture: {
            orderNumber: orderNumber,
            lampType: lampType,
            ratingLocation:ratingLocation,
            maxWattage: maxWattage,
            partNumber: partNumber,
            UL_Listed: UL_Listed
        }
    }


    // alert(maxWattage)
    // const {getFunction} = useContext(FirebaseContext)

    // const printLabel = getFunction('printLabel')

    const [pdfBoxLabel, set_pdfBoxLabel] = useState('')
    const [pdfFixtureLabel, set_pdfFixtureLabel] = useState('')


    useEffect(() => {


        // const dateFormated = moment(order.cohead_created['_seconds'], 'X').format('M/D/YYYY hh:mm:ss A')
        // const dateFormated = moment(order.cohead_created['_nanoseconds'], 'X').format('yyyy-mm-DD HH:mm:ss.SSS')
        // console.log(dateFormated)
        // const order_md5 = MD5(`${order.cohead_number}${order.cohead_cust_id}${order.cohead_salesrep_id}${dateFormated}`)
        // const date = new moment(order.cohead_created['_seconds'],'X')
        // console.log(order_md5)
        // alert(`${order.cohead_number},${order.cohead_cust_id},${order.cohead_salesrep_id},${order.cohead_created}`)
        const QRCodeURL = `https://bock.lighting/r/o/${orderNumber}/`
        needBoxLabel && QRCode.toDataURL(QRCodeURL).then(QR => {
            pdf(
                company === 'bock'
                    ?
                    <BockBox qrCode={QR} labelDetails={labelDetails}/>
                    :
                    <LSIBox qrCode={QR} labelDetails={labelDetails}/>
            ).toBlob().then(r => {
                // console.log(r.toString('base64'))
                const uRl = URL.createObjectURL(r)
                // const pdf2base64 = require('pdf-to-base64');
                if (returnBoxLabel) {
                    pdf2base64(uRl).then(r => {
                        returnBoxLabel(r)
                        // alert(r)
                    })
                    // alert('errr')
                }
                set_pdfBoxLabel(uRl)
            })
        })
        needFixtureLabel && pdf(company === 'bock' ?
            <BockFixture labelDetails={labelDetails}/> :
            <LSIFixture labelDetails={labelDetails}/>
        ).toBlob().then(r => {
            // console.log(r.toString('base64'))
            const uRl = URL.createObjectURL(r)
            // const pdf2base64 = require('pdf-to-base64');
            returnFixtureLabel && pdf2base64(uRl).then(r => returnFixtureLabel(r))
            set_pdfFixtureLabel(uRl)
        })


    }, [orderNumber, order, partNumber, company, maxWattage, qty, needBoxLabel, needFixtureLabel, fixtureType, lampType, notes, UL_Listed, ratingLocation])

    return (
        <div>
            {needFixtureLabel && pdfFixtureLabel.length && <div style={{width: '400px'}}>
                <h2>Fixture Label</h2>
                {needFixtureLabel && <RP_Document
                    file={pdfFixtureLabel}
                    // style={{width:'200px'}}
                    renderMode={'svg'}
                    // externalLinkTarget={'_self'}
                >
                    <RP_Page pageNumber={1} width={'398'}/>
                </RP_Document>}
            </div>}
            {needBoxLabel && pdfBoxLabel.length && <div style={{width: '400px'}}>
                <h2>Box Label</h2>
                <RP_Document
                    file={pdfBoxLabel}
                    // style={{width:'200px'}}
                    renderMode={'svg'}
                    // externalLinkTarget={'_self'}
                >
                    <RP_Page pageNumber={1} width={'398'}/>
                </RP_Document>
            </div>}
        </div>
    )
}

export default LabelPreview