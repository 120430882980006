import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

const ActionParamsNotes = (props) => {
    const { value, setValue, config, readonly, placeholder, customProps, maxLength, maxRows, fullWidth } = props;
    const { defaultMaxRows } = config.settings;
    const [shownValue, setShownValue] = useState(value.notes || "")
    const onChange = e => {
        let val = e.target.value;
        if (val === "")
            val = undefined; // don't allow empty value
        setShownValue(val)
    };

    const textValue = value.notes || "";

    return (
        <FormControl fullWidth={fullWidth}>
            <TextField
                variant="standard"
                fullWidth={fullWidth}
                maxRows={maxRows || defaultMaxRows}
                onChange={onChange}
                // multiline={true}
                value={shownValue}
                placeholder={!readonly ? placeholder : ""}
                InputProps={{
                    readOnly: readonly,
                }}
                inputProps={{
                    onBlur: () => setValue({ ...value, notes: shownValue }),
                    maxLength: maxLength,

                }}
                disabled={readonly}
                // onChange={onChange}
                size="small"
                {...customProps}
            />
        </FormControl>
    );
}

export default ActionParamsNotes