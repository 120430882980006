import { Container, Row } from "react-bootstrap";
import Header from "./Header";

//use when dont eant to lazy load
import AdminRouting from "./AdminRouting";

import React, { lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Helmet } from "react-helmet";
// import Safe from "react-safe";

import Login from "./Login";
import AutoLogin from "./AutoLogin";
// import Register from "./Register";
import { useFirebaseContext } from "./Firebase";
// import {deleteCookie, setCookie} from "../Helpers/cookies";
import QRScanner from "./QRScanner";
import AirTable from "./AirTable";
import { TextContainer, Heading } from "@shopify/polaris";
// import BarcodeRedirect from "./BarcodeRedirect";
import OrdersListPage from "./OrdersListPage";
import BomLogicPage from "./BomLogic";

const Page = () => {
  const FirebaseContext = useFirebaseContext();
  console.log(FirebaseContext.user);

  // useEffect(() => {
  //   const script1 = document.createElement("script");
  //   const script2 = document.createElement("script");
  //   script1.src = "https://chat-assets.frontapp.com/v1/chat.bundle.js";
  //   document.body.appendChild(script1);

  //   return () => {
  //     document.body.removeChild(script1);
  //     document.body.removeChild(script2);
  //   };
  // }, []);
  /*
    keeps failing, after loggin in, reload the expected script multiple times until ready.
    https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5
     */

  function retry(fn, retriesLeft = 5, interval = 1000) {
    return new Promise((resolve, reject) => {
      fn()
        .then(resolve)
        .catch((error) => {
          setTimeout(() => {
            if (retriesLeft === 1) {
              // reject('maximum retries exceeded');
              reject(error);
              return;
            }

            // Passing on "reject" is the important part
            retry(fn, retriesLeft - 1, interval).then(resolve, reject);
          }, interval);
        });
    });
  }

  // Use this for lazy loading. alt is import on the top of the file
  // const AdminRouting = FirebaseContext.user ? lazy(() => retry(() => {
  //     // // console.log(FirebaseContext.user)
  //     // // console.log(FirebaseContext.user.multiFactor.user)
  //     // setCookie(cookieName, FirebaseContext.user.multiFactor.user.accessToken)
  //     // TODO: make it so that there's a fallback if there user is not logged in.
  //     const returnCode = import(/* webpackChunkName: "AdminRouting" */ './AdminRouting.jsx')
  //     return returnCode
  // })) : 'div';
  useEffect(() => {
    setTimeout(() => {
      // window.FrontChat("init", {
      //   chatId: "c33fc0107723b468c2239eb9701388d8",
      //   useDefaultLauncher: true,
      // });
    }, 1000);
  }, []);

  return (
    <>
      <Helmet>
        {/* <script src="https://chat-assets.frontapp.com/v1/chat.bundle.js" /> */}
      </Helmet>
      <Container className="bockProduction_Container" fluid>
        {FirebaseContext.user && (
          <Route exact path={"/"}>
            <QRScanner />
            <p>.</p>
            <OrdersListPage />
          </Route>
        )}
        {FirebaseContext.user && (
          <Route
            exact
            path={"/autologin"}
            component={() => <Redirect to={"/"} />}
          />
        )}
        <Route path={"/login"} component={Login} />
        <Route path={"/airTable"} component={AirTable} />
        <Route exact path={"/autologin"} component={AutoLogin} />
        <Route
          exact
          path={"/logout"}
          component={() => {
            FirebaseContext.logOut();
            return <Redirect to={"/"} />;
          }}
        />
        {/* {!FirebaseContext.user && <Route exact path={"/"} component={Login} />} */}
        {FirebaseContext.user && (
          <Suspense fallback={<></>}>
            <AdminRouting />
          </Suspense>
        )}
        {/*{FirebaseContext.user === null && document.location && !['/login', '/register'].includes(document.location.pathname) && <Redirect to={'/login'} />}*/}
      </Container>
    </>
  );
};

export default Page;
