import { Card, OptionList } from "@shopify/polaris";
import { useState } from "react";

const SubscriptionType = (props) => {
  const { title, options, selected, onChange } = props;

  return (
    <Card>
      <OptionList
        title={`Subscribe to ${title} tagged with:`}
        onChange={onChange}
        options={options}
        selected={selected}
        allowMultiple
      />
    </Card>
  );
};
export default SubscriptionType;
