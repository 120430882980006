/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Badge, Icon } from "@shopify/polaris";
import * as polarisIcons from "@shopify/polaris-icons";
import { transformHexToDarkerOrLighter } from "../utils/colorTransformer";
const Tag = (props) => {
  const { value, onRemove, color, icon, large } = props;

  let paddingLeft = `8px`,
    paddingRight = `8px`;

  if (icon) paddingLeft = `4px`;
  if (large) paddingLeft = `10px`;
  if (large && !icon) paddingLeft = `15px`;

  if (onRemove) paddingRight = `3px`;
  if (large) paddingRight = `15px`;

  return (
    <div
      css={css`
        &&& {
          .Polaris-Badge {
            padding-top: ${large ? `10px` : `var(--pc-badge-vertical-padding)`};
            padding-bottom: ${large
              ? `10px`
              : `var(--pc-badge-vertical-padding)`};
            background-color: ${color ? color : `var(--p-surface-neutral)`};
            padding-left: ${paddingLeft};
            padding-right: ${paddingRight};
            box-shadow: ${large ? `0 3px 1px rgb(0 0 0 / 5%)` : `none`};
            border: ${large ? `1px solid rgba(0,0,0,.03)` : `none`};
          }
          .Polaris-Text--root {
            display: flex;
            align-items: center;
            color: ${color
              ? transformHexToDarkerOrLighter(color, -5, true)
              : `rgba(0,0,0,.8)`};
            font-size: ${large ? `14px` : `var(--p-font-size-100)`};
            font-weight: ${large
              ? `var(--p-font-weight-semibold)`
              : `var(--p-font-weight-regular)`};
          }
        }
      `}
    >
      <Badge>
        {icon && (
          <div
            css={css`
              &&& {
                .Polaris-Icon {
                  display: flex;
                  align-items: center;
                  width: auto;
                  height: auto;
                  padding-left: 3px;
                  margin-right: ${large ? `8px` : `3px`};
                }
                svg {
                  width: ${large ? `15px` : `10px`};
                  height: ${large ? `15px` : `10px`};
                  path {
                    fill: ${color
                      ? transformHexToDarkerOrLighter(color, -5, true)
                      : `rgba(0,0,0,.8)`};
                  }
                }
              }
            `}
          >
            <Icon source={polarisIcons[icon]} />
          </div>
        )}
        {value}
        {onRemove && (
          <div
            onClick={onRemove}
            css={css`
              &&& {
                .Polaris-Icon {
                  position: relative;
                  top: 0;
                  width: auto;
                  height: auto;
                  margin-left: 5px;
                  border: 1px solid rgba(0, 0, 0, 0.4);
                  border-radius: 50px;
                  cursor: pointer;
                  width: 14px;
                  height: 14px;
                  &:hover {
                    background: rgba(0, 0, 0, 0.1);
                    border-color: rgba(0, 0, 0, 0.01);
                  }
                }
                svg {
                  width: 14px;
                  height: 14px;
                  path {
                    fill: rgba(0, 0, 0, 0.4);
                  }
                }
              }
            `}
          >
            <Icon source={polarisIcons["CancelMinor"]} />
          </div>
        )}
      </Badge>
    </div>
  );
};
export default Tag;
