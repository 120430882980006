/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState, useContext, useEffect } from "react";
import { useFirebaseContext } from "../Firebase";
import {
  FullscreenBar,
  Box,
  Page,
  Text,
  Card,
  Stack,
  Thumbnail,
} from "@shopify/polaris";
import { NoteMinor } from "@shopify/polaris-icons";
import BomLineItemAction from "../OrderPage/Todos/BomLineItemAction";
import { Link } from "react-router-dom";
import Tag from "../Commons/Tag";
import ToastContext from "../../Context/Toast/ToastContext";
const Tasks = (props) => {
  const { expressFunction } = useFirebaseContext();
  const getMySubscriptionTags = expressFunction("getMySubscriptionTags");
  const getMyTasks = expressFunction("getMyTasks");
  const getMyTasksByTag = expressFunction("getMyTasksByTag");
  const updateBomItemPulledStatus = expressFunction(
    "updateBomItemPulledStatus"
  );
  const { pushNewToast } = useContext(ToastContext);
  const [myTags, setMyTags] = useState([]);
  const [myTasks, setMyTasks] = useState([]);
  useEffect(async () => {
    const res = await getMySubscriptionTags();

    if (res?.data) setMyTags(res.data);
  }, []);

  useEffect(async () => {
    const x = await getMyTasksByTag();
    setMyTasks(x.data);
  }, [myTags]);

  const itemPulled = async (quantityPulled, task) => {
    const { bom_item_id, task_id, qty_pulled, qty_total } = task;

    const newQuantity = quantityPulled + qty_pulled;

    const buildObj = {
      id: bom_item_id,
      task_id: task_id,
      qty_pulled: newQuantity,
    };
    // try {
    const data = await updateBomItemPulledStatus(buildObj);

    console.log(data, awaitUpdate, "data");
    if (data) {
      if (data.qty_total === data.qty_pulled)
        pushNewToast({ message: `All items pulled for ${task.name}.` });
      else pushNewToast({ message: `Pulled ${qty_total}x  ${task.name}.` });

      const x = myTasks.find((i) =>
        i.tasks.find((f) => f.task_id === data.task_id)
      );

      const newTasks = [...myTasks];

      newTasks.map((i, k) => {
        if (i.id === x.id) {
          const newTaskTasks = [...i.tasks];

          newTaskTasks.map((i, k) => {
            if (i.task_id === data.task_id) {
              newTaskTasks[k] = data;
            }
          });
          newTasks[k] = {
            ...i,
            tasks: newTaskTasks,
          };
        }
      });

      console.log(newTasks, "newTasks");
      setMyTasks(newTasks);
    } else {
      pushNewToast({ message: "Something failed.", error: true });
    }
    return data;
    // } catch (e) {
    //   pushNewToast({ message: "Something failed.", error: true });
    // }

    // const response = await setItemPulledQty(bomItemId, task_id, quantityPulled);
    // return response;
  };

  return (
    <>
      <div
        css={css`
          z-index: 0;
          position: relative;
          min-height: 100vh;
          margin: calc(-1 * var(--p-space-5)) calc(-1 * var(--p-space-6));
        `}
      >
        <div
          css={css`
            position: fixed;
            border-bottom: var(--p-border-divider);
            width: 100%;
            z-index: 1;
            background-color: var(--p-background);
          `}
        >
          <Box
            paddingBlockStart="4"
            paddingBlockEnd="4"
            paddingInlineStart="6"
            paddingInlineEnd="6"
          >
            <Text variant="headingMd">My Tasks</Text>
            <Text variant="bodyMd" color="subdued">
              Viewing all tasks assigned to you
            </Text>
          </Box>
        </div>
        <div
          css={css`
            height: 130px;
          `}
        />
        <Box paddingInlineStart="6" paddingInlineEnd="6">
          <Stack wrap={false}>
            {myTasks.map((item, k) => {
              return (
                <div
                  css={css`
                    @media (min-width: 993px) {
                      width: 300px;
                      margin-right: ${k === myTasks.length - 1
                        ? "var(--p-space-10)"
                        : "0"};
                    }
                  `}
                >
                  <Box paddingBlockEnd="6">
                    <Stack alignment="center">
                      <Tag
                        large
                        value={
                          <>
                            {item.shortname}{" "}
                            <span
                              css={css`
                                &&& {
                                  margin-left: 10px;
                                  font-size: 10px;
                                  font-weight: 500;
                                }
                              `}
                            >
                              ({item.tasks.length} Tasks)
                            </span>
                          </>
                        }
                        color={item.color}
                        icon={item.icon}
                      />
                    </Stack>
                  </Box>
                  {item.tasks.map((task, t) => {
                    const {
                      task_id,
                      qty_total,
                      qty_pulled,
                      thumbnail,
                      coitem_id,
                      cohead_number,
                      bom_item_id,
                    } = task;
                    return (
                      <>
                        <Card
                          title={
                            <Stack>
                              {" "}
                              <Thumbnail
                                source={thumbnail || NoteMinor}
                                size="medium"
                                alt="Black choker necklace"
                              />
                              <Stack vertical spacing="extraTight">
                                <Text variant="headingXs">{task.name}</Text>
                                <Text variant="bodySm">
                                  <Link
                                    to={`/order/${cohead_number}/bom/${coitem_id}/${task_id}`}
                                    target="_blank"
                                  >
                                    Order #{cohead_number}
                                  </Link>
                                </Text>
                              </Stack>
                            </Stack>
                          }
                        >
                          <Card.Section>
                            <Text variant="bodyMd" color="subdued">
                              {task.item_description}
                            </Text>
                            {task.notes !== "" && (
                              <Text variant="bodyMd" color="subdued">
                                {task.notes}
                              </Text>
                            )}
                          </Card.Section>
                          <Card.Section subdued>
                            <Stack distribution="trailing" alignment={"center"}>
                              <div
                                css={css`
                                  margin-left: auto;
                                `}
                              >
                                {qty_pulled} pulled of {qty_total}
                              </div>
                              <BomLineItemAction
                                qty_total={qty_total}
                                qty_pulled={qty_pulled}
                                setPulledQty={(qtyPulled) =>
                                  itemPulled(qtyPulled, task)
                                }
                              />
                            </Stack>
                          </Card.Section>
                        </Card>
                      </>
                    );
                  })}
                  {item.tasks.length === 0 && (
                    <>
                      <div
                        css={css`
                          background-color: rgba(0, 0, 0, 0.03);
                          height: 100%;
                          width: 100%;
                          min-height: 100vh;
                          border-radius: var(--p-border-radius-2);
                        `}
                      ></div>
                    </>
                  )}
                </div>
              );
            })}
          </Stack>
        </Box>
      </div>
    </>
  );
};

export default Tasks;
