import { useFirebaseContext } from "../Firebase";
import React, { useContext, useEffect, useState, useCallback } from "react";
import {
  Card,
  Checkbox,
  TextField,
  Form,
  FormLayout,
  Inline,
  Page,
  Tabs,
  SettingToggle,
} from "@shopify/polaris";

import { useHistory } from "react-router-dom";
import { tagTypesAsOptions } from "./utils";
import IconSelect from "./Form/IconSelect";
import ColorPicker from "./Form/ColorPicker";
import ToastContext from "../../Context/Toast/ToastContext";

const AddNewTag = (props) => {
  const { getFunction, reactFunction, expressFunction } = useFirebaseContext();

  const [selected, setSelected] = useState(0);
  const [bomLogicLoaded, setBomLogicLoaded] = useState(false);

  const { pushNewToast } = useContext(ToastContext);
  const [loading, setLoading] = useState(true);
  const [formValue, setFormValue] = useState({
    description: "",
    shortname: "",
    active: true,
    bom_tag: false,
    user_tag: false,
    task_tag: false,
    order_tag: false,
    item_tag: false,
    char_tag: false,
    charass_tag: false,
    woorder_tag: false,
    icon: null,
    color: null,
    subscriptions: {
      bom_tag: false,
      user_tag: false,
      task_tag: false,
      order_tag: false,
      item_tag: false,
      char_tag: false,
      charass_tag: false,
      woorder_tag: false,
    },
  });
  const [formValueBomTag, setFormValueBomTag] = useState({
    logic: {},
  });

  const history = useHistory();

  // const contentStatus = formValue.active ? "Deactivate" : "Activate";
  // const textStatus =  formValue.active ? "activated" : "deactivated";

  const createBomTag = expressFunction("createBomTag");
  const createTag = expressFunction("createTag");
  const getTag = expressFunction("getTag");
  const editTag = expressFunction("editTag");
  const [items, setItems] = useState([
    [1, "test"],
    [2, "test"],
    [3, "test"],
  ]);
  const addBomLogicTag = getFunction("addBomLogicTag");
  const updateBomLogicTag = getFunction("updateBomLogicTag");
  const getBomLogicTag = getFunction("getBomLogicTag");
  // alert(props?.location?.pathname)
  const [isEdit, setIsEdit] = useState(
    props?.location?.pathname !== "/tags/new"
  );

  const [options, setOptions] = useState([
    { value: false, label: "False" },
    { value: true, label: "True" },
  ]);

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );

  const EditTag = () => {
    if (
      true
      //&& (isEdit && !loading) || !isEdit
    )
      return <></>;
    return "Loading";
  };

  const setSubscription = (type, bool) => {
    setFormValue((prevState) => ({
      ...prevState,
      subscriptions: { ...prevState.subscriptions, [type]: bool },
    }));
  };

  useEffect(() => {
    if (tabList.filter((f) => f.visible === true)[selected].id === "bom_tag") {
      // getBomTag()
    }
  }, [selected]);
  const tabs = [
    {
      id: "edit-tag",
      content: "Edit Tag",
      component: (
        <div style={{ margin: "20px 0", maxWidth: "700px" }}>
          <Form>
            <FormLayout>
              <TextField
                value={formValue.shortname}
                onChange={(value) =>
                  setFormValue((v) => ({ ...v, shortname: value }))
                }
                label="Shortname"
                type="text"
              />
              <TextField
                value={formValue.description}
                onChange={(value) =>
                  setFormValue((v) => ({ ...v, description: value }))
                }
                label="Description"
                multiline={4}
              />
              <div>
                <Inline>
                  <Checkbox
                    label="BOM"
                    checked={formValue.bom_tag}
                    onChange={(value) =>
                      setFormValue((v) => ({ ...v, bom_tag: value }))
                    }
                  />
                  <Checkbox
                    label="User"
                    checked={formValue.user_tag}
                    onChange={(value) =>
                      setFormValue((v) => ({ ...v, user_tag: value }))
                    }
                  />
                  <Checkbox
                    label="Task"
                    checked={formValue.task_tag}
                    onChange={(value) =>
                      setFormValue((v) => ({ ...v, task_tag: value }))
                    }
                  />
                  <Checkbox
                    label="Order"
                    checked={formValue.order_tag}
                    onChange={(value) =>
                      setFormValue((v) => ({ ...v, order_tag: value }))
                    }
                  />
                  <Checkbox
                    label="Item"
                    checked={formValue.item_tag}
                    onChange={(value) =>
                      setFormValue((v) => ({ ...v, item_tag: value }))
                    }
                  />
                  <Checkbox
                    label="Char"
                    checked={formValue.char_tag}
                    onChange={(value) =>
                      setFormValue((v) => ({ ...v, char_tag: value }))
                    }
                  />
                  <Checkbox
                    label="Charass"
                    checked={formValue.charass_tag}
                    onChange={(value) =>
                      setFormValue((v) => ({ ...v, charass_tag: value }))
                    }
                  />
                  <Checkbox
                    label="Work Order"
                    checked={formValue.woorder_tag}
                    onChange={(value) =>
                      setFormValue((v) => ({ ...v, woorder_tag: value }))
                    }
                  />
                </Inline>
              </div>
              <ColorPicker
                value={formValue.color}
                updateValue={(v) =>
                  setFormValue((prevState) => ({ ...prevState, color: v }))
                }
              />
              <IconSelect
                value={formValue.icon}
                updateValue={(v) =>
                  setFormValue((prevState) => ({ ...prevState, icon: v }))
                }
              />
            </FormLayout>
          </Form>
        </div>
      ),
      panelID: "edit-tag-content",
      visible: true,
    },
  ];
  tagTypesAsOptions.map((item, k) => {
    const Component = () => {
      // if (item.value === "bom_tag") {
      //   return (
      //       <LogicBuilderWrapper
      //         token={token}
      //         // data={data?.field_json?.current_value}
      //         // register={register}
      //         // setValue={setValue}
      //         // setStateResult={(v) => setResult({ ...result, bomLogic: v })}
      //         data={formValue.bomTagData}
      //         setStateResult={(v) => {
      //           setFormValueBomTag((prevValue) => ({
      //             ...prevValue,
      //             logic: v,
      //           }));
      //         }}
      //       //  { ...result, bomLogic: v })}
      //       />
      //   );
      // }
      if (item.value === "task_tag")
        return (
          <>
            <FormLayout>
              <Checkbox
                label="Enable subscriptions"
                checked={formValue.subscriptions?.task_tag || false}
                onChange={(e) => setSubscription("task_tag", e)}
              />
            </FormLayout>
          </>
        );
      if (item.value === "order_tag")
        return (
          <>
            <FormLayout>
              <Checkbox
                label="Enable subscriptions"
                checked={formValue.subscriptions?.order_tag || false}
                onChange={(e) => setSubscription("order_tag", e)}
              />
            </FormLayout>
          </>
        );
      return <>{item.value}</>;
    };

    tabs.push({
      id: item.value,
      content: item.label.replaceAll(" Tag", ""),
      component: <Component />,
      panelID: item.id + "-content",
      visible: false,
    });
  });

  const [tabList, setTabList] = useState(tabs);

  useEffect(() => {
    if (tabList.length) {
      let prevFormValue = JSON.parse(JSON.stringify(formValue));
      let t = Object.keys(formValue).filter(
        (item) => item.includes("_tag") && formValue[item] === true
      );

      let prevTabList = [...tabList];

      prevTabList.map((v) => Object.assign({ ...v, visible: false }));

      let newTabList = tabs;
      newTabList.filter((f) => t.includes(f.id)).map((x) => (x.visible = true));

      setTabList(newTabList);
    }
  }, [formValue]);
  useEffect(async () => {
    if (isEdit) {
      let id = parseInt(props.match.params.tagId);
      let data = await getTag({ id: id });

      setFormValue(data);
      if ("bomTagData" in data) {
        setFormValueBomTag(data.bomTagData);
        setBomLogicLoaded(true);
      }
      setLoading(false);
    } else {
      setBomLogicLoaded(true);
      setLoading(false);
    }
  }, []);
  const submitForm = async (close = false) => {
    if (isEdit) {
      let id = props.match.params.tagId;
      const payload = { id: id, ...formValue };
      if (formValueBomTag) payload.bomLogic = formValueBomTag;

      const res = await editTag(payload);

      if (res) pushNewToast({ message: "Changes saved successfully." });
      else pushNewToast({ message: "An error occured", error: true });
    } else {
      let res = await createTag(formValue);
      if (res) {
        pushNewToast({ message: "Created successfully." });
        history.push(`/tags/tag/${res.tag}`);
      } else pushNewToast({ message: "An error occured", error: true });
    }
    if (close === true) {
      history.push("/tags");
    }
  };

  useEffect(() => {
    setIsEdit(props?.location?.pathname !== "/tags/new");
  }, [props.location]);

  if (loading === true) return "Loading";

  return (
    <Page
      title={
        isEdit
          ? `${formValue.shortname}: Edit  ${
              tabList.filter((f) => f.visible === true)[selected].content ===
              "Edit Tag"
                ? "Tag Settings"
                : "Tag " +
                  tabList.filter((f) => f.visible === true)[selected].content +
                  " Values"
            } `
          : `Creating a new Tag`
      }
      breadcrumbs={[{ content: "Tags", url: "/tags" }]}
      subtitle="Manage Tags for Logic, Users, Orders, and more."
      compactTitle
      primaryAction={{
        content: "Save",
        onAction: () => submitForm(),
      }}
      secondaryActions={[
        {
          content: "Save & Close",
          onAction: () => submitForm(true),
        },
      ]}
    >
      <Card>
        {/* {bomLogicLoaded === true && ( */}
        <Tabs
          tabs={tabList.filter((f) => f.visible === true)}
          selected={selected}
          onSelect={handleTabChange}
        >
          <Card.Section
          // title={tabList.filter((f) => f.visible === true)[selected].content}
          >
            {tabList.filter((f) => f.visible === true)[selected].component}
          </Card.Section>
        </Tabs>
        {/* )} */}
      </Card>

      <div style={{ marginTop: 20 }}>
        <SettingToggle
          action={{
            content: formValue.active ? "Deactivate " : "Activate",
            destructive: formValue.active ? true : false,
            onAction: () =>
              setFormValue((v) => ({
                ...v,
                active: !formValue.active,
              })),
          }}
          enabled={formValue.active}
        >
          This Tag is currently{" "}
          <strong>{formValue.active ? "active" : "inactive"}</strong>.
        </SettingToggle>
      </div>

      {/* {isEdit && (
        <Card>
          <ButtonGroup>
            <Card.Section
              title={`Last edited date:${new Date().toLocaleDateString(
                "pt-PT"
              )}`}
            />
            <Card.Section title="Created date:XXX" />
            <Card.Section title="Last modified by:XXX" />: XXX - ""
          </ButtonGroup>
        </Card>
      )} */}
    </Page>
  );
};

export default AddNewTag;
