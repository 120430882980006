import { Card, DataTable, Layout, Link, Page, Select } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { useFirebaseContext } from "../../Firebase";
import { Engine } from "json-rules-engine";

const FieldBuilder = ({ fieldData, build, setBuild, lockBuild }) => {
  //   console.log(fieldData);
  return (
    <>
      {Object.keys(fieldData).map((row) => (
        <SelectElement
          field={fieldData[row]}
          build={build}
          setBuild={setBuild}
          lockBuild={lockBuild}
        />
      ))}
    </>
  );
};

const SelectElement = ({ field, build, setBuild, lockBuild }) => {
  //   console.log(field);
  const rows = field.charValues.map((row) => {
    return { label: row, value: row };
  });
  rows.unshift({ label: "", value: "" });
  return (
    <>
      <Select
        disabled={lockBuild}
        label={field.charName}
        options={rows}
        labelInline
        onChange={(value) => {
          setBuild({ ...build, [field.charName]: value });
        }}
        value={build[field.charName]}
      />
    </>
  );
};

const RulesInterp = ({ rules, facts }) => {
  const [bom, setBom] = useState([]);
  const { reactFunction, expressFunction } = useFirebaseContext();
  const getItemsList = expressFunction("getItemsList");
  const [itemDB, setItemDB] = useState({});

  useEffect(() => {
    //{ items: bom.map((row) => row.item) }
    getItemsList().then((items) => {
      console.log(items);
      const newItems = {};
      items.forEach((row) => {
        newItems[row.value] = row;
      });
      setItemDB(newItems);
      // setItems(items.data);
    });
    // console.log(itemDB);
  }, []);

  let engine = new Engine([], { allowUndefinedFacts: true });
  useEffect(() => {
    setBom([]);
    engine = new Engine();
    rules.forEach((rule) => {
      // console.log(rule);
      engine.addRule(rule);
    });
    engine.run(facts).then((results) => {
      // console.log("results", results.events);
      results.events.forEach((event) => {
        event.params &&
          event.params.forEach((param) => {
            if (param.action === "addToBom") {
              param.payload &&
                param.payload.forEach((item) =>
                  setBom((bom) => [...bom, item])
                );
              // setBom([...bom, param.payload]);
            }
          });
      });
    });
  }, [rules]);

  const rows = bom.map((row) => {
    const itemName = (itemDB[row.item] && itemDB[row.item].label) || row.item;
    // console.log(itemName);
    return [
      <Link url={`item/${row.item}`}>{itemName}</Link>,
      row.qty,
      row.notes,
    ];
  });
  return (
    <>
      <DataTable
        headings={["Item", "Qty", "Notes"]}
        rows={rows}
        columnContentTypes={["text", "numeric", "text"]}
      />
      {/* {bom.map((row) => {
        console.log("row", row);
        return <div>{row.item}</div>;
      })} */}
    </>
  );
};

const BOMSim = () => {
  const { reactFunction, expressFunction } = useFirebaseContext();
  const [fieldData, setFieldData] = useState({});
  const [build, setBuild] = useState({});
  const [lockBuild, setLockBuild] = useState(false);
  const [Rules, setRules] = useState([]);
  const [facts, setFacts] = useState({});

  const searchByCharsFields = expressFunction("searchByCharsFields");
  const runBOMConfig = expressFunction("runBOMConfig");

  useEffect(() => {
    if (Object.keys(fieldData).length === 0) {
      searchByCharsFields().then((data) => {
        console.log(data, "data");
        setFieldData(data);
        setLockBuild(false);
      });
    }
  }, [fieldData]);

  useEffect(() => {
    setLockBuild(true);
    Object.keys(build).length !== 0 &&
      runBOMConfig({ build, fieldBy: "charName" }).then((data) => {
        setLockBuild(false);
        setFacts(data.buildArtifacts);
        setRules(data.Rules);
        console.log(data.buildArtifacts);
      });
    // setTimeout(() => {
    // }, 1000);
  }, [build]);

  return (
    <Layout>
      <Layout.Section oneThird>
        <Card sectioned title="BOM Simulator">
          <FieldBuilder
            fieldData={fieldData}
            build={build}
            setBuild={setBuild}
            lockBuild={lockBuild}
          />
        </Card>
      </Layout.Section>
      <Layout.Section oneThird>
        <Card title={"Results"} sectioned>
          <RulesInterp rules={Rules} facts={facts} />
        </Card>
      </Layout.Section>
    </Layout>
  );
};

export default BOMSim;
