import { Card, Tabs as PolarisTabs } from '@shopify/polaris';
import { useEffect } from 'react';
import { useState, useCallback } from 'react';
import TabsContainer from './TabsContainer'
function Tabs(props) {
    const { tabs, fitted, flush } = props
    const [selected, setSelected] = useState(0);
    const [tabItems, setTabItems] = useState([])

    const handleTabChange = useCallback(
        (selectedTabIndex) => setSelected(selectedTabIndex),
        [],
    );

    useEffect(() => {
        const createArr = []
        Object.keys(tabs).map((item, k) => {
            const obj = {
                id: item,
                content: tabs[item].title,
                component: tabs[item].content
            }
            createArr.push(obj)
        })
        setTabItems(createArr)

        console.log(tabs, 'tabs')
    }, [tabs])


    if (tabItems.length) return (
        <TabsContainer flush={flush}>
            <PolarisTabs tabs={tabItems} selected={selected} onSelect={handleTabChange} fitted={fitted ? fitted : false}>
                <Card.Section
                // title={tabItems[selected].content}
                >
                    {tabItems[selected].component}
                </Card.Section>
            </PolarisTabs>
        </TabsContainer>
    );
    return "Loading Tabs";
}

export default Tabs