import React, { useEffect, useState } from "react";
import { pdf /*, PDFRenderer*/ } from "@react-pdf/renderer";
import UniversalLabelDesign from "./UniversalLabelDesign";
import pdf2base64 from "pdf-to-base64";
import {
  Document as RP_Document,
  Page as RP_Page,
} from "react-pdf/dist/esm/entry.webpack";
import { Button } from "@shopify/polaris";
import { useFirebaseContext } from "../Firebase";

const UniversalLabelPage = () => {
  const { getFunction } = useFirebaseContext();
  const printLabel = getFunction("printLabel");

  const [searchParams, set_searchParams] = useState({});
  const [pdfBlobURL, set_pdfBlobURL] = useState("");
  const [pdfBase64, set_pdfBase64] = useState("");
  const [sendToPrinter, set_sendToPrinter] = useState(false);

  useEffect(() => {
    if (Object.keys(searchParams).length === 0) {
      const setObj = {};
      const pageURL = new URL(window.location);
      pageURL.searchParams.forEach((value, key) => {
        if (value) {
          setObj[key] = value;
        }
        if (key === "image" && value) {
          const imageURL = value.split("(")[1].split(")")[0];
          setObj["imageURL"] = imageURL;
        }
      });
      set_searchParams(setObj);
    }
    if (pdfBase64.length === 0) {
      pdf(<UniversalLabelDesign params={searchParams} />)
        .toBlob()
        .then((r) => {
          const uRl = URL.createObjectURL(r);
          set_pdfBlobURL(uRl);
          false && window.open(uRl, "_self");
          // const pdf2base64 = require('pdf-to-base64');
          pdf2base64(uRl).then((r) => {
            set_pdfBase64(r);
          });
        });
    }
  }, [searchParams]);

  const printToSharons = () => {
    set_sendToPrinter(true);

    const unviversalPaintOrder = {
      qty: 1,
      type: "unviversalPaintOrders",
      pdf: pdfBase64,
    };

    console.log([unviversalPaintOrder]);

    printLabel([unviversalPaintOrder]).then(() => {
      window.close();
    });
    // setTimeout(() => {
    //     alert(sendToPrinter)
    //     // if(sendToPrinter === true) {
    //     //     set_sendToPrinter(false)
    //     // }
    // }, 1000*1)
  };

  return (
    <>
      {pdfBlobURL.length !== 0 && true && (
        <>
          {/*<a href={pdfBlobURL}>Link</a>*/}
          {/*<p>{pdfBase64}</p>*/}
          <Button disabled={sendToPrinter} onClick={printToSharons}>
            Print & Close
          </Button>
          {/*<Button disabled={false} onClick={() => {window.open(pdfBlobURL,'_blank')}}>Open in New Window</Button>*/}
          <RP_Document
            file={pdfBlobURL}
            // style={{width:'200px'}}
            renderMode={"svg"}
            // externalLinkTarget={'_self'}
          >
            <RP_Page pageNumber={1} width={"600"} />
          </RP_Document>
        </>
      )}
    </>
  );
};

export default UniversalLabelPage;
