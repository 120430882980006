import { ChoiceList } from '@shopify/polaris';
import { useEffect } from 'react';
import { useState, useCallback } from 'react';

function RadioButtons(props) {
    const { options, value, handleChange, label, required } = props
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        if (value) {
            setSelected([value])
        } else {
            setSelected([options[0].value])
        }
    }, [value])

    let fieldLabel = label ? label : ""
    if (required) fieldLabel = fieldLabel + " *"
    return (
        <ChoiceList
            title={fieldLabel}
            choices={options ? options : []}
            selected={selected}
            onChange={handleChange}
        />
    );
}

export default RadioButtons