import { useFirebaseContext } from "../../Firebase";
import React, { useEffect, useState } from "react";
// import moment from "moment";
// import { Link } from "react-router-dom";
// import { Spinner } from "react-bootstrap";
// import charJson from "../../characteristics.json";
import charTypesJson from "../../characteristic_types.json";
import { DataTable, DisplayText, Pagination, Card } from "@shopify/polaris";

const BomLogicPage = () => {
  const { reactFunction, smallWindow } = useFirebaseContext();

  const [characteristics, setCharacteristics] = useState([]);
  const [characteristicTypes, setCharacteristicTypes] = useState(charTypesJson);
  const [loading, setLoading] = useState(true);
  const fetchCharacteristics = reactFunction("getCharacteristics");

  useEffect(() => {
    loading === true &&
      fetchCharacteristics().then((data) => {
        setCharacteristics(data.data.rows);
        setLoading(false);
      });
  }, [loading]);

  const [list, setList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [perPage, setPerPage] = useState(40);

  const mapThroughItem = (v) => [
    v.charass_id,
    v.charass_value,
    v.charass_char_id,
    v.charass_price,
    v.charass_default ? "True" : "False",
    v.charass_target_id,
    v.charass_target_type,
  ];
  // useEffect(async () => {
  //   let d = [...characteristics];
  //   let pages = Math.floor((characteristics.length + perPage - 1) / perPage);
  //   setTotalPage(pages);
  //   console.log(pages);
  //   let newPageData = d.splice(0, perPage);
  //   setList([...newPageData.map(mapThroughItem)]);
  // }, []);
  // console.log(totalPage);

  useEffect(async () => {
    let d = [...characteristics];

    let newPageData = d.splice(0, perPage);
    setList([...newPageData.map(mapThroughItem)]);
    // let total = characteristics.length;
    // setTotal(characteristics.length);

    // let pages = Math.floor((characteristics.length + perPage - 1) / perPage);
    // setTotalPage(pages);
  }, [characteristics]);

  // const onPageChange = async (v) => {
  //   setPageNumber(v);
  //   let d = [...characteristics];
  //   let count = v * perPage;
  //   console.log(perPage);
  //   let newPageData = d.splice(v !== 1 ? (v - 1) * perPage : 0, perPage);
  //   console.log(newPageData);
  //   setList([...newPageData.map(mapThroughItem)]);
  //   // if (props.refP) handleScrolltoRef(props.refP);

  //   // setTotal(newPageData.total)
  // };
  if (list.length)
    return (
      <>
        <div style={{ margin: "10px 0 20px 5px" }}>
          <DisplayText size="extraLarge" element="h1">
            Bom Logic
          </DisplayText>
        </div>
        <Card>
          <DataTable
            columnContentTypes={[
              "numeric",
              "text",
              "numeric",
              // "numeric",
              // "text",
              // "numeric",
              // "text",
            ]}
            headings={[
              "ID",
              "Value",
              "Char ID",
              // "Price",
              // "Default",
              // "Target ID",
              // "Target Type",
            ]}
            rows={list}
            // footerContent={`Showing ${list.length} items on this page`}
            // sortable={["ID", imageColN, "Name", ...otherItems, "Edit"]
            //   .filter(Boolean)
            //   .map((v) => {
            //     if (sortColsName.includes(v.toLowerCase())) return true;
            //     else return false;
            //   })}
            // defaultSortDirection="descending"
            // initialSortColumnIndex={0}
            // onSort={handleSort}
          />
        </Card>
        <div style={{ margin: "20px 0 40px 5px" }}>
          {/*<Pagination
          onPrevious={() => onPageChange(pageNumber - 1)}
          onNext={() => onPageChange(pageNumber + 1)}
          plain={false}
          hasPrevious={pageNumber > 1}
          hasNext={totalPage > pageNumber}
        />*/}
        </div>
      </>
    );
  return "loading";
};

export default BomLogicPage;
