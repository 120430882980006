/** @jsxImportSource @emotion/react */
import { useFirebaseContext } from "../../Firebase";
import { css } from "@emotion/react";
import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Stack,
  Tag,
  //   Badge,
  EmptySearchResult,
  Listbox,
  ButtonGroup,
  Box,
  Button,
  Icon,
  Text,
  Combobox,
} from "@shopify/polaris";
import { EditMinor } from "@shopify/polaris-icons";
import BomTag from "../../Commons/Tag";
import { TodoListContext } from "../../../Context/TodoListContext";
import { useIsMount } from "./utils";
import { CONSOLE_LEVELS } from "@sentry/utils";
const BomLineItemTags = (props) => {
  const { id, task_id, tags } = props;
  const isMount = useIsMount();

  const { reactFunction, expressFunction, permissions } = useFirebaseContext();
  // const { permissions } = state;

  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);

  const [suggestion, setSuggestion] = useState("");
  const [selectLoading, setSelectLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const getTags = expressFunction("getTags");
  const { updateBomItem } = useContext(TodoListContext);

  useEffect(async () => {
    if (!isMount && tags !== selectedTags) {
      const updateTags = await updateBomItem({
        id: id,
        task_id: task_id,
        tags: selectedTags.map((i) => i.value),
      });
    }
  }, [selectedTags]);

  useEffect(() => {
    if (isMount) {
      console.log(tags, "tags");
      setSelectedTags(tags);
    }
  });
  useEffect(() => {
    if (value === "") {
      setOptions([]);
      setSelectLoading(false);
      return;
    }
    const getRes = fetchTagByTitle(value).then((res) => {
      const opts = res
        .filter((f) => !selectedTags.map((i) => i.value).includes(f.id))
        .map((item) =>
          Object.assign({ value: item.id, label: item.shortname, ...item })
        );
      setOptions(opts);
      setSelectLoading(false);
    });
  }, [value]);
  const handleActiveOptionChange = useCallback(
    (activeOption) => {
      if (!selectLoading) {
        setSelectLoading(true);
      }

      // setTimeout(() => {
      if (value === "") {
        setOptions([]);
        setSelectLoading(false);
        return;
      }

      const getRes = fetchTagByTitle(value).then((res) => {
        const opts = res
          .filter((f) => !selectedTags.map((i) => i.value).includes(f.id))
          .map((item) =>
            Object.assign({ value: item.id, label: item.shortname })
          );
        setOptions(opts);
        setSelectLoading(false);
      });

      if (!selectedTags.includes(activeOption) /* @TODO */) {
      }

      if (!selectedTags.includes(activeOption)) {
        setSuggestion(activeOption);
      } else {
        setSuggestion("");
      }
    },
    [value, selectedTags]
  );
  const updateSelection = useCallback(
    (selected) => {
      const nextSelectedTags = new Set([...selectedTags]);

      if (nextSelectedTags.has(selected)) {
        nextSelectedTags.delete(selected);
      } else {
        nextSelectedTags.add(selected);
      }
      setSelectedTags([...nextSelectedTags]);
      setValue("");
      setSuggestion("");
    },
    [selectedTags]
  );

  const removeTag = useCallback(
    (tag) => () => {
      updateSelection(tag);
    },
    [updateSelection]
  );

  // const getAllTags = useCallback(() => {
  //     const savedTags = ['Rustic', 'Antique', 'Vinyl', 'Vintage', 'Refurbished'];
  //     return [...new Set([...savedTags, ...selectedTags].sort())];
  // }, [selectedTags]);

  const verticalContentMarkup =
    selectedTags && selectedTags.length > 0 ? (
      <Stack spacing="extraTight" alignment="center">
        {selectedTags.map((tag) => (
          <>
            <BomTag
              key={`option-${tag}`}
              value={tag.label}
              onRemove={removeTag(tag)}
              color={tag.color}
              icon={tag.icon}
            />
            {/* <Tag  onRemove={removeTag(tag)}>
              {tag.label} ({tag.value})
            </Tag> */}
          </>
        ))}
      </Stack>
    ) : null;

  const optionMarkup =
    options.length > 0
      ? options.map((option) => {
          console.log(option, "option");
          return (
            <Listbox.Option
              key={option.value}
              value={option}
              selected={selectedTags.includes(option)}
              accessibilityLabel={option}
            >
              <Listbox.TextOption selected={selectedTags.includes(option)}>
                {/* {formatOptionText(option)} */}

                <BomTag
                  key={`option-${option}`}
                  value={option.label}
                  color={option.color}
                  icon={option.icon}
                />

                {/* {option.label} ({option.value}) */}
              </Listbox.TextOption>
            </Listbox.Option>
          );
        })
      : null;

  // const noResults = value && !getAllTags().includes(value);

  // const actionMarkup = noResults ? (
  //     <Listbox.Action value={value}></Listbox.Action>
  // ) : null;

  const emptyStateMarkup = optionMarkup ? null : (
    <EmptySearchResult
      title=""
      description={`No tags found matching "${value}"`}
    />
  );

  // const listboxMarkup =
  //     optionMarkup || emptyStateMarkup ? (
  //         <Listbox
  //             autoSelection="FIRST"
  //             onSelect={updateSelection}
  //             onActiveOptionChange={handleActiveOptionChange}
  //         >
  //             {emptyStateMarkup}
  //             {/* {actionMarkup} */}
  //             {optionMarkup}
  //         </Listbox>
  //     ) : null;

  const fetchTagByTitle = async (title) => {
    let req = await getTags({ types: ["task_tag"], title: title });
    console.log("req", req.data);
    return req.data;
  };

  const RenderTags = () => {
    return (
      <Stack spacing={"tight"}>
        {typeof permissions !== "undefined" &&
          permissions.editBOM === true &&
          tags.length === 0 && <Text>No tags set</Text>}
        {tags.map((tag, t) => (
          <BomTag
            key={tag.value}
            value={tag.label}
            color={tag.color}
            icon={tag.icon}
          />
        ))}
      </Stack>
    );
  };

  const [isEditing, setIsEditing] = useState(false);

  if (
    permissions &&
    permissions.editBOM !== undefined &&
    permissions.editBOM === true
  )
    return (
      <>
        {isEditing ? (
          <>
            <Combobox
              allowMultiple={true}
              activator={
                <>
                  <Combobox.TextField
                    autoComplete="off"
                    label="Search tags"
                    labelHidden
                    value={value}
                    suggestion={suggestion}
                    placeholder="Search tags"
                    verticalContent={verticalContentMarkup}
                    onChange={setValue}
                  />
                  {/* <Combobox.TextField
           prefix={<Icon source={SearchMinor} />}
           onChange={updateText}
           label="Search tags"
           labelHidden
           value={inputValue}
           placeholder="Search tags"
         /> */}
                </>
              }
            >
              {options.length ? (
                <Listbox
                  onSelect={updateSelection}
                  // onActiveOptionChange={handleActiveOptionChange}
                >
                  {optionMarkup}
                </Listbox>
              ) : null}
            </Combobox>
            <Box paddingBlockStart="2">
              <ButtonGroup segmented>
                <Button plain onClick={() => setIsEditing(!isEditing)}>
                  Close
                </Button>
              </ButtonGroup>
            </Box>
          </>
        ) : (
          <>
            <div
              css={css`
                &:hover {
                  ._icon .Polaris-Icon {
                    opacity: 1;
                  }
                }
              `}
            >
              <div
                css={css`
                  cursor: pointer;
                  ._icon .Polaris-Icon {
                    height: 1rem;
                    width: 1rem;
                    opacity: 0;
                    transition: ease 0.2s all;
                  }
                `}
                onClick={() => setIsEditing(!isEditing)}
              >
                <Stack spacing="tight">
                  <RenderTags />
                  <div className="_icon">
                    <Icon source={EditMinor} color="" />
                  </div>
                </Stack>
              </div>
            </div>
          </>
        )}
      </>
    );

  return <RenderTags />;
};

export default BomLineItemTags;
