import { useFirebaseContext } from "../Firebase";
import React, { useEffect, useContext, useState, useCallback } from "react";
import {
  Button,
  TextField,
  Form,
  Stack,
  FormLayout,
  Page,
  // Tag,
  // Text,
  // EmptySearchResult,
  // Listbox,
  // Combobox,
} from "@shopify/polaris";
import Tabs from "../Commons/Tabs";
import { useHistory } from "react-router-dom";
import CharacteristicAddNew from "./CharacteristicAddNew";
import CharacteristicItemList from "./CharacteristicItemList";
import ToastContext from "../../Context/Toast/ToastContext";
import TagInput from "../TagInput";
import ConfirmationDialogDelete from "../EditEntity/ConfirmationDialogDelete";
const CharacteristicPage = (props) => {
  const { reactFunction, expressFunction } = useFirebaseContext();
  const { pushNewToast } = useContext(ToastContext);
  const getCharacteristic = expressFunction("getCharacteristic");
  const getTags = expressFunction("getTags");
  const editCharacteristic = expressFunction("editCharacteristic");

  // const [selectLoading, setSelectLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [value, setValue] = useState("");
  // const [suggestion, setSuggestion] = useState("");
  const [loading, setLoading] = useState(true);
  const [addDefaultItem, setAddDefaultItem] = useState(null);
  // const [options, setOptions] = useState([]);

  const addCharassItem = (selectedObj, qty) => {
    setFormValue((prevState) => ({
      ...prevState,
      default_items:
        "default_items" in prevState
          ? [...prevState.default_items, { ...selectedObj, qty: qty }]
          : [{ ...selectedObj, qty: qty }],
    }));
  };

  const editCharassItem = (selectedObj, qty) => {
    const newDefaultItems = [...formValue.default_items];

    const findItem = formValue.default_items.find((f, k) => {
      if (f.item_id === selectedObj.item_id) {
        newDefaultItems[k] = {
          item_id: selectedObj.value,
          item_number: selectedObj.label,
          qty: qty,
        };
        return true;
      }
    });

    if (!findItem)
      return pushNewToast({
        error: true,
        message: "Couldn't locate that item.",
      });

    setFormValue((prevState) => ({
      ...prevState,
      default_items: newDefaultItems,
    }));
  };

  const deleteCharassItem = (index) => {
    const newDefaultItems = [
      ...formValue.default_items.filter((f, k) => k !== index),
    ];

    // const findItem = formValue.default_items.find((f, k) => {
    //   if (f.item_id === selectedObj.item_id) {
    //     newDefaultItems[k] = {
    //       item_id: selectedObj.value,
    //       item_number: selectedObj.label,
    //       qty: qty,
    //     };
    //     return true;
    //   }
    // });

    // if (!findItem)
    //   return pushNewToast({
    //     error: true,
    //     message: "Couldn't locate that item.",
    //   });

    setFormValue((prevState) => ({
      ...prevState,
      default_items: newDefaultItems,
    }));
  };

  // const handleActiveOptionChange = useCallback(
  //   (activeOption) => {
  //     if (!selectLoading) {
  //       setSelectLoading(true);
  //     }

  //     if (value === "") {
  //       setOptions([]);
  //       setSelectLoading(false);
  //       return;
  //     }

  //     const getRes = fetchTagByTitle(value).then((res) => {
  //       const opts = res
  //         .filter((f) => !selectedTags.map((i) => i.value).includes(f.id))
  //         .map((item) =>
  //           Object.assign({ value: item.id, label: item.shortname })
  //         );
  //       setOptions(opts);
  //       setSelectLoading(false);
  //     });

  //     if (!selectedTags.includes(activeOption) /* @TODO */) {
  //     }

  //     const activeOptionIsAction = activeOption === value;

  //     if (!activeOptionIsAction && !selectedTags.includes(activeOption)) {
  //       setSuggestion(activeOption);
  //     } else {
  //       setSuggestion("");
  //     }
  //   },
  //   [value, selectedTags]
  // );
  const updateSelection = useCallback(
    (selected) => {
      // const nextSelectedTags = new Set([...selectedTags]);

      // if (nextSelectedTags.has(selected)) {
      //   nextSelectedTags.delete(selected);
      // } else {
      //   nextSelectedTags.add(selected);
      // }

      // console.log(Array.from(nextSelectedTags), 'next!')
      // setSelectedTags(Array.from(nextSelectedTags));

      setFormValue((prevState) => ({ ...prevState, tags: selected }));
      // setValue("");
      // setSuggestion("");
    },
    [selectedTags]
  );

  // const removeTag = useCallback(
  //   (tag) => () => {
  //     updateSelection(tag);
  //   },
  //   [updateSelection]
  // );

  // const getAllTags = useCallback(() => {
  //   const savedTags = ["Rustic", "Antique", "Vinyl", "Vintage", "Refurbished"];
  //   return [...new Set([...savedTags, ...selectedTags].sort())];
  // }, [selectedTags]);

  // const formatOptionText = useCallback(
  //   (option) => {
  //     const trimValue = value.trim().toLocaleLowerCase();
  //     const matchIndex = option.toLocaleLowerCase().indexOf(trimValue);

  //     if (!value || matchIndex === -1) return option;

  //     const start = option.slice(0, matchIndex);
  //     const highlight = option.slice(matchIndex, matchIndex + trimValue.length);
  //     const end = option.slice(matchIndex + trimValue.length, option.length);

  //     return (
  //       <p>
  //         {start}
  //         <Text variant="bodyMd" fontWeight="bold" as="span">
  //           {highlight}
  //         </Text>
  //         {end}
  //       </p>
  //     );
  //   },
  //   [value]
  // );

  // const verticalContentMarkup =
  // selectedTags.length > 0 ? (
  //   <Stack spacing="extraTight" alignment="center">
  //     {selectedTags.map((tag) => (
  //       <Tag key={`option-${tag}`} onRemove={removeTag(tag)}>
  //         {tag.label} ({tag.value})
  //       </Tag>
  //     ))}
  //   </Stack>
  // ) : null;

  // const optionMarkup =
  //   options.length > 0
  //     ? options.map((option) => {
  //         return (
  //           <Listbox.Option
  //             key={option.value}
  //             value={option}
  //             selected={selectedTags.includes(option)}
  //             accessibilityLabel={option}
  //           >
  //             <Listbox.TextOption selected={selectedTags.includes(option)}>
  //               {/* {formatOptionText(option)} */}
  //               {option.label} ({option.value})
  //             </Listbox.TextOption>
  //           </Listbox.Option>
  //         );
  //       })
  //     : null;

  // const noResults = value && !getAllTags().includes(value);

  // const actionMarkup = noResults ? (
  //   <Listbox.Action value={value}></Listbox.Action>
  // ) : null;

  // const emptyStateMarkup = optionMarkup ? null : (
  //   <EmptySearchResult
  //     title=""
  //     description={`No tags found matching "${value}"`}
  //   />
  // );

  // const listboxMarkup =
  //   optionMarkup || actionMarkup || emptyStateMarkup ? (
  //     <Listbox
  //       autoSelection="FIRST"
  //       onSelect={updateSelection}
  //       onActiveOptionChange={handleActiveOptionChange}
  //     >
  //       {emptyStateMarkup}
  //       {actionMarkup}
  //       {optionMarkup}
  //     </Listbox>
  //   ) : null;

  // const fetchTagByTitle = async (title) => {
  //   let req = await getTags({ types: ["charass_tag"], title: title });
  //   return req.data.rows;
  // };

  const [formValue, setFormValue] = useState({});
  const [formValueTags, setFormValueTags] = useState([]);
  const [isEdit, setIsEdit] = useState(
    props?.location?.pathname !== "/characteristic/new"
  );

  // const handleTabChange = useCallback(
  //   (selectedTabIndex) => setSelected(selectedTabIndex),
  //   []
  // );

  useEffect(() => {}, [value]);

  useEffect(() => {
    setFormValueTags((prev) => ({
      ...prev,
      tags: selectedTags.map((i) => i.value),
    }));
  }, [selectedTags]);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if (addDefaultItem !== null) {
      setModalIsOpen(true);
    }
  }, [addDefaultItem]);

  useEffect(() => {
    if (!modalIsOpen && addDefaultItem) setAddDefaultItem(null);
  }, [modalIsOpen]);

  const tabList = {
    tab1: {
      title: "General",
      content: (
        <Form>
          <FormLayout>
            <TextField
              value={formValue.charass?.charass_value}
              onChange={(value) =>
                setFormValue((v) => ({
                  ...v,
                  charass: { ...v.charass, charass_value: value },
                }))
              }
              label="Shortname"
              type="text"
            />
            <TextField
              value={formValue.charass?.description}
              onChange={(value) =>
                setFormValue((v) => ({
                  ...v,
                  charass: { ...v.charass, description: value },
                }))
              }
              label="Description"
              multiline={4}
            />

            <TagInput
              tags={selectedTags}
              callback={updateSelection}
              tagTypes={["charass_tag"]}
            />

            {/* <Combobox
              allowMultiple={true}
              activator={
                <>
                  <Combobox.TextField
                    autoComplete="off"
                    label="Search tags"
                    labelHidden
                    value={value}
                    suggestion={suggestion}
                    placeholder="Search tags"
                    verticalContent={verticalContentMarkup}
                    onChange={setValue}
                  />
                </>
              }
            >
              {listboxMarkup}
            </Combobox> */}
          </FormLayout>
        </Form>
      ),
    },
    tab2: {
      title: "Default Items",
      content: (
        <>
          <CharacteristicAddNew
            open={modalIsOpen}
            forItem={formValue?.charass?.charass_value}
            editingItem={addDefaultItem}
            editCharassItem={editCharassItem}
            addCharassItem={addCharassItem}
            onClose={() => setModalIsOpen(false)}
            activator={
              <Stack>
                <Button primary onClick={() => setModalIsOpen(true)}>
                  Add new item
                </Button>
              </Stack>
            }
          />
          <CharacteristicItemList
            deleteCharassItem={deleteCharassItem}
            items={formValue.default_items || []}
            setOpen={(e) => {
              setAddDefaultItem(formValue.default_items[e]);
            }}
          />
          <span>
            Note that updating qty on existing items does not work. need to
            delete, save and readd. TODO: EZRA
          </span>
        </>
      ),
    },
  };

  const history = useHistory();

  useEffect(async () => {
    if (isEdit) {
      let id = parseInt(props.match.params.charassId);
      let data = await getCharacteristic({ charassId: id });

      setFormValue(data);

      if ("tags" in data)
        setSelectedTags(
          data.tags.map((i) =>
            Object.assign({ label: i.shortname, value: i.id })
          )
        );

      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);
  const [charassId, setCharassId] = useState(null);
  const submitForm = async (close = false) => {
    if (isEdit) {
      console.log(formValue, "fvT");

      let charass_id = props.match.params.charassId;
      setCharassId(charass_id);
      const payload = {
        charass_id: charass_id,
        ...formValue,
        // tags: [...formValueTags.tags,
      };
      const x = await editCharacteristic(payload);

      if (x) {
        pushNewToast({ message: "Changes saved successfully." });
        if (close === true) history.goBack();
      } else {
        pushNewToast({ error: true, message: "That failed." });
      }
    } else {
      //  @TODO - We don't allow creating Characteristics here, yet... (?)
    }
  };

  const [confirmDelete, setConfirmDelete] = useState(false);

  if (loading === true) return "LoadingCharacteristic";

  return (
    <Page
      title={
        isEdit
          ? `Editing ${formValue.charass.charass_value}`
          : `Creating a new Characteristic`
      }
      subtitle="Edit characteristic values and tags."
      compactTitle
      primaryAction={{
        content: "Save",
        onAction: () => submitForm(),
      }}
      secondaryActions={[
        {
          content: "Save & Close",
          onAction: () => submitForm(true),
        },
      ]}
    >
      <Tabs tabs={tabList} />
      {/* <br />
      <br /> */}
      {/* Default items:
      <br />
      <br /> */}
      <ConfirmationDialogDelete open={confirmDelete} deleteTemplate />
    </Page>
  );
};

export default CharacteristicPage;
