import { Card, Form, Image, Modal } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { Gallery } from "react-grid-gallery";
import { useFirebaseContext } from "../../Firebase";

const ImageSection = ({ itemObj }) => {
  const [newImageModal, setNewImageModal] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    true &&
      setImages(
        itemObj.images.map((image) => {
          return {
            src: image.url,
            width: 320,
          };
        })
      );
  }, [itemObj]);

  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  const ImageModal = () => {
    return (
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        title="Image"
        large={true}
      >
        {modalImage !== null && <Image source={images[modalImage].src} />}
      </Modal>
    );
  };

  const NewImageModal = () => {
    const { reactFunction, expressFunction } = useFirebaseContext();
    const uploadItemImage = expressFunction("uploadItemImage");

    const [image, setImage] = useState(null);
    const [fileName, setFileName] = useState("");
    const [loading, setLoading] = useState(false);

    const handleImageChange = (e) => {
      e.target.files[0].arrayBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: e.target.files[0].type,
        });
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          const base64data = reader.result;
          setFileName(e.target.files[0].name);
          setImage(base64data);
        };
      });
    };

    const handleImageUpload = () => {
      setLoading(true);
      uploadItemImage({
        fileInBase64: image,
        itemID: itemObj.item_id,
        fileName: fileName,
      }).then((res) => {
        console.log(res);
        window.location.reload();
      });
    };

    return (
      <Modal
        open={newImageModal}
        onClose={() => setNewImageModal(false)}
        title="New Image"
        disabled={loading}
        large={true}
      >
        <Form>
          {true && (
            <Card
              sectioned
              disabled={true}
              primaryFooterAction={{
                content: "Save",
                onAction: handleImageUpload,
              }}
            >
              <input
                type="file"
                accept="image/*"
                capture="camera"
                onChange={handleImageChange}
              />
            </Card>
          )}
        </Form>
      </Modal>
    );
  };

  return (
    <Card
      title="Images"
      sectioned
      actions={[
        {
          content: "Add Image",
          onAction: () => {
            setNewImageModal(true);
          },
        },
      ]}
    >
      <NewImageModal />
      <ImageModal />
      <Gallery
        images={images}
        enableImageSelection={false}
        onClick={(e) => {
          setModalImage(e);
          setShowModal(true);
        }}
      />
    </Card>
  );
};

export default ImageSection;
