// const rows = ["D"];
const rows = [
  //   "A",
  //   "B",
  // Dont USE BEFORE
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
];
const cols = [
  //   "1",
  //   "2",
  //   "3",
  //   "4",
  //   "5",
  //   "6",
  //DONT USE BEFORE UNLESS ITS THE LAST ROW
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
];
// const levels = ["A", "B", "C", "D", "E", "F", "G", "H"];
const levels = ["E"];

function pad(num, size) {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

const areas = {};
rows.forEach((row) => {
  cols.forEach((col) => {
    levels.forEach((level) => {
      areas[`${row}${pad(col, 2)}${level}`] = {
        row: row,
        col: pad(col, 2),
        level: level,
      };
    });
  });
});

import { pdf } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Document as RP_Document, Page as RP_Page } from "react-pdf";
import Select from "react-select";
import { useFirebaseContext } from "../../Firebase";
import pdf2base64 from "pdf-to-base64";

import RackLabel from "./RackLabel";

const RackingLabelPrintPage = ({}) => {
  const { getFunction } = useFirebaseContext();
  const printLabel = getFunction("printLabel");
  const [pdfObj, setPdfObj] = useState(null);

  const [filterRow, setFilterRow] = useState([...rows]);
  const [filterCol, setFilterCol] = useState([...cols]);
  const [filterLevel, setFilterLevel] = useState([...levels]);

  const [printQueue, setPrintQueue] = useState([]);

  useEffect(() => {
    pdf(<RackLabel areas={areas} />)
      .toBlob()
      .then((r) => {
        const uRl = URL.createObjectURL(r);
        setPdfObj(uRl);
      });
  }, []);

  useEffect(() => {
    if (printQueue.length !== 0) {
      pdf2base64(pdfObj).then((r) => {
        printLabel([
          {
            qty: 1,
            type: "fixture",
            pdf: r,
          },
        ]);
      });

      setPrintQueue([]);
    }
  }, [printQueue]);

  console.log(areas);

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  return (
    <>
      <h1>Racking Labels</h1>
      <hr />
      <h2>Filtering</h2>
      <label>Row: </label>
      <Button onClick={() => setPrintQueue([1])}>Test</Button>
      <Select options={options} isMulti />
      <select>
        <option>All</option>
      </select>

      <label>Col: </label>

      <label>Level: </label>

      <hr />
      <RP_Document
        file={pdfObj}
        // style={{width:'200px'}}
        renderMode={"svg"}
        onLoadSuccess={(pdf) => {
          console.log("Document", pdf.numPages);
          //   setNumPages(pdf?.numPages);
        }}

        // externalLinkTarget={'_self'}
      >
        <RP_Page pageNumber={1} width={"398"} />
        <RP_Page pageNumber={2} width={"398"} />
      </RP_Document>
    </>
  );
};

export default RackingLabelPrintPage;
