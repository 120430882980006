import { useEffect, useState } from "react";
import { useFirebaseContext } from "../Firebase";
import Camera from "react-html5-camera-photo";
import { FACING_MODES } from "jslib-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { Alert /*, Button */ } from "react-bootstrap";
import CameraField from "../CameraField";
import { DefaultEditor } from "react-simple-wysiwyg";
import { Heading, List, TextField, TextContainer } from "@shopify/polaris";
import ImageUpload from "./ImageUpload";

const PhoneCamera = ({ uploadingStatus = false, setPhotoInBASE64 }) => {
  return (
    <>
      {/* <Button onClick={() => setCameraType("select")}>Go Back</Button> */}
      {!uploadingStatus ? (
        <Camera
          isImageMirror={false}
          onTakePhoto={(dataUri) => {
            setPhotoInBASE64(dataUri);
          }}
          idealFacingMode={FACING_MODES.ENVIRONMENT}
          imageType={"png"}
          //   idealResolution={{ width: 6000, height: 60001 }}
          isMaxResolution={true}
          // isFullscreen={true}
        />
      ) : (
        <div>
          <Alert variant={"warning"}>Uploading Photo</Alert>
        </div>
      )}
    </>
  );
};

const LocationImageField = ({ locationObj, setLocationObj }) => {
  const { expressFunction } = useFirebaseContext();
  const uploadLocationImage = expressFunction("uploadLocationImage");

  console.log("==========");
  console.log(locationObj);

  const [uploadingStatus /*, setUploadingStatus */] = useState(false);
  const [photoInBASE64, setPhotoInBASE64] = useState("");

  useEffect(() => {
    if (photoInBASE64.length !== 0) {
      console.log("locationObj", locationObj);
      uploadLocationImage({
        locationID: locationObj.location_id,
        images: [photoInBASE64],
      }).then((response) => {
        console.log(`responseresponseresponseresponse`);
        console.log(response);
        setLocationObj(response.data);
        setPhotoInBASE64("");
      });
    }
    // console.log(photoInBASE64);
  }, [photoInBASE64]);

  return (
    <>
      <CameraField value={photoInBASE64} onChange={setPhotoInBASE64} />
      <ImageUpload
        onChange={(v) => {
          v[0].arrayBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: "image/jpeg" });
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => {
              const base64data = reader.result;
              setPhotoInBASE64(base64data);
            };
          });
        }}
      />
      {/* <PhoneCamera
        uploadingStatus={uploadingStatus}
        setPhotoInBASE64={setPhotoInBASE64}
      /> */}
    </>
  );
};

const LocationPage = (props) => {
  const { expressFunction, reactFunction, tempStorageState } =
    useFirebaseContext();
  const findWarehouseLocation = expressFunction("findWarehouseLocation");

  let passedAlongPayload = {};
  if (Object.keys(tempStorageState[0]).length !== 0) {
    console.log("eeee");
    passedAlongPayload = tempStorageState[0];
    tempStorageState[1]({}); //clears the state.
  }

  const [locationObj, setLocationObj] = useState(passedAlongPayload);

  useEffect(() => {
    if (Object.keys(locationObj).length === 0) {
      const { locationID } = props.match.params;
      findWarehouseLocation({ id: locationID }).then((response) => {
        setLocationObj(response);
      });
    }
  }, [locationObj]);

  return (
    <>
      <TextContainer>
        <Heading>
          Location: {locationObj.location_formatname || "Loading...."}
        </Heading>

        {locationObj.location_formatname && (
          <h4>Description: {locationObj.location_descrip}</h4>
        )}
      </TextContainer>
      <hr />
      {locationObj.location_formatname && (
        <QuickLocNotes
          locationObj={locationObj}
          setLocationObj={setLocationObj}
        />
      )}
      <hr />
      {locationObj &&
        locationObj.locationPhoto &&
        locationObj.locationPhoto.length &&
        locationObj.locationPhoto.map((row) => {
          return (
            <>
              <img src={row.photoPublicURL} style={{ height: "50px" }} />
            </>
          );
        })}
      <hr />
      {locationObj.location_formatname && (
        <>
          <LocationImageField
            locationObj={locationObj}
            setLocationObj={setLocationObj}
          />
        </>
      )}
    </>
  );
};

const QuickLocNotes = ({ locationObj, setLocationObj }) => {
  const [notes, setNotes] = useState(locationObj.QuickLocNotes);
  const { expressFunction } = useFirebaseContext();
  const [saving, setSaving] = useState(false);
  const saveWarehouseLocationQuickNotes = expressFunction(
    "saveWarehouseLocationQuickNotes"
  );

  const saveNotes = () => {
    setSaving(true);
    const payload = {
      locationID: locationObj.location_id,
      QuickLocNotes: notes,
    };
    saveWarehouseLocationQuickNotes(payload).then((response) => {
      setSaving(false);
      console.log(response);
      // setLocationObj(response.data);
    });
  };

  return (
    locationObj && (
      <>
        <b>Notes:</b>
        <DefaultEditor
          value={notes}
          onChange={(event) => {
            setNotes(event.target.value);
          }}
          // setNotes(event.target.value)}}
          style={{ width: "100%" }}
        />
        <button onClick={saveNotes} disabled={saving}>
          Save
        </button>
      </>
    )
  );
};

export default LocationPage;
