import { useFirebaseContext } from "../../Firebase";
import React, { useContext, useCallback, useEffect, useState } from "react";
import {
  DataTable,
  Pagination,
  Card,
  Button,
  Popover,
  OptionList,
  Page,
  Badge,
  Checkbox,
  ButtonGroup,
  TextField,
  Form,
  FormLayout,
  SettingToggle,
} from "@shopify/polaris";
import RadioButtons from "../../Commons/Form/RadioButtons";
import Tabs from "../../Commons/Tabs";
import Weight from "../../Commons/Form/Weight";
import { Link, useHistory } from "react-router-dom";
import LogicBuilderWrapper from "../QueryBuilder/Rule/LogicBuilderWrapper";
import ToastContext from "../../../Context/Toast/ToastContext";
import ConfirmationDialogDelete from "../../EditEntity/ConfirmationDialogDelete";
import StatusToggle from "../../Commons/Form/StatusToggle/StatusToggle";
const Rule = (props) => {
  const [page, setPage] = useState(1);
  const [formValue, setFormValue] = useState({
    name: "",
    type: "",
    description: "",
    active: true,
    weight: 0,
    // tags: [],
    ruleJsonFormat: {},
    ruleConditionsJsonFormat: {},
    ruleEventsJsonFormat: {},
  });

  useEffect(() => {
    // console.log("formValue", formValue);
  }, [formValue]);
  const [loading, setLoading] = useState(true);
  const { expressFunction } = useFirebaseContext();
  const getRule = expressFunction("getRule");
  const editRule = expressFunction("editRule");
  const createRule = expressFunction("createRule");
  const deleteRule = expressFunction("deleteRule");

  const history = useHistory();
  const [isEdit, setIsEdit] = useState(
    props?.location?.pathname !== "/bom/rule/new"
  );

  console.log(isEdit, "isEdit");

  useEffect(() => {
    setIsEdit(props?.location?.pathname !== "/bom/rule/new");
  }, [props.location]);

  const [entityId, setEntityId] = useState(null);

  useEffect(() => {
    if (isEdit === true) setEntityId(parseInt(props.match.params.ruleId));
  }, [isEdit]);
  const { pushNewToast } = useContext(ToastContext);

  useEffect(async () => {
    // let res = await getRule()
    // setData(res)
    if (isEdit) {
      let id = parseInt(props.match.params.ruleId);
      let data = await getRule({ id: id });

      // console.log(data, 'datassssx')
      setFormValue(data);
      // if ("bomTagData" in data.data) {
      //     setFormValueBomTag(data.data.bomTagData);
      //     setBomLogicLoaded(true);
      // }
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  const submitForm = async (close = false) => {
    if (isEdit) {
      let id = props.match.params.ruleId;
      const payload = { id: id, ...formValue };
      const res = await editRule(payload);
      if (res) {
        pushNewToast({ message: "Changes saved successfully." });
        // setIsEdit(false)
      } else pushNewToast({ message: "An error occured", error: true });
    } else {
      const res = await createRule(formValue);
      if (res) {
        console.log(res, "res");
        pushNewToast({ message: "Created successfully." });
        history.push(`/bom/rule/${res}`);
      } else pushNewToast({ message: "An error occured", error: true });
    }
    if (close === true) history.goBack();
  };

  const [confirmDelete, setConfirmDelete] = useState(false);
  const submitDelete = () => {
    setConfirmDelete(true);
  };

  const onDeleteRule = async () => {
    const res = await deleteRule({ id: entityId });
    console.log(res, "res");
    if (res) {
      pushNewToast({ message: "Saved successfully" });
      history.push("/bom/rules");
    } else pushNewToast({ error: true });
  };

  useEffect(() => {
    console.log(formValue, "formValue");
  }, [formValue]);
  const [secondaryActions, setSecondaryActions] = useState([
    {
      content: "Save & Close",
      onAction: () => submitForm(true),
    },
  ]);

  useEffect(() => {
    if (isEdit === true) {
      const newSecondaryActions = [];
      newSecondaryActions.push({
        content: "Delete",
        destructive: true,
        onAction: () => submitDelete(),
      });
      newSecondaryActions.push(...secondaryActions);
      setSecondaryActions(newSecondaryActions);
    }
  }, [isEdit]);
  const tabList = {
    tab1: {
      title: "General",
      content: (
        <Form onSubmit={() => console.log("submit")}>
          <FormLayout>
            <TextField
              value={formValue.name}
              onChange={(value) => setFormValue((v) => ({ ...v, name: value }))}
              label="Name"
              type="text"
            />
            {loading === false && (
              <LogicBuilderWrapper
                token={null}
                data={formValue.ruleConditionsJsonFormat}
                events={formValue.ruleEventsJsonFormat}
                setStateResult={(v) => {
                  setFormValue((prevValue) => ({
                    ...prevValue,
                    ruleJsonFormat: v,
                  }));
                }}
                setStateResultConditions={(v) => {
                  setFormValue((prevValue) => ({
                    ...prevValue,
                    ruleConditionsJsonFormat: v,
                  }));
                }}
                setStateResultEvents={(v) => {
                  setFormValue((prevValue) => ({
                    ...prevValue,
                    ruleEventsJsonFormat: v,
                  }));
                }}
              />
            )}
            <TextField
              value={formValue.description}
              onChange={(value) =>
                setFormValue((v) => ({ ...v, description: value }))
              }
              label="Description"
              multiline={4}
            />

            <RadioButtons
              options={[
                {
                  value: "default",
                  label: "Default",
                },
                {
                  value: "other",
                  label: "Other",
                },
              ]}
              label={"Type"}
              value={formValue.type}
              required
              handleChange={(value) => {
                // console.log(value);
                setFormValue((v) => ({ ...v, type: value[0] }));
              }}
            />
            <Weight
              value={formValue.weight || 0}
              onChange={(value) => {
                // console.log(value, "v");
                setFormValue((v) => ({ ...v, weight: value }));
              }}
            />
          </FormLayout>
        </Form>
      ),
    },
  };
  return (
    <Page
      title="Edit Rule"
      subtitle="Create and edit global rules."
      compactTitle
      breadcrumbs={[{ content: "Rules", url: "/bom/rules" }]}
      primaryAction={{
        content: "Save",
        onAction: () => submitForm(),
      }}
      secondaryActions={[
        {
          content: (
            <StatusToggle
              value={formValue.active}
              label={"Active"}
              onChange={() => {}}
            />
          ),
          onAction: () =>
            setFormValue((v) => ({
              ...v,
              active: !v.active,
            })),
        },
        ...secondaryActions,
      ]}
    >
      <Tabs tabs={tabList} />
      <div style={{ height: "5rem" }} />
      <ConfirmationDialogDelete
        open={confirmDelete}
        setOpen={() => setConfirmDelete(!confirmDelete)}
        deleteTemplate
        callback={() => onDeleteRule()}
      />
    </Page>
  );
};

export default Rule;
