/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Button,
  Popover,
  ColorPicker as PolarisColorPicker,
  TextField,
  Stack,
  Icon,
  hsbToHex,
  rgbToHsb,
} from "@shopify/polaris";
import { useState, useEffect, useCallback } from "react";
import {
  TypeMinor,
  CancelMinor,
  EyeDropperMinor,
} from "@shopify/polaris-icons";
import hexRgb from "hex-rgb";
import { useIsMount } from "../../utils";

const ColorPicker = (props) => {
  const isMount = useIsMount();
  const { updateValue } = props;
  const initValue = {
    hue: 50,
    saturation: 50,
    brightness: 100,
    alpha: 1,
  };
  const [color, setColor] = useState(props.value ? null : initValue);
  const [value, setValue] = useState("");

  useEffect(() => {
    if (color && !isMount) {
      const tryHex = hsbToHex(color);
      if (tryHex.length === 7) setValue(hsbToHex(color));
    }
  }, [color]);

  useEffect(() => {
    if (props.value) {
      setValue(props.value)
      setColor(rgbToHsb(hexRgb(props.value)))
    }
  }, []);

  useEffect(() => {
    const reg = /^#[0-9A-F]{6}$/i;
    if (value && value !== "" && reg.test(value)) updateValue(value);
  }, [value]);

  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );

  const onChange = (e) => {
    console.log(e);
    setValue(e);
  };

  return (
    <>
      <TextField
        value={value}
        label="Color"
        onChange={onChange}
        // onFocus={togglePopoverActive}
        prefix={
          <div
            css={css`
              svg {
                fill: ${value ? value : "inherit"};
              }
            `}
          >
            <Icon source={TypeMinor} />
          </div>
        }
        connectedRight={
          <Popover
            active={popoverActive}
            autofocusTarget="first-node"
            onClose={togglePopoverActive}
            activator={
              <Button onClick={() => togglePopoverActive()}>
                {popoverActive ? (
                  <Icon source={CancelMinor} />
                ) : (
                  <Icon source={EyeDropperMinor} />
                )}
              </Button>
            }
          >
            <PolarisColorPicker onChange={setColor} color={color} />
          </Popover>
        }
      />
    </>
  );
};

export default ColorPicker;
