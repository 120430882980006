export const tagTypes = [
  "bom_tag",
  "user_tag",
  "order_tag",
  "char_tag",
  "charass_tag",
  "woorder_tag",
  "task_tag",
].sort();

export const tagTypesAsOptions = [
  {
    value: "bom_tag",
    label: "Bom Tag",
  },
  {
    value: "user_tag",
    label: "User Tag",
  },
  {
    value: "order_tag",
    label: "Order Tag",
  },
  {
    value: "char_tag",
    label: "Char Tag",
  },
  {
    value: "charass_tag",
    label: "Charass Tag",
  },
  {
    value: "woorder_tag",
    label: "Work Order Tag",
  },
  {
    value: "task_tag",
    label: "Task Tag",
  },
].sort((a,b) => a.label> b.label ? 1 : ((b.label > a.label) ? -1 : 0) ) ;
