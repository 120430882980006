import { Card, Page, Form, FormLayout, TextField } from "@shopify/polaris";
import { useState } from "react";
import { useFirebaseContext } from "../../Firebase";

const NewItem = () => {
  const { reactFunction } = useFirebaseContext();
  const checkIfItemExists = reactFunction("checkIfItemExists");
  const createItem = reactFunction("createItem");
  const [itemNumber, setItemNumber] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [itemNumberError, setItemNumberError] = useState("");

  const [submitName, setSubmitName] = useState("Check Item Number");

  const handlePrimaryAction = () => {
    if (submitName === "Check Item Number") {
      setSubmitName("Checking...");
      checkIfItemExists({ itemNumber }).then(({ exists }) => {
        console.log(exists);
        if (exists) {
          setItemNumberError("Item Number already exists");
          setSubmitName("Check Item Number");
        } else {
          setSubmitName("Save New Item");
        }
      });
    } else if (submitName === "Save New Item") {
      setSubmitName("Saving...");
      createItem({
        itemNumber: itemNumber,
        itemDescription: itemDescription,
      }).then((data) => {
        data.data &&
          data.data.item_id &&
          window.location.replace("/item/" + data.data.item_id);
      });
    }
  };

  return (
    <Page
      title="New Item"
      subtitle="Create a new item"
      primaryAction={{
        content: submitName,
        onAction: () => handlePrimaryAction(),
        disabled: itemNumber.length === 0 || itemDescription.length === 0,
      }}
    >
      <Card sectioned>
        <Form>
          <FormLayout>
            <TextField
              label="Item Number"
              value={itemNumber}
              onChange={(e) =>
                setItemNumber(e.toLocaleUpperCase().replace(" ", ""))
              }
              error={itemNumberError}
            />
            <TextField
              label="Description"
              value={itemDescription}
              onChange={setItemDescription}
            />
          </FormLayout>
        </Form>
      </Card>
    </Page>
  );
};
export default NewItem;
