import { Card, DataTable, TextContainer } from "@shopify/polaris";

const SourcingSection = ({ itemObj }) => {
  console.log("SourcingSection", itemObj.sources);

  return (
    <Card title={<strong>Sourcing</strong>} sectioned>
      <DataTable
        columnContentTypes={["text", "text", "text", "text"]}
        headings={["Vendor Number", "Vendor Name"]}
        rows={itemObj.sources.map((source) => {
          return [source.vend_number, source.vend_name];
        })}
      />
    </Card>
  );
};

export default SourcingSection;
