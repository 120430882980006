// import Page from "./Page";
import { useFirebaseContext } from "./Firebase";
import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  Button,
  Spinner,
  Select,
  DataTable,
  ButtonGroup,
  Page,
  IndexTable,
  TextStyle,
  Card,
  useIndexResourceState,
  Banner,
  Layout,
} from "@shopify/polaris";
import ToastContext from "../Context/Toast/ToastContext";

const OrdersListPage = () => {
  const { reactFunction, expressFunction } = useFirebaseContext();
  const fetchOrders = expressFunction("fetchOrders");
  const triggerBomRun = reactFunction("triggerBomRun");
  const { pushNewToast } = useContext(ToastContext);
  const [bomGenLoading, setBomGenLoading] = useState(false);
  const localStorageName = "bockProduction_orderlist1";
  //Load data from browser localStorage
  const ordersInitState =
    localStorage.getItem(localStorageName) &&
    JSON.parse(localStorage.getItem(localStorageName)).data
      ? JSON.parse(localStorage.getItem(localStorageName)).data
      : [];

  const [orders, setOrders] = useState(ordersInitState);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    orderStatus: "open",
    purchaseOrder: "",
  });

  useEffect(() => {
    if (loading === true) {
      fetchOrders({ filters }).then((data) => {
        setOrders(data);

        localStorage.setItem(
          localStorageName,
          JSON.stringify({ lastFetch: new Date(), data: data })
        );
        setLoading(false);
      });
    }
  }, [loading]);

  const pageSubtitle = loading ? (
    <p>
      Loading data
      <Spinner size="small" />
    </p>
  ) : (
    <p>Updated.</p>
  );

  const rows =
    orders && orders.length !== 0
      ? orders.map((order) => {
          const rowDate = moment(
            order.cohead_orderdate["_seconds"],
            "X"
          ).format("MM/DD/YYYY");

          const lastComment =
            order["lastComment"] &&
            `${order["lastComment"].comment} on ${moment(
              order["lastComment"]["created"]["_seconds"],
              "X"
            ).format("YYYY-MM-DD")}`;

          const div = document.createElement("div");
          div.innerHTML = lastComment;

          const lastCommentComponent = lastComment ? (
            <TextStyle variation="">
              <span
                dangerouslySetInnerHTML={{
                  __html: div.innerText.substring(0, 100),
                }}
              />
            </TextStyle>
          ) : null;

          return [
            <Link to={`order/${order.cohead_number}`}>
              {order.cohead_number}
            </Link>,
            order.cohead_custponumber,
            rowDate,
            order.cohead_shiptoname,
            lastCommentComponent,
          ];
        })
      : [];

  const triggerBom = async () => {
    setBomGenLoading(true);
    const res = await triggerBomRun({ trialRun: false });
    if (res)
      pushNewToast({
        message: `BOM run complete. Generated ${res.data.length} new tasks.`,
      });
    else pushNewToast({ error: true, message: "Unexpected error" });
    setBomGenLoading(false);
  };
  return (
    <Page
      title={"Orders"}
      subtitle={pageSubtitle}
      primaryAction={{
        content: "Regenerate BOMs",
        onAction: () => triggerBom(),
        loading: bomGenLoading,
      }}
    >
      <Card>
        <DataTable
          columnContentTypes={["text", "text", "date", "text", "text"]}
          headings={[
            "Order",
            "Purchase Order",
            "Order Date",
            "ShipToName",
            "Last Comment",
          ]}
          rows={rows}
        />
      </Card>
    </Page>
  );
};

export default OrdersListPage;
