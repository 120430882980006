import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Svg,
  Line,
} from "@react-pdf/renderer";
import { inchesTo } from "./../LabelHelper";
import BockLogo from "./../CompanyLogos/20120313---bocklogo1.jpg";
import React, { useMemo, useRef, useState } from "react";
import QRCode from "qrcode";
// import Barcode from "react-barcode";

// import QRCode from 'qrcode'

const styles = StyleSheet.create({
  page: {
    // flexDirection: 'row',
    // border: 0.5,
  },
  bold: {
    // backgroundColor: '#E4E4E4',
    "font-weight": 900,
  },
  locText: {
    // backgroundColor: "#E4E4E4",
    fontSize: "1.1in",
    // "font-weight": 1900,
  },
  qrCode: {
    position: "absolute",
    top: `0.05in`,
    // left: `${0}in`,
    width: `${1.4}in`,
  },
  labelDetails: {
    position: "absolute",
    top: 15,
    left: 15,
  },
});

const QRCodeJSX = ({ qrCode }) => {
  return (
    <View>
      <Image
        style={styles.qrCode}
        src={QRCode.toDataURL(qrCode, {
          errorCorrectionLevel: "M",
          type: "svg",
          scale: 10,
        })}
      />
      <Image
        style={{ ...styles.qrCode, left: "1.5in" }}
        src={QRCode.toDataURL(qrCode, {
          errorCorrectionLevel: "M",
          type: "svg",
          scale: 10,
        })}
      />
      <Text style={{ fontSize: "0.1in", left: "0.25in" }}>{qrCode}</Text>
    </View>
  );
};

const RackLabel = ({ areas }) => {
  //   console.log(JsBarcode().CODE128().render());

  //   const aaa = useRef(<svg> </svg>);
  //   JsBarcode(aaa, "Hello");
  //   console.log(aaa.current);

  //   console.log(<Barcode value="http://github.com/kciter" />);

  return (
    <Document>
      {Object.keys(areas).map((area) => {
        return (
          <>
            <Page size={[inchesTo(3), inchesTo(1.5)]} style={styles.page}>
              {/* <BockLogoComp /> */}
              <View style={styles.labelDetails}>
                <Text style={styles.locText}>{area}</Text>
              </View>
            </Page>

            <Page size={[inchesTo(3), inchesTo(1.5)]} style={styles.page}>
              {/* <BockLogoComp /> */}
              <QRCodeJSX
                style={styles.qrCode}
                qrCode={`location/WH1/${area}`}
              />
            </Page>
          </>
        );
      })}
    </Document>
  );
};

export default RackLabel;
