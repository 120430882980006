import { Page, Text, Document, StyleSheet, Image } from "@react-pdf/renderer";
import { inchesTo } from "./../LabelHelper";
import UL_CFL_LOGO from "./../ULLogos/E329969/Mark_E329969_EN.png";
import UL_HID_LOGO from "./../ULLogos/E329969/Mark_E329969_EN.png";
import UL_INC_LOGO from "./../ULLogos/E329855/Mark_E329855_EN.png";
import UL_LED_LOGO from "./../ULLogos/E475661/Mark_E475661_EN.png";
import React from "react";
import moment from "moment";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  bold: {
    // backgroundColor: '#E4E4E4',
    // "font-weight": 900
  },
});

const BockFixture = ({ labelDetails }) => {
  const ULDatabase = {
    cfl: { logo: UL_CFL_LOGO },
    hid: { logo: UL_HID_LOGO },
    inc: { logo: UL_INC_LOGO },
    led: { logo: UL_LED_LOGO },
  };
  const UL_Logo_To_Use =
    labelDetails.fixture.lampType &&
    ULDatabase[labelDetails.fixture.lampType].logo;

  const ratingLocation = {
    damp: "Damp",
    wet: "Wet",
    dry: "Dry",
  }[labelDetails.fixture.ratingLocation];

  const fixtureMaxWattage = Math.round(labelDetails.fixture.maxWattage);
  const fixtureAmperage = (labelDetails.fixture.maxWattage / 110).toFixed(2);

  return (
    <Document rotate={90}>
      {/* https://www.labelsdirect.com/3-x-15-thermal-transfer-industrial-printer-labels-2-mil-polyester-3-core-8-od-gloss-white-4-rollscase */}
      <Page size={[inchesTo(3), inchesTo(1.5)]}>
        <Text
          style={{
            position: "absolute",
            top: "6pt",
            left: "5pt",
            // margin: 5,
            "overflow-wrap": "normal",
          }}
        >
          Bock Lighting
        </Text>
        <Text
          style={{
            position: "absolute",
            top: "12pt",
            left: "120pt",
            // margin: 5,
            fontSize: "8pt",
            "overflow-wrap": "noral",
          }}
        >
          <b style={styles.bold}>PN: </b>
          {labelDetails.fixture.partNumber}
        </Text>
        <Text
          style={{
            position: "absolute",
            top: "19pt",
            left: "120pt",
            // margin: 5,
            fontSize: "8pt",
            "overflow-wrap": "noral",
          }}
        >
          <b style={styles.bold}>Date:</b>
          {moment().format("YYYY-MM-DD")}
        </Text>
        <Text
          style={{
            position: "absolute",
            top: "25pt",
            left: "5pt",
            // margin: 5,
            fontSize: "8pt",
            "overflow-wrap": "normal",
          }}
        >
          <b style={styles.bold}>SO:</b>
          {labelDetails.fixture.orderNumber}
        </Text>
        <Text
          style={{
            margin: 1,
            fontSize: "7.5pt",
            top: "34pt",
            left: "5pt",
            width: "169pt",
            position: "absolute",
          }}
        >
          MIN 60°C Supply conductors, Suitable for {ratingLocation} Locations,
          RISK OF FIRE, 120-277V {fixtureAmperage}A {fixtureMaxWattage}W 50/60HZ
          This product must be installed in accordance with applicable
          installation code by a person familiar with the construction and
          operation of the product and hazards involved. DOWN LIGHT ONLY!
          CAUTION - THIS LUMINAIRE CANNOT OPERATE A FLUORESCENT LAMP.
        </Text>
        {/*<Text>{`${Date.now()/100000}`}</Text>*/}
        {labelDetails.fixture.UL_Listed && (
          <Image
            src={UL_Logo_To_Use}
            fixed={true}
            style={{
              position: "absolute",
              width: `${0.5}in`,
              left: `${180}pt`,
              top: `${28}pt`,
              // height: '100vh',
              // width: '100vw',
            }}
          />
        )}
      </Page>
    </Document>
  );
};

export default BockFixture;
