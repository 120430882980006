import {Page, Text, View, Document, StyleSheet, Image, Svg, Line,} from "@react-pdf/renderer";
import {inchesTo} from './../LabelHelper'
// import logo from "./../ULLogos/Mark_E329969_EN.png";
import LSILogo from "./../CompanyLogos/lsiLogo.jpg";
import React from "react";
import moment from "moment";
// import QRCode from 'qrcode'


const styles = StyleSheet.create({
    page: {
        // flexDirection: 'row',
        border: 0.5
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    bold: {
        // backgroundColor: '#E4E4E4',
        "font-weight": 900
    },
    lsiLogo: {
        // "transform": `rotate(90deg)`,
        position: 'absolute',
        width: `${1.5}in`,
        // left: `${-1.4}in`,
        left: `${0.1}in`,
        // top: `${1.65}in`,
        top: `${0.1}in`,
    },
    qrCode: {
        position: 'absolute',
        top: `0.2in`,
        left: `${4.75}in`,
        width:`${1}in`,
    },
    labelDetails: {
        position: 'absolute',
        top: 10,
        left: 140,
    },
    shipTo: {
        // position:'absolute',
        // top:10,
        // left:80,
        // fontSize:'0.25in',
    },
    shipToHeader: {
        // position:'absolute',
        // top:10,
        // left:80,
        fontSize: '0.35in',
        fontWeight: 900,
    },
    shipToName: {
        // top:20,
        // left:80,
        fontSize: '0.25in',
    },
    shipToAddress1: {},
    shipToAddress2: {},
    shipToCityStateZip: {}
});

const LSILogoComp = () => <Image style={styles.lsiLogo} src={LSILogo}/>
// const LogoBreakComp = () => <Svg style={{position: 'absolute', top: 0, left: 70}} height="286" width="2">
//     <Line
//         x1="1"
//         y1="0"
//         x2="1"
//         y2="286"
//         strokeWidth={1}
//         stroke="rgb(0,0,0)"
//     />
// </Svg>

// const OrderQRCode = ({qrCode}) => {
//
//     return (
//         <View>
//             {false && qrCode.length !== 0 && <Image style={styles.qrCode} src={qrCode}/>}
//             {/*<Text>We're here to help!</Text>*/}
//         </View>
//     )
// }

const ShipTo = () => < >
    <View style={styles.shipTo}>
        <Text style={styles.shipToHeader}>LSI Industries - Abolite</Text>
        <Text style={styles.shipToName}>10000 Alliance Road</Text>
        <Text style={styles.shipToName}>Cincinnati, Ohio 45242</Text>
        {/*{shipToAddress2.length !== 0 && <Text style={styles.shipToAddress2}>{shipToAddress2}</Text>}*/}
        {/*<Text style={styles.shipToCityStateZip}>{shipToCityStateZip}</Text>*/}
    </View>
</>

const OrderDetails = ({orderNumber, purchaseOrder, partNumber}) => <View style={{...styles.bold, width: '3.8in'}}>
    <Text style={styles.bold}>Date: {moment().format('M/D/yyyy')}</Text>
    <Text style={styles.bold}>PN: {partNumber}</Text>
    <Text style={styles.bold}>DVO: {orderNumber}</Text>
    <Text style={styles.bold}>CP: {purchaseOrder}</Text>
</View>

// const ItemDetails = ({partNumber, qty, type, notes}) => <>
//     <View style={styles.bold}>
//         <Text style={styles.bold}>PN: {partNumber}</Text>
//         {/*{qty || type && <Text style={styles.bold}>{qty && `Qty: ${qty}`}{type && `Type: ${type}`}</Text>}*/}
//         <Text>
//             {type && <Text>Type: {type}</Text>}
//             {type && qty !== 0 && <Text> / </Text>}
//             {qty !==0 && <Text>Qty: {qty}</Text>}
//         </Text>
//         <Text style={styles.bold}>Notes: {notes}</Text>
//     </View>
// </>

const HorizontalLine = () => <Svg height="20" width="700">
    <Line
        x1="0"
        y1="10"
        x2="700"
        y2="10"
        strokeWidth={1}
        stroke="rgb(0,0,0)"
    />
</Svg>

const LSIBox = ({labelDetails}) => {

    return (
        <Document>
            <Page size={[inchesTo(6), inchesTo(4)]} style={styles.page}>
                <LSILogoComp/>
                {/*<LogoBreakComp/>*/}
                <View style={styles.labelDetails}>
                    <ShipTo
                        shipToName={labelDetails.box.shipToName}
                        shipToAddress1={labelDetails.box.shipToAddress1}
                        shipToAddress2={labelDetails.box.shipToAddress2}
                        shipToCityStateZip={`${labelDetails.box.shipToCity} ${labelDetails.box.shipToCity}, ${labelDetails.box.shipToZipCode}`}
                    />
                    <HorizontalLine/>
                    <OrderDetails
                        orderNumber={labelDetails.box.orderNumber}
                        purchaseOrder={labelDetails.box.purchaseOrder}
                        partNumber={labelDetails.box.partNumber}
                    />
                    {/*<HorizontalLine/>*/}
                    {/*<ItemDetails*/}
                    {/*    partNumber={labelDetails.box.partNumber}*/}
                    {/*    qty={labelDetails.box.qty}*/}
                    {/*    type={labelDetails.box.type}*/}
                    {/*    notes={labelDetails.box.notes}*/}
                    {/*/>*/}
                </View>
                {/*<OrderQRCode qrCode={qrCode}/>*/}
            </Page>
        </Document>
    )
}


export default LSIBox