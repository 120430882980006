import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useFirebaseContext } from "../Firebase";
import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Page,
  Spinner,
  TextField,
  Toast,
} from "@shopify/polaris";

const ShipdeskPage = () => {
  const { shipmentID } = useParams();

  const [shipment, setShipment] = useState({});

  const { expressFunction } = useFirebaseContext();
  const getShipment = expressFunction("getShipment");
  const updateShipment = expressFunction("updateShipment");
  const [showToast, setShowToast] = useState(false);
  const [toastContent, setToastContent] = useState("");

  useEffect(() => {
    getShipment({ shipmentID }).then((response) => {
      console.log(response);
      setShipment(response);
    });
  }, [shipmentID]);

  if (shipment.shiphead_number === undefined) {
    return <Spinner size="large" color="teal" accessibilityLabel="Loading" />;
  }

  const handleChange = (field) => (value) => {
    setShipment({ ...shipment, [field]: value });
  };

  const handleSave = (e) => {
    setShowToast(true);
    setToastContent("Updating Shipment...");
    e.preventDefault();
    updateShipment(shipment).then((response) => {
      // show success message using shopify polaris

      setTimeout(() => {
        document.location.href = "/shipdesk";
      }, 1000);
    });
  };

  return (
    <Page
      title={`Edit Shipment: ${shipment.shiphead_number} for Sales Order: ${shipment.cohead_number}`}
      fullWidth={true}
    >
      {showToast && (
        <Toast content={toastContent} onDismiss={() => setShowToast(false)} />
      )}
      <Form>
        <TextField
          label="Tracking Number"
          value={shipment.shiphead_tracknum}
          onChange={handleChange("shiphead_tracknum")}
          type="text"
        />
        <TextField
          label="Ship Via"
          value={shipment.shiphead_shipvia}
          onChange={handleChange("shiphead_shipvia")}
          type="text"
        />
        <TextField
          label="Freight"
          value={shipment.shiphead_freight}
          onChange={handleChange("shiphead_freight")}
          type="currency"
        />
        <Button primary onClick={handleSave}>
          Update
        </Button>
      </Form>
    </Page>
  );
};

export default ShipdeskPage;
