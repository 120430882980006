import { useState, useEffect } from "react";
import { useFirebaseContext } from "../../Firebase";
import ItemNotes from "././ItemNotes";
import { Gallery } from "react-grid-gallery";

import {
  Heading,
  List,
  TextField,
  TextContainer,
  Page,
  Badge,
  Thumbnail,
  Modal,
  Pagination,
  Card,
  Layout,
  Image,
  Form,
  DropZone,
} from "@shopify/polaris";
import ImageSection from "./ImageSection";
import QuickNoteSection from "./QuickNoteSection";
import GLBSection from "./GLBSection";
import TagsSection from "./TagsSection";
import ItemFile from "./ItemFile";
import SourcingSection from "./SourcingSection";

const ItemPage = (props) => {
  const itemID = props.match.params["itemID"];

  const { reactFunction, expressFunction } = useFirebaseContext();
  const getItem = expressFunction("getItem");

  const [itemObj, setItemObj] = useState({});

  useEffect(() => {
    !itemObj.item_id &&
      getItem({ itemID }).then((data) => {
        setItemObj(data);
        console.log("data.data", data);
      });
  }, [itemObj]);

  if (itemObj.item_id === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <PageWrapper itemObj={itemObj}>
      <Layout>
        <Layout.Section>
          <ItemDetails itemObj={itemObj} />
          {/* <Card
            title="Inventory"
            sectioned
            actions={[
              {
                content: "RFP",
                onAction: () => console.log("clicked"),
              },
              {
                content: "Report Issue",
                onAction: () => console.log("clicked"),
              },
            ]}
          >
            <TextContainer>
              <p>
                <strong>Item Number:</strong> {itemObj.item_number}
              </p>
              <p></p>
            </TextContainer>
          </Card>
          <Card title="Active Orders" sectioned>
            <TextContainer>
              <p>
                <strong>Item Number:</strong> {itemObj.item_number}
              </p>
              <p></p>
            </TextContainer>
          </Card> */}
          <SourcingSection itemObj={itemObj} />
          <ItemFile itemObj={itemObj} />
        </Layout.Section>
        <Layout.Section secondary>
          <ImageSection itemObj={itemObj} />
          <QuickNoteSection itemObj={itemObj} />
          <GLBSection itemObj={itemObj} />
          <TagsSection itemObj={itemObj} />
        </Layout.Section>
      </Layout>
      {/* <TextContainer>
        <Heading>{itemObj.item_number}</Heading>
        <Heading>{itemObj.item_descrip1}</Heading>
        <Heading>{itemObj.item_descrip2}</Heading>
      </TextContainer>
      <hr />
      <ItemNotes itemObj={itemObj} /> */}
    </PageWrapper>
  );
};

const PageWrapper = (props) => {
  const { children, itemObj } = props;
  return (
    <Page
      title={`${itemObj.item_number} - ${itemObj.item_descrip1}`}
      breadcrumbs={[
        {
          content: "Back",
          url: "/items",
        },
      ]}
      // primaryAction={{
      //   content: "Save",
      //   onAction: () => console.log("clicked save"),
      // }}
      // secondaryActions={[
      //   {
      //     content: "Print Label",
      //     onAction: () => console.log("clicked duplicate"),
      //   },
      // ]}
      // actionGroups={[
      //   {
      //     title: "Promote",
      //     actions: [
      //       {
      //         content: "Share on Facebook",
      //         onAction: () => console.log("clicked share on facebook"),
      //       },
      //     ],
      //   },
      //   {
      //     title: "More actions",
      //     actions: [
      //       {
      //         content: "Embed on a website",
      //         onAction: () => console.log("clicked embed on a website"),
      //       },
      //       {
      //         content: "Share on Twitter",
      //         onAction: () => console.log("clicked share on twitter"),
      //       },
      //     ],
      //   },
      // ]}
      fullWidth={true}
      separator={true}
      singleColumn
      subtitle={itemObj.item_descrip2}
      thumbnail={
        <Thumbnail
          source="https://burst.shopifycdn.com/photos/smiling-businesswoman-in-office.jpg?width=1850"
          alt="Black choker necklace"
        />
      }
    >
      {children}
    </Page>
  );
};

const ItemDetails = ({ itemObj }) => {
  return (
    <Card
      title="Item Details"
      sectioned
      actions={[
        {
          content: "Edit (Not Implemented Yet))",
          onAction: () => console.log("clicked"),
        },
      ]}
    >
      <TextContainer>
        <p>
          <strong>Item Number:</strong> {itemObj.item_number}
        </p>
        <p>
          <strong>Item Descption 1:</strong> {itemObj.item_descrip1}
        </p>
        <p>
          <strong>Item Descption 2:</strong> {itemObj.item_descrip2}
        </p>
        <p></p>
      </TextContainer>
    </Card>
  );
};

export default ItemPage;
