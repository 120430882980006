import React, { useContext, useEffect, useState } from "react";
import { useFirebaseContext } from "../Firebase";
import Tabs from "../Commons/Tabs";
import Tag from "../Commons/Tag";
import { Page } from "@shopify/polaris";
import SubscriptionType from "./SubscriptionType";
import ToastContext from "../../Context/Toast/ToastContext";
const Subscriptions = (props) => {
  const { reactFunction, expressFunction } = useFirebaseContext();
  const getSubscriptionTags = expressFunction("getSubscriptionTags");
  const getMySubscriptionTags = expressFunction("getMySubscriptionTags");
  const saveMySubscriptionTags = reactFunction("saveMySubscriptionTags");
  const [tags, setTags] = useState([]);
  const [types, setTypes] = useState([]);
  const [tabItems, setTabItems] = useState({});
  const [selected, setSelected] = useState({});
  const [changed, setChanged] = useState(false);
  const { pushNewToast } = useContext(ToastContext);
  useEffect(async () => {
    const response = await getMySubscriptionTags();
    if (response) setTags(response);
  }, []);

  useEffect(() => {
    if (types.length === 0) {
      const types = new Set(tags.map((i) => i.type));
      setTypes(Array.from(types));
    }
  }, [tags]);

  const updateSelected = (item, e) => {
    const newState = { ...selected };
    Object.keys(newState[item]).map(
      (i) => (newState[item][i] = e.includes(i) ? true : false)
    );
    e.map((i) => (newState[item][i] = true));
    setSelected(newState);
    changed === false && setChanged(true);
  };
  useEffect(() => {
    if (types.length) {
      const buildSelected = {};

      types.map((item) => {
        const x = {};

        tags
          .filter((f) => item === f.type)
          .map((i) =>
            Object.assign(x, {
              [i.subscription_id]: i.user_subscription_id !== null,
            })
          );
        buildSelected[item] = x;
      });
      setSelected(buildSelected);
    }
  }, [types]);

  useEffect(() => {
    if (types.length) {
      const x = {};

      types.map((item) => {
        const title =
          item.charAt(0).toUpperCase() +
          item.slice(1).replaceAll("_tag", "") +
          `s`;
        x[item] = {
          title: title,
          content: (
            <>
              <SubscriptionType
                title={title}
                selected={Object.keys(selected[item])
                  .filter((f) => selected[item][f] === true)
                  .map(Number)}
                onChange={(e) => updateSelected(item, e)}
                options={tags
                  .filter((f) => f.type === item)
                  .map((i) =>
                    Object.assign({
                      value: i.subscription_id,
                      label: (
                        <>
                          <Tag {...i} value={i.shortname} />
                        </>
                      ),
                    })
                  )}
              />
            </>
          ),
        };
      });
      setTabItems(x);
    }
  }, [selected]);

  const onSaveAction = async () => {
    const x = {};
    Object.keys(selected).map((i) =>
      Object.keys(selected[i]).map((e) => (x[e] = selected[i][e]))
    );

    const res = await saveMySubscriptionTags({ tags: x });

    if (res) pushNewToast({ message: "Changes saved successfully" });
  };
  return (
    <>
      <Page
        breadcrumbs={[{ content: "Tags", url: "/tags" }]}
        title="Tags: Subscriptions"
        subtitle="Manage your Tag subscriptions"
        compactTitle
        primaryAction={{
          content: "Save",
          disabled: !changed,
          onAction: onSaveAction,
        }}
      >
        <Tabs tabs={tabItems} flush />
      </Page>
    </>
  );
};

export default Subscriptions;
