/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Badge, SkeletonDisplayText } from "@shopify/polaris";
import React, { useState, useContext, useEffect } from "react";
import { TodoListContext } from "../../../Context/TodoListContext";
import BomLine from "./BomLine";
import Tabs from "../../Commons/Tabs";
import {
  Listbox,
  TextField,
  Icon,
  Box,
  Link,
  Card,
  Divider,
  SkeletonBodyText,
  Button,
  Popover,
  AutoSelection,
  Scrollable,
  Stack,
  Tag,
  EmptySearchResult,
  Text,
} from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import { Route, useHistory, useRouteMatch } from "react-router-dom";

const Todos = (props) => {
  const {
    lines,
    todoList: todos,
    taskLines,
    regenBom,
  } = useContext(TodoListContext);
  const [tabItems, setTabItems] = useState({});
  const history = useHistory();
  const match = useRouteMatch();

  const { path, url } = match;

  const [pickerOpen, setPickerOpen] = useState(false);
  const [query, setQuery] = useState("");

  const [filteredSegments, setFilteredSegments] = useState([]);

  const handleFilterSegments = (string) => {
    const nextFilteredSegments = taskLines.filter((segment) => {
      return segment
        .toLocaleLowerCase()
        .includes(query.toLocaleLowerCase().trim());
    });

    setFilteredSegments(nextFilteredSegments);
  };

  const handleQueryChange = (string) => {
    setQuery(query);

    if (query.length >= 2) handleFilterSegments(query);
  };

  const handleQueryClear = () => {
    handleQueryChange("");
  };

  const handleTogglePicker = () => setPickerOpen(!pickerOpen);

  const handleOpenPicker = () => {
    setPickerOpen(true);
  };

  const handleClosePicker = () => {
    setPickerOpen(false);
    handleQueryChange("");
  };
  const [activeBomLine, setActiveBomLine] = useState(null);

  useEffect(() => {
    console.log(tabItems, "tabItems");

    setActiveBomLine(Object.keys(tabItems)[0]);
  }, [tabItems]);

  const handleSegmentSelect = (coitem_id) => {
    setActiveBomLine(coitem_id);
    history.push(`${url}/${coitem_id}`);
    handleClosePicker();
  };

  const Activator = () => {
    const [activeItem, setActiveItem] = useState(activeBomLine);

    useEffect(() => {
      activeBomLine
        ? setActiveItem(
            lines.find((f) => parseInt(f.coitem_id) === parseInt(activeBomLine))
          )
        : setActiveItem(null);
    }, [activeBomLine]);

    if (!activeItem) return <SkeletonDisplayText />;
    return (
      <Button
        disclosure={pickerOpen ? "up" : "down"}
        onClick={handleTogglePicker}
      >
        Viewing: Line {activeItem.coitem_linenumber}
        {activeItem.coitem_custpn?.length > 0 &&
          ` - ${activeItem.coitem_custpn}`}
      </Button>
    );
  };

  const textFieldMarkup = (
    <div style={{ padding: "12px" }}>
      <StopPropagation>
        <TextField
          clearButton
          labelHidden
          label="Search BOM lines"
          placeholder="Search BOM lines"
          autoComplete="off"
          value={query}
          prefix={<Icon source={SearchMinor} />}
          onChange={handleQueryChange}
          onClearButtonClick={handleQueryClear}
        />
      </StopPropagation>
    </div>
  );

  const segmentOptions = query ? filteredSegments : tabItems;

  const segmentList =
    Object.keys(tabItems).length > 0
      ? Object.keys(tabItems).map((coitem_id) => {
          const item = lines.find(
            (f) => parseInt(f.coitem_id) === parseInt(coitem_id)
          );
          const selected = parseInt(coitem_id) === parseInt(activeBomLine);
          return (
            <Listbox.Option
              key={coitem_id}
              value={coitem_id}
              selected={selected}
            >
              <Listbox.TextOption selected={selected}>
                <div
                  css={css`
                    display: flex;
                    width: 100%;
                  `}
                >
                  <>
                    Line {item.coitem_linenumber}
                    {item.coitem_custpn.length > 0 &&
                      ` - ${item.coitem_custpn}`}
                  </>
                  <span
                    css={css`
                      margin-left: auto;
                    `}
                  >
                    <Text variant="bodySm" color="subdued">
                      Qty ordered: {parseInt(item.coitem_qtyord)}
                    </Text>
                  </span>
                </div>
              </Listbox.TextOption>
            </Listbox.Option>
          );
        })
      : null;

  const noResultsMarkup =
    segmentOptions.length === 0 ? (
      <EmptySearchResult
        title=""
        description={`No lines found matching "${query}"`}
      />
    ) : null;

  const listboxMarkup = (
    <Listbox
      enableKeyboardControl
      autoSelection={AutoSelection.FirstSelected}
      onSelect={handleSegmentSelect}
    >
      {segmentList}
      {noResultsMarkup}
    </Listbox>
  );

  useEffect(() => {
    const tabs = {};

    console.log(taskLines, "taskLines");
    taskLines.map((line, k) => {
      console.log(line, "line");
      const item = lines.filter((f) => f.coitem_id === line)[0];

      console.log(parseInt(item.coitem_qtyord), "item.coitem_qtyord");
      tabs[item.coitem_id] = {
        title: (
          <>
            {item.coitem_custpn} ({item.coitem_id}){" "}
            {item.tasks.filter((f) => f.qty_pulled < f.qty_total).length >
              0 && (
              <Badge status="new">
                {item.tasks.filter((f) => f.qty_pulled < f.qty_total).length}
              </Badge>
            )}
          </>
        ),
        content: (
          <>
            <BomLine
              coItemId={item.coitem_id}
              coItemQty={parseInt(item.coitem_qtyord)}
              coItemCustPn={item.coitem_custpn}
              tasks={item.tasks}
            />
          </>
        ),
      };
    });
    setTabItems(tabs);
  }, [taskLines]);

  // useEffect(() => {}, [activeBomLine]);

  const [regenLoading, setRegenLoading] = useState(false);

  const onClickRegen = async () => {
    setRegenLoading(true);

    console.log(activeBomLine, "activeBomLine");
    const regenerate = await regenBom(activeBomLine);
    setRegenLoading(false);
  };

  return (
    <>
      <Box
      // paddingInlineStart="4"
      >
        {activeBomLine ? (
          <Box paddingBlockEnd="4">
            <Stack>
              <Stack.Item fill>
                <Stack alignment="center">
                  <Popover
                    active={pickerOpen}
                    activator={<Activator />}
                    ariaHaspopup="listbox"
                    preferredAlignment="left"
                    autofocusTarget="first-node"
                    onClose={handleClosePicker}
                  >
                    <Popover.Pane fixed>
                      <div
                        style={{
                          alignItems: "stretch",
                          borderTop: "1px solid #DFE3E8",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "stretch",
                          position: "relative",
                          width: "100%",
                          height: "100%",
                          overflow: "hidden",
                        }}
                      >
                        {textFieldMarkup}

                        <Scrollable
                          shadow
                          style={{
                            position: "relative",
                            width: "310px",
                            // height: '292px',
                            padding: "var(--p-space-2) 0",
                            borderBottomLeftRadius: "var(--p-border-radius-2)",
                            borderBottomRightRadius: "var(--p-border-radius-2)",
                          }}
                          // onScrolledToBottom={handleLazyLoadSegments}
                        >
                          {listboxMarkup}
                        </Scrollable>
                      </div>
                    </Popover.Pane>
                  </Popover>
                  <Tag>
                    Qty ordered:{" "}
                    {parseInt(
                      lines.find((f) => f.coitem_id === parseInt(activeBomLine))
                        .coitem_qtyord
                    )}
                  </Tag>
                </Stack>
              </Stack.Item>
              <Button outline onClick={onClickRegen} loading={regenLoading}>
                Regenerate BOM
              </Button>
            </Stack>
          </Box>
        ) : (
          <Box paddingBlockEnd="4">
            <SkeletonDisplayText />
          </Box>
        )}
      </Box>
      <Route path={[path, `${path}/:coItemId`]}>
        {activeBomLine && lines.length ? (
          <BomLine
            coItemId={activeBomLine}
            coItemQty={parseInt(
              lines.filter((f) => f.coitem_id === parseInt(activeBomLine))[0]
                .coitem_qtyord
            )}
            coItemCustPn={
              lines.filter((f) => f.coitem_id === parseInt(activeBomLine))[0]
                .coitem_cust_pn
            }
            tasks={
              lines.filter((f) => f.coitem_id === parseInt(activeBomLine))[0]
                .tasks
            }
          />
        ) : (
          <>
            {Array.from(Array(5).keys()).map((a) => (
              <Box
                paddingInlineStart="4"
                paddingInlineEnd="4"
                paddingBlockEnd="4"
              >
                <SkeletonBodyText />
                <Box paddingBlockStart="4">
                  <Divider borderStyle="base" />
                </Box>
              </Box>
            ))}
          </>
        )}
      </Route>
      {/* render={(props) => <>{activeBomLine && lines.length && }</>} /> */}
      {/* {activeBomLine && lines.length &&
        <><BomLine
          coItemId={activeBomLine}
          coItemCustPn={lines.filter(f => f.coitem_id === parseInt(activeBomLine))[0].coitem_cust_pn}
          tasks={lines.filter(f => f.coitem_id === parseInt(activeBomLine))[0].tasks}
        /></>} */}
      {/* <Tabs flush tabs={tabItems} /> */}
    </>
  );
};

const StopPropagation = ({ children }) => {
  const stopEventPropagation = () => {
    event.stopPropagation();
  };

  return (
    <div onClick={stopEventPropagation} onTouchStart={stopEventPropagation}>
      {children}
    </div>
  );
};

export default Todos;
