import { useFirebaseContext } from "../Firebase";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Autocomplete,
  Icon,
  TextField,
  TextContainer,
  Modal,
} from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
const CharacteristicAddNew = (props) => {
  const {
    open,
    forItem,
    editingItem,
    onClose,
    activator,
    addCharassItem,
    editCharassItem,
  } = props;
  const { expressFunction } = useFirebaseContext();

  // useEffect(() => {
  //   console.log(open, "open");
  // }, [open]);
  const getItemsByTitle = expressFunction("getItemsByTitle");

  useEffect(async () => {
    if (editingItem) {
      const opts = await updateText(editingItem.item_number);

      setSelectedOptions([editingItem.item_id]);
      setSelectedObj(opts.filter((f) => f.value === editingItem.item_id)[0]);
      setQuantitySelected(editingItem.qty);
    }
  }, [open]);

  useEffect(() => {
    if (open === false) {
      setSelectedOptions([]);
      setQuantitySelected(1);
      setInputValue("");
    }
  }, [open]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [quantitySelected, setQuantitySelected] = useState(1);
  const updateText = useCallback(
    async (value) => {
      setInputValue(value);

      if (!loading) {
        setLoading(true);
      }

      if (value === "") {
        setOptions([]);
        setLoading(false);
        return;
      }

      const getRes = await getItemsByTitle({
        title: value,
        fields: ["item_id", "item_number"],
      });

      const opts = getRes.map((item) =>
        Object.assign({
          value: item.item_id,
          label: item.item_number,
          ...item,
        })
      );

      setOptions(opts);
      setLoading(false);
      return opts;
    },
    [loading]
  );

  const [selectedObj, setSelectedObj] = useState({});
  //
  const updateSelection = useCallback(
    (selected) => {
      setSelectedOptions(selected);
      setSelectedObj(options.filter((f) => f.value === selected[0])[0]);

      console.log(
        options.filter((f) => f.value === selected[0]),
        "1"
      );
      console.log(options.filter((f) => f.value === selected[0])[0], "2");
      console.log(options.filter((f) => f.value === selected[0])[0].label, "3");

      setInputValue(options.filter((f) => f.value === selected[0])[0].label);
    },
    [options]
  );

  const submitItem = () => {
    if (editingItem) editCharassItem(selectedObj, parseInt(quantitySelected));
    else
      addCharassItem(
        { item_id: selectedObj.item_id, item_number: selectedObj.item_number },
        parseInt(quantitySelected)
      );
    onClose();
  };

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label="Item"
      value={inputValue}
      prefix={<Icon source={SearchMinor} color="base" />}
      placeholder="Search"
    />
  );

  return (
    <Modal
      activator={activator}
      open={open}
      onClose={() => onClose()}
      title={
        editingItem
          ? `Edit default item for ${forItem}`
          : `Add new default item for ${forItem}`
      }
      primaryAction={{
        content: editingItem ? `Save changes` : `Add default item`,
        onAction: () => submitItem(),
        disabled: selectedOptions.length === 0 || quantitySelected === 0,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => onClose(),
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <Autocomplete
            options={options}
            selected={selectedOptions}
            onSelect={updateSelection}
            loading={loading}
            textField={textField}
          />

          {selectedOptions.length > 0 && (
            <TextField
              label="Quantity"
              type="number"
              helpText="How many should be added by default?"
              value={quantitySelected}
              onChange={(e) => setQuantitySelected(e)}
              autoComplete="off"
            />
          )}
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
};

export default CharacteristicAddNew;
