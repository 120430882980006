import { Route, Switch } from "react-router-dom";
// import Listings from "../TicketMasterTracker/Listings";
// import DiscountFinder from "../toolsTCT/discountFinder";
// import TicketEvo from "../TicketEvo";
import React from "react";
import { useFirebaseContext } from "./Firebase";
import SelfUserSettings from "./SelfUserSettings";
import OrdersListPage from "./OrdersListPage";
import OrderPage from "./OrderPage/index";
import Labels from "./Labels/index";
import BomLogicPage from "./BomLogic";
import BomLogicPage2 from "./BomLogic/BomLogic";
import ItemList from "./Item/ItemList";
import ItemPage from "./Item/ItemPage";
import RackingLabelPrintPage from "./Labels/WarehouseLabels/Racking";
import LocationList from "./Warehouse/LocationList";
import LocationPage from "./Warehouse/LocationPage";
import ConfigurableItemPage from "./BomLogic/ConfigurableItemPage/index";
import Simulator from "./BomLogic/Simulator";
import AddCharacteristic from "./BomLogic/ConfigurableItemPage/AddCharacteristic";
import ItemCharacteristicsPage from "./BomLogic/ConfigurableItemPage/ItemCharacteristicsPage";
import EditCharacteristic from "./BomLogic/ConfigurableItemPage/EditCharacteristic";
import FindOrderByChars from "./OrderSearch/FindOrderByChars";
import PositivePay from "./Accounting/PositivePay";
import NewItem from "./Item/NewItem";
import Tasks from "./Tasks";
// import AddNewLogicTag from "./BomLogic/BomLogic/tags/AddNewLogicTag";
// import BomLogicPageItem from './BomLogic/BomLogic/BomLogicPageItem'
// import BomLogicPageItemItem from './BomLogic/BomLogic/BomLogicPageItemItem'
// import BomLogicListTags from './BomLogic/BomLogic/tags/List'
import Characteristics from "./Characteristic/Characteristics";
import CharacteristicPage from "./Characteristic/Characteristic";
import Rules from "./BomLogic/BomLogic/Rules";
import Rule from "./BomLogic/BomLogic/Rule";
// import BomLogicList from './BomLogic/BomLogic/index.js'
import Tags from "./Tags";
import AddNewTag from "./Tags/AddNewTag";
import Subscriptions from "./Tags/Subscriptions";
import ShipdeskList from "./Shipdesk/ShipdeskList";
import ShipdeskPage from "./Shipdesk/ShipdeskPage";
const AdminRouting = () => {
  const { permissions } = useFirebaseContext();

  return (
    <>
      {(true || permissions.orders) && (
        <>
          <Route exact={true} path="/orders" component={OrdersListPage} />
          <Route
            exact={true}
            path="/orders/FindOrderByChars"
            component={FindOrderByChars}
          />
        </>
      )}
      {(true || permissions.orders) && (
        <Route exact={true} path="/items" component={ItemList} />
      )}
      {(true || permissions.orders) && (
        <Route exact={true} path="/warehouse" component={LocationList} />
      )}
      {(true || permissions.orders) && (
        <Route
          exact={true}
          path="/warehouse/:locationID"
          component={LocationPage}
        />
      )}
      {(true || permissions.orders) && (
        <Route
          // exact={true}
          path="/order/:orderNumber"
        >
          <OrderPage />
        </Route>
      )}
      {(true || permissions.orders) && (
        <>
          <Switch>
            <Route exact={true} path="/item/new" component={NewItem} />
            <Route exact={true} path="/item/:itemID" component={ItemPage} />
          </Switch>
        </>
      )}
      {(true || permissions.orders) && (
        <Route exact={true} path="/labels" component={Labels} />
      )}
      {true && (
        <Route
          exact={true}
          path="/RackingLabelPrintPage"
          component={RackingLabelPrintPage}
        />
      )}
      <Route path="/bom_logic" exact={true} component={BomLogicPage} />
      <Route path="/bom" exact={true} component={BomLogicPage2} />

      <Route path="/tags" exact={true} component={Tags} />
      <Route path="/tags/new" exact={true} component={AddNewTag} />
      <Route path="/tags/tag/:tagId" exact={true} component={AddNewTag} />
      <Route
        path="/tags/subscriptions"
        exact={true}
        component={Subscriptions}
      />
      <Route path="/tasks" exact={true} component={Tasks} />
      <Route exact path="/characteristics" component={Characteristics} />
      <Route exact path="/bom/rules" component={Rules} />
      <Route exact path="/bom/rule/:ruleId" component={Rule} />
      <Route exact path="/bom/simulator" component={Simulator} />
      <Route
        // exact
        path="/characteristic/:charassId"
        component={CharacteristicPage}
      />
      {/* <Route
        exact
        path="/bom/characteristic/new"
        component={CharacteristicPage}
      /> */}
      {/* <Route
        exact
        path="/bom/:bomLogicItem"
        component={BomLogicPageItem}
      /> */}
      {/* <Route
        exact
        path="/bom/:bomLogicItem/:bomLogicItemItem"
        component={BomLogicPageItemItem}
      /> */}
      {/* <Route exact path="/bomLogicList" component={BomLogicList} /> */}
      {/* <Route
        exact
        path="/bomLogicTags/:bomLogicTag/"
        component={AddNewLogicTag}
      />{" "}
      <Route exact path="/AddNewBomLogicTags" component={AddNewLogicTag} /> */}

      {permissions.editBOM && (
        <>
          <Route
            path="/bom_logic/item/:itemID/add_characteristics/:fieldID"
            exact={true}
            component={AddCharacteristic}
          />
          <Route
            path="/bom_logic/item/:itemID/characteristics/:charID/:charassID"
            exact={true}
            component={EditCharacteristic}
          />
          <Route
            path="/bom_logic/item/:itemID/characteristics/:charID"
            exact={true}
            component={ItemCharacteristicsPage}
          />
          <Route
            path="/bom_logic/item/:itemID/add_characteristics"
            exact={true}
            component={AddCharacteristic}
          />
          <Route
            path="/bom_logic/item/:itemID"
            exact={true}
            component={ConfigurableItemPage}
          />
        </>
      )}
      {permissions &&
        permissions.positivePay !== undefined &&
        permissions.positivePay === true && (
          <>
            <Route path="/positive_pay" exact={true} component={PositivePay} />
          </>
        )}
      {permissions &&
        permissions.shipdesk !== undefined &&
        permissions.shipdesk === true && (
          <>
            <Route path="/shipdesk" exact={true} component={ShipdeskList} />
            <Route
              path="/shipdesk/:shipmentID"
              exact={true}
              component={ShipdeskPage}
            />
          </>
        )}

      {/*{permissions.tdcc && <Route path="/tdc">*/}
      {/*    <DiscountFinder/>*/}
      {/*</Route>}*/}
      {/*{permissions.ticketEvoBulkPurchase && <Route path="/ticketevo/bulkbuyer">TicketEvoPage - Use Bookmarklet</Route>}*/}
      {/*{permissions.ticketEvoBulkPurchase && <Route path="/ticketevo/bulkbuyer/:eventID" component={TicketEvo}/>}*/}
      <Route exact={true} path="/users">
        {/*<Users />*/}
      </Route>
      <Route exact path={"/settings"} component={SelfUserSettings} />
      {/*<Route path="/abc">*/}
      {/*    <Abc />*/}
      {/*</Route>*/}
      <Route exact={true} path="/">
        {/*<Home />*/}
      </Route>
    </>
  );
};

export default AdminRouting;
