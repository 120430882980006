/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useFirebaseContext } from "../../../../Firebase";
import React, { useState, useEffect } from "react";
import LogicBuilderContext from "./LogicBuilderContext";
import { Query, Builder, Utils, BasicFuncs } from "react-awesome-query-builder";
import MuiConfig from "react-awesome-query-builder/lib/config/mui";
import MuiWidgets from "react-awesome-query-builder/lib/components/widgets/mui";

import throttle from "lodash/throttle";
import loadedConfigAction from "./config_action";
import loadedConfigRule from "./config_rule"; // <- you
// import { actionDataPackage, entitySuggest, activeHost } from "../DrupalHelper";
// import mapSingle from "../mapSingle";
// import { loadPreloaded } from "../helperForPreload";
import charJson from "../../../../characteristics.json";
// import { BasicConfig } from "react-awesome-query-builder/lib/config/mui";
import merge from "lodash/merge";
import {
  transformJsonLogicToRulesEngine,
  getTitleInListValues,
  stringifyForDisplay,
} from "./utils";
import Select from "react-select";
var _ = require("lodash");
import { useIsMount } from "../../../../utils.js";
const InitialConfig = MuiConfig;
const {
  jsonLogicFormat,
  queryString,
  mongodbFormat,
  sqlFormat,
  getTree,
  checkTree,
  loadTree,
  uuid,
  loadFromJsonLogic,
} = Utils;

const emptyInitValue = {
  id: uuid(),
  type: "group",
  properties: {
    conjunction: "AND",
    not: false,
  },
};

const {
  // MuiBooleanWidget,
  // MuiTextWidget,
  // MuiTextAreaWidget,
  // MuiDateWidget,
  // MuiTimeWidget,
  // MuiDateTimeWidget,
  // MuiMultiSelectWidget,
  MuiSelectWidget,
  // MuiNumberWidget,
  // MuiSliderWidget,
  // MuiRangeWidget,
  MuiAutocompleteWidget,
  // MuiFieldSelect,
  // MuiFieldAutocomplete,
  // MuiConjs,
  // MuiSwitch,
  // MuiButton,
  // MuiButtonGroup,
  // MuiValueSources,
  // MuiProvider,
  // MuiConfirm,
  // MuiUseConfirm,
} = MuiWidgets;

const LogicBuilderProvider = (props) => {
  const isMount = useIsMount();
  const { reactFunction, expressFunction } = useFirebaseContext();
  // const getCharacteristicTypes = expressFunction("getCharacteristicTypes")
  const getConfigurableChars = expressFunction("getConfigurableChars");
  const getTags = expressFunction("getTags");
  const getCharacteristics = expressFunction("getCharacteristics");
  const getCharacteristic = expressFunction("getCharacteristic");
  // const getItemsByTitle = reactFunction("getItemsByTitle")
  const getItemsList = expressFunction("getItemsList");

  const [ruleConfig, setRuleConfig] = useState({});
  const [ruleTree, setRuleTree] = useState(null);
  const [actionConfig, setActionConfig] = useState({});
  const [actionTree, setActionTree] = useState(null);

  const [fetchedDataPart, setFetchedDataPart] = useState([]);

  const [pbData, setPbData] = useState([]);
  const [result, setResult] = useState({});
  const [resultConditions, setResultConditions] = useState({});
  const [resultEvents, setResultEvents] = useState({});
  const [showAction, setShowAction] = useState(false);
  const [showLoader, setShowloader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [packageId, setPackageId] = useState(null);
  const [builderLodaing, setBuilderLoading] = useState(false);

  const getAsyncFetch = async (search, offset, charId) => {
    const obj = {
      // page: offset
    };
    if (search !== "" && search !== null) obj.title = search;
    if (charId) obj.types = [charId];
    const fetch = await getCharacteristics(obj);
    return {
      values: fetch.data.map((i) =>
        Object.assign({
          value: i.charass_id.toString(),
          title: `${i.charass_value} (${i.charass_id.toString()})`,
        })
      ),
      hasMore: true,
    };
  };

  useEffect(async () => {
    // config_rule

    const generateFields = async () => {
      const getTypes = await getConfigurableChars();
      const getTagsData = await getTags({ types: ["charass_tag"] });

      let subfieldObj = {};

      charJson
        .filter((f, k) => k < 170)
        .map((a, b) => {
          Object.assign(subfieldObj, {
            [a.charass_id]: {
              label: a.charass_value,
              type: "number",
              operators: ["equal"],
              valueSources: ["value"],
              preferWidgets: ["number"],
              fieldSettings: {
                min: 0,
                max: 1000,
                hideOperator: true,
              },
            },
          });
        });

      const charassTags = {};
      getTagsData.map((item, k) => {
        return Object.assign(charassTags, {
          [item.id]: {
            label: item.shortname,
            type: "number",
          },
        });
      });

      const set_types = {};

      getTypes.map((item, k) => {
        const { char_id } = item;
        return Object.assign(set_types, {
          [item.char_id]: {
            label: item.char_name,
            type: "!group",
            mode: "array",
            operators: [
              // w/ operand - count
              "equal",
              "not_equal",
              "less",
              "less_or_equal",
              "greater",
              "greater_or_equal",
              "between",
              "not_between",

              // w/o operand
              "some",
              "all",
              "none",
            ],

            subfields: {
              charass_id: {
                label: "Charass",
                fieldSettings: {
                  asyncFetch: async (search, offset) => {
                    const res = await getAsyncFetch(search, offset, char_id);
                    return res;
                  },
                  useAsyncSearch: false,
                  useLoadMore: false,
                  forceAsyncSearch: false,
                  allowCustomValues: false,
                },
                type: "select",
                valueSources: ["value"],
              },
              charass_tag: {
                label: "Charass Tag",
                type: "select",
                fieldSettings: {
                  listValues: getTagsData.map((i) =>
                    Object.assign({ value: i.id, title: i.shortname })
                  ),
                },
                valueSources: ["value"],
              },
            },
          },
        });
      });

      let confi = {
        ...set_types,
      };

      return confi;
    };

    const fields = await generateFields();

    //////////////////////////////////////////////////////////////////////

    const conjunctions = {
      AND: InitialConfig.conjunctions.AND,
      OR: InitialConfig.conjunctions.OR,
    };

    const operators = {
      ...InitialConfig.operators,
      between: {
        ...InitialConfig.operators.between,
        textSeparators: ["from", "to"],
      },
      in: {
        ...InitialConfig.operators.select_any_in,
        label: "Includes",
        // labelForFormat: "IN",
        // sqlOp: "IN",
        formatOp: function formatOp(
          field,
          op,
          values,
          valueSrc,
          valueType,
          opDef,
          operatorOptions,
          isForDisplay
        ) {
          // console.log(values);
          if (valueSrc == "value") return "".concat(field, " IN (");
          else return "".concat(field, " IN (");
        },
        // sqlFormatOp: function sqlFormatOp(field, op, values, valueSrc, valueType, opDef, operatorOptions) {
        //   return "".concat(field, " IN (").concat(values.join(", "), ")");
        // },
        // mongoFormatOp: mongoFormatOp1.bind(null, "$in", function (v) {
        //   return v;
        // }, false),
        // reversedOp: "select_not_any_in",
        // jsonLogic: "in",
        reversedOp: "notIn",
      },
      notIn: {
        ...InitialConfig.operators.select_not_any_in,
        label: "Does Not Include",
        formatOp: function formatOp(
          field,
          op,
          values,
          valueSrc,
          valueType,
          opDef,
          operatorOptions,
          isForDisplay
        ) {
          if (valueSrc == "value") return "".concat(field, " IN (");
          else return "".concat(field, " IN (");
        },
        reversedOp: "in",
      },
      canIn: {
        ...InitialConfig.operators.select_any_in,
        label: "Can Includes",
        // labelForFormat: "IN",
        // sqlOp: "IN",
        formatOp: function formatOp(
          field,
          op,
          values,
          valueSrc,
          valueType,
          opDef,
          operatorOptions,
          isForDisplay
        ) {
          if (valueSrc == "value") return "".concat(field, " IN (");
          else return "".concat(field, " IN (");
        },
        // sqlFormatOp: function sqlFormatOp(field, op, values, valueSrc, valueType, opDef, operatorOptions) {
        //   return "".concat(field, " IN (").concat(values.join(", "), ")");
        // },
        // mongoFormatOp: mongoFormatOp1.bind(null, "$in", function (v) {
        //   return v;
        // }, false),
        // reversedOp: "select_not_any_in",
        // jsonLogic: "in",
        reversedOp: "canNotIn",
      },
      canNotIn: {
        ...InitialConfig.operators.select_not_any_in,
        label: "Can Not Includes",
        formatOp: function formatOp(
          field,
          op,
          values,
          valueSrc,
          valueType,
          opDef,
          operatorOptions,
          isForDisplay
        ) {
          if (valueSrc == "value") return "".concat(field, " IN (");
          else return "".concat(field, " IN (");
        },
        reversedOp: "canIn",
      },
    };

    const widgets = {
      ...InitialConfig.widgets,
      slider: {
        ...InitialConfig.widgets.slider,
        customProps: {
          width: "300px",
        },
      },
      number: {
        ...InitialConfig.widgets.number,
        customProps: {
          width: "200px",
        },
      },
      boolean: {
        ...InitialConfig.widgets.boolean,
        labelYes: "True",
        labelNo: "False",
      },
      rangeslider: {
        ...InitialConfig.widgets.rangeslider,
        customProps: {
          width: "300px",
        },
      },
      date: {
        ...InitialConfig.widgets.date,
        dateFormat: "DD.MM.YYYY",
        valueFormat: "YYYY-MM-DD",
      },
      time: {
        ...InitialConfig.widgets.time,
        timeFormat: "HH:mm",
        valueFormat: "HH:mm:ss",
      },
      select: {
        ...InitialConfig.widgets.select,

        factory: (props) => {
          const { field } = props;
          const [fieldType, setFieldType] = useState(field.split(".").pop());

          console.log(fieldType, "fieldType");
          if (fieldType !== "charass_id")
            return props.asyncFetch || props.showSearch ? (
              <MuiAutocompleteWidget {...props} />
            ) : (
              <MuiSelectWidget {...props} />
            );

          const [val, setVal] = useState(props.value);
          const [selectLoading, setSelectLoading] = useState(true);

          useEffect(async () => {
            console.log(props, "props");
            if (fieldType === "charass_id") {
              const fetchCharacteristicData = await getCharacteristic({
                charassId: props.value,
              });
              setVal(
                `${fetchCharacteristicData.data.charass.charass_value} (props.value)`
              );
            }
          }, []);

          useEffect(() => {
            setVal(props.value);
          }, [props.value]);
          useEffect(() => {
            setSelectLoading(false);
          }, [val]);

          return props.asyncFetch || props.showSearch ? (
            <MuiAutocompleteWidget
              {...props}
              value={val}
              loading={selectLoading}
            />
          ) : (
            <MuiSelectWidget {...props} />
          );
        },
      },
      nType: {
        ...InitialConfig.widgets.select,
        factory: function factory(props) {
          return <Select {...props} />;
        },
      },
      qty: {
        ...InitialConfig.widgets.number,
      },
      datetime: {
        ...InitialConfig.widgets.datetime,
        timeFormat: "HH:mm",
        dateFormat: "DD.MM.YYYY",
        valueFormat: "YYYY-MM-DD HH:mm:ss",
      },
      treeselect: {
        ...InitialConfig.widgets.treeselect,
        customProps: {
          showSearch: true,
        },
      },
    };

    const types = {
      ...InitialConfig.types,
      select: merge(InitialConfig.types.select, {
        widgets: {
          select: {
            operators: [
              "select_equals",
              "select_not_equals",
              "less",
              "less_or_equal",
              "greater",
              "greater_or_equal",
              "is_empty",
              "is_not_empty",
              "in",
              "notIn",
              "canIn",
              "canNotIn",
            ],
          },
        },
      }),
      number: merge(InitialConfig.types.number, {
        widgets: {
          number: {
            operators: ["equal"],
            defaultOperator: "equal",
            widgetProps: {
              hideOperator: true,
              // showSearch: true,
            },
          },
        },
      }),
      multiselect: merge(InitialConfig.types.multiselect, {
        widgets: {
          multiselect: {
            operators: [
              "select_equals",
              "select_not_equals",
              "less",
              "less_or_equal",
              "greater",
              "greater_or_equal",
              "is_empty",
              "is_not_empty",
              "select_not_any_in",
              "select_any_in",
            ],
          },
        },
      }),

      nType: merge(InitialConfig.types.select, {
        widgets: {
          nType: {
            widgetProps: {
              hideOperator: true,
              showSearch: true,
            },
          },
        },
      }),
      qty: merge(InitialConfig.types.number, {
        widgets: {
          qty: {
            widgetProps: {
              hideOperator: true,
              showSearch: true,
            },
          },
        },
      }),
      boolean: merge(InitialConfig.types.boolean, {
        widgets: {
          boolean: {
            widgetProps: {
              hideOperator: true,
              operatorInlineLabel: "==",
            },
          },
        },
      }),
    };

    const localeSettings = {
      locale: {
        moment: "ru",
      },
      valueLabel: "Value",
      valuePlaceholder: "Value",
      fieldLabel: "Field",
      operatorLabel: "Operator",
      fieldPlaceholder: "Select item",
      operatorPlaceholder: "Select operator",
      deleteLabel: null,
      addGroupLabel: "Add group",
      addRuleLabel: "Add rule",
      delGroupLabel: null,
      notLabel: "Not",
      canLeaveEmptyGroup: false,
      shouldCreateEmptyGroup: false,
      valueSourcesPopupTitle: "Select value source",
      removeRuleConfirmOptions: {
        title: "Are you sure delete this rule?",
        okText: "Yes",
        okType: "danger",
      },
      removeGroupConfirmOptions: {
        title: "Are you sure delete this group?",
        okText: "Yes",
        okType: "danger",
      },
    };

    const settings = {
      ...InitialConfig.settings,
      ...localeSettings,

      valueSourcesInfo: {
        value: {
          label: "Value",
        },
        field: {
          label: "Field",
          widget: "field",
        },
        func: {
          label: "Function",
          widget: "func",
        },
      },
      // canReorder: false,
      // canRegroup: false,
      // showNot: false,
      // showLabels: true,
      maxNesting: 5,
      canLeaveEmptyGroup: false, //after deletion

      // renderField: (props) => <FieldCascader {...props} />,
      // renderOperator: (props) => <FieldDropdown {...props} />,
      // renderFunc: (props) => <FieldSelect {...props} />,
    };

    const config = {
      conjunctions,
      operators,
      widgets,
      types,
      settings,
      fields,
      ...BasicFuncs,
    };

    setRuleConfig(config);

    setRuleTree(checkTree(loadTree(emptyInitValue), config));
  }, []);

  useEffect(async () => {
    const createActionsConfig = loadedConfigAction;

    const generateFields = async () => {
      const subfieldObj = {};

      const data = await getItemsList(null);

      data.map((item, k) => {
        Object.assign(subfieldObj, {
          [item.value]: {
            label: item.label,
            type: "action_params",
            valueSources: ["value"],
            preferWidgets: ["action_params"],
            fieldSettings: {
              min: 0,
              max: 1000,
              hideOperator: true,
            },
          },
        });
      });

      const subfieldObjTag = {};

      const getTagsData = await getTags({ types: ["item_tag"] });

      getTagsData.map((item, k) => {
        Object.assign(subfieldObjTag, {
          [item.id]: {
            label: item.shortname,
            type: "action_params",
            valueSources: ["value"],
            preferWidgets: ["action_params"],
            fieldSettings: {
              min: 0,
              max: 1000,
            },
          },
        });
      });

      const subfieldObjMutiplyCharacteristicType = {};

      const getTypes = await getConfigurableChars();

      getTypes.map((item, k) => {
        const { char_id } = item;
        return Object.assign(subfieldObjMutiplyCharacteristicType, {
          [item.char_id]: {
            type: "action_params",
            // operators: ["equal"],
            valueSources: ["value"],
            preferWidgets: ["action_params"],
            fieldSettings: {
              min: 0,
              max: 1000,
              hideOperator: true,
            },

            label: item.char_name,
            // type: "!group",
            // mode: "array",
            operators: [
              // w/ operand - count
              "equal",
            ],
          },
        });
      });

      const fields = {
        insert: {
          label: "Insert item",
          type: "!group",
          subfields: subfieldObj,
        },
        insert_with_tag: {
          label: "Insert items tagged with",
          type: "!group",
          subfields: subfieldObjTag,
        },
        multiply: {
          label: "Multiply values for Characteristic Type",
          type: "!group",
          subfields: subfieldObjMutiplyCharacteristicType,
        },
        remove: {
          label: "Remove",
          type: "!group",
          subfields: subfieldObj,
        },
      };

      return fields;
    };

    const fields = await generateFields();

    createActionsConfig.fields = fields;
    setActionConfig(createActionsConfig);
    setActionTree(checkTree(loadTree(emptyInitValue), createActionsConfig));
  }, []);

  const onChangeRule = async (immutableTree, config) => {
    const checkActionShow = queryString(immutableTree, config);
    const jsonTree = getTree(immutableTree);
    setRuleTree(immutableTree);

    setResult({
      ...result,
      ...jsonTree,
    });

    setResultConditions({
      ...resultConditions,
      ...jsonTree,
    });
  };
  const onChangeAction = async (immutableTree, config) => {
    const jsonTree = getTree(immutableTree);
    setResult({
      ...result,
      action: [jsonTree],
    });

    setResultEvents({
      ...resultEvents,
      action: [jsonTree],
    });

    setActionTree(immutableTree);
  };

  useEffect(async () => {
    if (Object.keys(ruleConfig).length && Object.keys(actionConfig).length) {
      if (props.data && Object.keys(props.data).length) {
        const x = checkTree(
          loadFromJsonLogic(props.data, ruleConfig),
          ruleConfig
        );
        setRuleTree(x);
      }

      if (props.events && Object.keys(props.events).length) {
        const x = checkTree(
          loadFromJsonLogic(props.events, actionConfig),
          actionConfig
        );
        setActionTree(x);
      }
      setBuilderLoading(false);
    }
  }, [ruleConfig, actionConfig]);

  if (
    Object.keys(ruleConfig).length === 0 ||
    Object.keys(actionConfig).length === 0
  )
    return (
      <>
        <div
          css={css`
            height: 100px;
            width: 100%;
            display: block;
            max-width: none;
          `}
          className="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeMedium"
        />
        <div
          css={css`
            height: 100px;
            margin-top: 2rem;
            width: 100%;
            display: block;
            max-width: none;
          `}
          className="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeMedium"
        />
      </>
    );
  return (
    <LogicBuilderContext.Provider
      value={{
        ruleData: {
          config: ruleConfig,
          tree: ruleTree,
        },
        actionData: {
          config: actionConfig,
          tree: actionTree,
        },
        onChangeAction: onChangeAction,
        onChangeRule: onChangeRule,
        packageId: packageId,
        showAction: showAction,
        showModal: showModal,
        showLoader: showLoader,
        setShowModal: setShowModal,
        builderLodaing: builderLodaing,
        result,
        resultConditions,
        resultEvents,
        closeModal: () => {
          setRuleTree(checkTree(loadTree(emptyInitValue), ruleConfig));
          setShowModal(false);
        },
      }}
    >
      {props.children}
      {/* <strong>Conditions</strong><br />
      {queryString(ruleTree, ruleConfig, false)} <br />
      <br />
      {queryString(ruleTree, ruleConfig, true)}
      <br />
      <br />
      {sqlFormat(ruleTree, ruleConfig)}
      <br />
      <br />
      {JSON.stringify(jsonLogicFormat(ruleTree, ruleConfig))}
      <br />
      <br />
      {JSON.stringify(
        transformJsonLogicToRulesEngine(jsonLogicFormat(ruleTree, ruleConfig))
      )}
      <br />
      <br />
      <strong>Events</strong><br />
      {queryString(actionTree, actionConfig, false)} <br />
      <br />
      {queryString(actionTree, actionConfig, true)}
      <br />
      <br />
      {sqlFormat(actionTree, actionConfig)}
      <br />
      <br />
      {JSON.stringify(jsonLogicFormat(actionTree, actionConfig))}
      <br />
      <br />
      {JSON.stringify(
        transformJsonLogicToRulesEngine(jsonLogicFormat(actionTree, actionConfig))
      )}
      <br />
      <br /> */}
    </LogicBuilderContext.Provider>
  );
};
export default LogicBuilderProvider;
