import {Route, Switch, useRouteMatch} from "react-router-dom";
import UniversalLabelPage from "./UniversalLabelPage";

const AirTable = () => {

    const { path, url } = useRouteMatch();

    console.log('path',path, url)
    return (
        <Switch>
            <Route exact path={`${path}/universalPowderLabel`} component={UniversalLabelPage}/>
        </Switch>
    )
}

export default AirTable