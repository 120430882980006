import {
  Banner,
  Button,
  Card,
  DataTable,
  Form,
  FormLayout,
  Link,
  Modal,
  Page,
  Select,
  Spinner,
  TextField,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFirebaseContext } from "../../Firebase";

const ItemCharacteristicsPage = () => {
  const { itemID, charID } = useParams();
  const { reactFunction } = useFirebaseContext();
  const getItemCharacteristics = reactFunction("getItemCharacteristics");
  const addCharacteristicsToItemChar = reactFunction(
    "addCharacteristicsToItemChar"
  );

  const saveCharFieldHeaderInfo = reactFunction("saveCharFieldHeaderInfo");

  const [itemCharacteristics, setItemCharacteristics] = useState([]);
  const [charInfo, setCharInfo] = useState({});
  const [CharFieldHeaderInfo, setCharFieldHeaderInfo] = useState({});
  const [addCharModal, setAddCharModal] = useState(false);

  //Characteristic Field Settings
  const [eckBundle, setEckBundle] = useState("");
  const [pbFieldID, setPbFieldID] = useState("");

  const saveCharFieldHeaderInfoHandler = () => {
    saveCharFieldHeaderInfo({
      itemID,
      charID,
      eckBundle,
      pbFieldID,
    }).then(() => {
      window.location.reload();
    });
  };

  useEffect(() => {
    getItemCharacteristics({ itemID, charID }).then((response) => {
      //   console.log(response);
      setItemCharacteristics(response.data.charValues);
      setCharInfo(response.data.charInfo);

      console.log("eckBundleID", response.data.CharFieldHeaderInfo.eckBundleID);
      console.log("pbFieldID", response.data.CharFieldHeaderInfo.pbFieldID);

      setEckBundle(response.data.CharFieldHeaderInfo.eckBundleID);
      setPbFieldID(response.data.CharFieldHeaderInfo.pbFieldID);

      // console.log(response.data.charInfo);
    });
  }, [itemID, charID]);

  if (!itemCharacteristics.length) {
    return <Spinner />;
  }

  const rows = itemCharacteristics.map((characteristic) => {
    // console.log("characteristic", characteristic);
    return [
      <Link url={`./${charID}/${characteristic.charass_id}`}>
        {characteristic.charass_value}
      </Link>,
    ];
  });

  const AddCharacteristicModal = ({}) => {
    const [value, setValue] = useState("");
    const [blockSubmit, setBlockSubmit] = useState(true);
    const [saving, setSaving] = useState(false);
    useEffect(() => {
      const check = itemCharacteristics.find((characteristic) => {
        if (characteristic.charass_value === value) {
          return true;
        }
      });
      if (check) {
        setBlockSubmit(true);
      } else {
        setBlockSubmit(false);
      }
    }, [value]);

    const saveCharacteristic = () => {
      setSaving(true);
      addCharacteristicsToItemChar({
        itemID,
        charID,
        charValue: value,
      }).then((response) => {
        console.log(response);
        setSaving(false);
        window.location.reload();
      });
    };
    return (
      <Modal
        open={addCharModal}
        title="Add Characteristic"
        primaryAction={{
          disabled: saving || blockSubmit,
          content: "Save",
          onAction: saveCharacteristic,
        }}
        onClose={() => setAddCharModal(false)}
      >
        <Modal.Section>
          <Card>
            {blockSubmit && <Banner>Duplicate Detected.</Banner>}
            <Form>
              <TextField
                label="Characteristic"
                value={value}
                onChange={setValue}
              />
            </Form>
          </Card>
        </Modal.Section>
      </Modal>
    );
  };

  return (
    <Page>
      <AddCharacteristicModal />
      <Card
        title={`Item Characteristics for ${charInfo.char_name} field.`}
        sectioned
      >
        <Form>
          <FormLayout>
            <Select
              label="ECK Bundle Name"
              options={[
                {
                  label: "None",
                  value: "",
                },
                { label: "PB Accessory", value: "pb_accessory" },
                { label: "PB Colors", value: "pb_colors" },
                { label: "PB Dimming", value: "pb_dimming" },
                { label: "PB Fixture", value: "pb_fixture" },
                { label: "PB Guard", value: "pb_guard" },
                { label: "PB Kelvin", value: "pb_kelvin" },
                { label: "PB Lamp Type", value: "pb_lamp_type" },
                { label: "PB Lens", value: "pb_lens" },
                { label: "PB Mounting", value: "pb_mounting" },
                { label: "PB SwitchLegs	", value: "pb_switchlegs" },
              ]}
              value={eckBundle}
              onChange={setEckBundle}
              helpText="What type of ECK bundle is this field?"
            />
            <TextField
              label="PB Field ID"
              value={pbFieldID}
              onChange={setPbFieldID}
              helpText="How does the Product builder store the data in the json?"
            />
            <Button onClick={saveCharFieldHeaderInfoHandler}>Save</Button>
          </FormLayout>
        </Form>
      </Card>
      <Card
        actions={[
          {
            content: "Add",
            onAction: () => setAddCharModal(true),
          },
        ]}
      >
        <DataTable
          rows={rows}
          columnContentTypes={["text"]}
          headings={["Product"]}
        />
      </Card>
    </Page>
  );
};

export default ItemCharacteristicsPage;
