import {SettingToggle, Checkbox} from "@shopify/polaris";
import React, {useEffect} from "react";

const StatusToggle = (props) => {
    const {value, onChange, label} = props;

    useEffect(() => {
        console.log(value,'val');
    }, [value])
    return (
        <Checkbox label={label} checked={value}
                  // onChange={onChange}
        />
    )

    // return <SettingToggle
    //     action={{
    //         content: value ? "Deactivate " : "Activate",
    //         destructive: !!value,
    //         onAction: () => setValue(),
    //     }}
    //     enabled={value}
    // >
    //     {statusText}
    //     <strong>{value ? "active" : "inactive"}</strong>.
    // </SettingToggle>
}

export default StatusToggle;