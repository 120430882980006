import {Page, Text, Document, StyleSheet, Image,} from "@react-pdf/renderer";
import {inchesTo} from './../Labels/LabelHelper'
import React from "react";
import moment from "moment";

import BockLogo from './BockLogo.png'


const styles = StyleSheet.create({
    page: {
        // flexDirection: 'row',
        // backgroundColor: '#E4E4E4',
        margin: '30',
    },
    section: {
        // margin: 10,
        // padding: 10,
        // flexGrow: 1
    },
    bold: {
        // backgroundColor: '#E4E4E4',
        "fontWeight": 'bold'
    },
    normalFont: {
    },
    logo: {
        // width: `${5.60}in`,
        "width": "20%",
        "height": "auto",
    }
});


const UniversalLabelDesign = ({params}) => {

    const {imageURL, description, unvpo, color, qtySent, notes, BockSORef, DateSentOver} = params

    return (
        <Document rotate={0}>
            <Page size={[inchesTo(8.5), inchesTo(11)]} style={styles.page}>
                <Image src={BockLogo} style={styles.logo}/>
                <Text style={{
                    fontSize:'.35in',
                    position:'absolute',
                    left: '2.25in',
                    top: '0.25in',
                    width: '3.5in',
                    // backgroundColor:'#121',
                }}>Universal Work Order</Text>
                <Text>-----------------------------------------------------------------------------------------------------------</Text>
                <Text style={{...styles.bold}}><span>Universal PO: </span><span style={{...styles.bold, fontWeight:400}}>{unvpo}</span></Text>
                <Text>-----------------------------------------------------------------------------------------------------------</Text>
                {description && <Text>Description: {description}</Text>}
                {color && <Text>Color: {color}</Text>}
                {qtySent && <Text>Quantity Sent: {qtySent}</Text>}
                {notes && <Text>Notes: {notes}</Text>}
                {DateSentOver && <Text>DateSentOver: {moment(DateSentOver).format('MM / DD / YYYY')}</Text>}
                {BockSORef && <Text>BockSORef: {BockSORef}</Text>}
                {imageURL && imageURL.length && true && <Image src={imageURL} fixed={true}
                                                               style={{
                                                                   // position: 'absolute',
                                                                   width: `${4.60}in`,
                                                                   // left: `${167}pt`,
                                                                   // top: `${28}pt`,
                                                                   // height: '100vh',
                                                                   // width: '100vw',
                                                               }}
                />}

                {/*<Text style={{*/}
                {/*    position: 'absolute',*/}
                {/*    top: '12pt',*/}
                {/*    left: '120pt',*/}
                {/*    // margin: 5,*/}
                {/*    "fontSize": '8pt',*/}
                {/*    "overflow-wrap":'noral',*/}
                {/*}}><b style={styles.bold}>PN: </b>{labelDetails.fixture.partNumber}</Text>*/}
                {/*<Text style={{*/}
                {/*    position: 'absolute',*/}
                {/*    top: '19pt',*/}
                {/*    left: '120pt',*/}
                {/*    // margin: 5,*/}
                {/*    "fontSize": '8pt',*/}
                {/*    "overflow-wrap":'noral',*/}
                {/*}}><b style={styles.bold}>Date:</b>{moment().format('YYYY-MM-DD')}</Text>*/}
                {/*<Text style={{*/}
                {/*    position: 'absolute',*/}
                {/*    top: '25pt',*/}
                {/*    left: '5pt',*/}
                {/*    // margin: 5,*/}
                {/*    "fontSize": '8pt',*/}
                {/*    "overflow-wrap":'normal',*/}
                {/*}}><b style={styles.bold}>SO:</b>{labelDetails.fixture.orderNumber}</Text>*/}
                {/*<Text style={*/}
                {/*    {*/}
                {/*        margin: 1,*/}
                {/*        "fontSize": '8pt',*/}
                {/*        top: '35pt',*/}
                {/*        left: '5pt',*/}
                {/*        width: '160pt',*/}
                {/*        position: 'absolute',*/}
                {/*    }*/}
                {/*}>MIN 60°C Supply conductors, Suitable for {ratingLocation} Locations, RISK OF FIRE, MAX {labelDetails.fixture.maxWattage}W, E26 Base,*/}
                {/*    120-277VThis product must be installed in accordance with applicable installation code by a person*/}
                {/*    familiar with the construction and operation of the product and hazards involved. DOWN LIGHT*/}
                {/*    ONLY!</Text>*/}
                {/*/!*<Text>{`${Date.now()/100000}`}</Text>*!/*/}
                {/*{labelDetails.fixture.UL_Listed && <Image src={UL_Logo_To_Use} fixed={true}*/}
                {/*                                          style={{*/}
                {/*                                              position: 'absolute',*/}
                {/*                                              width: `${0.60}in`,*/}
                {/*                                              left: `${167}pt`,*/}
                {/*                                              top: `${28}pt`,*/}
                {/*                                              // height: '100vh',*/}
                {/*                                              // width: '100vw',*/}
                {/*                                          }}*/}
                {/*/>}*/}
            </Page>
        </Document>
    )
}

export default UniversalLabelDesign


