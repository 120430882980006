/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React, { useState, useEffect, useCallback, useContext } from "react";
// import { TodoListContext } from '../../../Context/TodoListContext'
import {
  ButtonGroup,
  Button,
  //  Popover, FormLayout, Select,
  TextField,
} from "@shopify/polaris";

const BomLineItemAction = (props) => {
  const { qty_total, qty_per, qty_ord, qty_pulled, setPulledQty } = props;
  const [isPulling, setIsPulling] = useState(false);
  const [pulledQuantity, setPulledQuantity] = useState(1);
  const [loading, setLoading] = useState(false);
  const handleChange = useCallback((newValue) => {
    if (newValue <= qty_total - qty_pulled && newValue > 0)
      setPulledQuantity(parseInt(newValue));
  }, []);

  const pullAll = async () => {
    setLoading(true);
    const makePull = await setPulledQty(qty_total - qty_pulled);
    if (makePull) {
      setLoading(false);
    }
  };
  const submitPull = async () => {
    setLoading(true);
    const makePull = await setPulledQty(pulledQuantity);

    if (makePull) {
      setLoading(false);
      setIsPulling(false);
      setPulledQuantity(1);
    }
  };

  // console.log(qty_total, qty_pulled, 'qties')

  if (qty_total === qty_pulled)
    return (
      <>
        <Button disabled size="">
          Pulled
        </Button>
      </>
    );
  return (
    <div>
      {isPulling === true ? (
        <div
          css={css`
            input {
              // min-height: var(--pc-button-slim-min-height);
              width: 2rem;
            }
            .Polaris-TextField__Backdrop {
              border-radius: var(--p-border-radius-1) 0 0
                var(--p-border-radius-1);
            }
          `}
        >
          <ButtonGroup segmented>
            <TextField
              type="number"
              onChange={handleChange}
              value={pulledQuantity}
            />
            <Button onClick={submitPull} loading={loading}>
              Pull{" "}
            </Button>
          </ButtonGroup>
        </div>
      ) : (
        <ButtonGroup segmented>
          {qty_pulled + 1 < qty_total && (
            <Button onClick={() => setIsPulling(!isPulling)}>
              Part pulled
            </Button>
          )}
          <Button primary onClick={() => pullAll()} loading={loading}>
            Pulled
          </Button>
        </ButtonGroup>
      )}
      <></>
    </div>
  );
};

export default BomLineItemAction;
