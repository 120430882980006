import { Toast, Frame, Page, ButtonGroup, Button } from '@shopify/polaris';
import { useState, useCallback, useEffect } from 'react';
import ToastContext from "./ToastContext";

export const ToastProvider = (props) => {

    const initToast = {
        message: null,
        error: false,
        action: null,
    }

    const [toast, setToast] = useState(initToast)

    const pushNewToast = ({
        error = false,
        message = error === true ? "An unexpected error occurred" : "Saved successfully",
        action = null,
    }) => {
        setToast({
            error, message, action
        })
    }

    const clearToast = () => {
        setToast(initToast)
    }

    const toastMarkup = toast.message !== null ? (
        <Toast error={toast.error} content={toast.message} onDismiss={clearToast} />
    ) : null;

    return (
        <ToastContext.Provider
            value={{
                toast,
                pushNewToast
            }}
        >
            {props.children}
            {toastMarkup}
        </ToastContext.Provider>
    );
};

export default ToastProvider;