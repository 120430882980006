/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { Page, Box, Text } from "@shopify/polaris";

export const PageDefault = (props) => {
  const { children, ...rest } = props;
  return <Page {...rest}>{children}</Page>;
};

export const PageFullHeader = (props) => {
  const { children, ...rest } = props;
  return (
    <>
      <div
        css={css`
          margin: calc(-1 * var(--p-space-5)) calc(-1 * var(--p-space-6));
          z-index: 0;
          position: relative;
        `}
      >
        <div
          css={css`
            &&& {
              > .Polaris-Page {
                margin-top: 6rem;
                > .Polaris-Box {
                  position: fixed !important;
                  top: 50px;
                  border-bottom: var(--p-border-divider);
                  width: 100%;
                  z-index: 99;
                  background-color: var(--p-background);
                  left: calc(15rem + env(safe-area-inset-left));
                  right: 0;
                  width: auto;
                  padding-top: var(--p-space-4);
                  padding-bottom: var(--p-space-4);
                  padding-left: var(--p-space-6);
                  padding-right: var(--p-space-6);
                  .Polaris-Page-Header__RightAlign {
                    align-self: center;
                  }
                }
              }
            }
          `}
        >
          <Page {...rest}>{children}</Page>
        </div>
      </div>
    </>
  );
};
