/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import React, { useState, useEffect,
    //  useMemo,
      useCallback, useContext } from 'react'
import { Box, Listbox, Combobox, Icon, Badge } from '@shopify/polaris';
import {
    ChevronDownMinor
} from '@shopify/polaris-icons';
import BomLineItemBadge from './BomLineItemBadge'
import { TodoListContext } from '../../../Context/TodoListContext';
const BomLineItemStatus = (props) => {
    const { taskStatus, qty_total, qty_pulled, id, task_id } = props
    const [error, setError] = useState(false)
    const { updateBomItem } = useContext(TodoListContext)

    const opts = [
        { value: 'new', label: 'Not Pulled' },
        { value: 'awaiting_stock', label: 'Awaiting stock' },
        { value: 'partially_pulled', label: 'Partially pulled' },
        { value: 'complete', label: 'Complete' }
    ]

    const [deselectedOptions, setDeselectedOptions] = useState(opts)

    useEffect(() => {
        setDeselectedOptions(opts.filter(f => f.value !== taskStatus))
    }, [taskStatus])

    useEffect(() => {
        setOptions(deselectedOptions)
    }, [deselectedOptions])

    const [selectedOption, setSelectedOption] = useState();
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState(deselectedOptions);

    const updateText = useCallback(
        (value) => {
            setInputValue(value);

            if (value === '') {
                setOptions(deselectedOptions);
                return;
            }

            const filterRegex = new RegExp(value, 'i');
            const resultOptions = deselectedOptions.filter((option) =>
                option.label.match(filterRegex),
            );
            setOptions(resultOptions);
        },
        [deselectedOptions],
    );

    const updateSelection = useCallback(
        (selected) => {

            if (selected === 'complete' && qty_pulled < qty_total) return setError(true);

            const matchedOption = options.find((option) => {
                return option.value.match(selected);
            });

            setSelectedOption(selected);
            setInputValue((matchedOption && matchedOption.label) || '');
        },
        [options],
    );

    useEffect(() => {
        console.log(selectedOption, 'selectedOpt')

        if (selectedOption && selectedOption !== taskStatus) {
            const run = updateBomItem({
                id: id,
                task_id: task_id,
                status: selectedOption
            })
        }

    }, [selectedOption])

    const optionsMarkup =
        options.length > 0
            ? options.map((option, k) => {
                const { label, value } = option;

                return (
                    <Listbox.Option
                        divider={k < options.length - 1}
                        key={`${value}`}
                        value={value}
                        selected={selectedOption === value}
                        accessibilityLabel={label}
                    >
                        <Box
                            padding="2"
                            paddingBlockEnd={k < options.length - 1 ? '2' : '0'}
                            paddingBlockStart={k > 0 ? '2' : '0'}>
                            <BomLineItemBadge taskStatus={value} />
                        </Box>
                    </Listbox.Option>
                );
            })
            : null;

    return (
        // <div style={{ height: '225px' }}>
        <Combobox
            activator={
                <Combobox.TextField
                    suffix={<Icon source={ChevronDownMinor
                    } />}
                    error={error ? <Box marginBlockStart="2">Not all items are marked as pulled.</Box> : null}
                    onChange={updateText}
                    label="Change status"
                    labelHidden
                    value={inputValue}
                    placeholder="Change status"
                    onFocus={() => error && setError(false)}
                />
            }
        >
            {options.length > 0 ? (
                <Listbox onSelect={updateSelection}>{optionsMarkup}</Listbox>
            ) : null}
        </Combobox>
        // </div>
    );
}

export default BomLineItemStatus