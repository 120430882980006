import {
  Button,
  Card,
  DataTable,
  Link,
  TextField,
  Select,
  Form,
  // Text,
} from "@shopify/polaris";
import moment from "moment";
import { useState } from "react";
import { useFirebaseContext } from "../../Firebase";

function toTitleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

const ItemFile = ({ itemObj }) => {
  const { reactFunction, expressFunction } = useFirebaseContext();
  const [showAddFileScreen, setShowAddFileScreen] = useState(false);

  const saveItemFile = expressFunction("saveItemFile");

  return (
    <Card
      title="File"
      sectioned
      actions={[
        {
          content: showAddFileScreen ? "Cancel" : "Add File",
          onAction: () => setShowAddFileScreen(!showAddFileScreen),
        },
      ]}
    >
      {showAddFileScreen ? (
        <AddFileScreen itemObj={itemObj} />
      ) : (
        <FilesCardContents itemObj={itemObj} />
      )}
    </Card>
  );
};

const FilesCardContents = ({ itemObj }) => {
  console.log("FilesCardContents", itemObj.files);
  return (
    <DataTable
      columnContentTypes={["text", "text", "text", "text"]}
      headings={[
        "File Name",
        "Description",
        "Type",
        "Created",
        // "Delete"
      ]}
      rows={itemObj.files.map((file) => {
        return [
          <Link url={file.url} external>
            {file.fileName}
          </Link>,
          file.description,
          toTitleCase(file.type),
          moment(file.createdAt).format("MM/DD/YYYY"),
          // <DeleteFile itemFileID={file.id} />,
        ];
      })}
    />
  );
};

const DeleteFile = ({ itemFileID }) => {
  const markFileAsInactive = () => {
    console.log("markFileAsInactive", itemFileID);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  return <Link onClick={markFileAsInactive}>Delete</Link>;
};

const AddFileScreen = ({ itemObj }) => {
  const { reactFunction, expressFunction } = useFirebaseContext();
  const saveItemFile = expressFunction("saveItemFile");

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("engeneering");
  const [fileDescription, setFileDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleFileSelect = (e) => {
    e.target.files[0].arrayBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: e.target.files[0].type,
      });
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        const base64data = reader.result;

        // clean filename so it can be used safely in a URL
        const cleanFileName = e.target.files[0].name;
        if (fileName === "") {
          setFileName(cleanFileName);
        }

        // setFileName(e.target.files[0].name);
        setFile(base64data);
      };
    });
  };

  const saveFileHandler = () => {
    const errors = {};
    if (!file) {
      errors.file = "Please select a file";
    }
    if (!fileName) {
      errors.fileName = "Please enter a file name";
    }
    if (!fileType) {
      errors.fileType = "Please select a file type";
    }
    setErrors(errors);
    console.log("saveFileHandler", file, fileName, fileType, fileDescription);

    if (Object.keys(errors).length === 0) {
      setLoading(true);
      saveItemFile({
        itemID: itemObj.id,
        fileInBase64: file,
        fileName: fileName,
        fileType: fileType,
        fileDescription: fileDescription,
      }).then(() => {
        setLoading(false);
        window.location.reload();
      });
    }
  };

  return (
    <div>
      <h1>Add File</h1>
      <Form>
        <input
          type="file"
          required={true}
          // value={file}
          onChange={handleFileSelect}
        />
        {errors.file && (
          <>
            {/*<Text color={"critical"}>{errors.file}</Text>*/}
            <p style={{ color: "red" }}>{errors.file}</p>
          </>
        )}
        <TextField
          label="File Name"
          value={fileName}
          onChange={setFileName}
          requiredIndicator={true}
          error={errors.fileName}
        />

        <Select
          label="File Type"
          options={[
            { label: "Engeneering", value: "engeneering" },
            { label: "Sourcing", value: "sourcing" },
            { label: "Marketing", value: "marketing" },
            { label: "Other", value: "other" },
          ]}
          value={fileType}
          onChange={setFileType}
        />
        <TextField
          multiline
          label="Description"
          value={fileDescription}
          onChange={setFileDescription}
        />
        <Button primary onClick={saveFileHandler}>
          Save
        </Button>
      </Form>
    </div>
  );
};

export default ItemFile;
