import {
  Autocomplete,
  Button,
  Card,
  DataTable,
  Form,
  Icon,
  Label,
  Modal,
  Page,
  Select,
  Spinner,
  Tag,
  TextField,
} from "@shopify/polaris";
import { SearchMinor, CirclePlusMinor } from "@shopify/polaris-icons";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useFirebaseContext } from "../../Firebase";
import DefaultItems from "./DefaultItems";

const AddTagModal = ({ storage, showAddTagModal, setShowAddTagModal }) => {
  const { reactFunction, expressFunction } = useFirebaseContext();
  // console.log("storage", storage.charassObj.charass_id);
  const getTagsList = expressFunction("getTagsList");
  const [selected, setSelected] = useState();

  const [tags, setTags] = useState([]);
  useEffect(() => {
    getTagsList({})
      .then((response) => {
        setTags([
          { label: "Select", value: null },
          ...response.data
            .filter((tag) => {
              return !storage.tags.find(
                (tagInStorage) => tagInStorage.value === tag.id
              );
            })
            .map((tag) => {
              return { label: tag.name, value: tag.id };
            }),
        ]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const addTagToCharacteristic = () => {
    expressFunction("addTagToCharacteristic")({
      charassID: storage.charassObj.charass_id,
      tagID: selected,
    }).then((response) => {
      setShowAddTagModal(false);
      window.location.reload();
    });
  };

  return (
    <Modal
      open={showAddTagModal}
      onClose={() => setShowAddTagModal(false)}
      title="Add Tag"
    >
      <Select
        options={tags}
        onChange={(value) => {
          setSelected(parseInt(value));
        }}
        value={selected}
      />
      <Button primary onClick={addTagToCharacteristic}>
        Add
      </Button>
    </Modal>
  );
};

const TagsField = ({ storage }) => {
  const [tagsDatabase, setTagsDatabase] = useState([...storage.tags]);
  const [showAddTagModal, setShowAddTagModal] = useState(false);
  const { reactFunction } = useFirebaseContext();
  const deleteTagFromCharacteristic = reactFunction(
    "deleteTagFromCharacteristic"
  );
  // console.log("tagsDatabase", tagsDatabase);
  return (
    <>
      {showAddTagModal && (
        <AddTagModal
          storage={storage}
          showAddTagModal={showAddTagModal}
          setShowAddTagModal={setShowAddTagModal}
        />
      )}
      <Card
        title={"Tags"}
        sectioned
        actions={[
          {
            content: "Add Tag",
            onAction: () => setShowAddTagModal(true),
          },
        ]}
      >
        {tagsDatabase.map((tag) => {
          return (
            <Tag
              key={tag.value}
              onRemove={(option) => {
                deleteTagFromCharacteristic({
                  charassID: storage.charassObj.charass_id,
                  tagID: tag.value,
                }).then((response) => {
                  window.location.reload();
                });
              }}
            >
              {tag.label}
            </Tag>
          );
        })}
      </Card>
    </>
  );
};

const EditCharacteristic = () => {
  const { itemID, charID, charassID } = useParams();
  const { reactFunction } = useFirebaseContext();
  const editCharacteristicLoad = reactFunction("editCharacteristicLoad");
  const [storage, setStorage] = useState({});

  const [charCode, setCharCode] = useState("");

  useEffect(() => {
    editCharacteristicLoad({ itemID, charID, charassID }).then((response) => {
      console.log("response", response);
      setStorage(response.data);
      setCharCode(response.data.charassObj.charass_value);
    });
  }, [itemID, charID, charassID]);

  // console.log("storage", storage);
  if (!storage.charObj) {
    return (
      <Page title="Loading">
        <Spinner />
      </Page>
    );
  }

  const deleteHandler = () => {
    const checkForConfirmaion = window.confirm(
      "Are you sure you want to delete this characteristic?"
    );
    if (checkForConfirmaion) {
      reactFunction("deleteCharacteristic")({
        itemID,
        charID,
        charassID,
      }).then((response) => {
        window.location.href = `/bom_logic/item/${itemID}/characteristics/${charID}`;
      });
    }
  };

  const updateSaveHandler = () => {
    reactFunction("updateCharacteristic")({
      itemID,
      charID,
      charassID,
      charValue: charCode,
    }).then((response) => {
      window.location.reload();
    });
  };

  return (
    <Page
      title={`Edit Characteristic: ${storage.charassObj.charass_value}`}
      subtitle={`${storage.itemObj.item_number} > ${storage.charObj.char_name}`}
      primaryAction={
        <Button primary onClick={updateSaveHandler}>
          Save
        </Button>
      }
      secondaryActions={[
        {
          content: "Delete",
          destructive: true,
          onAction: deleteHandler,
        },
      ]}
    >
      <Card title={`Item Details`} sectioned>
        <Form>
          <TextField
            label={"Characteristic Code"}
            value={charCode}
            onChange={setCharCode}
          />
          <TagsField storage={storage} />
          <>{JSON.stringify(storage.Rules)}</>
        </Form>
      </Card>
      <DefaultItems storage={storage} setStorage={setStorage} />
    </Page>
  );
};

export default EditCharacteristic;
