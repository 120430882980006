import { Card } from "@shopify/polaris";
import { useFirebaseContext } from "../../Firebase";
import { useRef, useState, useContext } from "react";
import TagInput from "../../TagInput";
import ToastContext from "../../../Context/Toast/ToastContext";
const TagsSection = ({ itemObj }) => {
  const { expressFunction } = useFirebaseContext();
  const updateItemTags = expressFunction("updateItemTags");
  const { tags, item_id } = itemObj;

  const { pushNewToast } = useContext(ToastContext);
  const callback = async (value) => {
    console.log(value, "valuex");
    const updateTags = await updateItemTags({ itemId: item_id, tags: value });
    console.log(updateTags, "updateTags");
    if (true) pushNewToast({ message: "Changes saved successfully." });
  };

  return (
    <Card sectioned title="Tags">
      <TagInput tags={tags} tagTypes={["item_tag"]} callback={callback} />
    </Card>
  );
};

export default TagsSection;
