import Viewer, {Worker} from '@phuocng/react-pdf-viewer';

import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import axios from "axios";
import {useEffect, useState} from "react";

const PdfViewer = ({url}) => {

    const fetchPDFBlob = () => {
        return axios.get(url, {
            responseType: 'blob',
            headers: {
                'Accept': 'application/pdf'
            }
        }).then(response => {

            // const bbb = new Blob([response.data], {type: 'application/pdf'})

            // console.log(bbb)

            const newFile = new File([response.data], 'OrdAck-501770733408.pdf', {type: 'application/pdf'})


            console.log(newFile)

            const url = window.URL.createObjectURL(newFile);

            return url

            // console.log(response.data)
            // const fileObj = new File(response.data, 'ddd.pdf', {type: "application/pdf"})
            // console.log(fileObj)
            // const urlBlob = fileObj // window.URL.createObjectURL(fileObj);
            // return urlBlob
        })
    }

    const [blobURL, setBlobURL] = useState('')




    useEffect(() => {
        false && blobURL === '' && fetchPDFBlob().then(bblob => {

            console.log(bblob)
            // // const blob = base64toBlob(base64String);
            // const burl = URL.createObjectURL(blob);
            // alert(burl)
            // console.log(burl)

            setBlobURL(bblob)
        })
    })
    // const onChange = (e) => {
    //     const files = e.target.files;
    //     files.length > 0 && setUrl(URL.createObjectURL(files[0]));
    // };


    return (
        <>
            {/*{console.log(blobURL)}*/}
            {/*<input type="file" accept=".pdf" onChange={onChange} />*/}
            {/*<a href={url} download>Download</a>*/}
            {url !== '' ? <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                <div style={{height: '750px'}}>
                    <Viewer fileUrl={url}/>
                </div>
            </Worker> : <div>Loading</div>
            }
        </>
    )
}

export default PdfViewer