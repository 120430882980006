/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState, useCallback, useContext } from "react";
import {
  Box,
  Stack,
  Text,
  ButtonGroup,
  Button,
  Icon,
  TextField,
} from "@shopify/polaris";
import { InfoMinor, EditMinor } from "@shopify/polaris-icons";
import { TodoListContext } from "../../../Context/TodoListContext";

const BomLineItemNotes = (props) => {
  const { value, id, task_id } = props;

  const [text, setText] = useState(value);

  const [edit, setEdit] = useState(false);

  const handleTextFieldChange = useCallback((value) => setText(value), []);
  const { updateBomItem } = useContext(TodoListContext);

  const saveText = async () => {
    const x = await updateBomItem({
      id: id,
      task_id: task_id,
      //   tags: selectedTags.map((i) => i.value),
      notes: text,
    });
    setEdit(false);
  };

  const cancel = () => {
    setText(value);
    setEdit(false);
  };

  // if (!value) return null

  return (
    <Box paddingBlockStart="4">
      {edit ? (
        <Box paddingBlockStart="1">
          <TextField
            label="Notes"
            labelHidden
            type="text"
            value={text}
            onChange={handleTextFieldChange}
            autoComplete="off"
            multiline
            verticalContent={
              <Stack spacing="tight">
                <Icon size="small" color="highlight" source={InfoMinor} />
                <strong>Notes: </strong>
              </Stack>
            }
          />
          <Box paddingBlockStart="2">
            <ButtonGroup segmented>
              <Button plain monochrome onClick={saveText}>
                Save
              </Button>
              <Button plain onClick={cancel}>
                Cancel
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      ) : (
        <div
          css={css`
            &:hover {
              .Polaris-Icon {
                opacity: 1;
              }
            }
          `}
        >
          <Stack spacing="tight" alignment="center">
            <Icon size="small" color="highlight" source={InfoMinor} />
            <strong>Notes: </strong>
            <div
              css={css`
                cursor: pointer;
                .Polaris-Icon {
                  height: 1rem;
                  width: 1rem;
                  opacity: 0;
                  transition: ease 0.2s all;
                }
              `}
              onClick={() => setEdit(!edit)}
            >
              <Icon source={EditMinor} color="" />
            </div>
          </Stack>
          <Text>{text}</Text>
        </div>
      )}
    </Box>
  );
};

export default BomLineItemNotes;
