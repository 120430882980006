/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useContext, useEffect } from "react";
// import LogicBuilderProvider from "./context/LogicBuilderProvider";
import { Query, Builder, Utils } from "react-awesome-query-builder";
import "react-awesome-query-builder/lib/css/styles.css";
import "react-awesome-query-builder/lib/css/compact_styles.css";
import "react-awesome-query-builder/lib/css/denormalize.css";
// import { actionDataPackage, entitySuggest } from "./DrupalHelper";
import LogicBuilderContext from "./context/LogicBuilderContext";
// import Modal from "../Modal";
// import {Spinner} from '@shopify/polaris';
// import loadedConfigRule from "./context/config_rule"; 
const {
  jsonLogicFormat,
  // queryString,
  // mongodbFormat,
  // sqlFormat,
  // getTree,
  // checkTree,
  // loadTree,
  // uuid,
  // loadFromJsonLogic,
} = Utils;

function LogicNew(props) {
  const {
    ruleData,
    showLoader,
    onChangeRule,
    showModal,
    closeModal,
    onChangeAction,
    actionData,
    showAction,
    builderLodaing,
    result,
    resultConditions,
    resultEvents,
  } = useContext(LogicBuilderContext);

  const renderBuilder = (props) => (
    <div className="query-builder-container" style={{ padding: "10px" }}>
      <div className="query-builder">
        <Builder {...props} />
      </div>
    </div>
  );
  useEffect(() => {
    props.setStateResultEvents && props.setStateResultEvents(resultEvents);
    Object.keys(resultEvents).length &&
      props.setStateResultEvents &&
      props.setStateResultEvents(jsonLogicFormat(actionData.tree, actionData.config));
  }, [resultEvents]);

  useEffect(() => {
    props.setStateResultConditions && props.setStateResultConditions(resultConditions);
    Object.keys(resultConditions).length &&
      props.setStateResultConditions &&
      props.setStateResultConditions(jsonLogicFormat(ruleData.tree, ruleData.config));
  }, [resultConditions]);
  return (
    <>
      {builderLodaing  ? (
        <>
          <div
            css={css`
              height: 100px;
              width: 100%;
              display: block;
              max-width: none;
            `}
            className="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeMedium"
          />
          <div
            css={css`
              height: 100px;
              margin-top: 2rem;
              width: 100%;
              display: block;
              max-width: none;
            `}
            className="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeMedium"
          />
        </>
      ) : (
        <>
          <Query
            {...ruleData.config}
            value={ruleData.tree}
            onChange={onChangeRule}
            renderBuilder={renderBuilder}
          // conjunctions="AND"
          />
          {true && (
            <Query
              {...actionData.config}
              value={actionData.tree}
              onChange={onChangeAction}
              renderBuilder={renderBuilder}
            // conjunctions="AND"
            />
          )}
        </>
      )}
      {/* </div> */}
    </>
  );
}

export default LogicNew;
