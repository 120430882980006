import {
  Banner,
  Button,
  Card,
  DataTable,
  Link,
  Modal,
  Autocomplete,
  TextField,
  Form,
} from "@shopify/polaris";
import { Component, useCallback, useEffect, useState } from "react";
import { useFirebaseContext } from "../../Firebase";
import Select from "react-select";
import { useParams } from "react-router-dom";

const AddItemModal = ({
  isAddItemModalActive,
  setIsAddItemModalActive,
  set_defaultItemsJSONObj,
  defaultItemsJSONObj,
}) => {
  const { reactFunction } = useFirebaseContext();
  const getItemsList = reactFunction("getItemsList");
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemQty, setSelectedItemQty] = useState(1);
  const [notes, setNotes] = useState("");

  useEffect(() => {
    if (isAddItemModalActive) {
      getItemsList().then((items) => {
        // console.log(items.data);
        setItems(items.data);
      });
    }
  }, [isAddItemModalActive]);

  const saveItemToBOM = () => {
    const newState = { ...defaultItemsJSONObj };
    newState["event"]["params"][0]["payload"].push({
      item: selectedItem,
      qty: selectedItemQty,
      notes: notes,
    });
    console.log("newState", newState);
    set_defaultItemsJSONObj(newState);
    setIsAddItemModalActive(false);
  };
  //   console.log("storage", storage.charassObj.charass_char_id);

  return (
    <Modal
      open={isAddItemModalActive}
      onClose={() => setIsAddItemModalActive(!isAddItemModalActive)}
      title="Add Item"
    >
      <Select
        label="Item"
        options={items}
        onChange={(obj) => {
          setSelectedItem(obj.value);
        }}
        // value={selectedItem}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
      <TextField
        label="Qty"
        type="number"
        value={selectedItemQty}
        onChange={(value) => {
          setSelectedItemQty(parseInt(value));
        }}
      />
      <TextField
        label="Notes"
        value={notes}
        onChange={(value) => setNotes(value)}
      />
      <Button primary onClick={saveItemToBOM}>
        Add Item
      </Button>
    </Modal>
  );
};

const CloneModal = ({
  isCloneModalActive,
  setIsCloneModalActive,
  set_defaultItemsJSONObj,
  defaultItemsJSONObj,
}) => {
  const { reactFunction } = useFirebaseContext();
  const { itemID, charID, charassID } = useParams();

  const editCharacteristicLoad = reactFunction("editCharacteristicLoad");
  const [cloneCharAssID, setCloneCharAssID] = useState(null);
  const [findCloneStatus, setFindCloneStatus] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemQty, setSelectedItemQty] = useState(1);
  const [notes, setNotes] = useState("");

  // useEffect(() => {
  //   editCharacteristicLoad({ itemID, charID, charassID }).then((response) => {
  //     console.log("response", response);
  //     setStorage(response.data);
  //     setCharCode(response.data.charassObj.charass_value);
  //   });
  // }, [itemID, charID, charassID]);

  const findClone = () => {
    setFindCloneStatus(true);
    editCharacteristicLoad({ itemID, charID, charassID: cloneCharAssID }).then(
      (response) => {
        const RuleJSON = response.data.Rules.RuleJSON;
        RuleJSON.conditions.all[0].value = charassID;
        set_defaultItemsJSONObj(RuleJSON);
        setIsCloneModalActive(false);
      }
    );
  };

  return (
    <Modal
      open={isCloneModalActive}
      onClose={() => setIsCloneModalActive(!isCloneModalActive)}
      title="Clone"
    >
      <Form>
        <TextField
          label="Characteristic ID"
          value={cloneCharAssID}
          onChange={(value) => setCloneCharAssID(parseInt(value))}
        />
        <Button primary onClick={findClone}>
          Find Clone
        </Button>
      </Form>
    </Modal>
  );
};

const EditModal = ({
  isEditModalActive,
  setIsEditModalActive,
  set_defaultItemsJSONObj,
  defaultItemsJSONObj,
  item,
  index,
}) => {
  const { reactFunction } = useFirebaseContext();
  const getItemsList = reactFunction("getItemsList");
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemQty, setSelectedItemQty] = useState(1);
  const [notes, setNotes] = useState("");

  useEffect(() => {
    if (isEditModalActive) {
      getItemsList().then((items) => {
        setItems(items.data);
        const newValues = defaultItemsJSONObj.event.params[0].payload[index];
        setSelectedItem(newValues.item);
        setSelectedItemQty(newValues.qty);
        setNotes(newValues.notes);
        setLoadingSpinner(false);
      });
    }
  }, [isEditModalActive]);

  useEffect(() => {
    if (selectedItem === null && items.length > 0) {
    }
  }, [items]);

  const saveItemToBOM = () => {
    const newState = { ...defaultItemsJSONObj };
    newState["event"]["params"][0]["payload"][index] = {
      item: selectedItem,
      qty: selectedItemQty,
      notes: notes,
    };
    console.log("newState", newState);
    set_defaultItemsJSONObj(newState);
    setIsEditModalActive(false);
    setSelectedItem(null);
    setSelectedItemQty(1);
    setNotes("");
  };

  return (
    <Modal
      open={isEditModalActive}
      onClose={() => setIsEditModalActive(!isEditModalActive)}
      title="Edit Item"
    >
      <Select
        isDisabled={loadingSpinner}
        label="Item"
        options={items}
        onChange={(obj) => {
          setSelectedItem(obj.value);
        }}
        value={items.find((item) => item.value === selectedItem)}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
      <TextField
        disabled={loadingSpinner}
        label="Qty"
        type="number"
        value={selectedItemQty}
        onChange={(value) => {
          setSelectedItemQty(parseInt(value));
        }}
      />
      <TextField
        disabled={loadingSpinner}
        label="Notes"
        value={notes}
        onChange={(value) => setNotes(value)}
      />
      <Button primary onClick={saveItemToBOM}>
        Save Item
      </Button>
    </Modal>
  );
};

const DefaultItems = ({ storage, setStorage }) => {
  const [isAddItemModalActive, setIsAddItemModalActive] = useState(false);
  const [isCloneModalActive, setIsCloneModalActive] = useState(false);

  const [isEditModalActive, setIsEditModalActive] = useState(false);
  const [editItemIndex, setEditItemIndex] = useState(null);
  const [editItemObj, setEditItemObj] = useState(null);

  const { reactFunction } = useFirebaseContext();

  const [itemDatabase, setItemDatabase] = useState([]);

  const factAddress = `char_${storage.charObj.char_id}`;
  const defaultItemsJSON = {
    logicVersion: "1.01",
    ruleType: "charassDefaultItems",
    conditions: {
      all: [
        {
          fact: factAddress,
          path: "$.charassID",
          operator: "equal",
          value: storage.charassObj.charass_id,
        },
      ],
    },
    event: {
      type: "add",
      params: [
        {
          action: "addToBom",
          payload: [
            // {
            //   item: 1464,
            //   qty: 1,
            //   notes: "",
            // },
          ],
        },
      ],
    },
  };

  const [defaultItemsJSONObj, set_defaultItemsJSONObj] = useState({
    ...(storage.Rules &&
    storage.Rules.RuleJSON &&
    storage.Rules.RuleJSON.ruleType !== undefined
      ? storage.Rules.RuleJSON
      : defaultItemsJSON),
  });

  useEffect(() => {
    updateStorage();
  }, [defaultItemsJSONObj]);

  useEffect(() => {
    if (storage.Rules && storage.Rules.event) {
      const itemsToFetch = storage.Rules.event.params[0].payload.map(
        (item) => item.item
      );
      const getItemsList = reactFunction("getItemsList");
      getItemsList({ items: itemsToFetch }).then((items) => {
        setItemDatabase(items.data);
      });
    }
  }, [storage]);

  const updateStorage = () => {
    setStorage({ ...storage, Rules: defaultItemsJSONObj });
  };

  const deleteRowFromBOM = (index) => {
    const newState = { ...defaultItemsJSONObj };
    newState["event"]["params"][0]["payload"].splice(index, 1);
    set_defaultItemsJSONObj(newState);
    updateStorage();
  };

  const rows = defaultItemsJSONObj.event.params[0].payload
    ? defaultItemsJSONObj.event.params[0].payload.map((row, index) => {
        const iObj = itemDatabase.find((item) => item.value === row.item);

        return [
          row.item,
          iObj ? iObj.label : "",
          iObj ? iObj.item_descrip1 : "",
          iObj ? iObj.item_descrip2 : "",
          row.qty,
          row.notes,
          <Link
            onClick={() => {
              setIsEditModalActive(true);
              setEditItemIndex(index);
              setEditItemObj(row);
            }}
          >
            Edit
          </Link>,
          <Link onClick={() => deleteRowFromBOM(index)}>Delete</Link>,
        ];
      })
    : [];

  const saveDefaultItemRule = () => {
    const saveDefaultItemRuleFunction = reactFunction("saveDefaultItemRule");
    saveDefaultItemRuleFunction({
      charassID: storage.charassObj.charass_id,
      defaultItemsJSONObj: defaultItemsJSONObj,
    }).then(() => {
      setIsAddItemModalActive(false);
      window.location.reload();
    });
  };

  return (
    <>
      <Card
        title={`Default Items`}
        actions={[
          {
            content: "Add Item",
            onAction: () => {
              setIsAddItemModalActive(true);
            },
          },
          {
            content: "Reset",
            onAction: () => {
              set_defaultItemsJSONObj(defaultItemsJSON);
            },
          },
          {
            content: "Clone",
            onAction: () => {
              setIsCloneModalActive(true);
            },
          },
          {
            content: <Button primary>Save</Button>,
            onAction: () => {
              saveDefaultItemRule();
            },
          },
        ]}
        sectioned
      >
        <AddItemModal
          isAddItemModalActive={isAddItemModalActive}
          setIsAddItemModalActive={setIsAddItemModalActive}
          defaultItemsJSONObj={defaultItemsJSONObj}
          set_defaultItemsJSONObj={set_defaultItemsJSONObj}
        />
        <CloneModal
          isCloneModalActive={isCloneModalActive}
          setIsCloneModalActive={setIsCloneModalActive}
          defaultItemsJSONObj={defaultItemsJSONObj}
          set_defaultItemsJSONObj={set_defaultItemsJSONObj}
        />
        <EditModal
          isEditModalActive={isEditModalActive}
          setIsEditModalActive={setIsEditModalActive}
          defaultItemsJSONObj={defaultItemsJSONObj}
          set_defaultItemsJSONObj={set_defaultItemsJSONObj}
          index={editItemIndex}
        />
        {defaultItemsJSONObj.logicVersion !== "1.01" && (
          <Banner
            status="warning"
            title="This rule is not compatible with the current version of the app. Please reset to the default rule."
          />
        )}
        <DataTable
          rows={rows}
          headings={[
            "partID",
            "Part Number",
            "Description 1",
            "Description 2",
            "Qty Per",
            "Notes",
            "Edit",
            "Delete",
          ]}
          columnContentTypes={["text", "text", "text"]}
        />
        <pre>{JSON.stringify(defaultItemsJSONObj, null, 2)}</pre>
        {/* <DataTable /> */}
      </Card>
    </>
  );
};

export default DefaultItems;
