import { Card, TextContainer } from "@shopify/polaris";
import { useState } from "react";
import { DefaultEditor } from "react-simple-wysiwyg";
import { useFirebaseContext } from "../../Firebase";

const QuickNoteSection = ({ itemObj }) => {
  const { reactFunction, expressFunction } = useFirebaseContext();
  const saveItemQuickNote = expressFunction("saveItemQuickNote");
  const [quickNoteValue, setQuickNoteValue] = useState(itemObj.quickNote || "");
  const [draftNoteValue, setDraftNoteValue] = useState(quickNoteValue);
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleSave = () => {
    setSaving(true);
    console.log("saving", {
      itemId: itemObj.id,
      quickNote: draftNoteValue,
    });
    saveItemQuickNote({ itemID: itemObj.item_id, quickNote: draftNoteValue })
      .then((res) => {
        console.log("res", res);
        setQuickNoteValue(draftNoteValue);
        setEditing(false);
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
      });
  };

  const editor = (
    <DefaultEditor
      value={draftNoteValue}
      onChange={(e) => {
        const { value } = e.target;
        setDraftNoteValue(value);
      }}
    />
  );

  const viewNote = (
    <TextContainer>
      <div dangerouslySetInnerHTML={{ __html: quickNoteValue }} />
    </TextContainer>
  );

  return (
    <Card
      title="QuickNote"
      sectioned
      actions={[
        {
          content: editing ? "Cancel" : "Edit",
          onAction: () => setEditing(!editing),
        },
      ]}
      primaryFooterAction={
        editing && {
          content: saving ? "Saving..." : "Save",
          disabled: saving ? true : false,
          onAction: handleSave,
        }
      }
    >
      {editing ? editor : viewNote}
    </Card>
  );
};

export default QuickNoteSection;
