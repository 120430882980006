/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useState, useCallback,
    //  useEffect, useRef,
     } from 'react'
import {
    Box, Stack,
    //  Text,
    ButtonGroup, Button, Icon, TextField
} from '@shopify/polaris';
import {
    // InfoMinor, 
    EditMinor,
    //  SaveMinor

} from '@shopify/polaris-icons';
// import ContentEditable from 'react-contenteditable'

const BomLineItemTitle = (props) => {
    const { value } = props
    // const text = useRef(value)
    const [text, setText] = useState(value)
    if (!value) return null

    // const handleChange = evt => {
    //     text.current = evt.target.value;
    // };

    // const handleBlur = () => {
    //     console.log(text.current);
    // };
    const [edit, setEdit] = useState(false)

    const handleTextFieldChange = useCallback(
        (value) => setText(value),
        [],
    );

    const saveText = () => {
        setEdit(false)
    }

    return <p css={css`
        font-size: 14px;
        font-weight: 600;
    `}>
        {value}
    </p>
    return (
        <>
            {edit ?
                <Box paddingBlockStart="0">
                    <TextField
                        label="Notes"
                        labelHidden
                        type="text"
                        value={text}
                        onChange={handleTextFieldChange}
                        autoComplete="off"
                    // multiline
                    // verticalContent={<Stack spacing="tight">
                    //     <Icon size="small" color="highlight" source={InfoMinor} />
                    //     <strong>Notes: </strong>
                    // </Stack>}
                    />
                    <Box paddingBlockStart="2">
                        <ButtonGroup segmented>
                            <Button plain monochrome onClick={saveText}>Save</Button>
                            <Button plain onClick={saveText}>Cancel</Button>
                        </ButtonGroup>
                    </Box>
                </Box>
                : <Stack alignment='center'>
                    <p css={css`
                        font-size: 14px;
                        font-weight: 600;
                    `}>
                        {value}
                    </p>
                    <div css={css`cursor:pointer; 
                        .Polaris-Icon {
                            height: 1rem;
                            width: 1rem;
                        }`} onClick={(e) => {
                            e.stopPropagation()
                            setEdit(!edit)
                        }}>
                        <Icon source={EditMinor} color="" />
                    </div>
                </Stack>
            }
        </>

    )
}

export default BomLineItemTitle