import { useState, useEffect } from "react";
import { useFirebaseContext } from "../Firebase";
import moment from "moment";

const PositivePay = () => {
  const { expressFunction } = useFirebaseContext();
  const getPositivePayData = expressFunction("getPositivePayData");
  const [data, setData] = useState([]);

  useEffect(() => {
    data.length === 0 &&
      getPositivePayData().then((res) => {
        const newData = res
          .map((item) => {
            return [
              `"${item[0]}"`,
              `"${item[1]}"`,
              item[2],
              item[3],
              item[4],
              `"${item[5]}"`,
            ];
          })
          .join("\n");
        setData(newData);
        makeTextFile(newData);
      });
  }, [data]);

  const [downloadLink, setDownloadLink] = useState("");

  // function for generating file and set download link
  const makeTextFile = (input) => {
    const d = new Blob([input], { type: "text/plain" });

    console.log("d", d);
    // this part avoids memory leaks
    if (downloadLink !== "") window.URL.revokeObjectURL(downloadLink);

    // update the download link state
    setDownloadLink(window.URL.createObjectURL(d));
  };

  return (
    <>
      {downloadLink.length !== 0 && (
        <a
          // this attribute sets the filename
          download={`positive-pay-${moment().format(
            "YYYY-MM-DD"
          )}${moment().unix()}.csv`}
          // link to the download URL
          href={downloadLink}
        >
          download
        </a>
      )}
    </>
  );
};

export default PositivePay;
