import { Page, Tabs, Card } from "@shopify/polaris";
import { useState } from "react";
import { useFirebaseContext } from "../Firebase";
import BOMSim from "./BOMSim";
import ConfigurableItemsList from "./ConfigurableItemsList";
import TagsList from "./TagsList";

const BomLogicPage = () => {
  const { permissions } = useFirebaseContext();
  const [selected, setSelected] = useState(0);

  const tabs = [
    {
      id: "simulator",
      content: "Simulator",
      component: () => <BOMSim />,
    },
  ];

  if (permissions.editBOM) {
    tabs.push({
      id: "configurableItems",
      content: "Configurable Items",
      component: () => <ConfigurableItemsList />,
    });
    tabs.push({ id: "tags", content: "Tags", component: () => <TagsList /> });
    tabs.push({
      id: "rules",
      content: "Rules",
      component: () => <div>Not working.</div>,
    });
  }

  return (
    <Page title="BOM Logic">
      <Tabs
        tabs={tabs}
        selected={selected}
        onSelect={(v) => {
          setSelected(v);
          console.log(v);
          window.location = "#" + v;
          // console.log();
        }}
        children={tabs[selected].component()}
      />
    </Page>
  );
};

export default BomLogicPage;
