import { useFirebaseContext } from "../Components/Firebase";
import React, { createContext, useState, useEffect, useContext } from "react";
import { FirebaseContext } from "../Components/Firebase";
import TodoSheet from "../Components/OrderPage/Todos/TodoSheet";
import ToastContext from "./Toast/ToastContext";
import AddNewTask from "../Components/OrderPage/Todos/AddNewTask";
export const TodoListContext = createContext({
  todoList: [],
  addTodo: (newTodoObj) => {},
  updateListWithIndex: (index, obj) => {},
  removeListWithIndex: (index) => {},
  updateCommentWithIndex: (listIndex, commentIndex, commentObj) => {},
  addComment: (listIndex, commentObj) => {},
  deleteComment: (listIndex, commentIndex) => {},
});

export const TodoListProvider = ({ children, ...props }) => {
  const { reactFunction, expressFunction } = useFirebaseContext();
  const updateBomItemPulledStatus = expressFunction(
    "updateBomItemPulledStatus"
  );
  const getBomTasksByOrderLine = expressFunction("getBomTasksByOrderLine");
  const getTags = expressFunction("getTags");
  const createNewTask = expressFunction("createNewTask");
  const regenerateBom = expressFunction("triggerBomRunByOrderLines");
  const [lines, setLines] = useState(props.lines);
  // const [tasks, setTasks] = useState([])
  const [current, setCurrent] = useState(null);
  const [open, setOpen] = useState(false);
  const [taskTags, setTaskTags] = useState([]);
  const { pushNewToast } = useContext(ToastContext);
  const [taskLines, setTaskLines] = useState([]);
  const [newTaskModal, setNewTaskModal] = useState(null);
  useEffect(async () => {
    const tags = await getTags({ types: ["task_tag"] });
    tags &&
      setTaskTags(
        tags.map((i) => Object.assign({ value: i.id, label: i.shortname }))
      );
  }, []);

  useEffect(() => {
    if (!lines) /*check for refresh*/ setLines(props.lines);
  }, [props.lines]);
  useEffect(() => {
    if (lines && taskLines.length === 0)
      setTaskLines(
        lines
          .filter((f) => "tasks" in f && f.tasks.length)
          .map((i) => i.coitem_id)
      );
  }, [lines]);

  const [charTypes, setCharTypes] = useState([]);
  const [list, setList] = useState(
    props.list
      ? props.list
      : [
          {
            text: "Todo 1",
            date: "",
            description: "",
            priority: "",
            assignee: "",
            comments: [],
            isDone: false,
          },
          {
            text: "Todo 2",
            date: "",
            description: "",
            priority: "",
            assignee: "",
            comments: [],
            isDone: false,
          },
        ]
  );

  const [liveData, setLiveData] = useState(props.order.liveData);

  useEffect(() => {}, [props.list]);
  const addTodo = (newTodoObj) => {
    let newList = [...list, newTodoObj];

    setList([...newList]);
  };
  const updateListWithIndex = (index, obj) => {
    if (index === undefined || index === null) return;
    const tempArray = [...list];
    const tempF = list[index];
    let tmpObj = { ...tempF, ...obj };
    tempArray.splice(index, 1, tmpObj);

    setList([...tempArray]);
  };
  const removeListWithIndex = (index) => {
    if (index === undefined || index === null) return;

    const tempArray = [...list];
    tempArray.splice(index, 1);
    setList([...tempArray]);
    if (!tempArray.length) return;
  };
  const updateCommentWithIndex = (listIndex, commentIndex, commentObj) => {
    if (listIndex === undefined || listIndex === null) return;
    const tempArray = [...list];
    const tempF = list[listIndex];
    const commentList = [...tempF.comments];
    const tempCommentObjOld = commentList[commentIndex];
    let tempCommentObj = { ...tempCommentObjOld, ...commentObj };
    commentList.splice(commentIndex, 1, tempCommentObj);
    let tmpObj = { ...tempF, comments: commentList };
    tempArray.splice(listIndex, 1, tmpObj);

    setList([...tempArray]);
  };
  const { user, logOut, envName, permissions } = useContext(FirebaseContext);

  const addComment = (listIndex, obj) => {
    if (listIndex === undefined || listIndex === null) return;
    let commentObj = { ...obj, timeStamp: +new Date(), user: user };
    const tempArray = [...list];
    const tempF = list[listIndex];
    let commentList = [];
    if (
      tempF?.comments &&
      Array.isArray(tempF.comments) &&
      tempF.comments.length > 0
    )
      commentList = [...tempF.comments, commentObj];
    else commentList = [commentObj];
    let tmpObj = { ...tempF, comments: commentList };
    tempArray.splice(listIndex, 1, tmpObj);

    setList([...tempArray]);
  };
  const deleteComment = (listIndex, commentIndex) => {
    if (listIndex === undefined || listIndex === null) return;
    const tempArray = [...list];
    const tempF = list[listIndex];
    const commentList = [...tempF.comments];
    commentList.splice(commentIndex, 1);
    let tmpObj = { ...tempF, comments: commentList };
    tempArray.splice(listIndex, 1, tmpObj);

    setList([...tempArray]);
  };

  const mapItems = () => {
    const types = [];
    list.map((item, k) => {
      if (!types.includes(item.char_name)) types.push(item.char_name);
    });
    setCharTypes(types);
  };

  useEffect(() => {
    if (list.length) mapItems();
  }, [list]);

  useEffect(() => {}, [current]);

  const setCurrentTask = (taskId) => {
    lines.map((item, k) => {
      if (item.tasks.filter((f) => f.id === taskId).length > 0)
        return setCurrent(item.tasks.filter((f) => f.id === taskId)[0]);
    });
    setOpen(true);
  };

  const saveNewTask = async (obj) => {
    const { item, qty_total, qty_ord, qty_per, ...rest } = obj;

    const saveTask = await createNewTask({
      ...rest,
      coitem_id: newTaskModal.coitem_id,
      qty_total: parseInt(qty_total),
      qty_per: parseInt(qty_per),
      qty_ord: parseInt(qty_ord),
      item_id: item.value,
    });

    if (saveTask) {
      const { data } = saveTask;
      const newLines = [...lines];

      newLines.map((line, l) => {
        if (parseInt(line.coitem_id) === parseInt(newTaskModal.coitem_id)) {
          newLines[l].tasks = data;
        }
      });
      setNewTaskModal(null);
      pushNewToast({ message: "New task added successfully." });
    } else {
      //error
      pushNewToast({
        message: "Oops, that action didn't succeed.",
        error: true,
      });
    }
  };

  const closeNewTaskModal = () => {
    setNewTaskModal(null);
  };

  const addNewTask = (coItemId, coItemQty) => {
    setNewTaskModal({ coitem_id: coItemId, coitem_qty: coItemQty });
  };
  const updateBomItem = async (obj) => {
    const { id, task_id } = obj;
    try {
      const data = await updateBomItemPulledStatus(obj);
      if (data) {
        const newLines = [...lines];

        newLines.map((line, l) => {
          line.tasks.map((task, t) => {
            if (task.id === id) {
              newLines[l].tasks[t] = data;
            }
          });
        });

        pushNewToast({ message: "Changes saved successfully" });
        setLines(newLines);
      } else {
      }
    } catch (e) {}
  };
  const setItemPulledQty = async (id, task_id, qty_total) => {
    const buildObj = { id: id, task_id: task_id };
    const newLines = [...lines];

    newLines.map((line, l) => {
      line.tasks.map(async (task, t) => {
        if (task.id === id) {
          const newQty = task.qty_pulled + qty_total;

          buildObj.qty_pulled = newQty;

          // if (newQty === task.qty_total) buildObj.status = 'complete'
          // if (newQty > task.qty_pulled && (task.status === "new" || task.status === "" || task.status === null)) buildObj.status = 'partially_pulled'

          if (newQty > task.qty_pulled)
            buildObj.tags = [
              ...task.tags
                .map((i) => i.value)
                .filter((f) => ![21, 20, 19].includes(f)),
              19,
            ];
          if (newQty === task.qty_total)
            buildObj.tags = [
              ...task.tags
                .map((i) => i.value)
                .filter((f) => ![21, 20, 19].includes(f)),
              21,
            ];

          try {
            const data = await updateBomItemPulledStatus(buildObj);

            if (data) {
              newLines[l].tasks[t] = data;

              if (data.qty_total === data.qty_pulled)
                pushNewToast({ message: `All items pulled for ${task.name}.` });
              else
                pushNewToast({
                  message: `Pulled ${qty_total}x  ${task.name}.`,
                });
            } else {
              pushNewToast({ message: "Something failed.", error: true });
            }
            return data;
          } catch (e) {
            pushNewToast({ message: "Something failed.", error: true });
          }
        }
      });
    });

    setLines(newLines);

    return true;
    // }
    // } catch (e) {
    //   pushNewToast({ message: e, error: true })
    // }
  };

  const searchBomLine = async ({
    coitem_id,
    tags = [],
    status = [],
    title = "",
    sort = { updatedAt: "desc" },
  }) => {
    if (coitem_id === null) alert(`Error. Can't continue.`);
    const x = await getBomTasksByOrderLine({
      coitem_id,
      tags,
      status,
      title,
      sort,
    });

    const newLines = [...lines];

    newLines.map((line, k) => {
      if (parseInt(line.coitem_id) === parseInt(coitem_id)) {
        console.log("found");
        newLines[k].tasks = x;
      }
    });

    console.log(x, "xdata");
    console.log(coitem_id, "coitem_id");
    console.log(newLines, "newLines");
    console.log(lines, "oldLines");
    setLines(newLines);
  };

  const regenBom = async (coitem_id) => {
    const x = await regenerateBom({ coitem_ids: [parseInt(coitem_id)] });
    if (x) {
      const newLines = [...lines];
      newLines.map((line, k) => {
        if (parseInt(line.coitem_id) === parseInt(coitem_id)) {
          newLines[k].tasks = x;
        }
      });
      setLines(newLines);
      pushNewToast({ message: "BOM regenerated successfully." });
    } else {
      pushNewToast({
        message: "Oops, that action didn't succeed.",
        error: true,
      });
    }
  };

  // if(taskLines.length === 0)return "Loading"
  return (
    <TodoListContext.Provider
      value={{
        todoList: list,
        updateListWithIndex,
        removeListWithIndex,
        updateCommentWithIndex,
        addComment,
        deleteComment,
        addTodo,
        charTypes: charTypes,
        liveData,
        lines,
        setCurrentTask,
        setOpen,
        setItemPulledQty,
        updateBomItem,
        searchBomLine,
        taskTags,
        taskLines,
        addNewTask,
        newTaskModal,
        saveNewTask,
        closeNewTaskModal,
        regenBom,
      }}
    >
      {children}
      <AddNewTask />
      {/* <TodoSheet
        open={open}
        toggleOpen={() => {
          setCurrent(null);
          setOpen(!open);
        }}
        todo={current}
      // index={current}
      /> */}
    </TodoListContext.Provider>
  );
};
