import { Select } from "@shopify/polaris";
import { useEffect } from "react";
import { useState, useCallback } from "react";
import { useIsMount } from "../../../OrderPage/Todos/utils";

Array.range = (start, end) =>
  Array.from({ length: end - start }, (v, k) => k + start);

const Weight = (props) => {
  const { onChange } = props;
  const isMount = useIsMount();

  const options = Array.range(-20, 21).map((i) =>
    Object.assign({ value: i, label: i })
  );
  // const [selected, setSelected] = useState(0);

  const handleSelectChange = useCallback((value) => {
   console.log(value, 'value') 
   console.log(options, 'options')
    // setSelected(value)

    onChange(parseInt(value));
    
  }, []);

  // useEffect(() => {
  //   isMount && props.value && setSelected(props.value);
  // });
  // useEffect(() => {
  //   console.log(selected, 'sel')
  //   onChange && onChange(selected);
  // }, [selected]);

  return (
    <Select
      label="Weight"
      options={options}
      onChange={handleSelectChange}
      value={props.value}
    />
  );
};

export default Weight;
