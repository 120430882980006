import { Banner, Button, DataTable, Modal, TextField } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { useFirebaseContext } from "../Firebase";

const TagsList = () => {
  const { expressFunction } = useFirebaseContext();
  const [tags, setTags] = useState([]);
  const getTagsList = expressFunction("getTagsList");
  const [showAddTagModal, setShowAddTagModal] = useState(false);

  const AddTagModal = () => {
    const [newTagName, setNewTagName] = useState("");
    const saveNewTag = expressFunction("saveNewTag");
    const [error, setError] = useState("");

    const submitNewTag = () => {
      saveNewTag({ newTagName })
        .then((response) => {
          console.log("responseresponseresponse", response);
          if (response.data.status === "success") {
            window.location.reload();
            setShowAddTagModal(false);
          } else {
            setError(response.error);
          }
        })
        .catch((error) => {
          setError(error.error);
        });
    };

    return (
      <Modal
        open={true}
        onClose={() => {}}
        title="Add Tag"
        primaryAction={{
          content: "Add",
          onAction: submitNewTag,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => {
              setNewTagName("");
              setShowAddTagModal(false);
            },
          },
        ]}
      >
        {error && (
          <Banner title="Duplicate Tag Name" status="critical">
            <p>This tag already exists.</p>
          </Banner>
        )}
        <TextField
          //   label="Tag Name"
          value={newTagName}
          onChange={(value) => {
            setNewTagName(value);
          }}
        />
      </Modal>
    );
  };

  useEffect(() => {
    getTagsList().then((data) => {
      console.log("tags", data);
      setTags(data);
    });
  }, []);

  const rows = tags.map((tag) => {
    return [tag.name, 0, "Delete"];
  });
  const headings = ["Name", "Number of Chars Using", "Delete"];
  return (
    <>
      <Button primary onClick={() => setShowAddTagModal(true)}>
        Add Tag
      </Button>
      {showAddTagModal && <AddTagModal />}
      <DataTable
        headings={headings}
        rows={rows}
        columnContentTypes={["text", "number", "text"]}
      />
    </>
  );
};

export default TagsList;
