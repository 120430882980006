import { Card } from "@shopify/polaris";
import { useFirebaseContext } from "../../Firebase";
import { useRef, useState } from "react";

const GLBSection = ({ itemObj }) => {
  console.log("itemObj.glb", itemObj.glb);
  const { expressFunction } = useFirebaseContext();
  const uploadItemGLB = expressFunction("uploadItemGLB");
  const removeItemGLB = expressFunction("removeItemGLB");
  const modelRef = useRef();
  const [editModel, setEditModel] = useState(false);

  const [fileInBase64, setFileInBase64] = useState(null);
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleGLBChange = (e) => {
    e.target.files[0].arrayBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: e.target.files[0].type,
      });
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        const base64data = reader.result;
        // console.log(base64data);
        setFileName(e.target.files[0].name);
        setFileInBase64(base64data);
      };
    });
  };

  const handleGLBUpload = () => {
    setLoading(true);
    uploadItemGLB({
      fileInBase64: fileInBase64,
      itemID: itemObj.item_id,
      fileName: fileName,
    }).then((res) => {
      console.log(res);
      window.location.reload();
    });
  };

  const handleGLBRemove = () => {
    setLoading(true);
    removeItemGLB({ itemID: itemObj.id }).then((res) => {
      console.log(res);
      window.location.reload();
    });
  };

  const editModelView = (
    <>
      <h1>Upload GLB</h1>
      <input
        type="file"
        accept=".glb"
        ref={modelRef}
        onChange={handleGLBChange}
      />
    </>
  );

  const viewModelView =
    itemObj.glbURL !== null ? (
      <model-viewer
        // className="model-viewer"
        src={itemObj.glbURL}
        alt="A rock"
        // exposure="0.008"
        camera-controls
        auto-rotate="true"
        auto-rotate-delay="1000"
        style={{ width: "100%", minHeight: "400px" }}
        ar
        ar-modes="webxr"
        ref={(ref) => {
          modelRef.current = ref;
        }}
      />
    ) : (
      <h1>No GLB</h1>
    );

  return (
    <Card
      sectioned
      title="3D Model"
      actions={[
        {
          content: editModel ? "Cancel" : "Edit",
          onAction: () => setEditModel(!editModel),
        },
        itemObj.glb !== null && {
          content: "Delete",
          onAction: handleGLBRemove,
        },
        // {
        //   content: "Full Screen",
        //   onAction: () => setFullScreen(!fullScreen),
        // },
      ]}
      primaryFooterAction={
        editModel && {
          content: loading ? "Saving.." : "Save",
          disabled: loading ? true : false,
          onAction: handleGLBUpload,
        }
      }
    >
      {editModel ? editModelView : viewModelView}
    </Card>
  );
};

export default GLBSection;
