import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useContext,
} from "react";
import {
  AppProvider,
  Avatar,
  Icon,
  VisuallyHidden,
  ActionList,
  Popover,
  Frame,
  TopBar,
  SkeletonDisplayText,
  Button,
  Modal,
  TextContainer,
} from "@shopify/polaris";
import {
  ArrowLeftMinor,
  QuestionMarkMajor,
  TroubleshootMajor,
  DeleteMinor,
} from "@shopify/polaris-icons";
import { useFirebaseContext } from "./Firebase";
import { NavbarBrand } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "./Header";

export default function TopBarComponent(props) {
  const state = useFirebaseContext();
  const { showSideMenu } = state;
  // console.log(state);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [entities, setEntities] = useState([]);
  const [isSecondaryMenuOpen, setIsSecondaryMenuOpen] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);

  const handleChangeHost = async (v) => {
    // const inputRef = action.name;
    localStorage.clear();
    // console.log(v);
    //should replace this filter like `https://${v.value}-bock2018.pantheonsite.io`

    let host = `https://env14.createdbycocoon.com`;
    localStorage.setItem("HOST_NAME", host);

    let data = localStorage.getItem("HOST_NAME");
    console.log(data);
    setSelectValueHost(v);
    window.location.reload();
    // await sessToken().then(async (token) => {
    //   await CallMyO2O(token).then(async (data) => {
    //     if (data.isAuthenticated === true) {
    //       sessionStorage.setItem("user", JSON.stringify(data));
    //       // setLoading(false);
    //       dispatch({
    //         type: "LOGIN",
    //         payload: data,
    //       });
    //       dispatch({
    //         type: "SUCCESSFUL_TOAST",
    //         payload: `Welcome ${data.name}, swtiched to host ${v.value} successful.`,
    //       });
    //       dispatch({
    //         type: "TOKEN_ONLY",
    //         payload: token,
    //       });
    //     } else {
    //       dispatch({
    //         type: "LOGOUT",
    //       });
    //     }
    //   });
    // });
  };

  const cacheButton = useRef();

  // const activator = <Button onClick={handleChange}>Open</Button>;

  const toggleIsUserMenuOpen = useCallback(
    () => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
    []
  );

  const toggleIsSecondaryMenuOpen = useCallback(
    () => setIsSecondaryMenuOpen((isSecondaryMenuOpen) => !isSecondaryMenuOpen),
    []
  );

  const [active, setActive] = useState(true);

  const handleItemAction = useCallback((p) => {
    history.push(p);
    setActive(false);
  }, []);

  const actionFunc = () => {
    alert("hi");
  };
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const userMenuMarkup = (
    <>
      <TopBar.UserMenu
        actions={[
          {
            items: [
              {
                content: showSideMenu[0] ? "Show Side Menu" : "Hide Side Menu",
                // icon: ArrowLeftMinor,
                onAction: () => {
                  showSideMenu[1](!showSideMenu[0]);
                },
              },
              {
                content: "Settings",
                icon: ArrowLeftMinor,
                url: "/settings",
              },
            ],
          },
          {
            items: [
              {
                content: "Log Out",
                icon: ArrowLeftMinor,
                url: "/logout",
              },
            ],
          },
        ]}
        name={state.user.displayName}
        detail={state.user.email}
        initials={
          ""
          // (state.user.displayName &&
          //   state.user.displayName.charAt(0).toUpperCase()) ||
          // (state.user.email && state.user.email.charAt(0).toUpperCase())
        }
        open={isUserMenuOpen}
        onToggle={toggleIsUserMenuOpen}
      />
    </>
  );

  const secondaryMenuMarkup = (
    <div>
      <Header />
    </div>
  );
  const searchFieldMarkup = () => (
    <div style={{ display: "flex" }}>
      <div style={{ width: "70%" }}>
        <Header />
      </div>
      <Header />
    </div>
  );
  return (
    <>
      <TopBar
        showNavigationToggle
        userMenu={userMenuMarkup}
        // secondaryMenu={secondaryMenuMarkup}
        // searchResultsVisible={true}
        // searchField={searchFieldMarkup}
        // searchResults={() => <></>}
        // onSearchResultsDismiss={() => null}
        onNavigationToggle={props.toggleMobileNavigationActive}
      />
    </>
  );
}
