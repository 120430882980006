import { useFirebaseContext } from "./../Firebase";
import { useCallback, useEffect, useState } from "react";
import { Toast } from "react-bootstrap";

import {
  useHistory,
  useLocation,
  useRouteMatch,
  Route,
  Switch,
} from "react-router-dom";
import moment from "moment";
import {
  Page,
  Box,
  Card,
  Tabs,
  Badge,
  Grid,
  Spinner,
  SkeletonDisplayText,
  SkeletonTabs,
  Banner,
} from "@shopify/polaris";
import "./OrderPage.css";
import Sidebar from "./Sidebar";
import { TodoListProvider } from "../../Context/TodoListContext";
import Todos from "./Todos/Todos";
import OrderHistory from "./OrderHistory";
import OrderAcknowledgement from "./OrderAcknowledgement";
import OrderPackingList from "./OrderPackingList";

const OrderPage = (props) => {
  const match = useRouteMatch();

  const location = useLocation();
  const { reactFunction, expressFunction, smallWindow } = useFirebaseContext();
  const getOrder = expressFunction("getOrder");
  const getCharsByOrderItem = expressFunction("getCharsByOrderItem");
  const getTaskHistory = expressFunction("getTaskHistory");

  const history = useHistory();
  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex) => {
    history.push(match.url + tabs[selectedTabIndex].path);
    setSelected(selectedTabIndex);
  }, []);

  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(true);
  const [bomItems, setBomItems] = useState([]);
  const [bomList, setBomList] = useState([]);
  const [tasksRemaining, setTasksRemaining] = useState(0);
  const [taskHistory, setTaskHistory] = useState([]);

  const orderNumber = match.params["orderNumber"];

  useEffect(async () => {
    if (order?.cohead_id) {
      const getHistory = await getTaskHistory({ cohead_id: order.cohead_id });
      const data = getHistory;
      setTaskHistory(data);
    }
  }, [order]);

  useEffect(() => {
    setLoading(true);
    const t = true;
    if (t) {
      getOrder({ orderNumber: orderNumber }).then((data) => {
        setOrder(data.order);
        setLoading(false);

        getCharsByOrderItem({ id: data.order.cohead_id }).then((x) => {
          // alert(data.order.cohead_id);
          let y = [];
          x?.data?.rows?.map((item, k) => {
            y.push({ ...item, uniq_id: k });
          });
          setBomItems(y);
        });
      });
    }
  }, [orderNumber]);

  useEffect(() => {
    if (bomItems.length) {
      const items = [];
      bomItems.map((i, k) => {
        items.push({
          ...i,
          text: `Todo ${k}`,
          date: "",
          description: "",
          priority: "",
          assignee: "",
          comments: [],
          isDone: false,
        });
      });
      setBomList(items);
    }
  }, [bomItems]);

  const CommentRow = ({ commentObj }) => {
    return (
      <Toast sm>
        <Toast.Header closeButton={false}>
          {/*<img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />*/}
          {/*<strong className="me-auto">Bootstrap</strong>*/}
          <small>
            {moment(commentObj.created["_seconds"], "X").format("MM-DD-yyyy")} /{" "}
            {commentObj.user || commentObj.drupalUsername}
          </small>
        </Toast.Header>
        <Toast.Body dangerouslySetInnerHTML={{ __html: commentObj.comment }} />
      </Toast>
    );
  };

  // const smallScreenTabs = [
  //   {
  //     id: "Comments",
  //     content: "Comments",
  //     component: <Comments />,
  //     panelID: "prospects-content-1",
  //   },
  //   {
  //     id: "Labels",
  //     content: "Labels",
  //     component: "Here goes Labels",
  //     panelID: "prospects-content-1",
  //   },
  // ];

  const commentTab = () => {};

  useEffect(() => {
    if (
      "liveData" in order &&
      order?.liveData?.lines?.filter((f) => f.tasks.length > 0).length
    ) {
      let countRemainingTasks = 0;
      order.liveData.lines.map(
        (item) =>
          (countRemainingTasks += item.tasks.filter(
            (f) => f.qty_pulled < f.qty_total
          ).length)
      );
      setTasksRemaining(countRemainingTasks);
    }
  }, [order]);

  useEffect(() => {
    const { pathname } = location;

    setSelected(
      tabs.map((e) => e.path).indexOf(pathname.replace(match.url, ""))
    );
  }, [location]);

  let tabs = [
    {
      id: "orderAck",
      content: "Order Acknowledgement",
      // component: order && order["ordAck"] && (
      //   <PdfViewer url={order["ordAck"]} />
      // ),
      // accessibilityLabel: "All customers",
      // panelID: "all-customers-content-1",
      path: "",
    },
    {
      id: "packingList",
      content: "Packing List",
      // component: order && order["packingList"] && (
      //   <PdfViewer url={order["packingList"]} />
      // ),
      // panelID: "accepts-marketing-content-1",
      path: "/packing-list",
    },
    {
      id: "BOM",
      content: (
        <span>
          BOM <Badge status="attention">{tasksRemaining} incomplete</Badge>
        </span>
      ),
      // component: <><Todos /> {/*<BOM order={order} />*/}</>,
      // panelID: "repeat-customers-content-1",
      path: "/bom",
    },
    {
      id: "History",
      content: (
        <span>
          History <Badge>{taskHistory.length}</Badge>
        </span>
      ),
      // component: (<><OrderHistory
      //   author={order?.cohead_creator}
      //   orderNumber={order?.cohead_number}
      //   createdDate={order?.cohead_orderdate}
      //   lines={order?.liveData?.lines}
      //   taskHistory={taskHistory}
      // /></>),
      // panelID: "order-history",
      path: "/history",
    },
  ];

  if (!smallWindow) {
    // tabs.unshift(...smallScreenTabs);
  }

  // if (bomList.length === 0) return 'loading bom'

  return (
    <>
      <TodoListProvider
        list={bomList}
        order={order}
        lines={order?.liveData?.lines}
      >
        <Page
          title={
            !order.cohead_number ? (
              <SkeletonDisplayText size="medium" />
            ) : (
              `Order: ${order.cohead_number}`
            )
          }
          subtitle={""}
          style={{
            width: "1%",
            margin: "0 auto",
          }}
        >
          {order &&
            order.liveData &&
            order.liveData.header &&
            order.liveData.header.cohead_holdtype !== "N" && (
              <Banner status="critical">
                Order is on
                {
                  { C: " Credit", S: " Shipping", P: " Packing", R: " Return" }[
                    order.liveData.cohead_holdtype
                  ]
                }{" "}
                hold.
              </Banner>
            )}
          <Grid columns={2}>
            <Grid.Cell columnSpan={{ xs: 6, sm: 4, md: 4, lg: 8, xl: 8 }}>
              <Card>
                {!order.cohead_number ? (
                  <SkeletonTabs count={4} />
                ) : (
                  <>
                    <Tabs
                      fitted
                      tabs={tabs}
                      selected={selected}
                      onSelect={handleTabChange}
                    />

                    <Box
                      paddingBlockStart="4"
                      paddingInlineStart="4"
                      paddingInlineEnd="4"
                      paddingBlockEnd="4"
                    >
                      <Route
                        exact
                        path={`${match.path}`}
                        render={(props) => (
                          <OrderAcknowledgement pdfUrl={order["ordAck"]} />
                        )}
                      />
                      <Route
                        path={`${match.path}/packing-list`}
                        render={(props) => (
                          <OrderPackingList pdfUrl={order["packingList"]} />
                        )}
                      />
                      <Route path={`${match.path}/bom`}>
                        <Todos />
                      </Route>
                      {/* <Route exact path={`${match.path}/bom/item/:bomItem`} render={(props) => <>hello</>}/> */}

                      <Route
                        path={`${match.path}/history`}
                        render={(props) => (
                          <OrderHistory
                            author={order?.cohead_creator}
                            orderNumber={order?.cohead_number}
                            createdDate={order?.cohead_orderdate}
                            lines={order?.liveData?.lines}
                            taskHistory={taskHistory}
                          />
                        )}
                      />
                    </Box>
                  </>
                )}
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 5, sm: 4, md: 6, lg: 4, xl: 4 }}>
              {!order.cohead_number ? (
                <>
                  <Card>
                    <SkeletonTabs number={3} />
                  </Card>
                </>
              ) : (
                <Sidebar order={order} setOrder={setOrder} />
              )}
            </Grid.Cell>
          </Grid>
        </Page>
      </TodoListProvider>
    </>
  );
};

export default OrderPage;
