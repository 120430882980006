import { useRef, useEffect } from 'react';

export const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
        isMountRef.current = false;
    }, []);
    return isMountRef.current;
};

export const mapStatusToLabel = {
    new: "Not Pulled",
    awaiting_stock: "Awaiting Stock",
    partially_pulled: "Partially Pulled",
    complete: "Complete",
}