import { Link, useHistory } from "react-router-dom";
import { CircleTickMinor, CancelMinor } from "@shopify/polaris-icons";
import { Icon } from "@shopify/polaris";
import moment from "moment";
export const createEditableDataTableItems = ({
  array = [],
  editSlug = undefined,
  deleteSlug = undefined
}) => {
  let arr = [];

  array.map((v, k) => {
    const x = [
      ...Object.values(v).map((item, k) => {
        if (typeof item === "boolean" && +item === 1)
          return <Icon source={CircleTickMinor} color={"success"} />;

        if (typeof item === "boolean" && +item === 0)
          return <Icon source={CancelMinor} color={"critical"} />;

        if (
          moment(item, moment.ISO_8601, true).isValid() &&
          (Object.keys(v)[k].includes("created") ||
            Object.keys(v)[k].includes("updated"))
        ) {
          return moment(item).fromNow();
        }

        return item;
      }),
    ]

    if (editSlug !== undefined) x.push(typeof editSlug === "function" ? (
      <a
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={() => editSlug(k)}
      >
        Edit
      </a>
    ) : (
      <Link to={editSlug + v.id}>Edit</Link>
    ))


    if (deleteSlug !== undefined) x.push(typeof deleteSlug === "function" ? <a
      style={{ textDecoration: "underline", cursor: "pointer", color: 'var(--p-interactive-critical)' }}
      onClick={() => deleteSlug(k)}
    >
      Delete
    </a> : <Link style={{ color: "var(--p-interactive-critical)" }} to={deleteSlug + v.id}>Delete</Link>)

    arr.push(x);
  });

  return arr;
};

export const activeOptions = [
  {
    value: "true",
    label: "Active",
  },
  {
    value: "false",
    label: "Inactive",
  },
];

export const getSymDifference = (a, b) => {
  return getDifference(a, b).concat(getDifference(b, a));
};

export const getDifference = (a, b) => {
  var setB = new Set(b);
  return a.filter((v) => !setB.has(v));
};
