import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

const ActionParamsQty = (props) => {
    const {
        value,
        setValue,
        config,
        readonly,
        min,
        max,
        step,
        placeholder,
        customProps
    } = props;


    const onChange = e => {
        let val = e.target.value;
        if (val === "" || val === null)
            val = undefined;
        else
            val = Number(val);
        setValue({ ...value, qty: val });
    };

    const numberValue = value == undefined || (typeof value === "object" && !'qty' in value) ? "" : value.qty;

    return <FormControl>
        <TextField
            variant="standard"
            type="number"
            value={numberValue}
            placeholder={!readonly ? placeholder : ""}
            InputProps={{
                readOnly: readonly,
            }}
            inputProps={{
                min: min,
                max: max,
                step: step,
            }}
            disabled={readonly}
            onChange={onChange}
            size="medium"
            {...customProps}
        />
    </FormControl>
}

export default ActionParamsQty