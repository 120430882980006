import React from "react";
import { Box, Text, DataTable } from "@shopify/polaris";
import { createEditableDataTableItems } from "../utils/utils.js";
const CharacteristicItemList = (props) => {
  const { items, deleteCharassItem, setOpen } = props;

  console.log(items, "items");

  if (items.length === 0)
    return (
      <Box paddingBlockStart="8" paddingBlockEnd="4">
        <Text>There are no default items for this characteristic yet.</Text>
      </Box>
    );
  return (
    <>
      <DataTable
        columnContentTypes={["numeric", "text", "numeric", "text"]}
        headings={[
          "Item ID",
          "Item Name",
          "Quantity",
          // "Notes",
          "Edit",
          "Delete",
        ]}
        rows={createEditableDataTableItems({
          array: items,
          editSlug: setOpen,
          deleteSlug: (e) => {
            deleteCharassItem(e)
          },
        })}
      />

      {/* {items.map((item, k) => {
        return (
          <>
            <br />
            <br />
            Item: {item.item_id}
            <br />
            ItemName: {item.item_number}
            <br />
            Qty: {item.qty}
          </>
        );
      })} */}
    </>
  );
};

export default CharacteristicItemList;
