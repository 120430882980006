import {
  List,
  Button,
  Page,
  Card,
  Sheet,
  Heading,
  Scrollable,
  ChoiceList,
  Select,
  Form,
  FormLayout,
  TextField,

} from "@shopify/polaris";
import { MobileCancelMajor } from "@shopify/polaris-icons";
import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { TodoListContext } from "../../../Context/TodoListContext";
import TodoComments from "./TodosComments";
const TodoSheet = (props) => {

  const {
    open: sheetActive,
    toggleOpen: toggleSheetActive,
    todo,
    index,
  } = props

  const optionsPriority = [
    { label: "Low", value: "low" },
    { label: "Medium", value: "medium" },
    { label: "High", value: "high" },
    { label: "Urgent", value: "urgent" },
  ];

  const optionsAssignee = [
    { label: "user1", value: "user1" },
    { label: "user2", value: "user2" },
    { label: "user3", value: "user3" },
    { label: "user4", value: "user4" },
  ];
  const { updateListWithIndex } = useContext(TodoListContext);

  // console.log(todo, 'todo')
  if (todo === null || typeof todo === "undefined") return null;
  return (
    <Sheet
      open={sheetActive}
      onClose={toggleSheetActive}
      accessibilityLabel={todo.text}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div
          style={{
            alignItems: "center",
            borderBottom: "1px solid #DFE3E8",
            display: "flex",
            justifyContent: "space-between",
            padding: "1rem",
            width: "100%",
          }}
        >
          <div>
            <span>{todo.char_name}</span>
            <Heading>

              <input
                // value={todo.text}
                value={todo.charass_value}
                type="text"
                style={{
                  border: "none",
                  width: "100%",
                  fontSize: "18px",
                  fontWeight: "700",
                  "&:focus": { border: "none", outline: "none" },
                  "&:hover": { border: "none", outline: "none" },
                }}
                onChange={(value) =>
                  updateListWithIndex(index, { text: value.target.value })
                }
              />
              {/* {todo.text} */}
            </Heading>
          </div>
          <Button
            accessibilityLabel="Cancel"
            icon={MobileCancelMajor}
            onClick={toggleSheetActive}
            plain
          />
        </div>
        <Scrollable style={{ padding: "1rem", height: "100%" }}>
          <Form onSubmit={() => console.log("submit")}>
            <FormLayout>
              <Select
                label="Priority"
                options={optionsPriority}
                onChange={(value) =>
                  updateListWithIndex(index, { priority: value })
                }
                value={todo.priority}
              />
              <Select
                label="Assignee"
                options={optionsAssignee}
                onChange={(value) =>
                  updateListWithIndex(index, { assignee: value })
                }
                value={todo.assignee}
              />
              <TextField
                value={todo.date}
                onChange={(value) =>
                  updateListWithIndex(index, { date: value })
                }
                label="Due Date"
                type="date"
              />
              <TextField
                value={todo.description}
                onChange={(value) =>
                  updateListWithIndex(index, { description: value })
                }
                label="Description"
                multiline={4}
              />
            </FormLayout>
          </Form>
          <TodoComments comments={todo.comments} listIndex={index} />
        </Scrollable>
        <div
          style={{
            alignItems: "center",
            borderTop: "1px solid #DFE3E8",
            display: "flex",
            justifyContent: "space-between",
            padding: "1rem",
            width: "100%",
          }}
        >
          <Button
            destructive
            onClick={() => updateListWithIndex(index, { isDone: false })}
          >
            ✕ Incomplete
          </Button>
          <Button
            primary
            onClick={() => updateListWithIndex(index, { isDone: true })}
          >
            ✓ Complete
          </Button>
        </div>
      </div>
    </Sheet>
  );
};
export default TodoSheet;
