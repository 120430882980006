import { Card, DataTable, Link } from "@shopify/polaris";
import { useFirebaseContext } from "../../Firebase";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

const CharacteristicsFields = ({
  xTupleItemCharacteristicsInUse,
  xTupleItemDetails,
}) => {
  console.log("xTupleItemCharacteristicsInUse", xTupleItemCharacteristicsInUse);
  const { item_id } = xTupleItemDetails;
  const { reactFunction } = useFirebaseContext();
  const history = useHistory();

  const rows = xTupleItemCharacteristicsInUse.map((field) => [
    <Link url={`/bom_logic/item/${item_id}/characteristics/${field.char_id}`}>
      {field.char_name}
    </Link>,
    field.count,
  ]);

  console.log("rows", rows);

  return (
    <Card
      title={`Characteristics Fields`}
      actions={[
        {
          content: "Add Characteristics Field",
          onAction: () => history.push(`${item_id}/add_characteristics/`),
        },
      ]}
      sectioned
    >
      <DataTable
        columnContentTypes={("text", "integer")}
        headings={["CharName", "# of Characteristics"]}
        rows={rows}
      />
    </Card>
  );
};

export default CharacteristicsFields;
