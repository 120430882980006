import { useFirebaseContext } from "../../Firebase";
import React, { useCallback, useEffect, useState } from "react";
import {
  DataTable,
  Pagination,
  Card,
  Button,
  Popover,
  OptionList,
  Page,
  Badge,
  Icon,
} from "@shopify/polaris";
import { Link, useHistory } from "react-router-dom";
import { CircleTickMinor, CancelMinor } from "@shopify/polaris-icons";
import { activeOptions, createEditableDataTableItems } from "../../utils/utils";

const Rules = () => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);

  const { expressFunction } = useFirebaseContext();
  const getRules = expressFunction("getRules");

  const [activeStatus, setActiveStatus] = useState(
    activeOptions.map((i) => i.value)
  );

  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );

  const history = useHistory();

  useEffect(async () => {
    let res = await getRules({ page: 1 });
    setData(
      createEditableDataTableItems({ array: res, editSlug: "/bom/rule/" })
    );
  }, []);

  return (
    <Page
      breadcrumbs={[{ content: "Home", url: "/" }]}
      title="Rules"
      subtitle="Create and edit global rules."
      compactTitle
      primaryAction={{
        content: "Add New",
        onAction: () =>
          history.push("/bom/rule/new", { state: { addNew: true } }),
      }}
      secondaryActions={[
        {
          content: (
            <Popover
              active={popoverActive}
              activator={
                <Button onClick={togglePopoverActive} disclosure>
                  Filter
                </Button>
              }
              onClose={togglePopoverActive}
            >
              <OptionList
                onChange={setActiveStatus}
                options={activeOptions}
                allowMultiple
                selected={activeStatus}
              />
            </Popover>
          ),
        },
      ]}
    >
      <Card>
        <DataTable
          columnContentTypes={["numeric", "text", "text", "text", "text"]}
          headings={["ID", "Title", "Description", "Active", "Edit"].filter(
            Boolean
          )}
          rows={data}
          footerContent={`Showing ${data.length} items on this page`}
        />
      </Card>
      <div style={{ margin: "20px 0 40px 5px" }}>
        {/* <Pagination
          onPrevious={() => {}} //onPageChange(pageNumber - 1)}
          onNext={() => {}} // onPageChange(pageNumber + 1)}
          plain={false}
          hasPrevious={pageNumber > 1}
          hasNext={totalPage > pageNumber}
        /> */}
      </div>
    </Page>
  );
};

export default Rules;
