import { useFirebaseContext } from "../Firebase";
import React, {
  useEffect,
  //  useCallback,
  useState,
} from "react";
import {
  DataTable,
  // TextField,
  // Popover,
  // Button,
  // OptionList,
  ChoiceList,
  Page,
  Pagination,
  Card,
  // IndexTable,
  Filters,
  // Select,
  // useIndexResourceState,
  Stack,
  // Text,
} from "@shopify/polaris";
import { createEditableDataTableItems } from "../utils/utils";
import { Link, useHistory } from "react-router-dom";
import Tag from "../Commons/Tag";
const Characteristics = () => {
  const { reactFunction, expressFunction, smallWindow } = useFirebaseContext();
  // const history = useHistory();
  const [characteristics, setCharacteristics] = useState([]);
  const [characteristicTypes, setCharacteristicTypes] = useState([]);
  const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [options, setOptions] = useState([]);
  // const [popoverActive, setPopoverActive] = useState(false);
  const [limit, setLimit] = useState(500);
  const fetchCharacteristics = expressFunction("getCharacteristics");
  // const fetchCharacteristicTypes = reactFunction("getCharacteristicTypes");
  const getConfigurableChars = expressFunction("getConfigurableChars");
  const getTags = expressFunction("getTags");
  const [tagTypes, setTagTypes] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(async () => {
    const tags = await getTags({ asOptions: true, types: ["charass_tag"] });
    const { data } = tags;
    setTagTypes(data);
  }, []);
  useEffect(async () => {
    const chars = await getConfigurableChars();
    const { data } = chars;

    setCharacteristicTypes(
      data.map((i) => Object.assign({ value: i.char_id, label: i.char_name }))
    );
    callFetch();
  }, []);

  const [loading, setLoading] = useState(true);
  const editSlug = "/characteristic/";

  const callFetch = async () => {
    const data = await fetchCharacteristics({
      page: (pageNumber - 1) * limit,
      types: options,
      tags: selectedTags,
      title: search,
      limit: limit,
      fields: [
        "charass.charass_id",
        "charass.charass_value",
        "charass.charass_char_id",
        "char.char_name",
        // "char.char_type",
        `COALESCE(
          json_agg(
            json_build_object(
              'value', tag.id, 'label', tag.shortname, 'icon', tag.icon, 'color', tag.color
            )
          ) 
          FILTER (
            WHERE tag.id IS NOT NULL
          ),
          '[]'
        ) as tags`,
      ],
    });

    if (data)
      setCharacteristics(
        createEditableDataTableItems({
          array: data.map(mapThroughItem),
          editSlug: editSlug,
        })
      );
  };
  useEffect(() => {
    callFetch();
  }, [options, selectedTags, search, pageNumber]);

  useEffect(() => {
    setPageNumber(1);
  }, [search]);

  const mapThroughItem = (v) => {
    const { charass_char_id, charass_id, tags, ...rest } = v;
    return Object.assign({
      id: charass_id,
      ...rest,
      tags: (
        <Stack alignment="leading" distribution="leading">
          {tags.map((i) => (
            <Tag value={i.label} color={i.color} icon={i.icon} />
          ))}
        </Stack>
      ),
    });
  };

  // const togglePopoverActive = useCallback(
  //   () => setPopoverActive((popoverActive) => !popoverActive),
  //   []
  // );

  // const customers = [
  //   {
  //     id: "3416",
  //     url: "#",
  //     name: "Mae Jemison",
  //     location: "Decatur, USA",
  //     orders: 20,
  //     amountSpent: "$2,400",
  //   },
  //   {
  //     id: "2566",
  //     url: "#",
  //     name: "Ellen Ochoa",
  //     location: "Los Angeles, USA",
  //     orders: 30,
  //     amountSpent: "$140",
  //   },
  // ];
  // const resourceName = {
  //   singular: "customer",
  //   plural: "customers",
  // };

  // const { selectedResources, allResourcesSelected, handleSelectionChange } =
  //   useIndexResourceState(customers);
  // const [taggedWith, setTaggedWith] = useState([]);
  // const [queryValue, setQueryValue] = useState(null);
  // const [sortValue, setSortValue] = useState("today");

  // const handleTaggedWithChange = useCallback(
  //   (value) => setTaggedWith(value),
  //   []
  // );
  // const handleTaggedWithRemove = useCallback(() => setTaggedWith(null), []);
  // const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  // const handleClearAll = useCallback(() => {
  //   handleTaggedWithRemove();
  //   handleQueryValueRemove();
  // }, [handleQueryValueRemove, handleTaggedWithRemove]);
  // const handleSortChange = useCallback((value) => setSortValue(value), []);

  const filters = [
    {
      key: "taggedWith",
      label: "Tagged with",
      filter: (
        <ChoiceList
          onChange={setSelectedTags}
          choices={tagTypes}
          allowMultiple
          selected={selectedTags}
        />
      ),
      shortcut: true,
    },
    {
      key: "type",
      label: "Type",
      filter: (
        <ChoiceList
          onChange={setOptions}
          choices={characteristicTypes}
          allowMultiple
          selected={options}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(options)) {
    const key = "type";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, options),
      onRemove: () => setOptions([]),
    });
  }
  if (!isEmpty(selectedTags)) {
    const key = "taggedWith";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, selectedTags),
      onRemove: () => setSelectedTags([]),
    });
  }

  // const appliedFilters = !isEmpty(taggedWith)

  //   ? [
  //       {
  //         key: "taggedWith",
  //         label: disambiguateLabel("taggedWith", taggedWith),
  //         onRemove: handleTaggedWithRemove,
  //       },
  //       {
  //         key: "type",
  //         label: disambiguateLabel(
  //           "type",
  //           options.map(
  //             (i) => characteristicTypes.find((f) => f.value === i).label
  //           )
  //         ),
  //         onRemove: handleTaggedWithRemove,
  //       },
  //     ]
  // : [];

  // const sortOptions = [
  //   { label: "Today", value: "today" },
  //   { label: "Yesterday", value: "yesterday" },
  //   { label: "Last 7 days", value: "lastWeek" },
  // ];

  // const rowMarkup = customers.map(
  //   ({ id, name, location, orders, amountSpent }, index) => (
  //     <IndexTable.Row
  //       id={id}
  //       key={id}
  //       selected={selectedResources.includes(id)}
  //       position={index}
  //     >
  //       <IndexTable.Cell>
  //         <Text variant="bodyMd" fontWeight="bold" as="span">
  //           {name}
  //         </Text>
  //       </IndexTable.Cell>
  //       <IndexTable.Cell>{location}</IndexTable.Cell>
  //       <IndexTable.Cell>
  //         <Text variant="bodyMd" as="span" alignment="end" numeric>
  //           {orders}
  //         </Text>
  //       </IndexTable.Cell>
  //       <IndexTable.Cell>
  //         <Text variant="bodyMd" as="span" alignment="end" numeric>
  //           {amountSpent}
  //         </Text>
  //       </IndexTable.Cell>
  //     </IndexTable.Row>
  //   )
  // );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        // console.log(value, 'val')
        return value
          ? `Tagged with ${value
              .map((i) => tagTypes.find((f) => f.value === i).label)
              .join(", ")}`
          : null;
      case "type":
        return value
          ? `Of type ${value
              .map((i) => characteristicTypes.find((f) => f.value === i).label)
              .join(", ")}`
          : null;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }

  return (
    <Page
      breadcrumbs={[{ content: "Home", url: "/" }]}
      title="Characteristics"
      subtitle="Edit characteristics values and tags."
      compactTitle
      // secondaryActions={[
      //   {
      //     content: (
      //       <Popover
      //         active={popoverActive}
      //         activator={
      //           <Button onClick={togglePopoverActive} disclosure>
      //             Filter
      //           </Button>
      //         }
      //         onClose={togglePopoverActive}
      //       >
      //         <OptionList
      //           onChange={setOptions}
      //           options={characteristicTypes}
      //           allowMultiple
      //           selected={options}
      //         />
      //       </Popover>
      //     ),
      //   },
      // ]}
    >
      <Card>
        <div style={{ padding: "16px", display: "flex" }}>
          <div style={{ flex: 1 }}>
            <Filters
              queryValue={search}
              filters={filters}
              appliedFilters={appliedFilters}
              onQueryChange={setSearch}
              onQueryClear={() => setSearch("")}
              // onClearAll={handleClearAll}
            />
          </div>
          {/* <div style={{ paddingLeft: "0.25rem" }}>
          <Select
            labelInline
            label="Sort by"
            options={sortOptions}
            value={sortValue}
            onChange={handleSortChange}
          />
        </div> */}
        </div>
        <DataTable
          columnContentTypes={["numeric", "text", "text", "text", "text"]}
          headings={[
            "ID",
            "Name",

            // <TextField
            //   placeholder="Name"
            //   value={search}
            //   onChange={setSearch}
            // />,
            "Characteristic Type",
            "Tags",
            "Edit",
          ]}
          rows={characteristics}
          footerContent={`Showing ${characteristics.length} items on this page`}
        />
      </Card>
      <div style={{ margin: "20px 0 40px 5px" }}>
        <Pagination
          onPrevious={() => pageNumber > 1 && setPageNumber(pageNumber - 1)}
          onNext={() => setPageNumber(pageNumber + 1)}
          plain={false}
          hasPrevious={pageNumber > 1}
          hasNext={true}
        />
      </div>
    </Page>
  );
};

export default Characteristics;
