import {
  Button,
  Card,
  DataTable,
  Form,
  Label,
  Link,
  Modal,
  Page,
  Select,
} from "@shopify/polaris";
import moment from "moment";
import { useEffect, useState } from "react";
import { useFirebaseContext } from "../Firebase";

const FindOrderByChars = ({}) => {
  const [OrderFields, setOrderFields] = useState({});
  const [searchModalActive, setSearchModalActive] = useState(true);
  const [OrderFieldSelections, setOrderFieldSelections] = useState({});
  const [searchInProgress, setSearchInProgress] = useState(false);
  const { reactFunction, expressFunction } = useFirebaseContext();
  const searchByCharsFields = expressFunction("searchByCharsFields");
  const searchOrderByChars = expressFunction("searchOrderByChars");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (Object.keys(OrderFields).length === 0) {
      searchByCharsFields().then((data) => {
        setOrderFields(data);
        const OFS = {};
        Object.keys(data).forEach((key) => {
          OFS[data[key]["charID"]] = "";
        });
        setOrderFieldSelections(OFS);
      });
    }
  }, [OrderFields]);

  const searchOrderByCharsHandler = () => {
    setSearchInProgress(true);
    searchOrderByChars(OrderFieldSelections).then((data) => {
      setSearchResults(data);
      setSearchModalActive(false);
    });
  };

  if (Object.keys(OrderFields).length === 0) {
    return <div>Loading...</div>;
  }

  const SearchModal = ({ showModal = false }) => {
    return (
      <Modal
        title="Search by Characteristic"
        open={showModal}
        onClose={() => setSearchModalActive(false)}
      >
        <Form>
          {Object.keys(OrderFields).map((key) => {
            // console.log("OrderFields[key]", );
            const FieldName = OrderFields[key].charName;
            //   console.log("FieldName", OrderFields[key]);
            const CharFieldID = OrderFields[key].charID;
            //   console.log(FieldName);
            const rows = OrderFields[key].charValues.map((value) => {
              return {
                label: value,
                value: value,
              };
            });
            rows.unshift({
              label: ``,
              value: "",
            });

            return (
              <>
                <Select
                  options={rows}
                  label={FieldName}
                  labelInline={true}
                  onChange={(value) => {
                    const OFS = { ...OrderFieldSelections };
                    OFS[OrderFields[key].charID] = value;
                    setOrderFieldSelections(OFS);
                  }}
                  value={OrderFieldSelections[OrderFields[key].charID]}
                />
              </>
            );
          })}
          {/* <Form.Group>
          {Object.keys(OrderFields).map((key) => {
            // return <Select label={key} options={OrderFields[key]} />;
          })}
        </Form.Group> */}
          <Button onClick={() => setSearchModalActive(false)}>Close</Button>
          <Button primary onClick={searchOrderByCharsHandler}>
            Search
          </Button>
        </Form>
      </Modal>
    );
  };

  let count = 0;
  const rows = searchResults.map((row) => {
    console.log("row", row);
    count += parseFloat(row.coitem_qtyord);
    return [
      <Link url={`/order/${row.cohead_number}`}>{row.cohead_number}</Link>,
      row.cohead_custponumber,
      moment(row.cohead_orderdate).format("MM/DD/YYYY"),
      parseFloat(row.coitem_qtyord),
    ];
  });

  return (
    <Page
      title="Find Orders by Characteristics"
      primaryAction={{
        content: "Search",
        onAction: () => setSearchModalActive(true),
      }}
    >
      <SearchModal showModal={searchModalActive} />
      <Card sectioned>
        {rows.length !== 0 && (
          <DataTable
            columnContentTypes={["text", "text", "date", "numeric"]}
            headings={["Order Number", "Purchase Order", "Order Date", "Count"]}
            rows={rows}
            totals={["", "", "", count]}
            showTotalsInFooter={true}
          />
        )}
      </Card>
    </Page>
  );
};

export default FindOrderByChars;
