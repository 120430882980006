/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Badge } from "@shopify/polaris";
import { WholesaleMajor } from "@shopify/polaris-icons";

const BomLineItemBadge = (props) => {
  const { taskStatus } = props;
  let progress = "incomplete",
    status = "attention",
    label = "Not Pulled",
    icon = null;
  if (taskStatus === "awaiting_stock")
    (progress = "partiallyComplete"),
      (status = "info"),
      (label = "Awaiting Stock"),
      (icon = WholesaleMajor);
  if (taskStatus === "partially_pulled")
    (progress = "partiallyComplete"),
      (status = "warning"),
      (label = "Partially Pulled");
  if (taskStatus === "complete")
    (progress = "complete"), (status = "success"), (label = "Complete");

  return (
    <div
      css={css`
        .Polaris-Icon {
          display: flex;
          align-items: center;
          width: auto;
          height: auto;
          padding-left: 3px;
        }
        svg {
          width: 8px;
          height: 8px;
          path {
            fill: var(--p-icon-${status});
          }
        }
      `}
    >
      <Badge progress={progress} status={status} icon={icon}>
        {label}
      </Badge>
    </div>
  );
};

export default BomLineItemBadge;
