import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { FirebaseContext } from "./Firebase";

// import {signOut, authHelper} from "../Components/Firebase";

function Header() {
  const { user, logOut, envName, permissions } = useContext(FirebaseContext);

  return (
    <>
      {envName.includes("dev1") && (
        <div style={{ background: "red", textAlign: "center" }}>
          <b>!!!!! - This is a test ENV - !!!!!</b>
        </div>
      )}
      <Navbar bg="dark" variant="dark" style={{ padding: "10px" }}>
        <Navbar.Brand as={Link} to="/">
          Bock Production
        </Navbar.Brand>
        {user && (
          <Nav className="mr-auto">
            <div></div>
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            {(true || permissions.orders) && (
              <Nav.Link as={Link} disabled={false} to="/orders">
                Orders
              </Nav.Link>
            )}
            {(true || permissions.orders) && (
              <Nav.Link as={Link} disabled={false} to="/items">
                ItemList
              </Nav.Link>
            )}
            {true && (
              <Nav.Link as={Link} disabled={false} to="/warehouse">
                Warehouse
              </Nav.Link>
            )}

            {/*{(true || permissions.orders) && <Nav.Link as={Link} disabled={false} to="/labels">Labels</Nav.Link>}*/}
            {/*{<Nav.Link as={Link} disabled={false} to="/bomLogic">BOM Logic</Nav.Link>}*/}
            {/*{<Nav.Link as={Link} disabled={false} to="/items">Itemsssddd</Nav.Link>}*/}

            {/*{permissions.tmtracker && <Nav.Link as={Link} disabled={true} to="/tm">TicketMaster</Nav.Link>}*/}
            {/*{permissions.tdcc && <Nav.Link as={Link} to="/tdc">Tickets.com</Nav.Link>}*/}
            {/*{permissions.ticketEvoBulkPurchase && <NavDropdown title={'TicketEvo'} className={'bg-dark'}>*/}
            {/*    <NavDropdown.Item as={Link} to="/ticketevo/bulkbuyer">Bulk Buyer</NavDropdown.Item>*/}
            {/*</NavDropdown>}*/}
            <NavDropdown title={"Account"} className={"bg-dark"}>
              <NavDropdown.Item as={Link} to="/settings">
                Settings
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => logOut().then(() => window.location.reload())}
              >
                LogOut
              </NavDropdown.Item>
            </NavDropdown>
            {permissions.admin && (
              <NavDropdown
                title={"Admin"}
                className={"bg-dark"}
                bg="dark"
                variant="dark"
              >
                <NavDropdown.Item as={Link} to="/admin/users">
                  Users
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/admin/health">
                  Health & Stats
                </NavDropdown.Item>
              </NavDropdown>
            )}
            <Navbar.Text>Commit: {process.env.REACT_APP_VERSION}</Navbar.Text>
            <Navbar.Text>
              Build Date: {process.env.REACT_APP_BUILD_DATE}
            </Navbar.Text>
          </Nav>
        )}
      </Navbar>
    </>
  );
}

export default Header;
