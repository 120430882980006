import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  connectAuthEmulator,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  createUserWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable,
} from "firebase/functions";
import { getAnalytics } from "firebase/analytics";

// import {signInWithEmailAndPassword} from "firebase/auth";
import { createContext, useEffect, useState, useContext } from "react";
import { useStatePersist } from "use-state-persist";

// import { deleteCookie, setCookie } from "../Helpers/cookies";
import axios from "axios";
import CryptoJS from "crypto-js";
// import PouchDB from 'pouchdb'
// import pouchdbUpsert from 'pouchdb-upsert'
// PouchDB.plugin(pouchdbUpsert);

// import {useHistory} from "react-router-dom";

// https://firebase.google.com/docs/web/setup#available-libraries

const REACT_APP_FIREBASEWEB = process.env.REACT_APP_FIREBASEWEB;
console.log("REACT_APP_FIREBASEWEB", REACT_APP_FIREBASEWEB);

const originhost = document.location.origin; // 'http://localhost:3000'

const firebaseConfigString = Buffer.from(REACT_APP_FIREBASEWEB, "base64");
console.log("firebaseConfigString", firebaseConfigString);

const firebaseConfig = JSON.parse(firebaseConfigString);
console.log(firebaseConfig, "firebaseConfig");
console.log(firebaseConfig);

const app = initializeApp(firebaseConfig);

const authHelper = getAuth(app);
const funcHelper = getFunctions(app);
const hostname = document.location.hostname;

if (
  [
    "http://127.0.0.1:3000",
    "http://10.1.1.103:3000",
    "http://localhost:3000",
  ].includes(originhost)
) {
  // set axios proxy
  // alert("axios proxy");
  // axios.defaults.baseURL = "http://localhost:7000";
  // pass along document.cookie
  // axios.defaults.headers.common["Authorization"] = document.cookie;
}

export const FirebaseContext = createContext();
export const useFirebaseContext = () => useContext(FirebaseContext);

const expressFunction = (functionName) => (data) => {
  console.log("data");
  // return funcHelper[functionName](
  return axios
    .post(`/expressFunction`, { functionName, data })
    .then((response) => response.data);
};

export const FirebaseProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [permissions, setPermissions] = useState({});
  const [cookieSet, setCookieSet] = useState(false);

  const setCookie = ({ accessToken }) => {
    if (cookieSet) return;
    setCookieSet(true);
    if (accessToken === undefined) return;
    return axios
      .post(`/mirrorCookie`, { accessToken })
      .then((response) => response.data);
  };

  useEffect(() => {
    if (user && Object.keys(permissions).length === 0) {
      // alert("eee");
      expressFunction("fetchUserObject")({}).then((response) => {
        // console.log(response);
        setPermissions(response.permissions);
      });
    }
  }, [user]);

  onAuthStateChanged(authHelper, (user) => {
    // const cookieName = "__session";
    // const accessToken = user && user.accessToken;
    // user ? setCookie(cookieName, accessToken, 1) : deleteCookie(cookieName);
    setCookie({ accessToken: user && user.accessToken });
    axios.defaults.headers.common["Authorization"] = user.accessToken;

    setUser(user);
  });

  return (
    <FirebaseContext.Provider
      value={{
        user,
        permissions,
        showSideMenu: useStatePersist(true),
        smallWindow: window.innerWidth > 600,
        functions: funcHelper,
        expressFunction: expressFunction,
        // PouchDB: new PouchDB('localCache'),
        getFunction: (functionName) => httpsCallable(funcHelper, functionName),
        reactFunctionOLD: ({ functionName, data }) =>
          httpsCallable(funcHelper, "reactFunction")({ functionName, data }),
        reactFunction: (functionName) => (data) =>
          httpsCallable(funcHelper, "reactFunction")({ functionName, data }),
        tempStorageState: useState({}), // This function needs to be cleared after used else will have weird side affects.
        autoLoginFunc: (token) =>
          signInWithCustomToken(authHelper, token).then((r) => {
            // console.log("yaya", r);
          }),
        signIn: (email, password) => {
          return signInWithEmailAndPassword(authHelper, email, password)
            .then(() => {
              window.location.replace("/");
            })
            .catch((error) => {
              alert(`Login Error: ${error.code} - ${error.message}`);
              console.log("Login Error", error);
              // const errorCode = error.code;
              // const errorMessage = error.message;
            });
        },
        logOut: () => signOut(authHelper),
        register: (email, password) =>
          createUserWithEmailAndPassword(authHelper, email, password),
        printers: {
          letterPrinter: useStatePersist("@letterPrinter", null),
          boxPrinter: useStatePersist("@boxPrinter", "71426910"),
          fixturePrinter: useStatePersist("@fixturePrinter", "71113609"),
        },
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

// const signIn = (email, password) => {
//     return signInWithEmailAndPassword(authHelper, email, password)
//         .then((userCredential) => {
//             // Signed in
//             // alert('ezra')
//             const user = userCredential.user;
//             // ...
//         })
//         .catch((error) => {
//             alert(error.code)
//             const errorCode = error.code;
//             const errorMessage = error.message;
//         });
// }

// const logOut = () => {
//     signOut(authHelper).then(() => {
//         // Sign-out successful.
//     }).catch((error) => {
//         // An error happened.
//     });
// }

// export  {app, authHelper, signIn, signOut}
