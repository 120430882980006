/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import {
  //  React,
  useState,
  useEffect,
  useContext,
} from "react";
import { Modal, FormLayout, Stack, TextField } from "@shopify/polaris";

// import {
//     ChevronDownMinor
// } from '@shopify/polaris-icons';
// import BomLineItemBadge from './BomLineItemBadge'
import { TodoListContext } from "../../../Context/TodoListContext";
import AutocompleteInput from "../../AutocompleteInput";
import TagInput from "../../TagInput";
import { useFirebaseContext } from "../../Firebase";
const AddNewTask = (props) => {
  const { expressFunction } = useFirebaseContext();
  const { newTaskModal, saveNewTask, closeNewTaskModal } =
    useContext(TodoListContext);

  const getItemsByTitle = expressFunction("getItemsByTitle");

  useEffect(() => {
    if (newTaskModal === null) return;
    setFormValue({
      title: "",
      qty_total: newTaskModal.coitem_qty * 1, // @TODO
      qty_per: 1, // @TODO
      qty_ord: newTaskModal.coitem_qty, // @TODO
      item: null,
      description: "",
      tags: [],
    });

    console.log(newTaskModal, "n");
  }, [newTaskModal]);

  const initFormValue = {
    title: "",
    qty_total: 1, // @TODO
    qty_per: 1, // @TODO
    qty_ord: newTaskModal?.coItemQty, // @TODO
    item: null,
    description: "",
    tags: [],
  };
  const [formValue, setFormValue] = useState(initFormValue);

  useEffect(() => {
    console.log(formValue.qty_per, "qty_total");
    setFormValue((prevValue) => ({
      ...prevValue,
      qty_total: formValue.qty_ord * formValue.qty_per,
    }));
  }, [formValue.qty_per]);

  const changeItem = (val) => {
    const { label } = val;

    let obj = JSON.parse(JSON.stringify(formValue));

    if (
      formValue.title === "" ||
      formValue.title === formValue.item?.label
      // formValue.item !== null &&
      // (formValue.title === formValue.item.label || formValue.title === "")
    ) {
      obj.title = label;
    }
    obj.item = val;

    console.log(formValue, "formValue");
    console.log(obj, "obj");

    setFormValue(obj);
  };

  useEffect(() => {
    console.log(formValue, "formValuenew");
  }, [formValue]);

  const handleClose = () => {
    closeNewTaskModal();

    setFormValue(initFormValue);
  };

  const searchCallback = async (title) => {
    const getData = await getItemsByTitle({ title: title });
    return getData;
  };

  return (
    <div>
      <Modal
        open={newTaskModal !== null}
        onClose={handleClose}
        title="Create new Task"
        primaryAction={{
          content: "Add Task",
          onAction: () => saveNewTask(formValue),
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleClose,
          },
        ]}
      >
        <Modal.Section>
          <FormLayout>
            <AutocompleteInput
              label="Item*"
              valueKey="item_id"
              valueLabel="item_number"
              placeholder="Search for an item"
              values={formValue.item ? [formValue.item] : []}
              searchCallback={searchCallback}
              callback={changeItem}
            />
            <TextField
              label="Task title*"
              value={formValue.title}
              onChange={(e) =>
                setFormValue((prevValue) => ({ ...prevValue, title: e }))
              }
            />
            <Stack wrap={false} distribution="fill">
              <TextField
                label="Qty total*"
                type="number"
                disabled
                value={formValue.qty_total}
                onChange={(e) =>
                  e > 0 &&
                  setFormValue((prevValue) => ({ ...prevValue, qty_total: e }))
                }
              />
              <TextField
                label="Qty ordered*"
                type="number"
                disabled
                value={formValue.qty_ord}
                onChange={(e) =>
                  e > 0 &&
                  setFormValue((prevValue) => ({ ...prevValue, qty_ord: e }))
                }
              />
              <TextField
                label="Quantity per*"
                type="number"
                value={formValue.qty_per}
                onChange={(e) =>
                  e > 0 &&
                  setFormValue((prevValue) => ({ ...prevValue, qty_per: e }))
                }
              />
            </Stack>

            <TextField
              label="Notes"
              value={formValue.description}
              onChange={(e) =>
                setFormValue((prevValue) => ({ ...prevValue, description: e }))
              }
              multiline={2}
            />
            <TagInput
              tagTypes={["task_tag"]}
              tags={[]}
              callback={(e) =>
                setFormValue((prevValue) => ({ ...prevValue, tags: e }))
              }
            />
          </FormLayout>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default AddNewTask;
