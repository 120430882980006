import {
  Button,
  Form,
  FloatingLabel,
  Card,
  Modal,
  Alert,
} from "react-bootstrap";
// import {Switch} from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import LabelPreview from "../Labels/LabelPreview";
import { useFirebaseContext } from "../Firebase";
import { Box, Lightbulb } from "react-bootstrap-icons";
// import _ from 'lodash'

const LabelCard = ({ label, order }) => {
  const [labelPrePrintModal, set_labelPrePrintModal] = useState(false);

  return (
    <>
      {labelPrePrintModal && (
        <LabelPreviewPrePrint
          label={label}
          order={order}
          set_labelPrePrintModal={set_labelPrePrintModal}
        />
      )}
      {!labelPrePrintModal && (
        <Card style={{ width: "18rem" }}>
          <Card.Body>
            <Card.Title>{label.partNumber}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              {label.fixtureLabel === true && <Lightbulb />}
              {label.boxLabel === true && <Box />}
            </Card.Subtitle>
            <Button onClick={() => set_labelPrePrintModal(!labelPrePrintModal)}>
              Open
            </Button>
            <Button disabled={true}>Edit</Button>
            <Button disabled={true}>Delete</Button>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

const LabelPreviewPrePrint = ({ label, order, set_labelPrePrintModal }) => {
  // console.log('e', rowLabels.filter(r=>r.labelType === 'fixture'))

  // const fixtureLabel = rowLabels.filter(r => r.labelType === 'fixture')
  // const boxLabel = rowLabels.filter(r => r.labelType === 'box')
  // const ulListed = rowLabels.filter(r => r.ulListed === true)
  // const lampType = rowLabels.find(r => r.lampType !== null)

  const [printBoxCount, set_printBoxCount] = useState(0);
  const [printFixtureCount, set_printFixtureCount] = useState(0);
  // console.log('lampType',lampType.lampType)

  const [pdfFixtureLabel, set_pdfFixtureLabel] = useState("");
  const [pdfBoxLabel, set_pdfBoxLabel] = useState("");
  const [currentlyPrinting, set_currentlyPrinting] = useState(false);
  // console.log()

  const { getFunction, printers } = useFirebaseContext();
  const printLabels = () => {
    set_currentlyPrinting(true);
    const printLabel = getFunction("printLabel");
    // console.log(printLabel)
    const boxPrintJob = {
      qty: printBoxCount,
      type: "box",
      pdf: pdfBoxLabel,
      printer: printers.boxPrinter[0],
    };
    const fixturePrintJob = {
      qty: printFixtureCount,
      type: "fixture",
      pdf: pdfFixtureLabel,
      printer: printers.fixturePrinter[0],
    };
    //
    printLabel([
      printBoxCount !== 0 && boxPrintJob,
      printFixtureCount !== 0 && fixturePrintJob,
    ]).then(() => {
      set_currentlyPrinting(false);
      // alert('eee')
    });
  };

  const LabelPreviewJSX = useMemo(
    () => (
      <LabelPreview
        orderNumber={order.cohead_number}
        order={order}
        partNumber={label.partNumber}
        company={label.company}
        maxWattage={label.maxWattage}
        qty={label.qty}
        lampType={label.lampType}
        notes={label.notes}
        needFixtureLabel={label.fixtureLabel}
        ratingLocation={label.ratingLocation}
        needBoxLabel={label.boxLabel}
        // lampType
        UL_Listed={label.ulListed}
        returnFixtureLabel={set_pdfFixtureLabel}
        returnBoxLabel={set_pdfBoxLabel}
      />
    ),
    []
  );

  return (
    <>
      <Modal.Dialog>
        <Modal.Header closeButton onClick={() => set_labelPrePrintModal(false)}>
          <Modal.Title>{label.partNumber}</Modal.Title>
        </Modal.Header>

        {LabelPreviewJSX}
        <Modal.Footer>
          <Form onSubmit={(e) => e.preventDefault()}>
            {label.fixtureLabel !== 0 && (
              <FloatingLabel label={"Fixture Labels"}>
                <Form.Control
                  disabled={currentlyPrinting}
                  type={"number"}
                  placeholder={"Fixture Labels"}
                  value={printFixtureCount}
                  onChange={(e) => set_printFixtureCount(e.target.value)}
                />
              </FloatingLabel>
            )}
            {label.boxLabel !== 0 && (
              <FloatingLabel label={"Box Labels"}>
                <Form.Control
                  disabled={currentlyPrinting}
                  type={"number"}
                  placeholder={"Box Labels"}
                  value={printBoxCount}
                  onChange={(e) => set_printBoxCount(e.target.value)}
                />
              </FloatingLabel>
            )}
            {currentlyPrinting && (
              <Alert transition={true} variant={"warning"}>
                Printing...
              </Alert>
            )}
            <Button
              disabled={
                currentlyPrinting || printBoxCount + printFixtureCount === 0
              }
              variant="primary"
              onClick={() => printLabels()}
            >
              Print
            </Button>
          </Form>
          <hr />
          <p>
            This will be using the {printers.fixturePrinter[0]} printer for
            fixture labels and the {printers.boxPrinter[0]} printer for box
            labels.
          </p>

          {/*<Button variant="secondary">Close</Button>*/}
        </Modal.Footer>
      </Modal.Dialog>
    </>
  );
};

const OrderPageLabels = ({ orderNumber, order }) => {
  const { reactFunction, expressFunction } = useFirebaseContext();
  const saveOrderLabel = expressFunction("saveOrderLabel");

  const [tabPage, setTabPage] = useState("listExisting");
  const [existingLabels, set_existingLabels] = useState([...order.label]);

  const ListExisting = () => {
    return (
      <div>
        <>
          <Button onClick={() => setTabPage("addNewLabel")}>
            Add New Label
          </Button>
          {/*<Button onClick={() => setTabPage('findExisting')}>Find Existing Label</Button>*/}
        </>
        <hr />
        {/*{console.log(existingLabels)}*/}
        {existingLabels &&
          existingLabels.map((row) => {
            return <LabelCard label={row} order={order} />;
          })}
      </div>
    );
  };

  const AddNewLabel = () => {
    console.log(order);
    const [company, setCompany] = useState(
      order.cohead_cust_id !== 11721 ? "bock" : "lsi"
    );
    const [lampType, setLampType] = useState("inc");
    const [ratingLocation, setRatingLocation] = useState("damp");
    const [UL_Listed, setUL_Listed] = useState(true);
    const [maxWattage, setMaxWattage] = useState(0);
    const [partNumber, setPartNumber] = useState("");
    const [fixtureType, setFixtureType] = useState("");
    const [notes, setNotes] = useState("");
    const [qty, setQty] = useState(0);
    const [needFixtureLabel, set_needFixtureLabel] = useState(true);
    const [needBoxLabel, set_needBoxLabel] = useState(true);
    const [blockSaving, set_blockSaving] = useState(true);

    useEffect(() => {
      let block = 0;
      if (!(needFixtureLabel || needBoxLabel)) {
        // block++
      }
      // console.log('maxWattage', )
      if (needFixtureLabel && maxWattage <= 0) {
        // console.log('wattage')
        // block++
      }
      if (partNumber.length !== 0) {
        // alert(partNumber.length)
        // block++
      }
      if (partNumber.length === 0) {
        block++;
      }
      if (block !== 0) {
        set_blockSaving(true);
      } else {
        set_blockSaving(false);
      }
    });

    const SaveLabel = () => {
      /*
            TODO: add a save status message and disable buttons
             */

      saveOrderLabel({
        orderNumber,
        company,
        lampType,
        ratingLocation,
        UL_Listed,
        maxWattage,
        partNumber,
        fixtureType,
        notes,
        qty,
        needFixtureLabel,
        needBoxLabel,
      }).then((results) => {
        console.log(results);
        set_existingLabels(results);
        setTabPage("listExisting");
        // console.log(results)
      });
    };

    return (
      <div>
        <Button onClick={() => setTabPage("listExisting")}>Go Back</Button>
        <p></p>
        <h1>New Label</h1>
        <Form>
          <Form.Group>
            <Form.Label>Labels needed.</Form.Label>
            <Form.Check
              type={"checkbox"}
              label={"Box Label"}
              onChange={() => set_needBoxLabel(!needBoxLabel)}
              checked={needBoxLabel}
            />
            <Form.Check
              type={"checkbox"}
              label={"Fixture Label"}
              onChange={() => set_needFixtureLabel(!needFixtureLabel)}
              checked={needFixtureLabel}
            />
          </Form.Group>
          <Form.Group>
            <FloatingLabel
              controlId="floatingInput"
              label="Company"
              className="mb-3"
            >
              <Form.Select
                onChange={(e) => setCompany(e.target.value)}
                value={company}
              >
                <option
                  selected={order.cohead_cust_id === 11721 ? true : false}
                  disabled={order.cohead_cust_id === 11721 ? true : false}
                  value="bock"
                >
                  Bock
                </option>
                <option
                  selected={order.cohead_cust_id === 11721 ? false : true}
                  disabled={order.cohead_cust_id === 11721 ? false : true}
                  value="lsi"
                >
                  LSI
                </option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          {
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Lamp Type"
                className="mb-3"
              >
                <Form.Select
                  disabled={!needFixtureLabel}
                  onChange={(e) => setLampType(e.target.value)}
                  value={lampType}
                >
                  <option selected value="inc">
                    Incandescent
                  </option>
                  <option value="led">LED</option>
                  <option disabled={order.cohead_cust_id === 11721} value="cfl">
                    CFL
                  </option>
                  <option disabled={order.cohead_cust_id === 11721} value="hid">
                    HID
                  </option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          }
          <Form.Group>
            <FloatingLabel
              controlId="floatingInput"
              label="Part Number"
              className="mb-3"
            >
              <Form.Control
                type={"text"}
                onChange={(e) => setPartNumber(e.target.value)}
                value={partNumber}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group>
            <FloatingLabel
              controlId="floatingInput"
              label="Rating Location"
              className="mb-3"
            >
              <Form.Select
                disabled={!needFixtureLabel}
                onChange={(e) => setRatingLocation(e.target.value)}
                value={ratingLocation}
              >
                <option selected value="damp">
                  Damp Location
                </option>
                <option value="wet">Wet Location</option>
                <option value="dry">Dry Location</option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          <Form.Group>
            <Form.Check
              disabled={!needFixtureLabel}
              type={"checkbox"}
              label={"UL Listed"}
              onChange={() => setUL_Listed(!UL_Listed)}
              checked={UL_Listed}
            />
          </Form.Group>
          <Form.Group>
            <FloatingLabel
              controlId="floatingInput"
              label="Max Wattage"
              className="mb-3"
            >
              <Form.Control
                disabled={!needFixtureLabel}
                type={"number"}
                onChange={(e) => setMaxWattage(e.target.value)}
                value={maxWattage}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group>
            <FloatingLabel
              label={"Fixture Type"}
              controlId="floatingInput"
              className="mb-3"
            >
              <Form.Control
                disabled={!needBoxLabel}
                value={fixtureType}
                onChange={(e) => setFixtureType(e.target.value)}
                type={"text"}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group>
            <FloatingLabel
              label={"Qty"}
              controlId="floatingInput"
              className="mb-3"
            >
              <Form.Control
                disabled={!needBoxLabel}
                value={qty}
                onChange={(e) => setQty(e.target.value)}
                type={"text"}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group>
            <FloatingLabel
              label={"Notes"}
              controlId="floatingInput"
              className="mb-3"
            >
              <Form.Control
                disabled={!needBoxLabel}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                type={"text"}
              />
            </FloatingLabel>
          </Form.Group>

          <hr />
          <LabelPreview
            orderNumber={orderNumber}
            order={order}
            company={company}
            lampType={lampType}
            ratingLocation={ratingLocation}
            UL_Listed={UL_Listed}
            maxWattage={maxWattage}
            partNumber={partNumber}
            qty={qty}
            fixtureType={fixtureType}
            notes={notes}
            needBoxLabel={needBoxLabel}
            needFixtureLabel={needFixtureLabel}
          />
          <Button disabled={blockSaving} onClick={() => SaveLabel()}>
            Save
          </Button>
        </Form>
      </div>
    );
  };

  return (
    <>
      {tabPage === "listExisting" && <ListExisting />}
      {tabPage === "addNewLabel" && <AddNewLabel />}
    </>
  );
};

export default OrderPageLabels;
