import React, { useCallback, useState } from "react";
import { Card, Tabs } from "@shopify/polaris";
import OrderPageLabels from "./OrderPageLabels";
import Comments from "./Comments";
import Photos from "./Photos";

const Sidebar = ({ order, setOrder }) => {
  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );

  const tabs = [
    {
      id: "comments",
      content: "Comments",
      component: <Comments order={order} setOrder={setOrder} />,
      panelID: "details-content-1",
    },
    {
      id: "labels",
      content: "Labels",
      component: (
        <OrderPageLabels order={order} orderNumber={order.cohead_number} />
      ),
      panelID: "details-content-1",
    },
    {
      id: "photos",
      content: "Photos",
      component: <Photos order={order} />,
      panelID: "details-content-1",
    },
  ];

  return (
    <Card>
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} />
      <Card.Section>{tabs[selected].component}</Card.Section>
    </Card>
  );
};

export default Sidebar;
