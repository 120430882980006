/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Text,
  Stack,
  Card,
  Page,
  Layout,
  Button,
  ButtonGroup,
  DataTable,
} from "@shopify/polaris";
import React, { useState, useEffect } from "react";
import { useFirebaseContext } from "../../Firebase";
import AutocompleteInput from "../../AutocompleteInput";
import { createEditableDataTableItems } from "../../utils/utils";
import { Link } from "react-router-dom";
import { SkeletonBodyText } from "@shopify/polaris";
import { PageFullHeader } from "../../Commons/Page";

const Simulator = (props) => {
  const { expressFunction } = useFirebaseContext();

  const getConfigurableChars = expressFunction("getConfigurableChars");
  const getCharacteristics = expressFunction("getCharacteristics");
  const triggerBomRun = expressFunction("triggerBomRun");

  // const initialState = {
  //   1: {
  //     type: 88,
  //     item: 501,
  //   },
  // };
  const [state, setState] = useState({});
  const [initState, setInitState] = useState({});
  const [result, setResult] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(async () => {
    const data = await getConfigurableChars();
    if (data) {
      const newObj = Object.assign({});
      data.map((i) => {
        newObj[i.char_id] = Object.assign({
          char_name: i.char_name,
          characteristic: null,
        });
      });
      setInitState(newObj);
      setState(newObj);
    }
  }, []);

  const updateVals = async () => {
    if (
      Object.keys(state).filter(
        (f) =>
          state[f].characteristic !== null && state[f].characteristic !== ""
      ).length
    ) {
      setLoading(true);

      const x = [
        {
          characteristics: Object.keys(state)
            .filter(
              (f) =>
                state[f].characteristic !== null &&
                state[f].characteristic !== ""
            )
            .map((f) =>
              Object.assign({
                charass_id: parseInt(state[f].characteristic.value),
                charass_value: state[f].characteristic.label,
                charass_char_id: parseInt(f),
              })
            ),
        },
      ];

      const resp = await triggerBomRun({
        trialRun: true,
        trialRunOrdersArr: x,
      });

      console.log(resp, "resp"); //EZRA
      setResult(resp);

      setLoading(false);
    }
  };
  useEffect(() => {
    updateVals();

    console.log(state, "state");
  }, [state]);

  useEffect(() => {
    if (result.length) {
      const x = createEditableDataTableItems({
        array: result.map((i) =>
          Object.assign({
            item_id: parseInt(i.item_id),
            item_number: (
              <Link target="_blank" to={`/item/${i.item_id}`}>
                {i.item_number}
              </Link>
            ),
            qty: parseInt(i.qty),
            notes: i.notes,
            type:
              i.type === "addDefaultItem"
                ? "Default item"
                : "Dynamically injected",
            id: i.rule_id,
          })
        ),
        editSlug: "/bom/rule/",
      });

      setRows(x);
    }
  }, [result]);

  const setCharacteristic = (v, i) => {
    setState((prevState) => ({
      ...prevState,
      [i]: { ...prevState[i], characteristic: v },
    }));
  };

  const clearState = () => {
    console.log(initState, "initState");
    setState({ ...initState });
    setResult([]);
  };

  const searchCharacteristic = async (v, i) => {
    const res = await getCharacteristics({ title: v, types: [i] });
    if (res) return res;
    else return [];
  };

  // result.map((item, k) => {
  //   const { item_id, item_number, action, notes, qty, rule_id, type } = item;
  //   return (
  //     <>
  //       {item_number} {item_id} {qty} {notes}
  //       {rule_id}
  //       {type}
  //     </>
  //   );
  // }

  return (
    <>
      <PageFullHeader
        title="BOM Simulator"
        subtitle="Test values against the BOM engine."
        primaryAction={{ content: "Re-run", onAction: () => updateVals() }}
        breadcrumbs={[{ content: "BOM", url: "/bom/rules" }]}
        secondaryActions={[
          {
            content: "Clear values",
            onAction: () => clearState(),
          },
        ]}
      >
        <Layout>
          <Layout.Section secondary>
            <Card title="C-CONFIG Values" sectioned>
              {Object.keys(state)
                .sort((a, b) =>
                  state[a].char_name.localeCompare(state[b].char_name)
                )
                .map((i, k) => {
                  const item = state[i];
                  return (
                    <>
                      <div
                        css={css`
                          margin-bottom: 1rem;
                        `}
                      >
                        <Text variant="headingSm" as="h6">
                          {item.char_name}
                        </Text>
                      </div>
                      <AutocompleteInput
                        label="Characteristic"
                        values={
                          state[i].characteristic
                            ? [state[i].characteristic]
                            : []
                        }
                        valueKey={"charass_id"}
                        valueLabel={"charass_value"}
                        placeholder={"Select a value"}
                        labelHidden
                        callback={(v) => setCharacteristic(v, i)}
                        searchCallback={(v) => searchCharacteristic(v, i)}
                        allowMultiple={false}
                      />

                      {k < Object.keys(state).length - 1 && (
                        <hr
                          css={css`
                            border: 0;
                            border-top: 1px solid rgba(0, 0, 0, 0.1);
                            margin: 1rem 0;
                          `}
                        />
                      )}
                    </>
                  );
                })}
            </Card>
          </Layout.Section>
          <div
            className="Polaris-Layout__Section Polaris-Layout__Section--primary"
            css={css`
              position: sticky;
              top: 10rem;
            `}
          >
            <Card sectioned title="Virtual BOM">
              {loading ? (
                <SkeletonBodyText />
              ) : (
                <>
                  {result.length ? (
                    <DataTable
                      columnContentTypes={[
                        "numeric",
                        "text",
                        "numeric",
                        "text",
                        "text",
                        "numeric",
                        "text",
                      ]}
                      headings={[
                        "Item ID",
                        "Item Name",
                        "Qty",
                        "Notes",
                        "Type",
                        "Rule ID",
                        "Edit Rule",
                      ]}
                      rows={rows}
                      hasZebraStripingOnData
                    />
                  ) : (
                    <>No results to show for the selected characteristics.</>
                  )}
                </>
              )}
            </Card>
          </div>
        </Layout>
      </PageFullHeader>
    </>
  );
};
export default Simulator;
