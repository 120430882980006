import {
  Button,
  Card,
  DataTable,
  Form,
  Modal,
  Page,
  Select,
  Spinner,
  TextField,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFirebaseContext } from "../../Firebase";
import CharacteristicsFields from "./CharacteristicsFields";

const ConfigurableItemPage = () => {
  const { itemID } = useParams();
  const { reactFunction } = useFirebaseContext();
  const loadConfigurableItem = reactFunction("loadConfigurableItem");

  const [xTupleItemDetails, set_xTupleItemDetails] = useState({});
  const [xTupleItemCharacteristicsInUse, set_xTupleItemCharacteristicsInUse] =
    useState([]);
  useEffect(() => {
    loadConfigurableItem({ itemID }).then((itemObject) => {
      // console.log("itemObject", );
      set_xTupleItemDetails(itemObject.data.xTupleItemDetails[0]);
      set_xTupleItemCharacteristicsInUse(
        itemObject.data.xTupleItemCharacteristicsInUse
      );
    });
  }, [itemID]);

  if (!xTupleItemDetails.item_id) {
    return <Spinner />;
  }

  // const Fields = ({ itemObject }) => {
  //   return <Card title={`Characteristics Fields`} sectioned></Card>;
  // };

  return (
    <Page
      title={`Configurable Item: ${xTupleItemDetails.item_number}`}
      subtitle={`${xTupleItemDetails.item_descrip1}${
        xTupleItemDetails.item_descrip2
          ? ` - ${xTupleItemDetails.item_descrip2}`
          : ""
      }`}
    >
      <Card title={`Item Details`} sectioned></Card>
      <CharacteristicsFields
        xTupleItemDetails={xTupleItemDetails}
        xTupleItemCharacteristicsInUse={xTupleItemCharacteristicsInUse}
      />
    </Page>
  );
};

export default ConfigurableItemPage;
