import { useState } from "react";
import {
  Button,
  Layout,
  Form,
  Card,
  TextContainer,
  TextStyle,
  Checkbox,
  Badge,
} from "@shopify/polaris";
import { useFirebaseContext } from "../Firebase";
import { DefaultEditor } from "react-simple-wysiwyg";
import "./OrderPage.css";
import moment from "moment";

const CommentLsting = ({ order }) => {
  const { comment } = order;

  return (
    <div>
      {comment.map((c, index) => {
        console.log(c);
        return (
          <TextContainer>
            <Card sectioned>
              <p dangerouslySetInnerHTML={{ __html: c.comment }} />

              <p
                // variation="subdued"
                style={{ "font-size": "x-small" }}
              >
                {c.user} /{" "}
                {moment(c.created["_seconds"], "X").format(
                  "MM-DD-yyyy hh:mm a"
                )}
              </p>
              {c.publicComment && <Badge status="attention">Public</Badge>}
            </Card>
          </TextContainer>
        );
      })}
    </div>
  );
};

const NewComment = ({ order, setOrder }) => {
  const { permissions } = useFirebaseContext();
  const [commentSaving, setCommentSaving] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [publicCommentBoolean, setPublicCommentBoolean] = useState(false);
  const { expressFunction } = useFirebaseContext();
  const saveCommentFunc = expressFunction("saveComment");

  const saveComment = () => {
    console.log(order);
    const orderNumber = order.cohead_number;
    setCommentSaving(true);
    return saveCommentFunc({
      order: orderNumber,
      comment: newComment,
      publicComment: publicCommentBoolean,
    }).then((data) => {
      setOrder(data.order);
      setNewComment("");
      setPublicCommentBoolean(false);
      setCommentSaving(false);
      return true;
    });
  };

  return (
    <Form>
      <DefaultEditor
        value={newComment}
        onChange={(event) => setNewComment(event.target.value)}
      />
      {permissions.publicOrderComments && (
        <div>
          <Checkbox
            label="Public Comment"
            checked={publicCommentBoolean}
            onChange={() => setPublicCommentBoolean(!publicCommentBoolean)}
          />
        </div>
      )}
      <Button
        loading={commentSaving}
        onClick={saveComment}
        disabled={newComment.length < 1}
      >
        Save Comment
      </Button>
    </Form>
  );
};

const Comments = ({ order, showOrder, setOrder }) => {
  const { reactFunction } = useFirebaseContext();

  const [commentSaving, setCommentSaving] = useState(false);

  //   console.log("order", );
  return (
    <div>
      <NewComment order={order} setOrder={setOrder} />
      <CommentLsting order={order} />
    </div>
  );
};

export default Comments;
