/** @jsxImportSource @emotion/react */
import { useFirebaseContext } from "./Firebase";
import { css } from "@emotion/react";
import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Stack,
  Tag,
  EmptySearchResult,
  Listbox,
  Combobox,
} from "@shopify/polaris";
import { useIsMount } from "./utils";

const TagInput = (props) => {
  const { tags, tagTypes, callback } = props;
  const isMount = useIsMount();
  const { reactFunction, expressFunction } = useFirebaseContext();
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);

  const [suggestion, setSuggestion] = useState("");
  const [selectLoading, setSelectLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const getTags = expressFunction("getTags");

  useEffect(async () => {
    if (!isMount && tags !== selectedTags) {
      const updateTags = await callback(selectedTags.map((i) => i.value));
    }
  }, [selectedTags]);

  useEffect(() => {
    if (isMount) {
      setSelectedTags(tags);
    }
  });
  useEffect(() => {
    setSelectLoading(true);
    if (value === "") {
      setOptions([]);
      setSelectLoading(false);
      return;
    }
    const getRes = fetchTagByTitle(value).then((res) => {
      const opts = res
        .filter((f) => !selectedTags.map((i) => i.value).includes(f.id))
        .map((item) =>
          Object.assign({ value: item.id, label: item.shortname })
        );
      setOptions(opts);
      setSelectLoading(false);
    });
  }, [value]);

  const updateSelection = useCallback(
    (selected) => {
      const nextSelectedTags = new Set([...selectedTags]);

      if (nextSelectedTags.has(selected)) {
        nextSelectedTags.delete(selected);
      } else {
        nextSelectedTags.add(selected);
      }
      setSelectedTags([...nextSelectedTags]);
      setValue("");
      setSuggestion("");
    },
    [selectedTags]
  );

  const removeTag = useCallback(
    (tag) => () => {
      updateSelection(tag);
    },
    [updateSelection]
  );

  const verticalContentMarkup =
    selectedTags.length > 0 ? (
      <Stack spacing="extraTight" alignment="center">
        {selectedTags.map((tag) => (
          <Tag key={`option-${tag}`} onRemove={removeTag(tag)}>
            {tag.label} ({tag.value})
          </Tag>
        ))}
      </Stack>
    ) : null;

  const optionMarkup =
    options.length > 0 ? (
      options.map((option) => {
        return (
          <Listbox.Option
            key={option.value}
            value={option}
            selected={selectedTags.includes(option)}
            accessibilityLabel={option}
          >
            <Listbox.TextOption selected={selectedTags.includes(option)}>
              {/* {formatOptionText(option)} */}
              {option.label} ({option.value})
            </Listbox.TextOption>
          </Listbox.Option>
        );
      })
    ) : value.length ? (
      selectLoading ? (
        <Listbox.Loading />
      ) : (
        <EmptySearchResult
          title="No results."
          description={`No tags found matching "${value}"`}
        />
      )
    ) : (
      <EmptySearchResult
        title="Start typing...."
        description={`Start typing to search for tags`}
      />
    );

  const fetchTagByTitle = async (title) => {
    let req = await getTags({ types: tagTypes, title: title });
    console.log("fetchTagByTitle", req);
    return req;
  };

  return (
    <>
      <Combobox
        allowMultiple={true}
        activator={
          <>
            <Combobox.TextField
              autoComplete="off"
              label="Search tags"
              labelHidden
              value={value}
              suggestion={suggestion}
              placeholder="Search tags"
              verticalContent={verticalContentMarkup}
              onChange={setValue}
            />
            {/* <Combobox.TextField
                prefix={<Icon source={SearchMinor} />}
                onChange={updateText}
                label="Search tags"
                labelHidden
                value={inputValue}
                placeholder="Search tags"
              /> */}
          </>
        }
      >
        <Listbox
          onSelect={updateSelection}
          // onActiveOptionChange={handleActiveOptionChange}
        >
          {optionMarkup}
        </Listbox>
      </Combobox>
    </>
  );
};

export default TagInput;
