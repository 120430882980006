import QrReader from "react-web-qr-reader";
import { useState } from "react";
import { Redirect } from "react-router-dom";
import { useFirebaseContext } from "./Firebase";
import { Spinner } from "react-bootstrap";

const QRScanner = () => {
  const { reactFunction, tempStorageState } = useFirebaseContext();
  const delay = 0;

  const previewStyle = {
    height: 240 / 2,
    width: 320 / 2,
  };

  // const [result, setResult] = useState('No result');
  const [redirectLoc, setRedirectLoc] = useState(null);

  const [loading, setLoading] = useState(false);

  const [scanProcessing, setScanProcessing] = useState(false);

  const handle_order = (qrData) =>
    setRedirectLoc(`order/${qrData.split("/")[1]}`);

  const handle_location = (qrData) => {
    if (scanProcessing === false) {
      setScanProcessing(true);
      const findWarehouseLocationByName = reactFunction(
        "findWarehouseLocationByName"
      );
      const locationName = qrData.split("/")[2];

      findWarehouseLocationByName({ locationName })
        .then((response) => {
          if (response.data.redirect) {
            tempStorageState[1](response.data); // this saves the location data in temp state to avoid needing another fetch.
            setRedirectLoc(response.data.redirect);
          }
          setScanProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          setScanProcessing(false);
        });
    }
  };

  const handleScan = (result) => {
    // console.log(result.data)
    if (result) {
      setLoading(true);
      const qrData = result.data;
      const qrFunc = qrData.split("/")[0];
      // alert(qrFunc);

      switch (qrFunc) {
        case "order":
          handle_order(qrData);
          break;
        case "location":
          handle_location(qrData);
          break;
      }

      // setResult(result.data);
    }
  };

  const handleError = (error) => {
    console.log(error);
  };

  return (
    <>
      {redirectLoc && <Redirect to={redirectLoc} />}
      {loading && (
        <div>
          Loading...
          <Spinner animation="border" variant="success" />
        </div>
      )}
      {!loading && (
        <QrReader
          delay={delay}
          style={previewStyle}
          onError={handleError}
          onScan={handleScan}
          showViewFinder={true}
        />
      )}
      {/*<p>{result}</p>*/}
      {/*<p>{result}</p>*/}
      {/*<p>{result}</p>*/}
      {/*<p>{result}</p>*/}
      {/*<p>{result}</p>*/}
    </>
  );
};

export default QRScanner;
