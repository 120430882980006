import { DataTable, Link } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { useFirebaseContext } from "../Firebase";

const ConfigurableItemsList = (props) => {
  const { reactFunction } = useFirebaseContext();
  const bom_configurableItemsList = reactFunction("bom_configurableItemsList");

  const [configurableItems, setConfigurableItems] = useState([]);

  useEffect(() => {
    bom_configurableItemsList().then((configurableItems) => {
      const { data } = configurableItems;
      console.log("configurableItems", data);
      const configurableItemsArray = data.map((row) => {
        return [
          <Link url={`bom_logic/item/${row.item_id}`}>{row.item_number}</Link>,
          row.item_descrip1,
          row.item_descrip2,
        ];
      });
      setConfigurableItems(configurableItemsArray);
    });
  }, []);
  const headings = ["Part Number", "Description 1", "Description 2"];

  return (
    <>
      {configurableItems.length > 0 && (
        <DataTable
          headings={headings}
          rows={configurableItems}
          columnContentTypes={["text", "text", "text"]}
        />
      )}
    </>
  );
};

export default ConfigurableItemsList;
