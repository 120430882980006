import { useFirebaseContext } from "./Firebase";
import { useState } from "react";
import { useStatePersist } from "use-state-persist";
import {
  Button,
  TextContainer,
  Heading,
  TextField,
  Card,
  Select,
  Form,
} from "@shopify/polaris";

const SelectPrinters = () => {
  const { printers } = useFirebaseContext();

  const [letterPrinter, setLetterPrinter] = printers.letterPrinter;
  const [boxPrinter, setBoxPrinter] = printers.boxPrinter;
  const [fixturePrinter, setFixturePrinter] = printers.fixturePrinter;

  return (
    <Card title="Select Printers" sectioned>
      <p>Savign is not required as the state is stored on device.</p>
      <br></br>
      <Form>
        <Select
          label="Letter Size"
          options={[
            { label: "Select", value: null },
            { label: "Assembly South: Letter Size", value: "printer1" },
            { label: "Assembly North: Letter Size", value: "printer2" },
            { label: "Office - Main Copier", value: "printer22" },
          ]}
          onChange={(e) => setLetterPrinter(e)}
          value={letterPrinter}
          helpText={`Current Printer: ${letterPrinter}. This is letter size.`}
        />
        <Select
          label="Box Label"
          options={[
            { label: "Select", value: null },
            { label: "Assembly South: Box Size", value: "71426910" },
            { label: "Assembly North: Box Size", value: "printer2" },
          ]}
          onChange={(e) => setBoxPrinter(e)}
          helpText={`Current Printer: ${boxPrinter}. This label is a 4x6 label.`}
          value={boxPrinter}
        />
        <Select
          label="Fixture Label"
          helpText={`Current Printer: ${fixturePrinter}. This label is a 3x1.5 label.`}
          options={[
            { label: "Select", value: null },
            { label: "Assembly South: Fixture Size", value: "71113609" },
            { label: "Assembly North: Fixture Size", value: "71779261" },
          ]}
          onChange={(e) => setFixturePrinter(e)}
          value={fixturePrinter}
        />
      </Form>
    </Card>
  );
};

const SelfUserSettings = () => {
  const Firebase = useFirebaseContext();

  const [email, setEmail] = useState(Firebase.user.email);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const updateUser_eMail = (event) => {
    event.preventDefault();
    Firebase.user.updateEmail(email).then(() => {
      alert(`eMail address updated to ${email}`);
    });
  };

  const updateUser_Password = (event) => {
    event.preventDefault();
    if (password !== passwordConfirm) {
      alert("Passwords dont match");
    } else if (password.length === 0) {
      alert("Password is empty.");
    } else if (password.length < 5) {
      alert("Password is weak");
    } else {
      Firebase.user.updatePassword(password).then(() => {
        alert("Password has been updated.");
      });
    }
  };

  return (
    <>
      <TextContainer>
        <Heading> User settings for: {Firebase.user.email}</Heading>
      </TextContainer>
      {Firebase.permissions.editOwnEmail && (
        <form>
          <TextField
            value={email}
            onChange={(e) => setEmail(e)}
            label="Email"
            type="email"
            required
            id="email"
            //   error={errorPassword}
          />
          <Button onClick={updateUser_eMail}>Change eMail</Button>
        </form>
      )}

      <p />
      {/*{Firebase.permissions.editOwnPassword && <form>*/}
      {true && (
        <form>
          <TextField
            value={password}
            onChange={(e) => setPassword(e)}
            label="Password"
            type="password"
            required
            id="password"
            //   error={errorPassword}
          />
          <TextField
            value={passwordConfirm}
            label="Confirm Password"
            type="password"
            required
            id="passwordConfirm"
            onChange={(e) => setPasswordConfirm(e)}
            //   error={errorPassword}
          />
          <br />
          <Button onClick={updateUser_Password}>Change Password</Button>
        </form>
      )}
      <hr />
      <SelectPrinters />
    </>
  );
};

export default SelfUserSettings;
