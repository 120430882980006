import { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useFirebaseContext } from "../Firebase";
import QrReader from "react-web-qr-reader";
import CameraField from "../CameraField";
import {
  Heading,
  List,
  TextField,
  TextContainer,
  DataTable,
} from "@shopify/polaris";
import ImageUpload from "./ImageUpload";

const QRCodeScannerForWharehouse = ({ findWarehouseLocationByName }) => {
  const delay = 0;

  const previewStyle = {
    height: 240 / 2,
    width: 320 / 2,
  };

  const [redirectLoc, setRedirectLoc] = useState(null);
  const [error, setError] = useState("");

  const [activeRequest, setActiveRequest] = useState(false);

  const handleScan = (result) => {
    if (result) {
      const qrData = result.data;
      const qrFunc = qrData.split("/")[0];

      switch (qrFunc) {
        case "location":
          const locationName = qrData.split("/")[2];
          // const redirectLocation = `warehouse/${qrData.split("/")[2]}`;
          // setRedirectLoc(redirectLocation);
          const allowedToFetch = !activeRequest;
          setActiveRequest(true);
          allowedToFetch === true &&
            findWarehouseLocationByName({
              locationName: locationName,
            }).then((data) => {
              if (data.data.error) {
                setError(data.data.error);
              } else {
                const redirectLocation = data.data.redirect;
                setRedirectLoc(redirectLocation);
              }
              // setActiveRequest(false);
            });
          setError(`Hey thats location ${locationName}`);
          break;
        default:
          setError("That is not a valid location in the warehouse...");
          break;
      }

      // setResult(result.data);
    }
  };

  const handleError = (error) => {
    console.log(error);
  };

  return (
    <>
      {redirectLoc && <Redirect to={redirectLoc} />}
      <QrReader
        delay={delay}
        style={previewStyle}
        onError={handleError}
        onScan={handleScan}
        showViewFinder={true}
      />
      <p>.</p>
      <p>.</p>
      <p>.</p>
      <p>.</p>
      <p>.</p>
      <p>.</p>
      <p>.</p>
      {error.length && <p>{error}</p>}
      {/* <p>{result}</p> */}
      {/* <p></p> */}
      {/*<p>{result}</p>*/}
      {/*<p>{result}</p>*/}
      {/*<p>{result}</p>*/}
      {/*<p>{result}</p>*/}
    </>
  );
};

const LocationList = () => {
  const { expressFunction } = useFirebaseContext();
  const locationList = expressFunction("locationList");
  // const findWarehouseLocationByName = expressFunction(
  //   "findWarehouseLocationByName"
  // );

  const [search, setSearch] = useState("");

  const [locations, setLocations] = useState([]);

  useEffect(() => {
    console.log("eee");
    locationList().then((data) => {
      setLocations(data);
    });
  }, []);

  return (
    <>
      <TextContainer>
        <Heading>Warehouse Locations</Heading>
      </TextContainer>

      {/* <CameraField onChange={() => {}} /> */}
      <hr />
      {/* <QRCodeScannerForWharehouse
        findWarehouseLocationByName={findWarehouseLocationByName}
      /> */}
      <hr />
      {locations.length === 0 && <>Loading.....</>}
      {locations.length !== 0 && (
        <>
          <TextField
            value={search}
            onChange={(e) => {
              const searchValue = e.toUpperCase();
              setSearch(searchValue);
            }}
            label="Filter"
            type="text"
            id="search"
            //   error={errorPassword}
          />

          <hr />
          <DataTable
            columnContentTypes={["text", "text"]}
            headings={["Location", "Description"]}
            rows={locations
              .filter((location) => {
                const { location_formatname } = location;
                return location_formatname.startsWith(search);
              })
              .sort((a, b) =>
                a.location_formatname.localeCompare(b.location_formatname)
              )
              .map((location) => {
                const { location_formatname, location_descrip } = location;

                return [
                  <Link to={`/warehouse/${location.id}`}>
                    {location_formatname}
                  </Link>,
                  location_descrip,
                ];
              })}
            // footerContent={`Showing ${state.items.length} items on this page`}
          />
          {/* <table>
            <thead>
              <tr>
                <th>Location</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {/* {JSON.stringify(locations)} 
              {locations
                .filter((location) => {
                  const { location_formatname } = location;
                  return location_formatname.startsWith(search);
                })
                .sort((a, b) =>
                  a.location_formatname.localeCompare(b.location_formatname)
                )
                .map((location) => {
                  const { location_formatname, location_descrip } = location;

                  return (
                    <tr key={location.id}>
                      <td>
                        <Link to={`/warehouse/${location.id}`}>
                          {location_formatname}
                        </Link>
                      </td>
                      <td>{location_descrip}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table> */}
        </>
      )}
    </>
  );
};

export default LocationList;
