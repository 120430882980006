import React, { useState, useCallback } from "react";
import {
  TextContainer,
  Button,
  Modal,
  Stack,
  ChoiceList,
} from "@shopify/polaris";

const ConfirmationDialog = (props) => {
  const {
    title,
    body,
    /* Templates will return a pre-set layout. Don't pass a template if you want to customize this. */
    deleteTemplate,
    open,
    setOpen,
    callback,
  } = props;

  // const [active, setActive] = useState(true);

  // const handleChange = useCallback(() => setActive(!active), [active]);

  let modalTitle = title || "Confirmation",
    modalBody = body || "Please confirm this action.",
    primaryAction = {
      content: "Confirm",
      onAction: callback,
    },
    secondaryActions = [
      {
        content: "Cancel",
        onAction: setOpen,
      },
    ];

  if (deleteTemplate) {
    modalTitle = "Are you sure?";
    modalBody =
      "Are you sure you want to delete this? This action cannot be undone.",
      primaryAction.content = "Delete"
      primaryAction.destructive = true
  }

  return (
    <div style={{ height: "500px" }}>
      <Modal
        open={open}
        onClose={setOpen}
        title={modalTitle}
        primaryAction={primaryAction}
        secondaryActions={secondaryActions}
      >
        <Modal.Section>
          <TextContainer>
            <p>{modalBody}</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </div>
  );
};
export default ConfirmationDialog;
