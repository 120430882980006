import React from "react";
import LogicBuilderProvider from "./context/LogicBuilderProvider";
// import LogicBuilder from "./LogicBuilder";
// import {
//   //  Query, Builder, 
//   Utils } from "react-awesome-query-builder";
import "react-awesome-query-builder/lib/css/styles.css";
import "react-awesome-query-builder/lib/css/compact_styles.css";
import "react-awesome-query-builder/lib/css/denormalize.css";
// import { actionDataPackage, entitySuggest } from "./DrupalHelper";
// import LogicBuilderContext from "./context/LogicBuilderContext";
import LogicNew from "./LogicNew";

// const {
//   jsonLogicFormat,
//   queryString,
//   mongodbFormat,
//   sqlFormat,
//   getTree,
//   checkTree,
//   loadTree,
//   uuid,
//   loadFromJsonLogic,
// } = Utils;

function LogicBuilderWrapper(props) {
  //<LogicBuilder {...props} />;


  return (
    <LogicBuilderProvider {...props}>
      <LogicNew {...props} />
    </LogicBuilderProvider>
  );
}

export default LogicBuilderWrapper;
