/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState, useCallback, useContext } from "react";
import { TodoListContext } from "../../../Context/TodoListContext";
import {
  Text,
  Icon,
  Stack,
  Modal,
  Box,
  Link,
  Collapsible,
  Thumbnail,
  IndexTable,
} from "@shopify/polaris";
import { InfoMinor, NoteMinor } from "@shopify/polaris-icons";
import BomLineItemBadge from "./BomLineItemBadge";
import BomLineItemTags from "./BomLineItemTags";
import BomLineItemAction from "./BomLineItemAction";
import BomLineItemStatus from "./BomLineItemStatus";
import BomLineItemNotes from "./BomLineItemNotes";
import BomLineItemLine from "./BomLineItemLine";
import BomLineItemTitle from "./BomLineItemTitle";
import { CopyToClipboard } from "react-copy-to-clipboard";
import BomTag from "../../Commons/Tag";
import ToastContext from "../../../Context/Toast/ToastContext";
import {
  Route,
  useRouteMatch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useEffect } from "react";
import { useIsMount } from "./utils";
const BomLineItem = (props) => {
  const match = useRouteMatch();
  const history = useHistory();

  const isMount = useIsMount();

  const { path, url } = match;
  // const [activeUrl, setActiveUrl ]= useState(url)

  useEffect(() => {}, [match]);

  const [open, setOpen] = useState(false);

  const { task } = props;
  const {
    name,
    qty_total,
    qty_per,
    qty_ord,
    qty_pulled,
    status,
    type,
    item_id,
    item_descrip1,
    item_number,
    thumbnail,
    notes,
    bom_note,
    tags,
    id,
    task_id,
    coitem_id,
  } = task;

  const [modal, setModal] = useState(null);
  const { setItemPulledQty } = useContext(TodoListContext);
  //   const [edit]
  const { pushNewToast } = useContext(ToastContext);
  const handleToggle = useCallback((task_id) => {
    if (parseInt(location.pathname.split("/").pop()) === parseInt(task_id))
      return history.push(`${url}/${coitem_id}`);
    return history.push(`${url}/${coitem_id}/${task_id}`);
  }, []);

  const itemPulled = async (quantityPulled) => {
    const response = await setItemPulledQty(id, task_id, quantityPulled);
    return response;
  };

  let itemTypeStr = `This item was injected dynamically.`;
  if (type === "addDefaultItem") itemTypeStr = `This is a default item.`;
  if (type === "manualEntry") itemTypeStr = `This item was added manually.`;

  const itemNote =
    notes && notes !== ""
      ? notes
      : bom_note && bom_note !== ""
      ? bom_note
      : null;

//   console.log(tags, "tags");
  const makePermalink = window.location.href + "/" + task_id;

  return (
    <>
      {/* <li className="Polaris-ResourceItem__ListItem"> */}
      <div
        css={css`
          padding: 1rem;
        `}
      >
        <Box paddingBlockEnd="4">
          {/* <BomLineItemBadge taskStatus={status} /> */}
          <BomLineItemTags tags={tags} id={id} task_id={task_id} />
        </Box>
        <div
          css={css`
            display: flex;
            width: 100%;
          `}
        >
          <div
            onClick={() => handleToggle(task_id)}
            css={css`
              cursor: pointer;
              flex-grow: 1;
              transition: 0.1s ease all;
              border-radius: 4px;
              // padding: .5rem 1rem;
              // margin: 0 1rem 0 0;
              &:hover {
                // background: rgba(0,0,0,.05);
              }
              > .Polaris-Stack__Item:last-child {
                flex: unset;
              }
            `}
          >
            <Stack vertical spacing={"tight"}>
              <Stack horizontal wrap={false} alignment="center">
                {true && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      if (thumbnail) setModal(thumbnail);
                    }}
                  >
                    <Thumbnail
                      source={thumbnail || NoteMinor}
                      size="medium"
                      alt="Black choker necklace"
                    />
                  </div>
                )}
                <Stack vertical spacing="tight" wrap={true}>
                  <BomLineItemTitle value={name} />
                  <Text color="subdued">{item_descrip1}</Text>
                </Stack>
              </Stack>
            </Stack>
          </div>
          {/* <Stack distribution="trailing" alignment={"center"}>
              <div
                css={css`
                  margin-left: auto;
                `}
              >
                {qty_pulled} pulled of {qty_total}
              </div>
              <BomLineItemAction
                qty_total={qty_total}
                qty_ord={qty_ord}
                qty_per={qty_per}
                qty_pulled={qty_pulled}
                setPulledQty={itemPulled}
              />
            </Stack> */}
        </div>
        <BomLineItemNotes id={id} task_id={task_id} value={itemNote} />

        <Route path={`${path}/:bomItemId/:taskId`}>
          <BomLineItemLine task={task} isMount={isMount} />
        </Route>
      </div>
      {/* </li> */}
      <Modal
        open={modal !== null}
        onClose={() => setModal(null)}
        title={`Item: ${item_number}`}
        // titleHidden
        limitHeight={false}
        small
      >
        <div
          css={css`
            border-radius: 0 0 var(--p-border-radius-2) var(--p-border-radius-2);
            overflow: hidden;
          `}
        >
          <img
            src={modal}
            css={css`
              display: block;
              width: 100%;
            `}
          />
        </div>
      </Modal>
    </>
  );
};

export default BomLineItem;
