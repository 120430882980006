/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useFirebaseContext } from "../../Firebase";
import React, { useEffect, useCallback, useState } from "react";
import {
  DataTable,
  TextField,
  Text,
  Popover,
  Button,
  Tooltip,
  OptionList,
  Page,
  Pagination,
  Card,
  Thumbnail,
} from "@shopify/polaris";
import { createEditableDataTableItems } from "../../utils/utils";
import { Link, useHistory } from "react-router-dom";
const ItemList = () => {
  const { reactFunction, expressFunction, smallWindow } = useFirebaseContext();
  const history = useHistory();
  const [items, setItems] = useState([]);
  // const [characteristicTypes, setCharacteristicTypes] = useState([]);
  const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  // const [options, setOptions] = useState([]);
  const [popoverActive, setPopoverActive] = useState(false);
  const [limit, setLimit] = useState(500);
  // const fetchCharacteristics = reactFunction("getCharacteristics");
  // const fetchCharacteristicTypes = reactFunction("getCharacteristicTypes");
  // const getConfigurableChars = reactFunction("getConfigurableChars");
  const getItemsByTitle = expressFunction("getItemsByTitle");

  // useEffect(async () => {
  //   // const chars = await getConfigurableChars();
  //   // const { data } = chars;

  //   // setCharacteristicTypes(
  //   //   data.map((i) => Object.assign({ value: i.char_id, label: i.char_name }))
  //   // );
  //   callFetch();
  // }, []);

  const [loading, setLoading] = useState(true);
  // const editSlug = "/item/";

  const callFetch = async () => {
    // console.log(options, "opts");
    const data = await getItemsByTitle({
      page: (pageNumber - 1) * limit,
      // types: options,
      fields: [
        "item_id",
        "item_number",
        "item_descrip1",
        "item_active",
        "item_created",
        "item_lastupdated",
        "file.url",
        // "item_type"
      ],
      title: search,
      limit: limit,
    });

    console.log("item data", data);
    if (data)
      setItems(
        createEditableDataTableItems({
          array: data.map(mapThroughItem),
          // editSlug: editSlug,
        })
      );
  };
  useEffect(() => {
    callFetch();
  }, [
    // options,
    search,
    pageNumber,
  ]);

  useEffect(() => {
    setPageNumber(1);
  }, [search]);

  const mapThroughItem = (v) => {
    const { item_id, item_number, item_descrip1, url, ...rest } = v;
    return Object.assign({
      id: item_id,

      item_number: url ? (
        <Tooltip
          hoverDelay={0}
          preferredPosition={"top"}
          hasUnderline={true}
          content={<Thumbnail source={url} />}
        >
          <div
            css={css`
              &&& {
                cursor: pointer;
              }
            `}
          >
            <Text>
              <Link to={`/item/${item_id}`}>{item_number}</Link>
            </Text>
          </div>
        </Tooltip>
      ) : (
        <Link to={`/item/${item_id}`}>{item_number}</Link>
      ),
      item_descrip1: item_descrip1,
      ...rest,
    });
  };

  // const togglePopoverActive = useCallback(
  //   () => setPopoverActive((popoverActive) => !popoverActive),
  //   []
  // );

  return (
    <Page
      breadcrumbs={[{ content: "Home", url: "/" }]}
      title="Items"
      subtitle="Search and edit items."
      compactTitle
      // secondaryActions={[
      //   {
      //     content: (
      //       <Popover
      //         active={popoverActive}
      //         activator={
      //           <Button onClick={togglePopoverActive} disclosure>
      //             Filter
      //           </Button>
      //         }
      //         onClose={togglePopoverActive}
      //       >
      //         <OptionList
      //           onChange={setOptions}
      //           options={[
      //             {label: "Active", value: "active"},
      //             {label: "Inactive", value: "inactive"},
      //           ]}
      //           allowMultiple
      //           selected={options}
      //         />
      //       </Popover>
      //     ),
      //   },
      // ]}
    >
      <Card>
        <DataTable
          columnContentTypes={[
            "text",
            "text",
            "boolean",
            "text",
            "text",
            "text",
          ]}
          headings={[
            // "ID",
            <TextField
              placeholder="Item Number"
              value={search}
              onChange={setSearch}
            />,
            "Item Description",
            "Active",
            "Created",
            "Last Updated",

            // "Edit",
          ]}
          rows={[
            ...items.map((item) => {
              const [, ...rest] = item;
              return [...rest];
            }),
          ]}
          footerContent={`Showing ${items.length} items on this page`}
        />
      </Card>
      <div style={{ margin: "20px 0 40px 5px" }}>
        <Pagination
          onPrevious={() => pageNumber > 1 && setPageNumber(pageNumber - 1)}
          onNext={() => setPageNumber(pageNumber + 1)}
          plain={false}
          hasPrevious={pageNumber > 1}
          hasNext={true}
        />
      </div>
    </Page>
  );
};

export default ItemList;

// import {
//   Heading,
//   List,
//   TextField,
//   TextContainer,
//   Page,
//   Card,
//   IndexTable,
//   TextStyle,
//   Tooltip,
//   Image,
// } from "@shopify/polaris";
// import { useEffect, useState } from "react";
// import { useStatePersist } from "use-state-persist";
// import { Link } from "react-router-dom";
// import { useFirebaseContext } from "../../Firebase";
// import styled from "styled-components";
// import { useTable, usePagination } from "react-table";

// const ItemList = () => {
//   const { reactFunction } = useFirebaseContext();
//   const getItemList = reactFunction("getItemList");
//   const [items, setItems] = useStatePersist("itemsDB", {
//     lastUpdated: 0,
//     items: [],
//   });

//   const [search, setSearch] = useState("");
//   const [searchResults, setSearchResults] = useState([...items.items]);

//   const handleSearch = (e) => {
//     const value = e.target.value;
//     setSearch(value);
//     if (value === "") {
//       setSearchResults([...items.items]);
//     } else {
//       const results = items.items.filter((item) =>
//         item.searchValue.toLowerCase().includes(value.toLowerCase())
//       );
//       setSearchResults(results);
//     }
//   };

//   useEffect(() => {
//     // if items db has not been updated in the last 5 minutes or if there are no items in the db then update the db
//     if (items.lastUpdated < Date.now() - 300000 || items.items.length === 0) {
//       getItemList()
//         .then((data) => {
//           console.log("data", data);
//           setItems({
//             lastUpdated: Date.now(),
//             items: [
//               ...data.data.map((item) => {
//                 return {
//                   ...item,
//                   searchValue:
//                     item.item_number +
//                     " " +
//                     item.item_descrip1 +
//                     " " +
//                     item.item_descrip2,
//                 };
//               }),
//             ],
//           });
//           setSearchResults([
//             ...data.data.map((item) => {
//               return {
//                 ...item,
//                 searchValue:
//                   item.item_number +
//                   " " +
//                   item.item_descrip1 +
//                   " " +
//                   item.item_descrip2,
//               };
//             }),
//           ]);
//         })
//         .catch((err) => {
//           console.log("err", err);
//         });
//     }
//   }, [items]);

//   const toolTipValue = (item_id) => {
//     const item = items.items.find((item) => item.item_id === item_id);
//     return (
//       <>
//         <Card>
//           {item.imageurl ? (
//             <Image
//               source={item.imageurl}
//               alt={item.item_number}
//               style={{ width: "100%" }}
//             />
//           ) : (
//             <></>
//           )}
//         </Card>
//       </>
//     );
//   };

//   return (
//     <>
//       <Page
//         title="Items"
//         secondaryActions={[
//           {
//             content: "New Item",
//             url: "/item/new",
//           },
//         ]}
//       >
//         <Card>
//           <input
//             type="text"
//             placeholder="Search"
//             onChange={handleSearch}
//             value={search}
//           />
//           <table>
//             <thead>
//               <tr>
//                 <th>Item Number</th>
//                 <th>Description</th>
//               </tr>
//             </thead>
//             <tbody>
//               {searchResults.map((item) => (
//                 <tr key={item.item_id}>
//                   <td id={item.item_id}>
//                     <Tooltip content={toolTipValue(item.item_id)}>
//                       <Link to={`/item/${item.item_id}`}>
//                         {/* <Image source={item.image} /> */}
//                         {item.item_number}
//                       </Link>
//                     </Tooltip>
//                   </td>
//                   <td>{item.item_descrip1}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </Card>
//       </Page>
//     </>
//   );
// };

// export default ItemList;
