/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { TodoListContext } from "../../../Context/TodoListContext";
import {
  Text,
  Icon,
  Stack,
  Modal,
  Box,
  Link,
  Collapsible,
  Thumbnail,
} from "@shopify/polaris";
import { InfoMinor, NoteMinor } from "@shopify/polaris-icons";
import BomLineItemBadge from "./BomLineItemBadge";
import BomLineItemTags from "./BomLineItemTags";
import BomLineItemAction from "./BomLineItemAction";
import BomLineItemStatus from "./BomLineItemStatus";
import BomLineItemNotes from "./BomLineItemNotes";
import BomLineItemTitle from "./BomLineItemTitle";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ToastContext from "../../../Context/Toast/ToastContext";
import { Route, useRouteMatch, useHistory } from "react-router-dom";
import { useIsMount } from "./utils";
const BomLineItemLine = (props) => {
  //   const isMount = useIsMount();
  const match = useRouteMatch();
  const history = useHistory();
  const { task, open, isMount } = props;
  const {
    name,
    qty_total,
    qty_pulled,
    status,
    type,
    item_id,
    item_descrip1,
    item_number,
    thumbnail,
    notes,
    bom_note,
    tags,
    id,
    task_id,
    coitem_id,
  } = task;

  // const [modal, setModal] = useState(null)
  // const { setItemPulledQty } = useContext(TodoListContext)
  const { pushNewToast } = useContext(ToastContext);
  // const handleToggle = useCallback(() => setOpen((open) => !open), []);

  // const itemPulled = async (quantityPulled) => {
  //     const response = await setItemPulledQty(id, task_id, quantityPulled)
  //     return response
  // }

  let itemTypeStr = `This item was injected dynamically.`;
  if (type === "addDefaultItem") itemTypeStr = `This is a default item.`;
  if (type === "manualEntry") itemTypeStr = `This item was added manually.`;

  // const itemNote = notes && notes !== "" ? notes : bom_note && bom_note !== "" ? bom_note : null;

  const makePermalink = window.location.href + "/" + task_id;

  const [isLoadedFromUrl, setIsLoadedFromUrl] = useState(false);

  const myRef = useRef(null);

  useEffect(() => {
    if (
      isMount &&
      isLoadedFromUrl === false &&
      "taskId" in match.params &&
      parseInt(match.params.taskId) === parseInt(task_id)
    ) {
      const yOffset = -130;
      const element = myRef.current;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
      setIsLoadedFromUrl(true);
    }
  }, []);

  return (
    <div ref={myRef}>
      <Collapsible
        open={parseInt(task_id) === parseInt(match.params.taskId)}
        id="basic-collapsible"
        transition={{ duration: "100ms", timingFunction: "ease-in-out" }}
        expandOnPrint
      >
        <div
          css={css`
            padding: 1rem 0;
          `}
        >
          <Stack>
            {/* <BomLineItemStatus
              taskStatus={status}
              qty_pulled={qty_pulled}
              qty_total={qty_total}
              id={id}
              task_id={task_id}
            /> */}
            {/* <BomLineItemTags tags={tags} id={id} task_id={task_id} /> */}
          </Stack>
          <Box paddingBlockStart="4">
            <Text color="subdued">{itemTypeStr}</Text>
          </Box>
          {true && (
            <Box paddingBlockStart="4">
              <Stack>
                <CopyToClipboard
                  text={makePermalink}
                  onCopy={() =>
                    pushNewToast({ message: "Copied to clipboard." })
                  }
                >
                  <Link>Permalink</Link>
                </CopyToClipboard>
                <Link onClick={() => history.push(`/item/${item_id}`)}>
                  Edit Item
                </Link>
              </Stack>
            </Box>
          )}
        </div>
      </Collapsible>
    </div>
  );
};

export default BomLineItemLine;
