import { TextContainer, Heading, Button, TextField } from "@shopify/polaris";
import { useContext, useState } from "react";
import { FirebaseContext } from "./Firebase";

const Login = () => {
  const { signIn } = useContext(FirebaseContext);

  const LoginFunc = (event) => {
    // console.log(event);
    event.preventDefault();
    signIn(email, password);
  };

  const [email, set_email] = useState("");
  const [password, set_password] = useState("");

  return (
    <div>
      <TextContainer>
        <Heading>Login for Bock Production</Heading>
      </TextContainer>
      <form className={'d-div p-2"'} onSubmit={LoginFunc}>
        <TextField
          value={email}
          onChange={(e) => set_email(e)}
          label="Email"
          type="email"
          required
          id="email"
          //   error={errorPassword}
        />
        <TextField
          value={password}
          onChange={(e) => set_password(e)}
          label="Password"
          type="password"
          required
          id="password"
          //   error={errorPassword}
        />

        <Button submit={true}>Login</Button>
      </form>
    </div>
  );
};

export default Login;
