import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useFirebaseContext } from "./Firebase";

const BarcodeRedirect = ({}) => {
  const currentURL = new URL(window.location.href);
  const { reactFunction, tempStorageState } = useFirebaseContext();
  const { loading, setLoading } = useState(false);
  const [redirectLoc, setRedirectLoc] = useState(null);

  const [barcodeValue, setBarcodeValue] = useState(
    currentURL.searchParams.get("code")
  );

  const handle_order = (qrData) => {
    setRedirectLoc(`order/${qrData.split("/")[1]}`);
  };

  const handle_location = (qrData) => {
    if (scanProcessing === false) {
      setScanProcessing(true);
      const findWarehouseLocationByName = reactFunction(
        "findWarehouseLocationByName"
      );
      const locationName = qrData.split("/")[2];

      findWarehouseLocationByName({ locationName })
        .then((response) => {
          if (response.data.redirect) {
            tempStorageState[1](response.data); // this saves the location data in temp state to avoid needing another fetch.
            setRedirectLoc(response.data.redirect);
          }
          setScanProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          setScanProcessing(false);
        });
    }
  };

  useEffect(() => {
    if (barcodeValue) {
      //   setLoading(true);
      const qrData = barcodeValue;
      const qrFunc = qrData.split("/")[0];

      switch (qrFunc) {
        case "order":
          handle_order(qrData);
          break;
        // case "location":
        //   handle_location(qrData);
        //   break;
      }
    }
  }, [barcodeValue]);

  return <> {redirectLoc && <Redirect to={redirectLoc} />}</>;
};
export default BarcodeRedirect;
