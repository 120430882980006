import React, { useState, useCallback } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Page from "./Components/Page";
import { FirebaseProvider, useFirebaseContext } from "./Components/Firebase";
// import "@shopify/polaris/build/esm/styles.css"; // was giving build errors, moved into index.html
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect,
} from "react-router-dom";
import { AppProvider, Frame, Navigation, Loading } from "@shopify/polaris";
import ToastProvider from "./Context/Toast/ToastProvider";
import {
  FavoriteMajor,
  HomeMinor,
  SearchMajor,
  OrdersMinor,
  Columns3Minor,
  ClipboardMinor,
  PlusMinor,
  ListMajor,
  HashtagMinor,
  WandMinor,
  InsertDynamicSourceMinor,
  DynamicSourceMinor,
  InviteMinor,
  // MarketingMinor,
  ChecklistAlternateMajor,
  SettingsMinor,
  CategoriesMajor,
  CustomersMinor,
  CurrencyConvertMinor,
  ProductsMinor,
  TroubleshootMajor,
  ShipmentMajor,
} from "@shopify/polaris-icons";
// import Header from "./Components/Header";
import Body from "./Components/Body";
import TopBarComponent from "./Components/TopBar";
import Login from "./Components/Login";
import { getCookie } from "./Helpers/cookies";
import AutoLogin from "./Components/AutoLogin";
import BarcodeRedirect from "./Components/BarcodeRedirect";
import moment from "moment";
const theme = {
  // colors: {
  //   surface: '#111213',
  //   onSurface: '#111213',
  //   interactive: '#2e72d2',
  //   secondary: '#111213',
  //   primary: '#41444e',
  //   critical: '#d82c0d',
  //   warning: '#ffc453',
  //   highlight: '#5bcdda',
  //   success: '#008060',
  //   decorative: '#ffc96b',
  // },
  logo: {
    width: 180,
    topBarSource: "/BockLighting_Flat_Blue.png",
    url: "/",
    accessibilityLabel: "Bock Production",
  },
};

function BypassApp() {
  const state = useFirebaseContext();

  const { permissions, showSideMenu } = state;
  // console.log("permissions", permissions, permissions.positivePay);
  // console.log(state.user);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );

  const openSubSections = (parentPrefix) => {
    const currentURL = window.location.pathname;
    return currentURL.startsWith(parentPrefix);
  };

  return (
    <Router>
      <Switch>
        <Route exact path={"/barcodeRedirect"} component={BarcodeRedirect} />
        <AppProvider
          theme={theme}
          i18n={{
            Polaris: {
              Avatar: {
                label: "Avatar",
                labelWithInitials: "Avatar with initials {initials}",
              },
              Frame: {
                skipToContent: "Skip to content",
                navigationLabel: "Navigation",
                Navigation: {
                  closeMobileNavigationLabel: "Close navigation",
                },
              },
              TopBar: {
                toggleMenuLabel: "Toggle menu",
                SearchField: {
                  clearButtonLabel: "Clear",
                  search: "Search",
                },
              },
            },
          }}
        >
          {getCookie("__session") && state?.user === null && (
            <div className="loading">
              <div className="loader"></div>
              <div style={{ height: "100px" }}>
                <Frame>
                  <Loading />
                </Frame>
              </div>
            </div>
          )}{" "}
          {state.user !== null && !state.user.isAnonymous && (
            <Frame
              logo={theme.logo}
              // topBar={topBarMarkup}
              navigation={
                !showSideMenu[0] && (
                  <Navigation location="/">
                    <Navigation.Section
                      items={[
                        {
                          url: "/.",
                          label: "Home",
                          icon: FavoriteMajor,
                        },
                        {
                          url: "/orders",
                          label: "Orders",
                          icon: OrdersMinor,
                          selected: openSubSections("/orders"),
                          subNavigationItems: [
                            {
                              url: "/orders/FindOrderByChars",
                              label: "Find Orders by Chars",
                              icon: SearchMajor,
                            },
                          ],
                        },
                        {
                          url: "/items",
                          label: "Items",
                          icon: ProductsMinor,
                        },
                        {
                          url: "/characteristics",
                          label: "Characteristics",
                          icon: CategoriesMajor,
                          selected: openSubSections("/characteristics"),
                        },
                        {
                          url: "/warehouse",
                          label: "Warehouse",
                          icon: HomeMinor,
                        },
                        {
                          url: "/bom/rules",
                          label: "BOM Config",
                          icon: ClipboardMinor,
                          selected: openSubSections("/bom"),
                          subNavigationItems: [
                            {
                              url: "/bom/rules",
                              label: "Rules",
                              icon: DynamicSourceMinor,
                            },
                            {
                              url: "/bom/rule/new",
                              label: "Add Rule",
                              icon: InsertDynamicSourceMinor,
                            },
                            {
                              url: "/bom/simulator",
                              label: "Simulator",
                              icon: WandMinor,
                            },
                          ],
                        },

                        {
                          url: "/tags",
                          label: "Tags",
                          icon: HashtagMinor,
                          selected: openSubSections("/tags"),
                          subNavigationItems: [
                            {
                              url: "/tags/new",
                              label: "New Tag",
                              icon: PlusMinor,
                            },
                            {
                              url: "/tags/subscriptions",
                              label: "Subscriptions",
                              icon: InviteMinor,
                            },
                          ],
                        },

                        {
                          url: "/tasks",
                          label: "My Tasks",
                          icon: Columns3Minor,
                        },

                        {
                          url: "/bom_logic",
                          label: "BOM Logic",
                          icon: TroubleshootMajor,
                        },
                        permissions &&
                          permissions.positivePay !== undefined &&
                          permissions.positivePay === true && {
                            url: "/positive_pay",
                            label: "Positive Pay",
                            icon: CurrencyConvertMinor,
                          },
                        permissions &&
                          permissions.shipdesk !== undefined &&
                          permissions.shipdesk === true && {
                            url: "/shipdesk",
                            label: "ShipDesk",
                            icon: ShipmentMajor,
                          },
                        {
                          url: "#",
                          label: "Account",
                          icon: CustomersMinor,
                          selected: false,
                          subNavigationItems: [
                            {
                              url: "/settings",
                              label: "Settings",
                              icon: SettingsMinor,
                            },
                          ],
                        },
                      ]}
                    />
                    <div style={{ "text-align": "center" }}>
                      <p>
                        <b>Version: </b>
                        {process.env.REACT_APP_VERSION}
                        <br />
                        <b>Build Date: </b>
                        {moment(process.env.REACT_APP_BUILD_DATE).format(
                          "MM/DD/YYYY"
                        )}
                      </p>
                    </div>
                  </Navigation>
                )
              }
              // topBar={<Header />}
              topBar={
                <TopBarComponent
                  toggleMobileNavigationActive={toggleMobileNavigationActive}
                />
              }
              showMobileNavigation={mobileNavigationActive}
              onNavigationDismiss={toggleMobileNavigationActive}
              // skipToContentTarget={skipToContentRef.current}
            >
              <ToastProvider>
                <Body fullwidth>
                  <Page />
                </Body>
              </ToastProvider>
            </Frame>
          )}
          {!getCookie("__session") && state?.user === null && (
            <Frame>
              <Body fullwidth>
                {
                  <Switch>
                    <Route path="/" exact={true}>
                      <Login />
                    </Route>
                    <Route
                      path="/autoLogin"
                      exact={true}
                      component={AutoLogin}
                    />
                  </Switch>
                }
              </Body>
            </Frame>
          )}
        </AppProvider>
        {/* <Route path={"/autoLogin"} component={AutoLogin} /> */}
        {/* <Route path={"/login"} component={Login} /> */}
      </Switch>
    </Router>
  );
}
function App() {
  return (
    <FirebaseProvider>
      <BypassApp />
    </FirebaseProvider>
  );
}

export default App;
