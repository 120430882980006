import { Card, Page, DataTable, Link } from "@shopify/polaris";
import { useFirebaseContext } from "../Firebase";
import { useEffect, useState } from "react";
import moment from "moment";

const ShipdeskList = () => {
  const { expressFunction } = useFirebaseContext();
  const getShipmentList = expressFunction("getShipmentList");

  const [shipments, setShipments] = useState([]);

  useEffect(() => {
    getShipmentList({}).then((response) => {
      console.log(response);
      setShipments(response);
    });
  }, []);

  const convertDataToRows = (data) => {
    return data.map((shipment) => {
      return [
        <Link url={`/shipdesk/${shipment.shiphead_id}`}>
          {shipment.shiphead_number}
        </Link>,
        shipment.cohead_number,
        moment(shipment.shiphead_shipdate).format("MM/DD/YYYY"),
        shipment.cohead_custponumber,
        shipment.cohead_shiptoname,
        shipment.shiphead_shipvia,
        shipment.shiphead_tracknum,
        "$" + parseFloat(shipment.shiphead_freight).toFixed(2),
      ];
    });
  };

  return (
    <Page title="Shipdesk List" fullWidth={true}>
      {/* <Card title="Contents" sectioned></Card> */}
      <DataTable
        columnContentTypes={["text", "text", "text", "text", "text", "numeric"]}
        headings={[
          "Shipment Number",
          "Sales Order",
          "Ship Date",
          "Purchase Order",
          "Ship to",
          "Ship Via",
          "Tracking Number",
          "Freight",
        ]}
        rows={shipments ? convertDataToRows(shipments) : []}
      />
    </Page>
  );
};

export default ShipdeskList;
