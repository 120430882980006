import React, { useEffect, useState } from "react";
import merge from "lodash/merge";
import { BasicConfig } from "react-awesome-query-builder";
// import MaterialConfig from "react-awesome-query-builder/lib/config/material/index";
// import {
//   allEntities,
//   entitySuggest,
//   EntitySuggestions,
//   sessToken,
// } from "../DrupalHelper";
import Select from "react-select";
const InitialConfig = BasicConfig;
import charJson from "../../../../characteristics.json";
import charTypesJson from "../../../../characteristic_types.json";

//////////////////////////////////////////////////////////////////////

const generateFields = () => {
  // const getPartTypes = async () => {
  //   //   let subfieldarray = []
  //   //  await allEntities(token).then(async data=> {
  //   //     const { product_builder } = data
  //   //       let arr = Object.entries(product_builder.bundles).map(value=>{
  //   //         let body ={
  //   //           "entity": "product_builder",
  //   //           "bundle": [value[0]]
  //   //         }
  //   //         let pp = []
  //   //         getPackage(body).then(data =>{
  //   //           data.map(v=>{
  //   //             pp.push(v.title)
  //   //             return v.title
  //   //           })
  //   //         })
  //   //          let nSubfield ={
  //   //           Equals: {
  //   //           label: "==",
  //   //           type: "nType",
  //   //           operators:["select_equals"],
  //   //           fieldSettings: {
  //   //             listValues: pp,
  //   //             hideOperator:true
  //   //           },
  //   //           valueSources: ["value"],
  //   //           },
  //   //           Not_Equals: {
  //   //           label: "!=",
  //   //           type: "nType",
  //   //           operators:["select_not_equals",],
  //   //           fieldSettings: {
  //   //             listValues: pp,
  //   //             hideOperator:true
  //   //           },
  //   //           valueSources: ["value"],
  //   //           },
  //   //           isEmpty: {
  //   //           label:" Is Empty",
  //   //           type: "nType",
  //   //           operators:["is_empty"],
  //   //           fieldSettings: {
  //   //             hideOperator:true
  //   //           },
  //   //           valueSources: ["value"],
  //   //           },
  //   //           isNotEmpty: {
  //   //           label:" Is Not Empty",
  //   //           type: "nType",
  //   //           operators:["is_not_empty"],
  //   //           fieldSettings: {
  //   //             hideOperator:true
  //   //           },
  //   //           valueSources: ["value"],
  //   //           },
  //   //         }
  //   //         getSubFieldPart(value[0]).then(data=>{
  //   //           Object.entries(data).map(v=>{
  //   //            if(v[1].hasOwnProperty("allowed_values")){
  //   //              let list = v[1].allowed_values.map(v=> v.title)

  //   //             let obj= {
  //   //               [v[0]]:{
  //   //                     label: v[1].label,
  //   //                     type: "select",
  //   //                     operators:["greater","greater_or_equal","less","less_or_equal","select_equals",
  //   //                     "select_not_equals"],
  //   //                     fieldSettings: {
  //   //                       listValues: list,
  //   //                     },
  //   //                     valueSources: ["value"],
  //   //                 }
  //   //             }
  //   //             Object.assign(nSubfield ,{[v[0]]:obj[v[0]]})
  //   //             return {[v[0]]:obj[v[0]]}
  //   //            }
  //   //            if(v[1].type=== "entity_reference"){
  //   //             let list = v[1].entities.map(v=> v.machine_name)
  //   //             let body ={
  //   //               "entity": v[1].entity_type.machine_name,
  //   //               "bundle": list
  //   //             }
  //   //             let array= []
  //   //             entitySuggest(token,body).then(data=>{
  //   //               // console.log(data)
  //   //               data.map(v=>array.push(v.title))
  //   //             })
  //   //             // console.log(array)
  //   //             let obj= {
  //   //               [v[0]]:{
  //   //                     label: v[1].label,
  //   //                     type: "select",
  //   //                     operators:["greater","greater_or_equal","less","less_or_equal","select_equals",
  //   //                     "select_not_equals"],
  //   //                     fieldSettings: {
  //   //                       listValues: array,
  //   //                     },
  //   //                     valueSources: ["value"],
  //   //                 }
  //   //             }
  //   //             Object.assign(nSubfield ,{[v[0]]:obj[v[0]]})
  //   //             return {[v[0]]:obj[v[0]]}
  //   //            }
  //   //           })
  //   //          }
  //   //         )

  //   //       //  console.log(nSubfield)
  //   //         let a = {
  //   //         key:value[0],
  //   //         label: value[1],
  //   //         type: "!group",
  //   //         valueSources: ["value"],
  //   //         subfields: nSubfield,
  //   //         }
  //   //         // console.log(pp)

  //   //       return a
  //   //     })
  //   //     // console.log(arr)
  //   //     subfieldarray.push(...arr)
  //   //   })

  //   //   let subfieldobj =  convertArrayToObject(subfieldarray, 'key')
  //   //   return subfieldobj

  //   let partContionsSubfields = {
  //     equals: {
  //       label: "==",
  //       type: "nType",
  //       operators: ["select_equals"],
  //       fieldSettings: {
  //         listValues: [],
  //         hideOperator: true,
  //       },
  //       valueSources: ["value"],
  //     },
  //     not_equals: {
  //       label: "!=",
  //       type: "nType",
  //       operators: ["select_not_equals"],
  //       fieldSettings: {
  //         listValues: [],
  //         hideOperator: true,
  //       },
  //       valueSources: ["value"],
  //     },
  //     isEmpty: {
  //       label: " Is Empty",
  //       type: "nType",
  //       operators: ["is_empty"],
  //       fieldSettings: {
  //         hideOperator: true,
  //       },
  //       valueSources: ["value"],
  //     },
  //     isNotEmpty: {
  //       label: " Is Not Empty",
  //       type: "nType",
  //       operators: ["is_not_empty"],
  //       fieldSettings: {
  //         hideOperator: true,
  //       },
  //       valueSources: ["value"],
  //     },
  //   };

  //   let a = {
  //     fixture: {
  //       label: "Fixture",
  //       type: "!group",
  //       subfields: partContionsSubfields,
  //     },
  //     fixtureExterrior: {
  //       label: "Fixture Exterrior",
  //       type: "!group",
  //       subfields: partContionsSubfields,
  //     },
  //   };
  //   return a;
  // };
  // let listVal = [{ title: "All/Default", value: "default" }];
  // getPackage({
  //   entity: "product_builder_package",
  //   bundle: ["pb_package"],
  // }).then((data) => {
  //   // console.log(data)
  //   data.map((v) => {
  //     listVal.push(v);
  //     return v.title;
  //   });
  //   // listVal.push(...data)
  // });
  let subfieldObj = {};
  // getPartTypes().then((data) => {
  //   Object.assign(subfieldVal, data);
  // });

  // let listValues = [];

  // charJson
  //   .filter((f, k) => k < 170)
  //   .map((a, b) => {
  //     Object.assign(subfieldObj, {
  //       [a.charass_id]: {
  //         label: a.charass_value,
  //         type: "number",
  //         operators: ["equal"],
  //         valueSources: ["value"],
  //         preferWidgets: ["number"],
  //         fieldSettings: {
  //           min: 0,
  //           max: 1000,
  //           hideOperator: true,
  //         },
  //       },
  //     });
  //     // listValues.push({
  //     //   title: a.charass_value,
  //     //   value: a.charass_id,
  //     // });
  //   });

  // charTypesJson.map((item, k) => {
  //   // let singleListValues = [];
  //   // charJson
  //   //   .filter((f) => f.charass_char_id === item.char_id)
  //   //   .map((a, b) => {
  //   //     singleListValues.push({
  //   //       title: a.charass_value,
  //   //       value: a.charass_id,
  //   //     });
  //   //   });
  //   //
  //   //   console.log(singleListValues)
  //   //   console.log("singleListValues")
  //   confi[item.char_id] = {
  //     label: item.char_name,
  //     type: "select",
  //     valueSources: ["value"],
  //     operators: ["select_equals", "select_not_equals"],
  //     fieldSettings: { showSearch: true, listValues: singleListValues },
  //   };
  // });

  // console.log(subfieldVal)
  let confi = {
    // ...charJson.filter((f,k) => k < 100)
    bom: {
      label: "BOM",
      type: "!group",
      subfields: {
        in: {
          label: "Includes",
          type: "!group",
          subfields: subfieldObj,
        },
        notIn: {
          label: "Does not Include",
          type: "!group",
          subfields: subfieldObj,
        },
      },
    },
    // parts: {
    //   label: "Part",
    //   type: "!group",
    //   subfields: subfieldVal,
    // },
  };

  return confi;
};

const fields = generateFields();
// console.log(fields);
//////////////////////////////////////////////////////////////////////

const conjunctions = {
  AND: InitialConfig.conjunctions.AND,
  OR: InitialConfig.conjunctions.OR,
};

const operators = {
  ...InitialConfig.operators,
  // examples of  overriding
  between: {
    ...InitialConfig.operators.between,
    textSeparators: ["from", "to"],
  },
  in: {
    ...InitialConfig.operators.select_any_in,
    label: "Includes",
    // labelForFormat: "IN",
    // sqlOp: "IN",
    formatOp: function formatOp(
      field,
      op,
      values,
      valueSrc,
      valueType,
      opDef,
      operatorOptions,
      isForDisplay
    ) {
      // console.log(values);
      if (valueSrc == "value") return "".concat(field, " IN (");
      else return "".concat(field, " IN (");
    },
    // sqlFormatOp: function sqlFormatOp(field, op, values, valueSrc, valueType, opDef, operatorOptions) {
    //   return "".concat(field, " IN (").concat(values.join(", "), ")");
    // },
    // mongoFormatOp: mongoFormatOp1.bind(null, "$in", function (v) {
    //   return v;
    // }, false),
    // reversedOp: "select_not_any_in",
    // jsonLogic: "in",
    reversedOp: "notIn",
  },
  notIn: {
    ...InitialConfig.operators.select_not_any_in,
    label: "Does Not Include",
    formatOp: function formatOp(
      field,
      op,
      values,
      valueSrc,
      valueType,
      opDef,
      operatorOptions,
      isForDisplay
    ) {
      // console.log(values);
      if (valueSrc == "value") return "".concat(field, " IN (");
      else return "".concat(field, " IN (");
    },
    reversedOp: "in",
  },
  canIn: {
    ...InitialConfig.operators.select_any_in,
    label: "Can Includes",
    // labelForFormat: "IN",
    // sqlOp: "IN",
    formatOp: function formatOp(
      field,
      op,
      values,
      valueSrc,
      valueType,
      opDef,
      operatorOptions,
      isForDisplay
    ) {
      // console.log(values);
      if (valueSrc == "value") return "".concat(field, " IN (");
      else return "".concat(field, " IN (");
    },
    // sqlFormatOp: function sqlFormatOp(field, op, values, valueSrc, valueType, opDef, operatorOptions) {
    //   return "".concat(field, " IN (").concat(values.join(", "), ")");
    // },
    // mongoFormatOp: mongoFormatOp1.bind(null, "$in", function (v) {
    //   return v;
    // }, false),
    // reversedOp: "select_not_any_in",
    // jsonLogic: "in",
    reversedOp: "canNotIn",
  },
  canNotIn: {
    ...InitialConfig.operators.select_not_any_in,
    label: "Can Not Includes",
    formatOp: function formatOp(
      field,
      op,
      values,
      valueSrc,
      valueType,
      opDef,
      operatorOptions,
      isForDisplay
    ) {
      // console.log(values);
      if (valueSrc == "value") return "".concat(field, " IN (");
      else return "".concat(field, " IN (");
    },
    reversedOp: "canIn",
  },
};

const widgets = {
  ...InitialConfig.widgets,
  // examples of  overriding
  slider: {
    ...InitialConfig.widgets.slider,
    customProps: {
      width: "300px",
    },
  },
  number: {
    ...InitialConfig.widgets.number,
    customProps: {
      width: "200px",
    },
  },
  boolean: {
    ...InitialConfig.widgets.boolean,
    labelYes: "True",
    labelNo: "False",
  },
  rangeslider: {
    ...InitialConfig.widgets.rangeslider,
    customProps: {
      width: "300px",
    },
  },
  date: {
    ...InitialConfig.widgets.date,
    dateFormat: "DD.MM.YYYY",
    valueFormat: "YYYY-MM-DD",
  },
  time: {
    ...InitialConfig.widgets.time,
    timeFormat: "HH:mm",
    valueFormat: "HH:mm:ss",
  },
  select: {
    ...InitialConfig.widgets.select,
  },
  nType: {
    ...InitialConfig.widgets.select,
    factory: function factory(props) {
      return <Select {...props} />;
    },
  },
  qty: {
    ...InitialConfig.widgets.number,
    factory: function factory(props) {
      console.log(props);
      return <div {...props}>Sa HEllo</div>;
    },
  },
  datetime: {
    ...InitialConfig.widgets.datetime,
    timeFormat: "HH:mm",
    dateFormat: "DD.MM.YYYY",
    valueFormat: "YYYY-MM-DD HH:mm:ss",
  },
  treeselect: {
    ...InitialConfig.widgets.treeselect,
    customProps: {
      showSearch: true,
    },
  },
};
// console.log(widgets)

const types = {
  ...InitialConfig.types,
  // examples of  overriding
  // text: merge(InitialConfig.types.text, {
  //   widget: {
  //     text: {
  //       operators: [
  //         "greater",
  //         "greater_or_equal",
  //         "less",
  //         "less_or_equal",
  //         "equal",
  //         "not_equal",
  //         "is_empty",
  //         "is_not_empty",
  //       ],
  //     },
  //   },
  // }),
  select: merge(InitialConfig.types.select, {
    widgets: {
      select: {
        operators: [
          "select_equals",
          "select_not_equals",
          "less",
          "less_or_equal",
          "greater",
          "greater_or_equal",
          "is_empty",
          "is_not_empty",
          "in",
          "notIn",
          "canIn",
          "canNotIn",
        ],
      },
    },
  }),
  number: merge(InitialConfig.types.number, {
    widgets: {
      number: {
        operators: ["equal"],
        defaultOperator: "equal",
        widgetProps: {
          hideOperator: true,
          // showSearch: true,
        },
      },
    },
  }),
  multiselect: merge(InitialConfig.types.multiselect, {
    widgets: {
      multiselect: {
        operators: [
          "select_equals",
          "select_not_equals",
          "less",
          "less_or_equal",
          "greater",
          "greater_or_equal",
          "is_empty",
          "is_not_empty",
          "select_not_any_in",
          "select_any_in",
        ],
      },
    },
  }),
  nType: merge(InitialConfig.types.select, {
    widgets: {
      nType: {
        widgetProps: {
          hideOperator: true,
          showSearch: true,
        },
      },
    },
  }),
  qty: merge(InitialConfig.types.number, {
    widgets: {
      qty: {
        widgetProps: {
          hideOperator: true,
          showSearch: true,
        },
      },
    },
  }),
  boolean: merge(InitialConfig.types.boolean, {
    widgets: {
      boolean: {
        widgetProps: {
          hideOperator: true,
          operatorInlineLabel: "==",
        },
      },
    },
  }),
};

const localeSettings = {
  locale: {
    moment: "ru",
  },
  valueLabel: "Value",
  valuePlaceholder: "Value",
  fieldLabel: "Field",
  operatorLabel: "Operator",
  fieldPlaceholder: "Select item",
  operatorPlaceholder: "Select operator",
  deleteLabel: null,
  addGroupLabel: "Add group",
  addRuleLabel: "Add rule",
  delGroupLabel: null,
  notLabel: "Not",
  canLeaveEmptyGroup: false,
  shouldCreateEmptyGroup: false,
  valueSourcesPopupTitle: "Select value source",
  removeRuleConfirmOptions: {
    title: "Are you sure delete this rule?",
    okText: "Yes",
    okType: "danger",
  },
  removeGroupConfirmOptions: {
    title: "Are you sure delete this group?",
    okText: "Yes",
    okType: "danger",
  },
};

const settings = {
  ...InitialConfig.settings,
  ...localeSettings,

  valueSourcesInfo: {
    value: {
      label: "Value",
    },
    field: {
      label: "Field",
      widget: "field",
    },
    func: {
      label: "Function",
      widget: "func",
    },
  },
  // canReorder: false,
  // canRegroup: false,
  // showNot: false,
  // showLabels: true,
  maxNesting: 5,
  canLeaveEmptyGroup: false, //after deletion

  // renderField: (props) => <FieldCascader {...props} />,
  // renderOperator: (props) => <FieldDropdown {...props} />,
  // renderFunc: (props) => <FieldSelect {...props} />,
};

const funcs = {
  LOWER: {
    label: "Lowercase",
    mongoFunc: "$toLower",
    jsonLogic: "toLowerCase",
    jsonLogicIsMethod: true,
    returnType: "select",
    args: {
      str: {
        label: "String",
        type: "select",
        valueSources: ["value", "field"],
      },
    },
  },
};

const config = {
  conjunctions,
  operators,
  widgets,
  types,
  settings,
  fields,
  funcs,
};

export default config;
