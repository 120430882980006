import React, { useEffect, useState } from "react";
import { Button, Heading, TextContainer } from "@shopify/polaris";
import { useFirebaseContext } from "../../Firebase";
import { DefaultEditor } from "react-simple-wysiwyg";

const ItemNotes = ({ itemObj }) => {
  const { reactFunction } = useFirebaseContext();
  const updateItem = reactFunction("updateItem");
  const [saveState, setSaveState] = useState(false);
  const [itemUserNotes, setItemUserNotes] = useState(itemObj.itemUserNotes);

  useEffect(() => {
    // const autoSave = setTimeout(() => {}, 2000);
    if (saveState === true) {
      setSaveState(false);
      updateItem({
        item_id: itemObj.item_id,
        itemUserNotes: itemUserNotes,
      }).then(() => {
        // setSaveState(false);
      });
    }
  }, [saveState]);

  return (
    <div>
      <TextContainer>
        <Heading>Notes</Heading>
      </TextContainer>

      <DefaultEditor
        // style={{ backgroundColor: "lime" }}
        value={itemUserNotes}
        onChange={(e) => setItemUserNotes(e.target.value)}
      />

      <Button variant="primary" onClick={() => setSaveState(true)}>
        Save
      </Button>
    </div>
  );
};

export default ItemNotes;
