import { Alert, Button, Form, Image } from "react-bootstrap";
import Camera from "react-html5-camera-photo";
import { FACING_MODES } from "jslib-html5-camera-photo";
import { MediaCard } from "@shopify/polaris";

import "react-html5-camera-photo/build/css/index.css";
import { useEffect, useState } from "react";
import { useFirebaseContext } from "../Firebase";
import CameraField from "../CameraField";
// import axios from "axios";

const Photos = ({ order }) => {
  const photoArray = order.photo;
  const orderNumber = order.cohead_number;
  const [cameraType, setCameraType] = useState("phone");
  const [displayphotos, set_displayphotos] = useState([...photoArray]);

  // useEffect(() => {

  // }, [orderNumber, photoArray, displayphotos])

  const AddPhotosForm = () => {
    const FirebaseContext = useFirebaseContext();
    const { reactFunction, expressFunction } = useFirebaseContext();
    const uploadOrderPhoto = expressFunction("uploadOrderPhoto");
    const [photoInBASE64, setPhotoInBASE64] = useState("");

    const [uploadingStatus, setUploadingStatus] = useState(false);

    // alert(JSON.stringify(FACING_MODES.ENVIRONMENT))

    useEffect(() => {
      if (photoInBASE64.length !== 0) {
        // console.log("photoInBASE64", photoInBASE64);

        setUploadingStatus(true);
        // console.log(photos)
        uploadOrderPhoto({ orderNumber, photos: [photoInBASE64] }).then(
          (response) => {
            // console.log(response.data)
            set_displayphotos([...response]);
            setUploadingStatus(false);
            setPhotoInBASE64("");
          }
        );
      }
    }, [photoInBASE64]);

    function handleTakePhoto(dataUri) {
      handleUploading(dataUri);
    }

    const PhoneCamera = () => {
      return (
        <>
          <Button onClick={() => setCameraType("select")}>Go Back</Button>
          {!uploadingStatus ? (
            <Camera
              isImageMirror={false}
              onTakePhoto={(dataUri) => {
                console.log("dataUri", dataUri);
                handleTakePhoto(dataUri);
              }}
              idealFacingMode={FACING_MODES.ENVIRONMENT}
              imageType={"jpg"}
              // idealResolution={{width:6000, height:60001}}
              // isMaxResolution={true}
              // isFullscreen={true}
            />
          ) : (
            <div>
              <Alert variant={"warning"}>Uploading Photo</Alert>
            </div>
          )}
        </>
      );
    };

    const ComputerUploadHandler = (event) => {
      console.log(event.target.files);
    };

    const ComputerUpload = (
      <>
        <Form action="Files/Upload" enctype="multipart/form-data">
          <div>
            <h2>Upload images</h2>
          </div>
          <h3>Images</h3>
          <input
            type="file"
            accept={"image/png, image/gif, image/jpeg"}
            onChange={ComputerUploadHandler}
          />
        </Form>
      </>
    );

    const handleUploading = (photo) => {
      setUploadingStatus(true);
      // console.log(photos)
      uploadOrderPhoto({ orderNumber, photos: [photo] }).then((response) => {
        // console.log(response.data)
        set_displayphotos([...response.data]);
        setUploadingStatus(false);
      });
    };

    // console.log(photos)

    return (
      <>
        {cameraType === "select" && (
          <>
            <h1>Add Photos</h1>
            <Button onClick={() => setCameraType("phone")}>Camera</Button>
            {/*<div onClick={() => setCameraType('computer')}>Computer Upload</div>*/}
          </>
        )}
        {cameraType === "phone" && (
          <CameraField value={photoInBASE64} onChange={setPhotoInBASE64} />
        )}
        {cameraType === "computer" && ComputerUpload}
      </>
    );
  };

  return (
    <>
      <AddPhotosForm />
      <hr />
      <div
        style={
          {
            // // display: "flex",
            // width: "21px,
            // "flex-basis": "100%",
            // height: 0,
            // "flex-flow": "row nowrap",
            // "align-items": "center",
          }
        }
      >
        {displayphotos.map((photo) => {
          console.log(photo.user);
          return (
            <>
              <div>
                <Image
                  src={photo.photoPublicURL}
                  width={"100px"}
                  onClick={() => {
                    window.open(photo.photoPublicURL, "_blank");
                  }}
                />
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default Photos;
