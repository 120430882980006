import {Page, Text, View, Document, StyleSheet, Image, Svg, Line,} from "@react-pdf/renderer";
import {inchesTo} from './../LabelHelper'
// import logo from "./../ULLogos/Mark_E329969_EN.png";
import BockLogo from "./../CompanyLogos/20120313---bocklogo1.jpg";
import React from "react";
// import QRCode from 'qrcode'


const styles = StyleSheet.create({
    page: {
        // flexDirection: 'row',
        border: 0.5
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    bold: {
        // backgroundColor: '#E4E4E4',
        "font-weight": 900
    },
    notesfield: {
        width: `${4.5}in`,
        // height: `${.5}in`,
    },
    notesarea: {
        // backgroundColor: '#E4E4E4',
        fontSize: '0.225in',
        overflow:'visible'
    },
    bockLogo: {
        "transform": `rotate(90deg)`,
        position: 'absolute',
        width: `${3.75}in`,
        left: `${-1.4}in`,
        top: `${1.65}in`,
    },
    qrCode: {
        position: 'absolute',
        top: `0.2in`,
        left: `${4.75}in`,
        width:`${1}in`,
    },
    labelDetails: {
        position: 'absolute',
        top: 10,
        left: 80,
    },
    shipTo: {
        // position:'absolute',
        // top:10,
        // left:80,
        // fontSize:'0.25in',
    },
    shipToHeader: {
        // position:'absolute',
        // top:10,
        // left:80,
        fontSize: '0.35in',
        fontWeight: 900,
    },
    shipToName: {
        // top:20,
        // left:80,
        fontSize: '0.25in',
    },
    shipToAddress1: {},
    shipToAddress2: {},
    shipToCityStateZip: {}
});

const BockLogoComp = () => <Image style={styles.bockLogo} src={BockLogo}/>
const LogoBreakComp = () => <Svg style={{position: 'absolute', top: 0, left: 70}} height="286" width="2">
    <Line
        x1="1"
        y1="0"
        x2="1"
        y2="286"
        strokeWidth={1}
        stroke="rgb(0,0,0)"
    />
</Svg>

const OrderQRCode = ({qrCode}) => {

    return (
        <View>
            {false && qrCode.length !== 0 && <Image style={styles.qrCode} src={qrCode}/>}
            {/*<Text>We're here to help!</Text>*/}
        </View>
    )
}

const ShipTo = ({shipToName, shipToAddress1, shipToAddress2 = '', shipToCityStateZip = ''}) => < >
    <View style={styles.shipTo}>
        <Text style={styles.shipToHeader}>Ship To: </Text>
        <Text style={styles.shipToName}>{shipToName}</Text>
        <Text style={styles.shipToAddress1}>{shipToAddress1}</Text>
        {shipToAddress2.length !== 0 && <Text style={styles.shipToAddress2}>{shipToAddress2}</Text>}
        <Text style={styles.shipToCityStateZip}>{shipToCityStateZip}</Text>
    </View>
</>

const OrderDetails = ({orderNumber, purchaseOrder}) => <View style={styles.bold}>
    <Text style={styles.bold}>Order Number: {orderNumber}</Text>
    <Text style={styles.bold}>Purchase Order: {purchaseOrder}</Text>
</View>

const ItemDetails = ({partNumber, qty, type, notes}) => <>
    <View style={styles.bold}>
        <Text style={styles.bold}>Part Number: {partNumber}</Text>
        {/*{qty || type && <Text style={styles.bold}>{qty && `Qty: ${qty}`}{type && `Type: ${type}`}</Text>}*/}
        <Text>
            {type && <Text>Type: {type}</Text>}
            {type && qty !== 0 && <Text> / </Text>}
            {qty !==0 && <Text>Qty: {qty}</Text>}
        </Text>
        <Text style={styles.notesfield}><span style={styles.bold}>Notes: </span><span style={styles.notesarea}>{notes}</span></Text>
    </View>
</>

const HorizontalLine = () => <Svg height="10" width="700">
    <Line
        x1="0"
        y1="5"
        x2="700"
        y2="5"
        strokeWidth={1}
        stroke="rgb(0,0,0)"
    />
</Svg>

const BockBox = ({qrCode, labelDetails}) => {

    return (
        <Document>
            <Page size={[inchesTo(6), inchesTo(4)]} style={styles.page}>
                <BockLogoComp/>
                <LogoBreakComp/>
                <View style={styles.labelDetails}>
                    <ShipTo
                        shipToName={labelDetails.box.shipToName}
                        shipToAddress1={labelDetails.box.shipToAddress1}
                        shipToAddress2={labelDetails.box.shipToAddress2}
                        shipToCityStateZip={`${labelDetails.box.shipToCity} ${labelDetails.box.shipToState}, ${labelDetails.box.shipToZipCode}`}
                    />
                    <HorizontalLine/>
                    <OrderDetails
                        orderNumber={labelDetails.box.orderNumber}
                        purchaseOrder={labelDetails.box.purchaseOrder}
                    />
                    <HorizontalLine/>
                    <ItemDetails
                        partNumber={labelDetails.box.partNumber}
                        qty={labelDetails.box.qty}
                        type={labelDetails.box.type}
                        notes={labelDetails.box.notes}
                    />
                </View>
                <OrderQRCode qrCode={qrCode}/>
            </Page>
        </Document>
    )
}


export default BockBox