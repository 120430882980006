/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Listbox, Combobox, Icon } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import { useState, useEffect, useCallback, useMemo } from "react";
import metadata from "@shopify/polaris-icons/dist/metadata";
import * as polarisIcons from "@shopify/polaris-icons";
import { useIsMount } from "../../OrderPage/Todos/utils";

const IconSelect = (props) => {
  const isMount = useIsMount();
  const { updateValue, value } = props;
  const deselectedOptions = useMemo(
    () => [
      ...Object.keys(metadata).map((item, k) =>
        Object.assign({
          value: metadata[item].id,
          label: metadata[item].id,
        })
      ),
    ],
    []
  );

  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState(deselectedOptions);

  useEffect(() => {
    if (isMount && value) {
      updateText(value);
      setSelectedOption(value);
    }
  }, []);
  useEffect(() => {
    updateValue(selectedOption);
  }, [selectedOption]);

  const updateText = useCallback(
    (value) => {
      setInputValue(value);

      if (value === "") {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter(
        (option) =>
          //   console.log(option)
          option.label.toLowerCase().includes(value.toLowerCase())
        // option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const updateSelection = useCallback(
    (selected) => {
      const matchedOption = options.find((option) => {
        return option.value.match(selected);
      });

      setSelectedOption(selected);
      setInputValue((matchedOption && matchedOption.label) || "");
    },
    [options]
  );

  const optionsMarkup =
    options.length > 0
      ? options.map((option) => {
          const { label, value } = option;

          return (
            <Listbox.Option
              key={`${value}`}
              value={value}
              selected={selectedOption === value}
              accessibilityLabel={label}
            >
              <Icon source={polarisIcons[value]} />
              {label.replaceAll("Major", "").replaceAll("Minor", "")}
            </Listbox.Option>
          );
        })
      : null;

  const handleClearButtonClick = useCallback(() => {
    setInputValue("");
    setSelectedOption(null);
  }, []);

  return (
    <Combobox
      activator={
        <Combobox.TextField
          prefix={
            selectedOption ? (
              <Icon source={polarisIcons[selectedOption]} />
            ) : (
              <Icon source={SearchMinor} />
            )
          }
          onChange={updateText}
          label="Icon"
          clearButton
          onClearButtonClick={handleClearButtonClick}
          value={inputValue}
          placeholder="Search icons"
        />
      }
    >
      {options.length > 0 ? (
        <div
          css={css`
            &&& {
              .Polaris-Listbox {
                display: flex;
                flex-wrap: wrap;
                padding: 0 1%;
                max-height: 300px;
                li {
                  display: flex;
                  flex-direction: column;
                  font-size: 10px;
                  border-radius: 4px;
                  height: 60px;
                  padding: 0 0 0.3em;
                  width: 23%;
                  overflow: hidden;
                  justify-content: center;
                  align-items: center;
                  border: 1px solid rgba(0, 0, 0, 0.04);
                  cursor: pointer;
                  margin: 0 1% 2%;
                  background: rgba(0, 0, 0, 0.015);
                }
              }
            }
          `}
        >
          <Listbox onSelect={updateSelection}>{optionsMarkup}</Listbox>
        </div>
      ) : null}
    </Combobox>
  );
};

export default IconSelect;
