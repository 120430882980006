/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { TodoListContext } from "../../../Context/TodoListContext";
import { useIsMount } from "./utils";
import {
  TextField,
  Filters,
  Button,
  Card,
  ResourceList,
  Avatar,
  ResourceItem,
  IndexTable,
  useIndexResourceState,
  Text,
  ChoiceList,
  Badge,
  Stack,
  Collapsible,
  Tag,
  Autocomplete,
  Box,
} from "@shopify/polaris";
import BomLineItemBadge from "./BomLineItemBadge";
import BomLineItem from "./BomLineItem";
import BomLineItemTags from "./BomLineItemTags";
import { mapStatusToLabel } from "./utils";
import BomLineItemAction from "./BomLineItemAction";
const BomLine = (props) => {
  const { tasks: propTasks, coItemId, coItemQty, coItemCustPn } = props;
  const isMount = useIsMount();

  const [tasks, setTasks] = useState(propTasks);

  useEffect(() => {
    if (!isMount) setTasks(propTasks);
  }, [propTasks]);
  const {
    setCurrentTask,
    setOpen,
    taskTags,
    searchBomLine,
    addNewTask,
    setItemPulledQty,
  } = useContext(TodoListContext);

  const [selectedItems, setSelectedItems] = useState([]);
  const [sortValue, setSortValue] = useState("DATE_MODIFIED_DESC");
  const [taggedWith, setTaggedWith] = useState([]);
  const [taskStatus, setTaskStatus] = useState(null);

  const initFilters = {
    tags: [],
    status: [],
    sort: { updated: "desc" },
    title: "",
  };
  const [taskFilters, setTaskFilters] = useState(initFilters);

  const [queryValue, setQueryValue] = useState(null);

  const handleTaskStatusChange = useCallback(
    // (value) => setTaskStatus(value),
    (value) => setTaskFilters((prevState) => ({ ...prevState, status: value })),
    []
  );

  const handleTaggedWithChange = useCallback(
    (value) =>
      setTaskFilters((prevState) => ({
        ...prevState,
        tags: value,
      })),
    // (value) => setTaggedWith(value),
    // setTaggedWith((prevState) => [...prevState, value]),
    []
  );
  const handleQueryValueChange = useCallback(
    (value) => setTaskFilters((prevState) => ({ ...prevState, title: value })),
    []
  );
  const handleTaskStatusRemove = useCallback(
    // () => setTaskStatus(null),
    () => setTaskFilters((prevState) => ({ ...prevState, status: [] })),
    []
  );

  // const handleTaggedWithRemove = useCallback(() => setTaggedWith(null), []);
  const handleTaggedWithRemove = useCallback(
    () => setTaskFilters((prevState) => ({ ...prevState, tags: [] })),
    []
  );
  const handleQueryValueRemove = useCallback(
    () => setTaskFilters((prevState) => ({ ...prevState, title: "" })),
    []
  );

  const handleClearAll = useCallback(() => {
    handleTaskStatusRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [handleQueryValueRemove, handleTaggedWithRemove, handleTaskStatusRemove]);

  const resourceName = {
    singular: "customer",
    plural: "customers",
  };

  const promotedBulkActions = [
    {
      content: "Mark all as pulled",
      onAction: () => console.log("Todo: implement bulk edit"),
    },
  ];

  const bulkActions = [
    {
      content: "Change status",
      onAction: () => console.log("Todo: implement bulk add tags"),
    },
    {
      content: "Add tags",
      onAction: () => console.log("Todo: implement bulk delete"),
    },
    {
      content: "Remove tags",
      onAction: () => console.log("Todo: implement bulk remove tags"),
    },
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState(taskTags);

  const updateText = useCallback(
    (value) => {
      setInputValue(value);

      if (value === "") {
        setOptions(taskTags);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = taskTags.filter((option) =>
        option.label.match(filterRegex)
      );
      let endIndex = resultOptions.length - 1;
      if (resultOptions.length === 0) {
        endIndex = 0;
      }
      setOptions(resultOptions);
    },
    [taskTags]
  );

  const removeTag = useCallback(
    (tag) => () => {
      const options = [...selectedOptions];
      options.splice(options.indexOf(tag), 1);
      setSelectedOptions(options);
    },
    [selectedOptions]
  );

  const verticalContentMarkup =
    selectedOptions.length > 0 ? (
      <Stack spacing="extraTight" alignment="center">
        {selectedOptions.map((option) => {
          let tagLabel = "";
          tagLabel = option.replace("_", " ");
          tagLabel = titleCase(tagLabel);
          return (
            <Tag key={`option${option}`} onRemove={removeTag(option)}>
              {tagLabel}
            </Tag>
          );
        })}
      </Stack>
    ) : null;

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label="Tags"
      value={inputValue}
      placeholder="Select tags"
      verticalContent={verticalContentMarkup}
    />
  );

  const statusOptions = [
    { label: <BomLineItemBadge taskStatus={"new"} />, value: "new" },
    {
      label: <BomLineItemBadge taskStatus={"awaiting_stock"} />,
      value: "awaiting_stock",
    },
    {
      label: <BomLineItemBadge taskStatus={"partially_pulled"} />,
      value: "partially_pulled",
    },
    { label: <BomLineItemBadge taskStatus={"complete"} />, value: "complete" },
  ];

  const filters = [
    // {
    //   key: "taskStatus",
    //   label: "Status",
    //   filter: (
    //     <ChoiceList
    //       title="Status"
    //       titleHidden
    //       choices={statusOptions}
    //       selected={taskFilters.status || []}
    //       onChange={handleTaskStatusChange}
    //       allowMultiple
    //     />
    //   ),
    //   shortcut: true,
    // },

    {
      key: "taggedWith",
      label: "Tagged with",
      filter: (
        <>
          <Autocomplete
            allowMultiple
            options={options}
            selected={taskFilters.tags}
            textField={textField}
            // onSelect={setSelectedOptions}
            onSelect={handleTaggedWithChange}
            // listTitle="Suggested Tags"
            labelHidden
          />
          {/* <TextField
                        label="Tagged with"
                        value={taggedWith}
                        onChange={handleTaggedWithChange}
                        autoComplete="off"
                        labelHidden
                    /> */}
        </>
      ),
      shortcut: true,
    },
  ];

  function titleCase(string) {
    return string
      .toLowerCase()
      .split(" ")
      .map((word) => word.replace(word[0], word[0].toUpperCase()))
      .join("");
  }

  const appliedFilters = [];

  if (!isEmpty(taskFilters.status)) {
    const key = "taskStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taskFilters.status),
      onRemove: handleTaskStatusRemove,
    });
  }

  if (!isEmpty(taskFilters.tags)) {
    const key = "taggedWith";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taskFilters.tags),
      onRemove: handleTaggedWithRemove,
    });
  }

  const filterControl = (
    <Box paddingBlockEnd="4">
      <Filters
        queryValue={taskFilters.title}
        filters={filters}
        appliedFilters={appliedFilters}
        onQueryChange={handleQueryValueChange}
        onQueryClear={handleQueryValueRemove}
        onClearAll={handleClearAll}
      />
    </Box>
  );

  useEffect(() => {
    if (!isMount) {
      const x = searchBomLine({ coitem_id: coItemId, ...taskFilters });

      // setTasks
    }
  }, [taskFilters]);

  const sortOptions = {
    updatedAtDesc: { updated: "DESC" },
    updatedAtAsc: { updated: "ASC" },
  };
  return (
    <>
      <div
        css={css`
          .Polaris-IndexTable__Table {
            // table-layout: fixed;
          }
          .Polaris-IndexTable__StickyTableHeader {
            top: 56px;
          }
          .Polaris-IndexTable__Table--unselectable.Polaris-IndexTable__Table--sticky
            .Polaris-IndexTable__TableCell:first-child {
            white-space: normal;
          }
          .Polaris-IndexTable__Table--unselectable.Polaris-IndexTable__Table--sticky
            .Polaris-IndexTable__TableCell:first-child {
            width: 100%;
          }
        `}
      >
        {filterControl}
        <IndexTable
          resourceName={{ singular: "customer", plural: "customers" }}
          itemCount={tasks.length} // @createdbycocoon getting an error, something is not being passed down properly after i moved from firebase functions to node
          // selectedItemsCount={tasks ? "All" : tasks.length}
          // onSelectionChange={handleSelectionChange}
          selectable={false}
          headings={[
            { title: "Item" },
            { title: "Qty" },
            { title: "Qty Per" },
            { title: "Qty Total" },
          ]}
          // lastColumnSticky
        >
          {tasks.map((task, t) => {
            const { qty_pulled, qty_ord, qty_total, qty_per, id, task_id } =
              task;

            const itemPulled = async (quantityPulled) => {
              const response = await setItemPulledQty(
                id,
                task_id,
                quantityPulled
              );
              return response;
            };
            return (
              <IndexTable.Row>
                <IndexTable.Cell>
                  <BomLineItem task={task} />
                </IndexTable.Cell>

                <IndexTable.Cell>{task.qty_ord}</IndexTable.Cell>
                <IndexTable.Cell>{task.qty_per}</IndexTable.Cell>
                <IndexTable.Cell>
                  <Stack
                    //  distribution="trailing"
                    distribution="leading"
                    alignment={"center"}
                  >
                    <div
                      css={css`
                        margin-left: auto;
                      `}
                    >
                      {qty_pulled} pulled of {qty_total}
                    </div>
                    <BomLineItemAction
                      qty_total={qty_total}
                      qty_ord={qty_ord}
                      qty_per={qty_per}
                      qty_pulled={qty_pulled}
                      setPulledQty={itemPulled}
                    />
                  </Stack>
                </IndexTable.Cell>
              </IndexTable.Row>
            );
          })}
        </IndexTable>
      </div>

      {/* <ResourceList
        resourceName={resourceName}
        items={tasks}
        renderItem={(task) => <BomLineItem task={task} />}
        selectedItems={selectedItems}
        onSelectionChange={setSelectedItems}
        promotedBulkActions={promotedBulkActions}
        bulkActions={bulkActions}
        // sortValue={taskFilters.sort}
        // sortOptions={[
        //     { label: 'Latest pull', value: 'updatedAtDesc' },
        //     { label: 'Oldest pull', value: 'updatedAtAsc' },
        // ]}
        onSortChange={(selected) => {
          // setSortValue(selected);

          setTaskFilters((prevState) => ({
            ...prevState,
            sort: sortOptions[selected],
          }));
        }}
        filterControl={filterControl}
      /> */}
      <Box paddingBlockStart="4" paddingBlockEnd="4" paddingInlineStart="4">
        <Stack horizontal alignment="center">
          <Button size="" onClick={() => addNewTask(coItemId, coItemQty)}>
            Add Task
          </Button>
          <Text variant="bodySm">
            New tasks will not be added to the xTuple BOM.
          </Text>
        </Stack>
      </Box>
    </>
  );

  function renderItem(task, setCurrent, setOpen) {
    const {
      name,
      qty_total,
      qty_pulled,
      status,
      type,
      item_id,
      notes,
      item_descrip1,
      unique_id,
      id,
    } = task;

    return (
      <ResourceItem
        id={item_id}
        // url={url}
        // media={media}
        accessibilityLabel={`View details for ${name}`}
        // shortcutActions={shortcutActions}
        persistActions
        onClick={() => {
          setCurrent(id);
          setOpen(true);
        }}
      >
        <Stack alignment={"leading"} distribution={"fill"}>
          <Stack vertical spacing={"tight"}>
            <ItemBadge status={status} />
            <p
              css={css`
                font-size: 14px;
                font-weight: 600;
              `}
            >
              {name}
            </p>
            <Text>{item_descrip1}</Text>
          </Stack>
          <Stack distribution="trailing">
            <div
              css={css`
                margin-left: auto;
              `}
            >
              {qty_pulled} pulled of {qty_total}
            </div>
          </Stack>
        </Stack>
      </ResourceItem>
    );
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        // return `Tagged with ${value}`;
        return `Tagged with ${value.map(
          (i) => taskTags.filter((f) => f.value === i)[0].label
        )}`;
      case "taskStatus":
        return value.map((val) => mapStatusToLabel[val]).join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};

export default BomLine;
