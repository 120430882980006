import React from "react";
import { Page, Frame } from "@shopify/polaris";

function Body(props) {
  const { children, fullwidth } = props;

  return <Page fullWidth={fullwidth ? true : false}>{children}</Page>;
}

export default Body;
