import { useEffect, useState } from "react";
import { useFirebaseContext } from "./Firebase";
import qrcode from "qrcode";
import { Page } from "@shopify/polaris";

const AutoLogin = () => {
  const queryString = window.location.search;

  const FirebaseContext = useFirebaseContext();
  // const autoLogin = FirebaseContext.getFunction('autoLogin')
  const { reactFunction } = useFirebaseContext();
  const autoLogin = reactFunction("autoLogin");

  const autoLoginFunc = FirebaseContext.autoLoginFunc;

  const parseURL = () => {
    const r = {};
    queryString
      .substr(1)
      .split("&")
      .forEach((s) => {
        const ss = s.split("=");
        r[ss[0]] = ss[1];
      });
    return r;
  };

  const [urlParams] = useState(parseURL());
  const [qrCode, setQrCode] = useState("");

  useEffect(() => {
    // const urlForAutoLogin = (window.location.host === 'localhost:3000') ? 'http://localhost:5001/func/autoLogin' : '/func/autoLogin'

    qrcode.toDataURL(urlParams.kid).then((url) => setQrCode(url));

    // console.log(urlForAutoLogin)

    autoLogin(urlParams).then((reply) => {
      const loginToken = reply.data.login;
      loginToken && autoLoginFunc(loginToken);
    });
  }, []);

  return (
    <Page
      title="Auto Login"
      subtitle={
        "Uses device ID to automatiaclly login into Bock Production's website."
      }
    >
      {/*{JSON.stringify(urlParams)}*/}
      {/*{qrCode.length}*/}
      <hr />
      {<p>{urlParams && urlParams.kid}</p>}
      {qrCode.length !== 0 && <img src={qrCode} />}
    </Page>
  );
};

export default AutoLogin;
