import {
  Button,
  Card,
  Form,
  Label,
  Page,
  Select,
  TextField,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFirebaseContext } from "../../Firebase";

const AddCharacteristic = () => {
  const { itemID, fieldID = 0 } = useParams();

  const { reactFunction } = useFirebaseContext();
  const get_characteristicFieldOptions = reactFunction(
    "get_characteristicFieldOptions"
  );

  const [selected, setSelected] = useState(parseInt(fieldID));
  const [charCode, setCharCode] = useState("");
  const [charName, setCharName] = useState("");

  const [characteristicFieldOptions, setCharacteristicFieldOptions] = useState(
    []
  );

  useEffect(() => {
    get_characteristicFieldOptions(itemID).then((response) => {
      console.log(response);
      setCharacteristicFieldOptions(
        response.data.map((field) => ({
          label: field.char_name,
          value: parseInt(field.char_id),
        }))
      );
    });
  }, [itemID]);

  return (
    <Page>
      <Card sectioned>
        <Form>
          <Select
            label="Characteristic Field"
            value={selected}
            onChange={(value) => setSelected(parseInt(value))}
            options={characteristicFieldOptions}
          />
          <TextField label="Code" value={charCode} onChange={setCharCode} />
          <TextField label="Name" value={charName} onChange={setCharName} />
          <h1>
            {charCode} - {charName}
          </h1>
          <Button primary>Save</Button>
        </Form>
      </Card>
    </Page>
  );
};

export default AddCharacteristic;
